<template>
  <div class="my-courses" v-bind:class="isAll ? 'full-screen-container' : ''">
    <div class="layout">
      <div class="my-courses-block">
        <div
          v-for="course in calcCourses"
          :key="course.id"
          class="my-courses-item"
        >
          <div class="preview">
            <img :src="course.coverImage" :alt="course.title" />
          </div>
          <div class="content">
            <div class="content-description">
              <h3>{{ course.title }}</h3>
              <div class="content-progress">
                <div class="item-nums">
                  <ul>
                    <li
                      v-if="
                        course.lessonsCount !== 0 && 'lessonsCount' in course
                      "
                    >
                      <span>
                        {{ course.courseUser.completedLessonsCount }}/{{
                          course.lessonsCount
                        }}
                        уроков
                      </span>
                    </li>
                  </ul>
                </div>
                <div class="progress">
                  <div class="progress-buttons">
                    <router-link
                      class="content-btn"
                      :to="
                        new Date(course.startDate) > new Date()
                          ? {}
                          : {
                              name: 'lesson',
                              params: { id: course.id },
                            }
                      "
                    >
                      <btn
                        class="content-progress-btn primary"
                        :disabled="new Date(course.startDate) > new Date()"
                      >
                        <span>Смотреть</span>
                      </btn>
                    </router-link>
                    <btn
                      @click="showCourseInfo(course)"
                      class="content-description-btn secondary"
                    >
                      О курсе
                    </btn>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="my-courses-item my-courses-item-link" @click="$router.push('/my_courses')">
          <div class="preview preview-link">
            Смотреть все
            <img
              src="@/icons/ArrowRight.svg"
              style="margin-left: 8px"
            />
          </div>
        </div>
      </div>
      <div v-if="commons.length" class="my-courses-header">
        <h2 v-bind:class="isAll ? 'is-all-header' : 'courses-header'">
          Дополнительные блоки
        </h2>
        <div
          v-if="!isAll"
          class="show-all-div"
          @click="$router.push('/my_courses')"
        >
          Смотреть все<img
            src="@/icons/ArrowRight.svg"
            style="margin-left: 8px"
          />
        </div>
      </div>
      <div v-if="commons.length" class="my-courses-block">
        <div v-for="course in commons" :key="course.id" class="my-courses-item">
          <div class="preview">
            <img :src="course.coverImage" :alt="course.title" />
          </div>
          <div class="content">
            <div class="content-description">
              <h3>{{ course.title }}</h3>
              <div class="content-progress">
                <div class="item-nums">
                  <ul>
                    <li
                      v-if="
                        course.lessonsCount !== 0 && 'lessonsCount' in course
                      "
                    >
                      <span>
                        {{ course.courseUser.completedLessonsCount }}/{{
                          course.lessonsCount
                        }}
                        уроков
                      </span>
                    </li>
                  </ul>
                </div>
                <div class="progress">
                  <div class="progress-buttons">
                    <router-link
                      class="content-btn"
                      :to="
                        new Date(course.startDate) > new Date()
                          ? {}
                          : {
                              name: 'lesson',
                              params: { id: course.id },
                            }
                      "
                    >
                      <btn
                        class="content-progress-btn primary"
                        :disabled="new Date(course.startDate) > new Date()"
                      >
                        <span>Смотреть</span>
                      </btn>
                    </router-link>
                    <btn
                      @click="showCourseInfo(course)"
                      class="content-description-btn secondary"
                    >
                      О курсе
                    </btn>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <HomeWorkList v-if="$route.path !== '/my_courses'" />
    </div>
    <CourseModal
      :show="showModal"
      :course="selectedCourse"
      :isIntroCourseCompleted="isIntroCourseCompleted"
      :isCourseChosen="isCourseChosen"
      @close="showModal = !showModal"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import Btn from '@/components/Button';
import trackAmplitudeEvent from '@/utils/trackAmplitudeEvent';
import HomeWorkList from '../../components/HomeWorkList';
import CourseModal from '../../components/Course/modals/Course.vue';
import $globalFn from '../../fn';

export default {
  name: 'MyCourses',
  components: {
    Btn,
    HomeWorkList,
    CourseModal,
  },
  props: {
    isAll: { type: Boolean, default: true },
  },
  data() {
    return {
      showModal: false,
      selectedCourse: null,
      isIntroCourseCompleted: false,
      isCourseChosen: false,
    };
  },
  mounted() {
    this.fetchCommonCourses();
    const userId = $globalFn.ls.get('userId');
    trackAmplitudeEvent('courses_page_opened', userId);
  },
  computed: {
    ...mapGetters({
      courses: 'getMyCourses',
      units: 'getUnits',
      activity: 'getMyCourseActivity',
      commons: 'getCommons',
    }),
    calcCourses() {
      if (!this.isAll) return this.$store.state.isMobile ? this.courses.slice(0, 1) : this.courses.slice(0, 2);
      return this.courses;
    },
    additionalCourses() {
      const data = this.courses.filter((el) => el.common);
      if (!this.isAll) return data.slice(0, 3);
      return data;
    },
  },
  methods: {
    ...mapActions([
      'fetchCourseUnits',
      'fetchMyCourseActivity',
      'fetchCommonCourses',
    ]),
    ...mapMutations(['SET_PROGRAMS']),
    async showCourseInfo(value) {
      await this.fetchCourseUnits(value.id);
      try {
        await this.fetchMyCourseActivity(value.id);
      } catch (e) {
        console.log(e);
      }
      const userId = $globalFn.ls.get('userId');

      trackAmplitudeEvent('show_course_info', userId, { course_id: value.id });

      this.selectedCourse = value;
      this.selectedCourse.units = this.units;
      this.isIntroCourseCompleted = true;
      this.isCourseChosen = this.activity?.isMainCourse ?? false;
      this.showModal = true;
    },
    progressClasses(progress) {
      let color = null;
      if (progress < 50) {
        color = 'red';
      } else if (progress >= 50 && progress < 100) {
        color = 'orange';
      } else {
        color = 'green';
      }
      return color;
    },
  },
};
</script>

<style lang="scss" scoped>
.full-screen-container {
  min-height: 70vh;
}

.is-all-header {
  font-weight: 400;
  font-size: 32px;
  margin-top: 60px;
  font-family: 'Rubik', sans-serif;

  @media (max-width: $layout-s) {
    font-size: 24px;
    margin-top: 60px;
    margin-left: 20px;
  }
}

.my-courses {
  width: 90%;
  margin: 0 auto;

  .show-all-div {
    padding: 5px;
    float: right;
    color: $primary;
    font-size: 16px;
    font-weight: 400;
    font-family: 'Rubik', sans-serif;
    cursor: pointer;
  }

  .courses-header {
    font-weight: 400;
    float: left;
    margin-top: 30px;
    margin-bottom: 30px;
    font-size: 26px;
  }

  @media (max-width: $layout-s) {
    .courses-header {
      font-weight: 500;
      float: left;
      font-size: 20px;
    }
  }

  h3 {
    font-size: 14px;
    color: map-get($gray, 500);
    font-weight: normal;
    margin-top: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &-block {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 25px;
    margin-top: 37px;
  }

  &-item {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    position: relative;
    border: 1px solid map-get($gray, 400);
    border-radius: 20px;
    margin-bottom: 40px;

    &:hover .my-courses-item-overlay {
      display: flex;
      z-index: 9;
    }

    h3 {
      font-size: 18px;
      font-weight: 500;
      color: $dark;
    }

    .preview {
      position: relative;
      display: flex;
      justify-content: center;
      width: 100%;
      padding-bottom: 56.25%;
      overflow: hidden;

      img {
        position: absolute;
        object-fit: cover;
        width: 100%;
        height: 100%;
      }

      &-link {
        cursor: pointer;
        place-items: center;
        height: 100%;
        padding: 0;

        img{
          position: relative;
          width: 9px;
          height: 17px;
        }
      }

    }

    .item-label {
      margin-right: 5px;
      font-size: 14px;
      color: map-get($gray, 500);
    }

    .content {
      padding: 20px 20px;
      width: 100%;

      &-description {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &-btn {
          width: 90px;
          margin: 15px 0 auto;
          align-self: flex-end;
        }

        &-btn.secondary {
          width: 90px;
          background-color: $secondary;
          color: $primary;
          margin-bottom: auto;

          &:hover i {
            transform: translateX(4px);
          }

          & i {
            margin-left: 10px;
            transition: transform 0.2s ease-in-out;
          }
        }

        &-btn.secondary:hover {
          color: white;
        }
      }

      &-progress {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &-btn {
          margin-top: 15px;
          width: 100%;
          text-align: center;
        }

        &-btn.primary {
          width: 100%;
          background-color: $primary;
          color: $white;

          &:hover i {
            transform: translateX(4px);
          }

          & i {
            margin-left: 10px;
            transition: transform 0.2s ease-in-out;
          }
        }
      }
    }

    .item-nums {
      ul {
        display: flex;
        list-style: none;
        flex-wrap: wrap;

        li {
          //width: 50%;
          margin-right: 10px;
          display: flex;
          align-items: center;
          font-size: 14px;
          margin-top: 17px;

          span {
            color: rgba(149, 149, 149, 0.5);
          }

          i {
            margin-right: 5px;
          }
        }
      }
    }

    .progress {
      margin-top: 16px;

      &-label {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      &-buttons {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 15px;
      }

      &-start-course {
        font-size: 12px;
        font-weight: 500;
        color: $primary;
        user-select: none;
        cursor: pointer;
      }

      &-line {
        position: relative;
        width: 100%;
        height: 10px;
        border-radius: 3px;
        background-color: rgba(58, 125, 255, 0.1);
        margin-top: 15px;

        p {
          position: absolute;
          top: 0;
          border-radius: 3px;
          height: 100%;
          left: 0;
          display: block;
        }
      }

      &-percent {
        font-weight: bold;
        font-size: 12px;
      }

      &.red {
        .progress-percent {
          color: $danger;
        }

        .progress-line {
          p {
            background-color: $danger;
          }
        }
      }

      &.orange {
        .progress-percent {
          color: $yellow;
        }

        .progress-line {
          p {
            background-color: $yellow;
          }
        }
      }

      &.green {
        .progress-percent {
          color: $success;
        }

        .progress-line {
          p {
            background-color: $success;
          }
        }
      }
    }

    &-overlay {
      display: none;
      align-items: center;
      justify-content: center;
      position: absolute;
      width: 100%;
      height: 100%;
      background: rgba(30, 77, 220, 0.7);

      button {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        width: 56px;
        height: 56px;
        background-color: $white;
        border: none;
        outline: none;
        cursor: pointer;
        color: $primary;
      }
    }

    &-link {
      background: #CCDDFF;
      color: #1C77FF;
    }
  }
}

.my-courses-block {
  width: 100%;
}

@media screen and (min-width: $layout-m) and (max-width: $layout-l) {
  .my-courses-item-link{
      padding: 12px 18px;
      border-radius: 15px;
      min-height: 320px;
  }
}

@media screen and (max-width: $layout-s) {
  .my-courses {
    margin-top: 30px;
  }

  .my-courses-item {
    flex-direction: column;
    height: unset;
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    margin-bottom: 0;

    &-link{
      padding: 12px 18px;
      border-radius: 15px;
    }
  }

  .my-courses-item .preview {
    width: 100%;
    max-height: 250px;
  }
}
</style>
