<template>
  <modal :show="show" :closable="false">
    <div class="loader-overlay">
      <loader :text="text" />
    </div>
  </modal>
</template>

<script>
import Loader from '@/components/Loader';
import Modal from '@/components/Modal';

export default {
  name: 'LoaderOverlay',
  components: { Loader, Modal },
  props: {
    show: { type: Boolean, default: false },
    text: { type: String, default: '' },
  },
};
</script>
