<template>
  <router-link :to="{name: 'webinar-room', params: { id: webinar.id } }" class="webinar-card">
    <div class="webinar-card-img">
      <img :src="webinar.image" alt="Вебинар">
    </div>
    <div class="webinar-card-content">
      <div class="webinar-card-header">
        <span v-if="webinar.availableFor === 2" class="webinar-card-meta">
          <i class="fas fa-star" />Близким друзьям
        </span>
        <span v-else class="webinar-card-meta available-all">
          Доступно всем
        </span>
        <span v-if="!isPast">
          <span v-if="webinar.isRegistered" class="webinar-card-status registered">
          Вы зарегистрированы на вебинар
          <i class="fas fa-check-circle" />
        </span>
        <span v-else class="webinar-card-status">
          Зарегистрироваться на вебинар
          <i class="far fa-circle" />
        </span>
        </span>
      </div>
      <div class="webinar-card-category">Вебинар</div>
      <h1 class="webinar-card-title">
        {{ webinar.title }}
      </h1>
      <p class="webinar-card-subtitle">
        {{ webinar.description }}
      </p>
      <div class="webinar-card-footer">
        <div class="webinar-card-date">
          <span class="date-icon"><i class="fas fa-calendar-alt"></i></span>
          <div class="date-text">
            <p>Дата трансляции</p>
            <span>
              <b>{{ webinar.broadcastAt | hour }}</b> {{ webinar.broadcastAt | dateFormat }}
            </span>
          </div>
        </div>
<!--        <div v-if="webinar.speakers.length === 0" class="speaker">-->
<!--          <div class="speaker-img">-->
<!--            <img :src="webinar.speakers[0].image" alt="/">-->
<!--          </div>-->
<!--          <div class="speaker-text">-->
<!--            <span>Спикер</span>-->
<!--            <p>{{ webinar.speakers[0].firstName }} {{ webinar.speakers[0].firstName }}</p>-->
<!--          </div>-->
<!--        </div>-->
        <div class="speakers">
          <span>Спикеры:</span>
          <img v-for="speaker in webinar.speakers"
               :key="speaker.id"
               :src="speaker.image"
               alt="/"
          >
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>
import $dayjs from '@/plugins/dayjs';

export default {
  name: 'Webinarcard',
  props: {
    webinar: { type: Object, default: () => ({}) },
    isPast: { type: Boolean, default: false },
  },
  filters: {
    dateFormat(date) {
      return $dayjs(date).format('DD/MM/YYYY');
    },
    hour(date) {
      return $dayjs(date).format('hh:mm');
    },
  },
};
</script>

<style lang="scss" scoped>
.webinar-card {
  margin: 40px 0;
  display: flex;
  width: 100%;
  color: $dark;
  text-decoration: none;
  background-color: $white;
  border-radius: 4px;
  &-img {
    height: 220px;
    width: 40%;
    min-width: 40%;
    overflow: hidden;
    img {
      max-width: 100%;
      object-fit: cover;
    }
  }
  &-content {
    flex-grow: 1;
    padding: 16px 16px 20px 30px;
  }
  &-header {
    margin-bottom: 17px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }
  &-meta {
    margin-bottom: 10px;
    padding: 4px 10px;
    font-weight: 500;
    font-size: 11px;
    background-color: $success;
    border-radius: 2px;
    color: $white;
    i {
      margin-right: 10px;
    }
    &.available-all {
      background-color: $primary;
    }
  }
  &-status {
    color: $primary;
    font-weight: 500;
    text-decoration: none;
    font-size: 16px;
    i {
      color: $primary;
    }
    &.registered {
      color: map-get($gray, 700);
      font-weight: 400;
    }
  }
  &-category {
    margin-bottom: 10px;
    color: map-get($gray, 500);
    font-size: 14px;
  }
  &-title {
    margin-bottom: 5px;
    width: 100%;
    font-weight: 500;
    font-size: 20px;
    word-break: break-all;
    //white-space: nowrap;
    //overflow: hidden;
    //text-overflow: ellipsis;
  }
  &-subtitle {
    font-size: 14px;
    margin-bottom: 25px;
  }
  &-footer {
    display: flex;
    flex-wrap: wrap;
  }
  &-date {
    display: flex;
    margin-right: 35px;
    margin-bottom: 10px;
  }
  .date-icon {
    margin-right: 15px;
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $primary;
    color: $white;
    border-radius: 50%;
  }
  .date-text {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    font-size: 14px;
    p {
      color: map-get($gray, 500);
    }
  }
  .speaker {
    display: flex;
    &-img {
      margin-right: 15px;
      width: 44px;
      height: 44px;
      background-color: map-get($gray, 300);
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 42px;
        height: 42px;
        object-fit: cover;
        border-radius: 50%;
      }
    }
    &-text {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      font-size: 14px;
      span {
        color: map-get($gray, 500);
      }
    }
  }
}

.speakers {
  display: flex;
  align-items: center;
  span {
    margin-right: 10px;
  }
  img {
    margin-right: -15px;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    border: 1px solid map-get($gray, 200);
    object-fit: cover;
  }
}
@media screen and (max-width: $layout-s) {
  .webinar-card {
    flex-direction: column;
    &-img {
      width: 100%;
    }
    &-content {
      flex-direction: column;
    }
    &-header {
      flex-direction: column;
      align-items: flex-start;
    }
    &-status {
      font-size: 14px;
    }
  }
}
</style>
