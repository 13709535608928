import 'vue-range-slider/dist/vue-range-slider.css';
import 'vue-select/dist/vue-select.css';
import 'video.js/dist/video-js.css';

import Vue from 'vue';
// import * as Sentry from '@sentry/vue';
// import { Integrations } from '@sentry/tracing';
import Vuelidate from 'vuelidate';
import vSelect from 'vue-select';
import VueExpandableImage from 'vue-expandable-image';
import VueVideoPlayer from 'vue-video-player';
import Datepicker from 'vuejs-datepicker';
import RangeSlider from 'vue-range-slider';
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

import SelectProgram from '@/components/SelectProgram';

import dayjs from './plugins/dayjs';
import App from './App.vue';
import router from './router';
import store from './store';
import directives from './directives';
import api from './api';
import fn from './fn';
import vars from './vars';
import UiIcon from './icons';

import './registerServiceWorker';
import './amlitude';

Vue.config.productionTip = false;

Vue.config.silent =
  process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'staging';

Vue.prototype.$api = api;
Vue.prototype.$fn = fn;
Vue.prototype.$vars = vars;
Vue.prototype.$date = dayjs;

Vue.use(directives);
Vue.use(Vuelidate);
Vue.use(VueExpandableImage);
Vue.use(VueVideoPlayer, {});
Vue.component('vue-phone-number-input', VuePhoneNumberInput);
Vue.component('range-slider', RangeSlider);
Vue.component('ui-icon', UiIcon);
Vue.component('v-select', vSelect);
Vue.component('date-picker', Datepicker);
Vue.component('select-program', SelectProgram);

// let tracingOrigins = [];
// if (process.env.NODE_ENV === 'production') {
//   tracingOrigins = ['theflow.online', /^\//];
// } else {
//   tracingOrigins = [];
// }
// Sentry.init({
//   Vue,
//   logErrors: process.env.NODE_ENV === 'development',
//   environment: process.env.NODE_ENV,
//   dsn: 'https://fac19bc3c76c4640b0032932947a7527@o951238.ingest.sentry.io/5904262',
//   integrations: [
//     new Integrations.BrowserTracing({
//       routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//       tracingOrigins,
//     }),
//   ],
//   tracesSampleRate: process.env.NODE_ENV === 'development' ? 0.1 : 0.01,
// });

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
