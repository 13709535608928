<template>
  <footer class="footer">
    <div class="layout">
      <div class="footer-links-wrapper">
        <div class="footer-links">
          <!--          <div>
            <router-link to="/" style="text-decoration: none">
              <div class="footer-logo">The Flow</div>
            </router-link>
          </div>-->
          <div
            v-for="item in items"
            :key="item.heading"
            :class="[
              'footer-links-column',
              {
                'footer-links-column-no-header': !item.heading,
              },
            ]"
          >
            <div class="footer-links-heading">{{ item.heading }}</div>
            <ul>
              <li v-for="child in item.children" :key="child.name">
                <router-link
                  tag="a"
                  @click.native="scrollToTop(child)"
                  :to="{ name: child.route ? $route.name : child.link }"
                  >{{ child.name }}</router-link
                >
              </li>
            </ul>
          </div>
          <div>
            <div class="footer-links-heading">Следите за нами</div>
            <div class="footer-soc">
              <a
                @click="trackTelegramEvent"
                :href="this.isMemberOfSova ? 'https://t.me/+osNYWsiex-o5YjEy' : 'https://t.me/flowkz'"
                target="_blank"
                class="footer-soc-item"
              >
                <ui-icon name="telegram-flat" color="black" />
              </a>
              <a
                @click="trackInstagramEvent"
                :href="this.isMemberOfSova ? 'https://instagram.com/sova.ed/' : 'https://instagram.com/theflow.online/'"
                target="_blank"
                class="footer-soc-item"
              >
                <ui-icon name="instagram-flat" color="black" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="footer-copyright">
        © Все права защищены 2020 - {{ new Date().getFullYear() }}
      </div>
    </div>
  </footer>
</template>

<script>
import trackAmplitudeEvent from '@/utils/trackAmplitudeEvent';
import $globalFn from '../fn';

export default {
  name: 'Footer',
  data() {
    const isMemberOfSova = $globalFn.ls.get('is_member_of_sova') === 'true';
    return {
      items: [
        {
          heading: 'Документация',
          children: [
            {
              name: 'Правила пользования',
              eventName: 'terms_of_use_click',
              link: this.$vars.files.personalDataAgreement,
              route: this.$vars.files.personalDataAgreement,
            },
            {
              name: 'Публичная оферта',
              eventName: 'public_offer_click',
              link: this.$vars.files.offerAgreement,
              route: this.$vars.files.offerAgreement,
            },
            // {
            //   name: 'Публичная оферта "Наставничество"',
            //   eventName: 'public_offer_mentoring_click',
            //   link: this.$vars.files.offerAgreementMentoring,
            //   route: this.$vars.files.offerAgreementMentoring,
            // },
            // {
            //   name: 'Информация об онлайн платежах',
            //   link: 'about-payment',
            // },
          ],
        },
        {
          heading: 'Техническая поддержка',
          children: [
            {
              name: 'Телеграм бот',
              eventName: 'telegram_support_click',
              link: isMemberOfSova ? this.$vars.files.telegramBot.sova : this.$vars.files.telegramBot.flow,
              route: isMemberOfSova ? this.$vars.files.telegramBot.sova : this.$vars.files.telegramBot.flow,
            },
          ],
        },
        // {
        //   heading: 'FAQ',
        //   children: [
        //     // {
        //     //   name: 'Чат с поддержкой',
        //     //   eventName: 'footer_faq_support_chat_click',
        //     //   link: this.$vars.files.personalDataAgreement,
        //     //   route: this.$vars.files.personalDataAgreement,
        //     // },
        //     {
        //       name: 'Вопросы и ответы',
        //       eventName: 'footer_faq_qa_click',
        //       link: this.$vars.files.offerAgreement,
        //       route: this.$vars.files.offerAgreement,
        //     },
        //     {
        //       name: 'Список вопросов',
        //       eventName: 'footer_faq_questions_list_click',
        //       link: this.$vars.files.offerAgreement,
        //       route: this.$vars.files.offerAgreement,
        //     },
        //   ],
        // },
      ],
      isMemberOfSova,
    };
  },
  async mounted() {
    const curriculum = await this.$api.courses.getCurriculum();
    const isMemberOfSova = curriculum.data.some((program) => program.title.includes('SOVA') || program.id === '6555ed683ecf832bcc1e10ea');
    $globalFn.ls.set('is_member_of_sova', isMemberOfSova);
  },
  methods: {
    scrollToTop(item) {
      if (item.route) {
        const userId = $globalFn.ls.get('userId');
        trackAmplitudeEvent(item.eventName, userId, { date: new Date() });
        window.open(item.route, '_blank');
        return;
      }
      this.$fn.window.scrollToTop();
    },
    trackTelegramEvent() {
      const userId = $globalFn.ls.get('userId');

      trackAmplitudeEvent('telegram_follow_us_click', userId, {
        date: new Date(),
      });
    },
    trackInstagramEvent() {
      const userId = $globalFn.ls.get('userId');

      trackAmplitudeEvent('instagram_follow_us_click', userId, {
        date: new Date(),
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.footer {
  margin-top: auto;
  background: map-get($gray, 1000);
  padding: 40px 20px;

  .footer-logo a {
    text-decoration: none;
    color: inherit;
  }

  &-links {
    display: flex;
    margin-top: 55px;
    gap: 40px;
    justify-content: space-between;

    @media screen and (max-width: $layout-m) {
      flex-wrap: wrap;
    }

    &-wrapper {
      @media screen and (max-width: $layout-l) {
        flex-direction: column;
      }
    }

    &-heading {
      font-size: 1.125rem;
      color: $deep-black;
      font-weight: bold;
      height: 21px;
      margin-bottom: 35px;
      white-space: nowrap;

      @media screen and (max-width: $layout-m) {
        margin-top: 20px;
      }
    }

    &-column {
      &-no-header {
        @media screen and (max-width: $layout-s) {
          .footer-links-heading {
            display: none;
          }
        }
      }
    }

    ul {
      list-style: none;

      li {
        margin-bottom: 20px;
        white-space: nowrap;

        a {
          color: map_get($gray, 800);
          text-decoration: none;
        }

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  &-copyright {
    font-weight: 200;
    text-align: right;
    margin-top: 50px;
    color: map_get($gray, 800);

    @media screen and (max-width: $layout-xs) {
      text-align: center;
    }
  }

  &-soc {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: max-content;
    margin-top: 55px;

    @media screen and (max-width: $layout-xs) {
      justify-content: center;
    }

    &-item {
      margin-left: 20px;

      @media screen and (max-width: $layout-xs) {
        &:first-child {
          margin-left: 0;
        }
      }
    }
  }

  &-logo {
    background: #3a7dff;
    border-radius: 10px;
    color: $white;
    padding: 12px 24px;
    font-weight: bold;
  }
}

@media (max-width: $layout-s) {
  .footer-links-column {
    width: 100%;
  }
}

@media (max-width: $layout-m) {
  .footer-links-column {
    width: 40%;
  }
}

@media (max-width: $layout-xs) {
  .footer-links-column {
    width: 100%;
  }
}

.footer-copyright {
  width: 100%;
  text-align: center;
}
</style>
