<template>
  <div class="home">
    <section v-if="courses">
      <h1 class="home--welcome-title">
        Добро пожаловать, <mark>{{ user.profile.firstName }}</mark>
      </h1>
      <carousel />
      <my-courses :is-all="false" />
    </section>
    <section v-else style="height: 800px">
      <Loader :text="'Loading...'" style="position: relative; top: 50%" />
    </section>
    <first-auth-modal v-if="firstAuthModal.show" />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import Carousel from '@/components/Main/Carousel';
import MyCourses from '@/views/MyCourses';
import Loader from '@/components/Loader';
import FirstAuthModal from '@/components/Auth/FirstAuthModal';
import identifyAmplitudeEvent from '../../utils/identifyAmplitudeEvent';
import trackAmplitudeError from '../../utils/trackAmplitudeError';
import $globalFn from '../../fn';
import trackAmplitudeEvent from '../../utils/trackAmplitudeEvent';

export default {
  name: 'Home',
  components: { FirstAuthModal, Carousel, MyCourses, Loader },
  computed: {
    ...mapGetters({
      user: 'getUser',
      courses: 'getMyCourses',
      firstAuthModal: 'getFirstAuthModal',
    }),
  },
  async mounted() {
    try {
      const userId = $globalFn.ls.get('userId');
      const curriculum = await this.$api.courses.getCurriculum();
      const { data } = curriculum;
      this.SET_PROGRAMS(data);
      const availableCurriculumIds = data.map((el) => el.id);
      trackAmplitudeEvent('fetch_user_curriculum_list', userId, {
        user_curriculums: availableCurriculumIds,
      });
      identifyAmplitudeEvent('user_curriculums', availableCurriculumIds);
      if (curriculum.data.length === 1) {
        this.$fn.ls.set('program', data[0].id);
        await this.fetchMyCourses(this.$fn.ls.get('program'));
        identifyAmplitudeEvent('last_selected_curriculum', data[0].id);
      }
      if (this.$fn.ls.get('program')) {
        await this.fetchMyCourses(this.$fn.ls.get('program'));
      }
      this.programDataReady = true;
    } catch (error) {
      const userId = $globalFn.ls.get('userId');
      trackAmplitudeError('fetch_user_curriculum_list', userId, { error });
    }
  },
  watch: {
    selectedProgram() {
      if (this.selectedProgram !== 'undefined') {
        this.fetchMyCourses(this.selectedProgram);
      }
    },
  },
  data() {
    return {};
  },
  methods: {
    ...mapMutations(['SET_PROGRAMS']),
    ...mapActions(['fetchMyCourses']),
  },
};
</script>

<style lang="scss">
.home--welcome-title {
  display: block;
  font-weight: 400;
  font-size: 32px;
  font-family: 'Rubik', sans-serif;
  color: rgba(0, 0, 0, 0.36);
  margin-top: 55px;
  margin-left: 100px;

  @media (max-width: $layout-s) {
    font-size: 20px;
    margin-top: 60px;
    margin-left: 20px;
  }

  mark {
    color: $deep-black;
    background: none;
  }
}
</style>
