var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"my-courses",class:_vm.isAll ? 'full-screen-container' : ''},[_c('div',{staticClass:"layout"},[_c('div',{staticClass:"my-courses-block"},[_vm._l((_vm.calcCourses),function(course){return _c('div',{key:course.id,staticClass:"my-courses-item"},[_c('div',{staticClass:"preview"},[_c('img',{attrs:{"src":course.coverImage,"alt":course.title}})]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"content-description"},[_c('h3',[_vm._v(_vm._s(course.title))]),_c('div',{staticClass:"content-progress"},[_c('div',{staticClass:"item-nums"},[_c('ul',[(
                      course.lessonsCount !== 0 && 'lessonsCount' in course
                    )?_c('li',[_c('span',[_vm._v(" "+_vm._s(course.courseUser.completedLessonsCount)+"/"+_vm._s(course.lessonsCount)+" уроков ")])]):_vm._e()])]),_c('div',{staticClass:"progress"},[_c('div',{staticClass:"progress-buttons"},[_c('router-link',{staticClass:"content-btn",attrs:{"to":new Date(course.startDate) > new Date()
                        ? {}
                        : {
                            name: 'lesson',
                            params: { id: course.id },
                          }}},[_c('btn',{staticClass:"content-progress-btn primary",attrs:{"disabled":new Date(course.startDate) > new Date()}},[_c('span',[_vm._v("Смотреть")])])],1),_c('btn',{staticClass:"content-description-btn secondary",on:{"click":function($event){return _vm.showCourseInfo(course)}}},[_vm._v(" О курсе ")])],1)])])])])])}),_c('div',{staticClass:"my-courses-item my-courses-item-link",on:{"click":function($event){return _vm.$router.push('/my_courses')}}},[_vm._m(0)])],2),(_vm.commons.length)?_c('div',{staticClass:"my-courses-header"},[_c('h2',{class:_vm.isAll ? 'is-all-header' : 'courses-header'},[_vm._v(" Дополнительные блоки ")]),(!_vm.isAll)?_c('div',{staticClass:"show-all-div",on:{"click":function($event){return _vm.$router.push('/my_courses')}}},[_vm._v(" Смотреть все"),_c('img',{staticStyle:{"margin-left":"8px"},attrs:{"src":require("@/icons/ArrowRight.svg")}})]):_vm._e()]):_vm._e(),(_vm.commons.length)?_c('div',{staticClass:"my-courses-block"},_vm._l((_vm.commons),function(course){return _c('div',{key:course.id,staticClass:"my-courses-item"},[_c('div',{staticClass:"preview"},[_c('img',{attrs:{"src":course.coverImage,"alt":course.title}})]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"content-description"},[_c('h3',[_vm._v(_vm._s(course.title))]),_c('div',{staticClass:"content-progress"},[_c('div',{staticClass:"item-nums"},[_c('ul',[(
                      course.lessonsCount !== 0 && 'lessonsCount' in course
                    )?_c('li',[_c('span',[_vm._v(" "+_vm._s(course.courseUser.completedLessonsCount)+"/"+_vm._s(course.lessonsCount)+" уроков ")])]):_vm._e()])]),_c('div',{staticClass:"progress"},[_c('div',{staticClass:"progress-buttons"},[_c('router-link',{staticClass:"content-btn",attrs:{"to":new Date(course.startDate) > new Date()
                        ? {}
                        : {
                            name: 'lesson',
                            params: { id: course.id },
                          }}},[_c('btn',{staticClass:"content-progress-btn primary",attrs:{"disabled":new Date(course.startDate) > new Date()}},[_c('span',[_vm._v("Смотреть")])])],1),_c('btn',{staticClass:"content-description-btn secondary",on:{"click":function($event){return _vm.showCourseInfo(course)}}},[_vm._v(" О курсе ")])],1)])])])])])}),0):_vm._e(),(_vm.$route.path !== '/my_courses')?_c('HomeWorkList'):_vm._e()],1),_c('CourseModal',{attrs:{"show":_vm.showModal,"course":_vm.selectedCourse,"isIntroCourseCompleted":_vm.isIntroCourseCompleted,"isCourseChosen":_vm.isCourseChosen},on:{"close":function($event){_vm.showModal = !_vm.showModal}}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"preview preview-link"},[_vm._v(" Смотреть все "),_c('img',{staticStyle:{"margin-left":"8px"},attrs:{"src":require("@/icons/ArrowRight.svg")}})])
}]

export { render, staticRenderFns }