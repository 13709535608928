<template>
  <div v-if="filteredCourses.length" class="content">
    <div>
      <p class="stage-secondary position">2 этап</p>
      <p class="header-text position">Выбери связку</p>
      <div class="professions-container">
        <div v-for="course in filteredCourses" :key="course.id" class="profession-td profession-position">
          <table class="profession-container first">
            <tr>
              <td class="first-part">
                <p class="progression-text">Связка</p>
                <p class="title-text">{{ course.title }}</p>
                <p class="description">
                  Видеоуроки / Практические задания / Тесты / Вебинары
                </p>
                <p class="link-text" @click="showCourseInfo(course)">
                  Подробнее
                </p>
              </td>
              <td class="second-part">
                <img class="cover-image" :src="course.coverImage" />
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <CourseModal :show="showModal" :course="selectedCourse" :isIntroCourseCompleted="isIntroCourseCompleted"
      :isCourseChosen="isCourseChosen" @close="showModal = !showModal" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import CourseModal from './modals/Course.vue';

export default {
  name: 'choose-profession-component',
  components: {
    CourseModal,
  },
  data() {
    return {
      showModal: false,
      selectedCourse: null,
      isIntroCourseCompleted: false,
      isCourseChosen: false,
    };
  },
  computed: {
    ...mapGetters({
      courses: 'getMyCourses',
      units: 'getUnits',
      activity: 'getMyCourseActivity',
    }),
    filteredCourses() {
      return this.courses.filter((el) => el.type === 'REQUIRED')
    }
  },
  methods: {
    ...mapActions(['fetchCourseUnits', 'fetchMyCourseActivity']),
    async showCourseInfo(value) {
      await this.fetchCourseUnits(value.id);
      try {
        await this.fetchMyCourseActivity(value.id);
      } catch (e) {
        console.log(e);
      }
      this.selectedCourse = value;
      this.selectedCourse.units = this.units;
      this.isIntroCourseCompleted = true;
      this.isCourseChosen = this.activity?.isMainCourse ?? false;
      this.showModal = true;
    },
    // openCourseData(course) {
    //   App.methods.openCourseData(course);
    // },
  },
};
</script>

<style lang="scss" scoped>
.content {
  width: 100%;
  margin-top: 32px;
  text-align: start;
}

.header-text {
  color: black;
  font-size: 30px;
  font-family: Rubik, sans-serif;
  margin-left: 24px;
}

.stage-secondary {
  background-color: #3a7dff1a;
  color: #3a7dff;
  border-radius: 10px;
  width: max-content;
  font-size: 14px;
  padding: 5px 20px;
  margin-bottom: 24px;
  font-family: Rubik, sans-serif;
}

.position {}

tr,
td {
  height: 1px;
}

@-moz-document url-prefix() {

  tr,
  td {
    height: 100%;
  }
}

.professions-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.progression-text {
  color: white;
  font-size: 18px;
  line-height: 30px;
  font-weight: 300;
}

.link-text {
  margin-top: 8px;
  color: rgba(255, 255, 255, 0.8);
  font-size: 15px;
  width: max-content;
  line-height: 27px;
  font-family: Rubik, sans-serif;
  font-weight: 300;
  cursor: pointer;
  padding: 5px;
}

.title-text {
  margin-top: 14px;
  color: white;
  font-size: 24px;
  line-height: 42px;
  font-family: Rubik, sans-serif;
  font-weight: 500;
}

.description {
  margin-top: 9px;
  color: white;
  font-weight: 400;
  width: 80%;
  font-size: 15px;
  line-height: 27px;
  font-family: Rubik, sans-serif;
}

.first-part {
  width: 70%;
  text-align: start;
}

.second-part {
  width: 30%;
  text-align: end;
}

.cover-image {
  width: 100%;
}

.profession-container {
  width: 100%;
  background-color: #416bff;
  border-radius: 22px;
  padding: 25px 35px;
}

.first {
  margin-right: 20px;
  margin-bottom: 10px;
  background-color: #416bff;
  min-height: 100%;
}

.second {
  margin-left: 20px;
  margin-bottom: 10px;
  background-color: #8941ff;
}

.third {
  margin-right: 20px;
  margin-top: 10px;
  background-color: #ff6a3a;
}

.fourth {
  margin-left: 20px;
  margin-top: 10px;
  background-color: #ffae50;
}

.profession-td {
  max-width: 48%;
  min-height: 200px;
  margin: 10px;
}

.profession-position {}

@media screen and (max-width: $layout-s) {
  .profession-td {
    max-width: 100%;
    min-height: 200px;
    margin: 10px;
  }

  .professions-container {
    width: 100%;
  }

  .first {
    margin: 0 auto;
    width: 90%;
  }

  .profession-td {
    width: 100%;
  }

  .header-text {
    font-weight: 500;
    font-size: 20px;
    margin-left: 8px;
  }

  .title-text {
    font-size: 17px;
    font-weight: 600;
  }

  .first-part {
    width: 100%;
  }

  .description {
    width: 100%;
  }

  .second-part {
    display: none;
  }

  .first {
    margin-top: 5px;
  }
}</style>
