import axios from 'axios';
import $api from '@/api';
import $globalFn from '../../fn';

const defaultState = {
  lesson: null,
  id: String || Number,
  title: String,
  position: Number,
  isActive: Boolean,
  playlist: String,
  quizzes: [],
  glossary: {
    id: String || Number,
    entries: [],
  },
  attachments: [
    {
      id: Number || String,
      type: String,
      title: String,
      file: String,
    },
  ],
};
export default {
  state: {
    data: [],
    currentLessonIndex: 1,
    activity: null,
    lessonInfo: null,
  },
  getters: {
    getLesson(state) {
      return state.data;
    },
    getAssignments(state) {
      return state.data ? state.data.assignments : [];
    },
    getCurrentLessonIndex(state) {
      return state.currentLessonIndex;
    },
    getLessonActivity(state) {
      return state.activity;
    },
    getLessonInfo(state) {
      return state.lessonInfo;
    },
  },
  actions: {
    initLesson({ commit }, lessonId) {
      if (lessonId !== null) {
        $api.lesson
          .getLesson({ id: lessonId })
          .then((r) => {
            if (r.data.quizzes && r.data.quizzes.length > 0) {
              $api.quiz.get(r.data.quizzes[0]).then((res) => {
                r.data.quizzes[0] = res.data;
                commit('setLessonData', r.data);
              });
            } else {
              commit('setLessonData', r.data);
            }
          })
          .catch((e) => console.log(e));
      }
    },
    async initLessonInfo({ commit }, id) {
      if (!id) {
        return;
      }
      const res = await $api.lesson.getLessonInfo(id);
      commit('setLessonInfo', res.data);
    },
    initLessonActivity({ commit }, id) {
      $api.lesson
        .getLessonActivity(id)
        .then((res) => {
          commit('setLessonActivity', res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // eslint-disable-next-line no-unused-vars
    sendLessonActivity({ commit }, lesson) {
      if (!lesson?.id) {
        return;
      }
      $api.lesson.postLessonActivity(lesson).catch((er) => {
        console.log(er);
      });
    },
    loadCourseBlocks({ commit }, courseId) {
      $api.course.getBlocks({ id: courseId }).then((r) => {
        commit('setLessonBlocks', r.data);
      });
    },
    loadLesson({ commit }, lessonId) {
      axios
        .get(
          `${process.env.VUE_APP_SERVICE_CURRICULUM}/api/curriculum/lesson/${lessonId}`,
          {
            headers: {
              Authorization: `Bearer ${$globalFn.ls.get('auth_token')}`,
            },
          }
        )
        .then((r) => {
          commit('setLessonData', r.data);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    resetLesson({ commit }) {
      commit('setLessonData', defaultState.data);
    },
    enableLessonNoteEditing({ commit }, payload) {
      commit('setLessonNoteIsEdit', true);
      commit('setLessonNoteEditId', payload.noteId);
      commit('setLessonNoteCurrentValue', payload.value);
    },
    beforeLessonChange({ dispatch }, lessonId) {
      dispatch('resetLesson');
      dispatch('initLesson', lessonId);
      dispatch('loadLesson', lessonId);
    },
    cancelLessonNoteEdit({ commit }) {
      commit('setLessonNoteIsEdit', false);
      commit('setLessonNoteEditId', 0);
      commit('setLessonNoteCurrentValue', '');
    },
    initCurrentLessonIndex({ commit }, value) {
      commit('setCurrentLessonIndex', value);
    },
  },
  mutations: {
    setLessonInfo(state, value) {
      state.lessonInfo = value;
    },
    setLessonActivity(state, value) {
      state.activity = value;
    },
    setCurrentLessonIndex(state, value) {
      state.currentLessonIndex = value;
    },
    setLessonVideo(state, value) {
      state.playlist = value;
    },
    setLessonBlocks(state, value) {
      state.blocks = value;
    },
    setLessonData(state, value) {
      state.data = value;
    },
    setLessonNoteActiveType(state, value) {
      state.notes.activeType = value;
    },
    setLessonNotes(state, value) {
      state.notes.list = value;
    },
    setLessonNoteIsEdit(state, value) {
      state.notes.isEdit = value;
    },
    setLessonNoteEditId(state, value) {
      state.notes.editId = value;
    },
    setLessonNoteCurrentValue(state, value) {
      state.notes.currentValue = value;
    },
    setLessonHomework(state, value) {
      state.homework = value;
    },
    setLessonAttachments(state, value) {
      state.attachments = value;
    },
    setLessonTerms(state, value) {
      state.entries = value;
    },
    setLessonUser(state, value) {
      state.lessonUser = value;
    },
    setReview(state, value) {
      state.review = value;
    },
  },
};
