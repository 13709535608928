<template>
  <div>
    <loader v-if="showLoading" />
    <div v-else-if="certificates.length !== 0" class="row">
      <div
        class="col-75 certificate-box"
        v-for="item in certificates"
        :key="item.id"
      >
        <p class="cert-title">{{ item.title }}</p>
        <button @click="downloadCertificate(item)" class="save-pdf__btn">
          <a type="download" :href="item.certificate">Скачать PDF</a>
        </button>
      </div>
    </div>
    <div v-else class="row">
      <p class="no-cert">Сертификаты еще не доступны</p>
    </div>
  </div>
</template>

<script>
import Loader from '@/components/Loader';
import $globalFn from '../../fn';
import trackAmplitudeEvent from '../../utils/trackAmplitudeEvent';

export default {
  name: 'File',
  components: {
    Loader,
  },
  data() {
    return {
      showLoading: true,
      certificates: {},
      warning: false,
    };
  },
  created() {
    this.checkCertificates();
  },
  mounted() {
    const userId = $globalFn.ls.get('userId');
    trackAmplitudeEvent('certificate_page_opened', userId);
  },
  methods: {
    async checkCertificates() {
      const res = await this.$api.user.getCertificates();
      this.certificates = res.data;
      this.showLoading = false;
    },
    downloadCertificate(certificate) {
      const userId = $globalFn.ls.get('userId');
      const { id, title, certificate: link } = certificate;
      trackAmplitudeEvent('certificate_download', userId, {
        certificate_id: id,
        certificate_title: title,
        certificate_link: link,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.no-cert {
  text-align: center;
}

.certificate-box {
  background-color: #f8f8f8;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 30px;
}

.warning {
  color: red;
}

.save-pdf__btn {
  display: flex;
  align-items: center;
  border-radius: 10px;
  border-color: #3a7dff;
  font-size: 18px;
  font-weight: 300;
  padding: 10px;

  a {
    text-decoration: none;
  }

  &:disabled {
    color: #8e8e8e;
    border: 1px solid #8e8e8e;
  }
}

.note {
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  background: #f5f5f5;
  border-radius: 5px;
  color: #8e8e8e;
  padding: 5px 10px;
}

.text-center {
  text-align: center;
}

span {
  display: inline-block;
  padding-top: 12px;
  padding-bottom: 12px;
}

input,
select {
  outline: none;
  width: 100%;
  margin-top: 5px;
  display: inline-block;
  border: 1px solid #e9eaea;
  background-color: white;
  padding: 15px;
  border-radius: 4px;
  background: white;
  resize: none;

  &:focus {
    background-color: white;
    outline: none;
  }
}

.d-flex {
  display: flex;
  flex-wrap: wrap;
}

.d-flex > div {
  padding: 0.1rem;
  color: grey;
  margin-right: 0.5rem;
  margin-top: 0.5rem;
  background-color: rgba(86, 61, 124, 0.15);
  border: 1px solid rgba(86, 61, 124, 0.15);
}

.vacancy-description {
  font-size: 17px;
}

.error,
.email-dirty {
  color: $danger;
}

.help-text {
  color: #6c757d !important;
  display: block;
  margin-top: 0.25rem;
}

.email-verified {
  color: $success;
}

.server-error {
  color: $danger;
  margin-bottom: 10px;
}

.server-error ul {
  list-style-type: none;
}

/* Clear floats after the columns */
.row:after {
  content: '';
  display: table;
  clear: both;
}

.col-25 {
  float: left;
  width: 25%;
  margin-top: 6px;
}

.col-75 {
  width: 75%;
  float: left;
  margin-top: 6px;
}

label {
  padding: 0;
  display: inline-block;
  color: rgba(0, 0, 0, 0.15);
}

/* Responsive layout - when the screen is less than 600px wide,
make the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: $layout-s) {
  .col-25,
  .col-75 {
    margin-top: 0px;
    width: 100%;
  }

  button {
    width: 100%;
    margin-top: 20px;
  }
}
</style>
