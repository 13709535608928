<template>
  <div>
    <div class="row">
      <div class="col-75">
        <div class="note">
          Ваше резюме готово! Вы можете скачать готовый файл.
        </div>
      </div>
      <div class="col-75">
        <button
          @click="downloadResumePdf"
          :disabled="showLoading"
          class="save-pdf__btn"
        >
          Скачать в PDF
          <i v-if="showLoading" class="fas fa-spinner fa-spin"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'File',
  data() {
    return {
      showLoading: false,
    };
  },
  computed: {
    ...mapState(['user']),
  },
  methods: {
    downloadResumePdf() {
      this.showLoading = true;
      this.$api.resume.downloadResumePdf().then((response) => {
        const blob = new Blob([response.data]);
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = `${this.user.profile.email}.pdf`;
        link.click();
        URL.revokeObjectURL(link.href);
        this.showLoading = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.save-pdf__btn {
  text-align: center;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  background: white;
  text-align: center;
  color: #8e8e8e;
  border: 1px solid #8e8e8e;
  box-sizing: border-box;
  border-radius: 2px;
  width: 100%;
}

.row {
  margin-top: 15px;
}
.note {
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  background: #f5f5f5;
  border-radius: 5px;
  color: #8e8e8e;
  padding: 5px 10px;
}

.text-center {
  text-align: center;
}
span {
  display: inline-block;
  padding-top: 12px;
  padding-bottom: 12px;
}
input,
select {
  outline: none;
  width: 100%;
  margin-top: 5px;
  display: inline-block;
  background: map-get($gray, 1000);
  border: none;
  border-radius: 10px;
  padding: 15px;
  border-radius: 4px;
  resize: none;
  &:focus {
    background-color: white;
    outline: none;
  }
}
.d-flex {
  display: flex;
  flex-wrap: wrap;
}
.d-flex > div {
  padding: 0.1rem;
  color: grey;
  margin-right: 0.5rem;
  margin-top: 0.5rem;
  background-color: rgba(86, 61, 124, 0.15);
  border: 1px solid rgba(86, 61, 124, 0.15);
}
.vacancy-description {
  font-size: 17px;
}
.error,
.email-dirty {
  color: $danger;
}
.help-text {
  color: #6c757d !important;
  display: block;
  margin-top: 0.25rem;
}
.email-verified {
  color: $success;
}

.server-error {
  color: $danger;
  margin-bottom: 10px;
}

.server-error ul {
  list-style-type: none;
}

/* Clear floats after the columns */
.row:after {
  content: '';
  display: table;
  clear: both;
}

.col-25 {
  float: left;
  width: 25%;
  margin-top: 6px;
}

.col-75 {
  width: 75%;
  float: left;
  margin-top: 6px;
}

button {
  margin-top: 20px;
  float: right;
  padding: 12px 20px;
}

label {
  padding: 0;
  display: inline-block;
  color: rgba(0, 0, 0, 0.15);
}

/* Responsive layout - when the screen is less than 600px wide,
make the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: $layout-s) {
  .col-25,
  .col-75 {
    margin-top: 0px;
    width: 100%;
  }
  button {
    width: 100%;
    margin-top: 20px;
  }
}
</style>
