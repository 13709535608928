import { request, login } from './utils';
import $globalFn from '../fn';

export default {
  /**
   * Вход в систему
   *
   * @param {Object}    params                   Параметры
   * @param {Object}    params.data              Данные
   * @param {String}    params.data.email        Почта
   * @param {String}    params.data.password     Пароль
   *
   * @return {Promise}                           Возвращает промис с ответом из сервера.
   */
  auth(params = {}) {
    return request('post', '/iam/profile/auth', { data: params });
  },
  login(params = {}) {
    return request('post', '/token/login/', params).then((r) => {
      login(r.data.token);
      return r;
    });
  },
  logout() {
    $globalFn.ls.remove('auth_token');
    $globalFn.ls.remove('refresh_token');
    // request('post', '/curriculum/token/logout/').then((r) => {
    //   console.log('LOGOUT', r);
    // });
  },
  /**
   * Получить данные о профиле
   *
   * @return {Promise}                           Возвращает промис с ответом из сервера.
   */
  me() {
    return request('get', '/iam/profile/whoami');
  },
  /**
   * Обновить данные профиля
   *
   * @param {Object}    params                   Данные
   * @return {Promise}                           Возвращает промис с ответом из сервера.
   */
  update(params) {
    return request('patch', `/iam/profile/${params.id}`, { data: params });
  },
  /**
   * Установить пароль
   *
   * @param {Object}    params                   Данные
   * @return {Promise}                           Возвращает промис с ответом из сервера.
   */
  setPassword(params) {
    return request('post', '/iam/profile/reset-password', params);
  },
  /**
   * Установить почту
   *
   * @param {Object}    params                   Данные
   * @return {Promise}                           Возвращает промис с ответом из сервера.
   */
  setEmail(params) {
    return request('post', '/users/set_email/', params);
  },
  resetPassword(params) {
    return request('post', '/iam/profile/send-code', params);
  },
  validateCode(params) {
    return request('post', '/iam/profile/validate-code', { data: params });
  },
  changePassword(params) {
    return request('post', '/iam/profile/reset-password-by-code', {
      data: params,
    });
  },
  activateAccount(params = {}) {
    return request('post', '/users/activation/', params);
  },
  resendActivation(params = {}) {
    return request('post', '/users/resend_activation/', params);
  },
  getCertificates() {
    return request('get', '/curriculum/activity/certificates');
  },
};
