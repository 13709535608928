<template>
  <loader v-if="loading" />
  <form v-else @submit.prevent="saveSpecialization">
    <div class="row">
      <div class="col-75">
        <label>Профессия*</label>
        <v-select
          v-model="resumeSpecialization.profession"
          :options="proffesions"
          :reduce="(profession) => profession.id"
          label="specialty"
          class="sel1"
          :placeholder="'Укажите профессию'"
        >
          <template v-slot:no-options="{ search, searching }">
            <template v-if="searching">
              Ничего не найдено по запросу <em>{{ search }}</em
              >.
            </template>
            <em v-else style="opacity: 0.5"
              >Начните печатать, чтобы найти профессию.</em
            >
          </template>
        </v-select>
      </div>
    </div>
    <div class="row">
      <div class="col-75">
        <label>Занятость*</label>

        <v-select
          class="style-chooser"
          multiple
          v-model="resumeSpecialization.employmentType"
          :options="employmentTypes"
          :reduce="(employmentType) => employmentType.id"
          :placeholder="'Укажите занятость'"
          label="name"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-75">
        <label>Формат работы*</label>

        <v-select
          multiple
          v-model="resumeSpecialization.workFormat"
          :options="workFormats"
          :reduce="(workFormat) => workFormat.id"
          :placeholder="'Укажите формат'"
          required
          label="name"
        />
      </div>
    </div>
    <!-- <div class="row">
      <div class="col-25">
        <label>Готовность к работе</label>
      </div>
      <div class="col-75">
        <select v-model="resumeSpecialization.lookingWork">
          <option value="dont-look">Не ищу работу</option>
          <option value="actively-looking">Ищу работу</option>
          <option value="open-to-offer">Рассмотрю предложения</option>
        </select>
      </div>
    </div> -->
    <div class="row">
      <div class="col-75">
        <label>Мои навыки*</label>

        <v-select
          multiple
          v-model="resumeSpecialization.skills"
          :options="skills"
          :reduce="(skill) => skill.id"
          label="name"
        />
        <span class="help-text"
          >Выберите до десяти своих профессиональных навыков. Точно указанный
          список навыков поможет нам подбирать для вас самые интересные
          вакансии.</span
        >
      </div>
    </div>
    <div class="server-error" v-if="errors.length">
      <ul v-for="error in errors" :key="error">
        <li>{{ error }}</li>
      </ul>
    </div>
    <div class="row-button">
      <form-button variant="primary" :loading="loading" :disabled="loading">
        Сохранить
        <img :src="btnicon" alt="">
      </form-button>
    </div>
  </form>
</template>

<script>
import Loader from '@/components/Loader';
import FormButton from '../Button';
import btnicon from '../../../public/img/icons/Vector.svg';

export default {
  name: 'Specialization',
  components: { FormButton, Loader },
  data() {
    return {
      btnicon,
      loading: true,
      resumeSpecialization: {
        profession: null,
        workFormat: null,
        skills: [],
        employmentType: [],
        lookingWork: null,
      },
      errors: [],
      specializations: [],
      proffesions: [],
      workFormats: [],
      employmentTypes: [],
      skills: [],
    };
  },
  validations: {},
  created() {
    Promise.all([
      this.$api.resume.getUserSpecialization(),
      this.$api.profession.getSkills(),
      this.$api.profession.getSpecialties(),
      this.$api.profession.getWorkFormats(),
      this.$api.profession.getEmploymentTypes(),
    ])
      .then((r) => {
        this.resumeSpecialization = r[0].data;
        this.skills = r[1].data;
        this.specializations = r[2].data;
        this.workFormats = r[3].data;
        this.employmentTypes = r[4].data;
        this.specializations.forEach((element) => {
          this.proffesions.push(element);
        });
      })
      .finally(() => {
        this.loading = false;
      });
  },
  methods: {
    saveSpecialization() {
      this.loading = true;
      const data = {
        profession: this.resumeSpecialization.profession,
        skills: this.resumeSpecialization.skills,
        employmentType: this.resumeSpecialization.employmentType,
        workFormat: this.resumeSpecialization.workFormat,
        lookingWork: this.resumeSpecialization.lookingWork,
      };
      this.$api.resume.updateSpecialization({ data }).finally(() => {
        this.loading = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.text-center {
  text-align: center;
}
span {
  display: inline-block;
  padding-top: 12px;
  padding-bottom: 12px;
}
input,
select {
  outline: none;
  width: 100%;
  margin-top: 5px;
  display: inline-block;
  border: 1px solid #e9eaea;
  background-color: white;
  padding: 15px;
  border-radius: 4px;
  background: white;
  resize: none;
  &:focus {
    background-color: white;
    outline: none;
  }
}
.d-flex {
  display: flex;
  flex-wrap: wrap;
}
.d-flex > div {
  padding: 0.1rem;
  color: grey;
  margin-right: 0.5rem;
  margin-top: 0.5rem;
  background-color: rgba(86, 61, 124, 0.15);
  border: 1px solid rgba(86, 61, 124, 0.15);
}
.vacancy-description {
  font-size: 17px;
}
.error,
.email-dirty {
  color: $danger;
}
.help-text {
  color: #6c757d !important;
  display: block;
  margin-top: 0.25rem;
}
.email-verified {
  color: $success;
}

.row-button {
  display: flex;
  width: 75%;
  justify-content: flex-end;
}

.server-error {
  color: $danger;
  margin-bottom: 10px;
}

.server-error ul {
  list-style-type: none;
}

h3 {
  margin-bottom: 15px;
}

.row {
  padding: 10px 0;
}

/* Clear floats after the columns */
.row:after {
  content: '';
  display: table;
  clear: both;
}

.col-25 {
  float: left;
  width: 25%;
  margin-top: 6px;
}

.col-75 {
  width: 75%;
  float: left;
  margin-top: 6px;
  label {
    margin-bottom: 10px;
  }
}

button {
  margin-top: 20px;
  display: flex;
  padding: 12px 20px;
  width: 35%;
  justify-content: space-evenly;
  align-items: center;
}

label {
  padding: 0;
  display: inline-block;
  color: rgba(0, 0, 0, 0.15);
}

/* Responsive layout - when the screen is less than 600px wide,
make the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: $layout-s) {
  .col-25,
  .col-75 {
    margin-top: 0px;
    width: 100%;
  }
  button {
    width: 100%;
    margin-top: 20px;
  }
}
</style>

<style>
.vs__selected {
  background: #f5f5f5;
  border: none !important;
  padding: 5px;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #c4c4c4 !important;
}

.vs__search::placeholder {
  font-weight: normal;
  font-size: 14px;
  color: #c4c4c4 !important;
}

.sel1 .vs__selected {
  font-weight: normal;
  font-size: 14px;
  color: black !important;
}
</style>
