<template>
  <div
    id="main"
    v-bind:class="isNewsActive ? 'small-from-news' : 'normal-from-news'"
  >
    <auth-modal />
    <main>
      <sidebar-component />
      <router-view></router-view>
      <newsbar-component ref="news_bar" />
    </main>
    <footer-component style="margin-top: 30px" />
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import AuthModal from '@/components/Auth/modals/Auth';
import FooterComponent from '@/layout/Footer';
import SidebarComponent from '@/layout/Sidebar';
import NewsbarComponent from '@/layout/Newsbar';
import $globalFn from '@/fn';

export default {
  name: 'Main',
  components: {
    SidebarComponent,
    NewsbarComponent,
    AuthModal,
    FooterComponent,
  },
  data() {
    return {
      isNewsActive: false,
      token: $globalFn.ls.get('auth_token'),
    };
  },
  mounted() {
    this.loadProfile();
  },
  computed: {
    // tokenChange() {
    //   if (this.token !== null) return this.loadProfile();
    //   return leave();
    // }
  },
  methods: {
    async loadProfile() {
      const response = await this.$api.user.me();
      if (!response.data.onboarding_finished) {
        this.openFirstAuthModal('data');
      }
      if (!response.data.custom_password_set) {
        this.openFirstAuthModal('password');
      }
      this.setUserProfile(response.data);
    },
    ...mapMutations(['setUserProfile', 'setUserTariff']),
    ...mapActions(['openFirstAuthModal'])
  },
};
</script>

<style lang="scss" scoped>
#main {
  position: relative;
  min-height: 100vh;
  display: flex;
  margin-left: 100px;
  flex-direction: column;

  @media (max-width: $layout-s) {
    margin-left: 0;
  }
}

.small-from-news {
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;

  @media (min-width: $layout-m) {
    margin-right: 380px;
  }
}

.normal-from-news {
  margin-right: 90px;
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;

  @media (max-width: $layout-m) {
    margin-right: 64px;
  }

  @media (max-width: $layout-s) {
    margin-right: 0px;
  }
}

@media (max-width: $layout-s) {
  .small-from-news {
    margin-right: 0;
  }
}
</style>
