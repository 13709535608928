<template>
  <div class="mailing">
    <form @submit.prevent="send">
      <h2>Рассылка</h2>
      <label
        ><span>*Шаблон: </span>
        <input type="text" v-model="mail.template" required />
      </label>
      <label
        ><span>email(если не вводить, отправится всем): </span>
        <input type="text" v-model="mail.email" />
      </label>
      <label
        ><span>Роли: </span>
        <input type="text" v-model="mail.userRole" :required="!mail.email" />
      </label>
      <label
        ><span>Программа: </span>
        <input type="number" v-model="mail.programId" />
      </label>
      <label
        ><span>ID курса: </span>
        <input type="number" v-model="mail.course_id" required />
      </label>
      <span v-if="attentionText" class="attention">
        {{ attentionText }}
      </span>
      <span v-if="statusText" class="attention">{{ statusText }}</span>
      <btn v-if="!attentionText" @click="askModerator">Отправить</btn>
      <div v-else class="actions">
        <btn type="submit" :loading="loading">Да, отправить</btn>
        <btn @click="cancel" type="reset" variant="secondary">Отмена</btn>
      </div>
    </form>
  </div>
</template>

<script>
import Btn from '@/components/Button';

export default {
  components: { Btn },
  data() {
    return {
      mail: {
        template: '',
        email: 'aibek.uisinbayev@gmail.com',
        userRole: '',
        programId: null,
        course_id: null,
      },
      hasAgreed: false,
      attentionText: '',
      statusText: '',
      loading: false,
    };
  },
  methods: {
    askModerator() {
      if (!this.mail.email) {
        this.attentionText = 'Получатель письма: ВСЕ СТУДЕНТЫ!';
        return;
      }
      this.attentionText = `Получатель письма: ${this.mail.email}`;
    },
    send() {
      this.loading = true;
      this.$api.mail
        .sendMail({ data: this.mail })
        .then(() => {
          this.statusText = 'Отправлено!';
        })
        .catch(() => {
          this.statusText = 'Ошибка!';
        })
        .finally(() => {
          this.loading = false;
          this.attentionText = '';
        });
    },
    cancel() {
      this.attentionText = '';
      this.statusText = '';
    },
  },
};
</script>

<style lang="scss" scoped>
.mailing {
  padding: 100px;
  display: flex;
  align-items: center;
  flex-direction: column;
  h2 {
    margin-bottom: 30px;
    color: $danger;
    text-align: center;
  }
  form {
    display: flex;
    flex-direction: column;
    label {
      display: flex;
      flex-direction: column;
      input {
        width: 300px;
        padding: 10px 5px;
        margin-bottom: 30px;
        border: none;
        outline: none;
        box-shadow: 0 0 2px $primary;
        border-radius: 4px;
      }
      span {
        font-size: 14px;
        display: inline-block;
        margin-bottom: 5px;
      }
    }
    .attention {
      max-width: 300px;
      color: $danger;
      font-size: 15px;
      margin-bottom: 15px;
    }
    button {
      max-width: 300px;
      margin-bottom: 10px;
    }
    .actions {
      display: flex;
      flex-direction: column;
      button {
        width: 300px;
        margin-bottom: 10px;
      }
    }
  }
}
</style>
