import countries from './countries';
import tariffs from './tariffs';
import phoneMasks from './phoneMasks';
import files from './files';

export default {
  mobileDeviceWidth: 540,
  countries,
  phoneMasks,
  files,
  tariffs,
};
