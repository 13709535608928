<template>
  <div class="course-wrapper">
    <section v-if="courses">
      <intro-component :course="intro" />
      <choose-profession-component />
      <other-component :course="other" />
    </section>
    <section v-else>
      <Loader text="Loading..." style="position: relative; top: 50%" />
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import IntroComponent from '@/components/Course/Intro';
import ChooseProfessionComponent from '@/components/Course/ChooseProfession';
import OtherComponent from '@/components/Course/Other';
import Loader from '@/components/Loader';
import $globalFn from '../../fn';
import trackAmplitudeEvent from '../../utils/trackAmplitudeEvent';

export default {
  name: 'Course',
  components: { OtherComponent, ChooseProfessionComponent, IntroComponent, Loader },
  computed: {
    ...mapGetters({
      courses: 'getMyCourses'
    }),
  },
  data() {
    return {
      intro: null,
      requiredCourses: [],
      other: null,
    };
  },
  watch: {
    courses: {
      handler() {
        if (this.courses) {
          this.divideByClasses(this.courses)
        }
      },
      immediate: true,
      deep: true,
    }
  },
  mounted() {
    const userId = $globalFn.ls.get('userId');
    trackAmplitudeEvent('potok_pro_opened', userId)
  },
  methods: {
    divideByClasses(courses) {
      for (let i = 0; i < courses.length; i += 1) {
        if (courses[i].type === 'INTRO') {
          this.intro = courses[i];
        } else if (courses[i].type === 'REQUIRED') {
          this.requiredCourses.push(courses[i]);
        } else {
          this.other = courses[i];
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.course-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
  padding: 15px;
  height: fit-content;
}

@media screen and (max-width: $layout-s) {
  .course-wrapper {
    width: 90%;
  }
}
</style>
