<template>
  <div class="login-form">
    <h2>Вход в аккаунт</h2>
    <form @submit.prevent="submitLogin">
      <form-input
        v-model.trim="email"
        placeholder="Эл. почта"
        type="email"
        class="login-form-mail"
        :error="$v.email.$error"
        :error-text="emailError"
        @input="$v.email.$reset()"
      />
      <div class="login-form-password">
        <form-input
          v-model.trim="password"
          :type="passwordFieldType"
          placeholder="Пароль"
          :error="$v.password.$error"
          :error-text="passwordError"
          @input="$v.password.$reset()"
        />
        <i class="fa fa-eye" @click="togglePasswordVisibility" id="togglePassword_1"></i>
      </div>
      <div class="server-error" v-if="errors.length">
        <ul v-for="error in errors" :key="error">
          <li>{{ error }}</li>
        </ul>
      </div>

      <div class="action-buttons">
        <div class="remember-me">
          <input type="checkbox" id="rememberMe" v-model="rememberMe">
          <label for="rememberMe">Запомнить меня</label>
        </div>
        <p @click="switchToForgotPasswordAuthModal" class="forgot-pass">
          Забыли пароль?
        </p>
        <!--        <p @click="switchToRegAuthModal" class="register">-->
        <!--          У вас нет аккаунта?-->
        <!--        </p>-->
      </div>
      <form-button
        variant="primary"
        fluid
        :loading="loading"
        :disabled="loading"
      >
        Войти
      </form-button>
    </form>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { required, minLength, email } from 'vuelidate/lib/validators';
import { login } from '@/api/utils';
import trackAmplitudeError from '@/utils/trackAmplitudeError';
import trackAmplitudeEvent from '@/utils/trackAmplitudeEvent';
import FormInput from '../Input';
import FormButton from '../Button';
import $globalFn from '../../fn';

export default {
  name: 'LoginForm',
  components: { FormInput, FormButton },
  data() {
    return {
      rememberMe: false,
      email: '',
      password: '',
      emailError: '',
      passwordError: '',
      loading: false,
      errors: [],
      passwordFieldType: 'password',
    };
  },
  validations: {
    email: {
      email,
      required,
      minLength: minLength(4),
    },
    password: {
      required,
      minLength: minLength(6),
    },
  },
  methods: {
    ...mapActions([
      'switchToRegAuthModal',
      'switchToForgotPasswordAuthModal',
      'fetchProfile',
      'openFirstAuthModal'
    ]),
    checkErrors() {
      if (!this.$v.email.required) {
        this.emailError = 'Поле должно быть заполнено';
      }
      if (!this.$v.email.email) {
        this.emailError = 'Почта указана в неверном формате';
      }
      if (!this.$v.password.required) {
        this.passwordError = 'Поле должно быть заполнено';
      }
      if (!this.$v.password.minLength) {
        this.passwordError = 'Должено содержать больше 6 символов';
      }
    },
    togglePasswordVisibility(e) {
      if (e.target.className === 'fa fa-eye') {
        e.target.className = 'fa fa-eye-slash';
      } else e.target.className = 'fa fa-eye';
      this.passwordFieldType =
        this.passwordFieldType === 'password' ? 'text' : 'password';
    },
    async submitLogin() {
      this.errors = [];
      this.$v.$touch();
      this.checkErrors();
      if (!this.$v.$error && !this.$v.$pending) {
        this.loading = true;
        const payload = {
          email: this.email,
          password: this.password,
          rememberMe: this.rememberMe
        };
        this.fetchProfile(payload)
          .then(async (response) => {
            if (response.status === 200) {
              this.$emit('success');
              await login(response.data);
              const me = await this.$api.user.me();
              if (!me.data.onboarding_finished) {
                this.openFirstAuthModal('data');
              }
               if (!me.data.custom_password_set) {
                this.openFirstAuthModal('password');
              }
              const userId = me.data.id;
              $globalFn.ls.set('userId', userId);
              await trackAmplitudeEvent('sign_in', userId, {
                provider: 'email',
                success: true,
              });
            }
          })
          .catch((error) => {
            const { data } = error.response;
            trackAmplitudeError('sign_in', email, {
              provider: 'email',
              success: false,
              error,
            });
            if ('error' in data) {
              this.errors.push('Неправильный логин или пароль!');
            }
            if ('nonFieldErrors' in data) {
              this.errors.push(...data.nonFieldErrors);
            }
            if ('email' in data) {
              this.errors.push(...data.email);
            }
            if ('password' in data) {
              this.errors.push(...data.password);
            }
          })
          .finally(() => {
            this.loading = false;
          });
        // this.$api.user.login({
        //     data: {
        //       email: this.email,
        //       password: this.password,
        //     },
        //   })
        //   .then((r) => {
        //     this.$store.commit('setUserProfile', r.data.user);
        //     this.$emit('success');
        //   })
        //   .catch((error) => {
        //     const { data } = error.response;
        //     if ('error' in data) {
        //       this.errors.push(data.error);
        //     }
        //     if ('nonFieldErrors' in data) {
        //       this.errors.push(...data.nonFieldErrors);
        //     }
        //     if ('email' in data) {
        //       this.errors.push(...data.email);
        //     }
        //     if ('password' in data) {
        //       this.errors.push(...data.password);
        //     }
        //   })
        //   .finally(() => {
        //     this.loading = false;
        //   });
      }
    },
    ...mapActions(['switchToRegAuthModal', 'switchToForgotPasswordAuthModal', 'openFirstAuthModal']),
  },
};
</script>

<style lang="scss" scoped>
.login-form {
  width: 50%;

  .action-buttons {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    .forgot-pass {
      text-decoration: none;
      color: rgba($color: #000000, $alpha: 0.5);
      cursor: pointer;
    }

    .register {
      color: #1c77ff;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  h2 {
    font-weight: lighter;
    font-size: 18px;
    text-align: center;
  }

  form {
    margin-top: 30px;
  }

  &-mail {
    margin-bottom: 10px;
  }

  &-password {
    position: relative;
    margin-bottom: 20px;

    .fa-eye, .fa-eye-slash {
      cursor: pointer;
      position: absolute;
      top: 35%;
      right: 10px;
    }
  }

  &-forgot {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 30px;

    a {
      text-decoration: none;
      font-size: 14px;
      line-height: 53px;
      color: map_get($gray, 800);
    }
  }

  &-registration {
    text-align: center;
    margin-top: 8px;
    height: 36px;
    line-height: 36px;
    font-size: 14px;
    text-decoration: underline;
    color: $primary;
    user-select: none;
    cursor: pointer;
  }

  &-options {
    display: flex;
    justify-content: space-between;
  }
}

.server-error {
  color: #e74c3c;
  margin-bottom: 10px;
  font-size: 12px;

  ul {
    list-style-type: none;
  }
}

@media screen and (max-width: $layout-s) {
  .login-form {
    width: 90%;
    height: 100%;
    margin-top: 40px;
  }
}

.remember-me {
  color: rgba($color: #000000, $alpha: 0.5);
  display: flex;
  align-items: center;
  margin-bottom: 15px;

  input[type="checkbox"] {
    margin-right: 8px;
  }
}
</style>
