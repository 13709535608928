<template>
  <div id="app">
    <about-component v-if="courseDataVisible" :course="courseData" />
    <router-view />
    <modal :show="show" @close="close" :closeButton="false">
      <loader v-if="loading" />

      <div v-else class="get-access-modal">
        <span>
          <b>
            {{ modalContent }}
          </b>
        </span>
      </div>
    </modal>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

import windowResizeMixin from '@/mixins/windowResize';
import AboutComponent from '@/components/Course/About';
import Modal from '@/components/Modal';
import Loader from '@/components/Loader';
import $globalFn from './fn';
import trackAmplitudeError from './utils/trackAmplitudeError';
import identifyAmplitudeEvent from './utils/identifyAmplitudeEvent';
import trackAmplitudeEvent from './utils/trackAmplitudeEvent';

export default {
  name: 'App',
  components: { AboutComponent, Modal, Loader },
  mixins: [windowResizeMixin],
  beforeCreate() {
    const packageVersion = process.env.PACKAGE_VERSION;
    const currentVersion = $globalFn.ls.get('app_version');
    console.log(
      'Current App Version:',
      currentVersion,
      'Package Version:',
      packageVersion
    );
    if (!currentVersion || currentVersion !== packageVersion) {
      $globalFn.ls.set('app_version', packageVersion);
      window.location = `${window.location.href}?eraseCache=true`;
    }
  },
  created() {
    document.title = 'Sova';
  },
  data() {
    return {
      courseDataVisible: false,
      courseData: null,
      programDataReady: false,
      show: false,
      loading: false,
      modalContent: '',
    };
  },
  async mounted() {
    const userId = $globalFn.ls.get('userId');
    try {
      const curriculum = await this.$api.courses.getCurriculum();
      const { data } = curriculum;
      this.SET_PROGRAMS(data);
      const availableCurriculumIds = data.map((el) => el.id);
      trackAmplitudeEvent('fetch_user_curriculum_list', userId, {
        user_curriculums: availableCurriculumIds,
      });
      identifyAmplitudeEvent('user_curriculums', availableCurriculumIds);
      if (curriculum.data.length === 1) {
        this.$fn.ls.set('program', data[0].id);
        await this.fetchMyCourses(this.$fn.ls.get('program'));
        identifyAmplitudeEvent('last_selected_curriculum', data[0].id);
      }
      if (this.$fn.ls.get('program')) {
        await this.fetchMyCourses(this.$fn.ls.get('program'));
      }
      this.programDataReady = true;
    } catch (error) {
      trackAmplitudeError('fetch_user_curriculum_list', userId, { error });
    }
    if ('get-access' in this.$route.query) {
      this.show = true;
      try {
        this.loading = true;
        const rank = this.$route.query.rank;
        const cid = this.$route.query.cid;
        const sheetId = this.$route.query['get-access'];
        const { getAccessToCurriculumByCid, getAccessToCurriculumByRank } =
          this.$api.courses;
        const newAccess = rank
          ? await getAccessToCurriculumByRank(sheetId, rank)
          : await getAccessToCurriculumByCid(sheetId, cid);
        if (newAccess.data) {
          this.modalContent = `Поздравляем!
Вы получили доступ к новой программе!

Чтобы начать просматривать новые уроки:

1. Перейдите в меню, которое находится в левой части экрана.
2. Нажмите на раздел Программы.
3. Выберите новую программу, которая появилась в списке.
4. Теперь вы можете просматривать уроки!`;
          trackAmplitudeEvent('get_access_new_curriculum', userId, {
            rank,
          });
          setTimeout(() => {
            window.location.href = '/';
          }, 3500);
        }
      } catch (error) {
        this.modalContent =
          error.response.data.error === 2
            ? error.response.data.message
            : `Кажется, произошла ошибка :(

Убедитесь, что ссылка на доступ к этой программе была выдана вам сотрудником!

Если же ваш адрес электронной почты указанный на платформе отличается от того, что зафиксирован в нашей базе доступов, обратитесь в службу поддержки для помощи!`;
        trackAmplitudeError('get_access_new_curriculum', userId, { error });
        setTimeout(() => {
          this.show = false;
        }, 3500);
      } finally {
        this.loading = false;
      }
    }
  },
  watch: {
    selectedProgram() {
      if (this.selectedProgram !== 'undefined') {
        this.fetchMyCourses(this.selectedProgram);
      }
    },
    authToken: {
      handler() {
        if (!this.authToken && this.$route.path !== '/auth') {
          this.$router.push('/auth');
        }
      },
      immediate: true,
    },
  },
  computed: {
    authToken() {
      return $globalFn.ls.get('auth_token');
    },
    refreshToken() {
      return $globalFn.ls.get('refresh_token');
    },
    ...mapGetters({
      user: 'getUser',
      courses: 'getMyCourses',
    }),
  },
  methods: {
    ...mapMutations(['SET_PROGRAMS']),
    ...mapActions(['fetchMyCourses']),
    openCourseData(course) {
      this.courseData = course;
      this.courseDataVisible = true;
      const body = document.body;
      body.classList.add('non-scroll');
    },
    closeCourseData() {
      this.courseDataVisible = false;
      const body = document.body;
      body.classList.remove('non-scroll');
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss">
@import './assets/fonts/fontawesome/css/all.min.css';
@import './style/main';

.non-scroll {
  overflow: hidden;
}

.get-access-modal {
  min-width: 25vw;
  max-width: 40vw;
  span {
    white-space: pre-line;
    display: flex;
  }
}
</style>
