<template>
  <div class="player">
    <MyPlayer
      v-if="ready"
      :options="videoOptions"
      :videoWidth="windowWidth"
      :onTimeUpdate="onTimeUpdate"
      @videoInitialize="onVideoInitialize"
      @videoCompleted="onVideoCompleted"
      @video-ended="$emit('video-ended')"
      @momentSaved="setLessonMoment($event)"
      :onPause="onPause"
      :onPlay="onPlay"
    ></MyPlayer>
    <loader v-else :text="'Loading...'" style="position: relative; top: 50%" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import trackAmplitudeEvent from '../../utils/trackAmplitudeEvent';
import $globalFn from '../../fn';

const Loader = () => import('../Loader');
const MyPlayer = () => import('@/components/Lesson/MyPlayer');

export default {
  name: 'LessonPlayer',
  data() {
    return {
      windowWidth: window.innerWidth,
      ready: false,
      videoOptions: {
        userActions: {
          hotkeys: {
            volumeStep: 0.1,
          },
        },
        controls: true,
        fill: true,
        playbackRates: [0.75, 1, 1.25, 1.75, 2],
        sources: [
          {
            src: '',
            type: 'application/x-mpegURL',
          },
        ],
      },
    };
  },

  components: {
    MyPlayer,
    Loader,
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onWidthChange);
    });
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onWidthChange);
  },
  computed: {
    // mobileVideoHeight() {
    //   return MOBILE_WISTIA_PLAYER_HEIGHT;
    // },
    ...mapGetters({
      lesson: 'getLesson',
    }),
    width() {
      return this.windowWidth;
    },
  },
  watch: {
    lesson() {
      this.ready = false;
      this.videoOptions.sources.forEach((el) => {
        el.src = this.lesson?.playlist;
      });
      this.ready = true;
    },
  },
  methods: {
    onWidthChange() {
      this.windowWidth = window.innerWidth;
    },
    onVideoInitialize(player) {
      const { lessonUser } = this.lesson.data;
      if (lessonUser && lessonUser.moment) {
        player.setCurrentTime(lessonUser.moment);
      }
    },
    onVideoCompleted() {
      if (!this.lesson?.lessonUser?.isCompleted) {
        this.$api.lesson
          .completeLesson({ id: this.lesson.data.id })
          .then(() => {
            this.$store.commit('setLessonUser', { isCompleted: true });
            this.$store.dispatch(
              'loadCourseBlocks',
              this.lesson.data.course.id
            );
          });
      }
    },
    onTimeUpdate(event) {
      this.$emit('time-update', event);
      const lastWatchedLessonPath = this.$route.path;
      const lastWatchedVideoMoment = event.target.player.currentTime();
      const lastWatchedLessonId = this.$store.state.lesson.data.id;
      const lastWatchedLessonTitle = this.$store.state.lesson.data.title;
      const lastMoment = {
        lessonId: lastWatchedLessonId,
        path: lastWatchedLessonPath,
        moment: lastWatchedVideoMoment,
        title: lastWatchedLessonTitle,
      };
      localStorage.setItem('last_video', JSON.stringify(lastMoment));
    },
    setLessonMoment(moment) {
      this.$store.commit('setLessonUser', {
        lessonId: this.lessonId,
        moment,
        isCompleted: this.lesson?.lessonUser?.isCompleted,
      });
      this.$api.lesson.setLessonMoment({
        id: this.lessonId,
        data: { moment },
      });
    },
    onPause(event) {
      const video = event.target;
      const userId = $globalFn.ls.get('userId');

      trackAmplitudeEvent('lesson_video_paused', userId, {
        lesson_id: this.lesson.id,
        lesson_title: this.lesson.title,
        seconds: video.player.currentTime(),
      });
    },
    onPlay(event) {
      const video = event.target;
      const userId = $globalFn.ls.get('userId');

      trackAmplitudeEvent('lesson_video_play', userId, {
        lesson_id: this.lesson.id,
        lesson_title: this.lesson.title,
        seconds: video.player.currentTime(),
      });
    },
  },
};
</script>

<style scoped lang="scss">
.player {
  min-width: 80%;
  min-height: 100%;
}

// .player {
//   position: relative;
//   @media screen and (max-width: $layout-l) {
//     height: 450px;
//   }
//   @media screen and (max-width: $layout-m) {
//     height: 400px;
//   }
//   @media screen and (max-width: $layout-s) {
//     height: 300px;
//   }
//   @media screen and (max-width: $layout-xs) {
//     height: 200px;
//   }
// }
</style>
