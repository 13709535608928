import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import Vue from 'vue';

dayjs.locale('ru');

const plugin = {
  install() {
    Vue.prototype.$day_js = dayjs;
  }
};
Vue.use(plugin);

export default dayjs;
