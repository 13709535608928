<template>
  <component :is="name" :color="color" />
</template>

<script>
import MainLogo from './MainLogo';
import AuthMail from './AuthMail';
import AuthPassword from './AuthPassword';
import Loader from './Loader';
import Burger from './Burger';
import Wave from './Wave';
import Lock from './Lock';
import Checkmark from './Checkmark';
import QuizCheckedFlag from './QuizCheckedFlag';
import TelegramFlat from './TelegramFlat';
import InstagramFlat from './InstagramFlat';
import FilterConfig from './FilterConfig';

import Home from './Home';
import Book from './Book';
import Logout from './Logout';
import Toggler from './Toggler';
import Forward from './Forward';

export default {
  components: {
    MainLogo,
    AuthMail,
    AuthPassword,
    Loader,
    Burger,
    Wave,
    Lock,
    Checkmark,
    InstagramFlat,
    TelegramFlat,
    FilterConfig,
    QuizCheckedFlag,
    Home,
    Book,
    Logout,
    Toggler,
    Forward,
  },
  props: {
    name: { type: String, default: '' },
    color: { type: String, default: '#FFFFFF' },
  },
};
</script>
