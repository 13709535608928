<template>
  <div>
    <table class="homework-list">
      <tr>
        <th>Заголовок</th>
        <th>Статус</th>
      </tr>
      <tr v-for="item in lesson?.attachments" :key="item.id">
        <td>{{ item.title }}</td>
        <td>
          <a :href="item.file" class="done" @click="trackDownload(item.title)" download="Lesson material">Скачать</a>
        </td>
      </tr>
    </table>
    <div class="mobile-view" v-for="item in lesson?.attachments" :key="item.id">
      <div @click="download(item)" class="mobile-view__item">
        <div class="description">
          <p class="header">
            {{ item.title }}
          </p>
        </div>
        <div class="button">
          <img src="./../assets/images/mobileButton.svg" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import trackAmplitudeEvent from '../utils/trackAmplitudeEvent';
import $globalFn from '../fn';

export default {
  name: 'MaterialList',
  computed: {
    ...mapGetters({
      lesson: 'getLesson',
    }),
  },
  methods: {
    download(attachment) {
      const { file, title } = attachment
      this.trackDownload(title)

      const a = document.createElement('a');
      a.href = file;
      a.download = file.split('/').pop();
      a.click();
      document.body.removeChild(a);
    },
    trackDownload(title) {
      const userId = $globalFn.ls.get('userId');
      trackAmplitudeEvent('attachment_download', userId, { lesson_id: this.lesson.id, attachment_title: title });
    }
  },
};
</script>

<style lang="scss" scoped>
.mobile-view {
  display: none;
  margin-top: 20px;

  @media (max-width: $layout-xs) {
    display: block;
  }

  &__item {
    background-color: #e8e8e885;
    border-radius: 10px;
    padding: 20px 20px;
    display: flex;
    justify-content: space-between;

    .button {
      display: flex;
      align-items: center;
      padding-right: 20px;
    }

    .description {
      .header {
        font-weight: 700;
      }
    }
  }
}

.homework-list {
  width: 100%;
  border: 1px solid #e8e8e8;
  border-radius: 20px;
  margin: 15px auto;

  @media (max-width: $layout-s) {
    display: none;
  }

  th {
    border-right: 1px solid #e8e8e8;
    text-align: left;
    padding: 10px;
    padding-left: 25px;
    font-size: 18px;
    line-height: 24px;
    /* identical to box height */
    font-weight: 200;
    color: rgba(0, 0, 0, 0.3);

    &:last-of-type {
      border: none;
    }
  }

  td {
    padding: 20px;
    border-bottom: 1px solid #e8e8e8;

    &:last-of-type {
      width: 30%;
    }

    .teacher {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 20px;

      img {
        width: 40px;
        height: auto;
      }
    }
  }

  tr {
    &:last-of-type {
      td {
        border: none;
      }
    }
  }
}

.homework-moble-list {
  display: none;
  flex-direction: column;
  width: 100%;
  gap: 15px;

  @media (max-width: $layout-s) {
    display: flex;
  }

  h4 {
    font-weight: 400;
    font-size: 16.9px;
    line-height: 20px;
    color: #000000;
    text-align: left;
  }

  &-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
    width: 100%;
    border: 1px solid #e8e8e8;
    border-radius: 20px;
    padding: 5px;
    cursor: pointer;

    span {
      &:last-of-type {
        color: rgba(0, 0, 0, 0.38);
        font-size: 13px;
        font-weight: lighter;
      }
    }

    img {
      width: 40px;
      height: auto;
    }

    .description {
      display: flex;
      flex-direction: column;
    }
  }
}

.done {
  display: block;
  font-weight: 400;
  font-size: 18px;
  line-height: 12px;
  color: $primary;
  background: $secondary;
  padding: 15px 30px;
  border-radius: 10px;
  text-align: center;
  width: 100%;
  margin: 0 auto;
  height: 44px;
}

.missed {
  display: block;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #e85555;
  background: rgba(255, 58, 58, 0.05);
  padding: 15px 30px;
  border-radius: 10px;
  text-align: center;
  width: 100%;
  margin: 0 auto;
}
</style>
