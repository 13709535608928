<template>
  <button
    class="burger-menu"
    :class="{ opened }"
    @click="$emit('click')"
    aria-label="Main Menu">
    <icon name="burger" />
  </button>
</template>

<script>
import Icon from '../icons';

export default {
  name: 'Burger',
  components: { Icon },
  props: {
    opened: { type: Boolean, default: false },
  },
};
</script>

<style lang="scss">
.burger-menu {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  padding: 0;
  .line {
    fill: none;
    stroke: #292929;
    stroke-width: 7!important;
    transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
    stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
  }
  .line1 {
    stroke-dasharray: 60 207;
  }
  .line2 {
    stroke-dasharray: 60 60;
  }
  .line3 {
    stroke-dasharray: 60 207;
  }
  &.opened .line1 {
    stroke-dasharray: 90 207;
    stroke-dashoffset: -134;
  }
  &.opened .line2 {
    stroke-dasharray: 1 60;
    stroke-dashoffset: -30;
  }
  &.opened .line3 {
    stroke-dasharray: 90 207;
    stroke-dashoffset: -134;
  }
  svg{
    background: #F8F8F8;
    border-radius: 10px;
    width: 32px;
    height: 32px;
  }
}

.opened{
  svg{
    background: #FFFFFF;
  }
}

</style>
