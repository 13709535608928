<template>
  <modal :show="show" @close="$emit('close')" :noPadding="true" modalWidthS closeButton>
    <div class="course-content">
      <header class="course-header">
        <div class="course-info">
          <p class="course-category">Связка</p>
          <h1 class="course-title">{{ course?.title }}</h1>
          <div v-if="isCourseChosen && !['INTRO', 'OTHER'].includes(course?.type)">
            <div class="course-in-progress">
              <router-link :to="{ name: 'lesson', params: { id: course?.id } }">
                <btn class="course-btn">
                  <span>Продолжить обучение</span>
                  <i class="fas fa-play"></i>
                </btn>
              </router-link>
              <!-- <div class="course-change">Сменить профессию</div> -->
            </div>

          </div>
          <div v-else-if="!['INTRO', 'OTHER'].includes(course?.type)" class="course-choose">
            <div>
              <p>Старует с {{ formatDate(course?.startDate) }}</p>
              <btn class="course-btn" v-if="new Date(course?.startDate) < new Date()" @click="openConfirmModalShow"
                :loading="chooseBtnLoad">
                Выбрать эту связку
              </btn>
              <router-link
                :to="new Date(course?.startDate) > new Date() ? {} : { name: 'lesson', params: { id: course?.id } }">
                <btn class="course-btn" :disabled="new Date(course?.startDate) > new Date()">
                  <span>Смотреть</span>
                </btn>
              </router-link>

              <!-- <span
                @click="startWatchingLessonsCourse"
                class="course-watch-lessons-btn"
              >
                Начать и смотреть уроки
              </span> -->
            </div>
          </div>
          <div v-else class="course-choose">
            <router-link :to="{ name: 'lesson', params: { id: course?.id } }">
              <btn class="course-btn">
                <span>Смотреть</span>
              </btn>
            </router-link>
          </div>
        </div>

        <div class="course-image">
          <img :src="course?.coverImage" alt="" />
        </div>
      </header>
      <div v-if="course?.units.length > 0" class="course-program">
        <h3>Программа связки</h3>
        <p class="course-description">
          {{ course?.description }}
        </p>
        <accordion v-for="(block, index) in course?.units" :key="block.id" :index="index" :block="block"
          :collapsable="true" :lessonStartIndex="getLessonStartIndex(index)">
          <template #accordion-toggle-text>
            <span class="accordion-toggle-text">Кол-во уроков: {{ block.lessons.length }}</span>
          </template>
        </accordion>
      </div>
    </div>
    <profession-change-confirmation :show="confirmModalShow" :name="course?.title" @close="closeConfirmModalShow"
      :course-id="course?.id" @finish="onProfessionChanged" />
  </modal>
</template>

<script>
import $dayjs from '@/plugins/dayjs';
import Accordion from '@/components/Accordion';
import ProfessionChangeConfirmation from '@/components/Program/modals/ProfessionChangeConfirmation';
import Modal from '../../Modal.vue';
import Btn from '../../Button';
import trackAmplitudeEvent from '../../../utils/trackAmplitudeEvent';
import $globalFn from '../../../fn';

export default {
  name: 'CourseModal',
  components: {
    Modal,
    Btn,
    Accordion,
    ProfessionChangeConfirmation,
  },
  props: {
    show: { type: Boolean, default: false },
    course: { type: Object, default: () => { } },
    isIntroCourseCompleted: { type: Boolean, default: false },
    isCourseChosen: { type: Boolean, default: false },
  },
  data() {
    return {
      chooseBtnLoad: false,
      chooseBtnError: false,
      confirmModalShow: false,
    };
  },
  computed: {
    chosenCourse() {
      return this.course.courseUser?.isChosen;
    },
  },
  methods: {
    getLessonStartIndex(index) {
      return this.course.units.slice(0, index).reduce((res, unit) => res + unit.lessons.length, 0);
    },
    formatDate(v) {
      return $dayjs(v).format('DD/MM/YYYY');
    },
    openConfirmModalShow() {
      this.confirmModalShow = true;
    },
    closeConfirmModalShow() {
      this.confirmModalShow = false;
    },
    goToCourse() {
      this.$router.push({ name: 'course', params: { id: this.course.id } });
    },
    onProfessionChanged() {
      this.closeConfirmModalShow();
      this.$emit('close');
      this.$emit('loadProgram');
      const userId = $globalFn.ls.get('userId');

      trackAmplitudeEvent('choose_profession', userId, {
        course_id: this.course.id,
        course_title: this.course.title,
      });
    },
    startWatchingLessonsCourse() {
      this.$api.course.start({ id: this.course.id }).then(() => {
        this.$router.push({ name: 'course', params: { id: this.course.id } });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  width: 100%;
  max-width: 700px;
}

.course {
  &-content {}

  &-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    z-index: 10;
    background-color: $primary;
    padding: 40px;
    color: #ffffff;
  }

  &-category {
    margin-bottom: 60px;
    font-size: 16px;
    font-weight: normal;
  }

  &-image {
    display: flex;
    align-items: center;

    img {
      max-width: 250px;
    }
  }

  &-description {
    margin-bottom: 20px;
  }

  &-title {
    margin: 0 0 30px;
    font-size: 24px;
    font-weight: 500;
  }

  &-subtitle {
    margin: 0 0 40px;
    font-size: 14px;
    line-height: 24px;
  }

  &-btn {
    width: 220px;
    margin-bottom: 5px;
    border-radius: 2px;
    color: $primary !important;
    background-color: #fff !important;

    i {
      margin-left: 8px;
      transform: translateY(1px);
    }
  }

  &-notice {
    margin: 26px 0 70px;
    font-size: 14px;
    line-height: 21px;
  }

  &-program {
    display: flex;
    flex-direction: column;
    padding: 40px;

    & h3 {
      margin: 0 0 34px;
      font-weight: 500;
      font-size: 18px;
    }
  }

  &-modal-bookmark {
    width: 60px;
    height: 130px;
    background-color: map-get($pink, 100);
    position: absolute;
    top: 0;
    left: 0;
  }

  &-in-progress {
    display: flex;
    align-items: center;
  }

  &-change {
    color: $primary;
    font-size: 14px;
    margin-left: 40px;
    cursor: pointer;
    user-select: none;
  }

  &-choose {
    div {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
    }

    p {
      margin-bottom: 30px;
    }
  }

  &-watch-lessons-btn {
    margin: 30px auto;
    color: $primary;
    text-decoration: underline;
    font-size: 16px;
    cursor: pointer;
  }
}

.accordion-toggle-text {
  margin: 0 15px 0 60px;
  min-width: 140px;
  font-size: 14px;
  font-weight: normal;
  text-align: end;
  color: map-get($gray, 500);
}

@media (max-width: $layout-s) {
  .course {
    &-content {
      height: auto;
      padding-bottom: 100px;
    }

    &-modal-bookmark {
      width: 120px;
      height: 25px;
    }

    &-category {
      color: white;
    }

    &-image {
      display: none;
    }
  }
}
</style>
