<template>
  <modal :show="show" @close="$emit('close')">
    <div class="profession-change">
      <i class="far fa-exclamation-circle"></i>
      <h2>Вы делаете связку “{{ name }}” основной</h2>
      <p>
        Вы можете поменять связку только один раз. Вы должны пройти этот курс
        полностью, чтобы получить сертификат
      </p>
      <btn fluid @click="chooseCourse" :loading="loading"
        >Сделать связку основной</btn
      >
      <div v-if="error" class="profession-change-error">
        {{ this.errorText }}
      </div>
      <div class="profession-change-cancel" @click="$emit('close')">Отмена</div>
    </div>
  </modal>
</template>

<script>
import Modal from '@/components/Modal';
import Btn from '@/components/Button';

export default {
  name: 'ProfessionChangeConfirmation',
  components: { Modal, Btn },
  props: {
    show: { type: Boolean, default: false },
    name: { type: String, default: '' },
    courseId: { type: String, default: '' },
  },
  data() {
    return {
      loading: false,
      error: '',
      errorText: '',
    };
  },
  methods: {
    chooseCourse() {
      this.loading = true;
      this.$api.course
        .choose({ id: this.courseId, isMainCourse: true })
        .then(() => {
          this.$emit('finish');
        })
        .catch((e) => {
          this.error = true;
          this.errorText = e.response.data.detail;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.profession-change {
  width: 339px;
  i {
    font-size: 34px;
    color: $danger;
  }
  h2 {
    margin-top: 40px;
    font-weight: normal;
    font-size: 18px;
  }
  p {
    margin-top: 25px;
    font-size: 14px;
  }
  .btn {
    margin-top: 45px;
  }
  &-cancel {
    text-align: center;
    font-size: 14px;
    margin-top: 20px;
    cursor: pointer;
    user-select: none;
  }
  &-error {
    margin-top: 10px;
    font-size: 12px;
    color: $danger;
  }
}
</style>
