<template>
  <div class="mailing">
    <form @submit.prevent="send">
      <h2>Раздача доступа</h2>
<!--      Программа-->
      <label><span>*ID программы: </span>
        <input
          type="number"
          min="0"
          max="10"
          required
          v-model.trim="distribution.programId"
        >
      </label>
<!--      Роли-->
      <label><span>*Роли: </span>
        <input
          type="text"
          required
          v-model.trim="distribution.userRole"
        >
      </label>
<!--      Шаблон-->
      <label><span>*Шаблон: </span>
        <input
          type="text"
          required
          v-model.trim="distribution.template"
        >
      </label>
<!--      *Аккаунты(файл)-->
      <label>
        <span>*Файл с аккаунтами </span>
        <input
          type="file"
          required
          @change="onUploadFile"
        >
      </label>
      <label><span>Почта для ответов: </span>
        <input
          type="text"
          ref="fileInput"
          required
          v-model.trim="distribution.responseEmail"
        >
      </label>
      <span v-if="statusText" class="error">{{ statusText }}</span>
      <span v-if="success" class="success">Отправлено!</span>
      <btn v-if="!hasAgreed" @click="hasAgreed = true">Отправить</btn>
      <div v-else class="actions">
        <btn type="submit" :loading="loading" :disabled="loading">Да, отправить</btn>
        <btn @click="cancel" type="reset" variant="secondary">Отмена</btn>
      </div>
    </form>
  </div>
</template>

<script>
import Btn from '@/components/Button';

export default {
  components: { Btn },
  data() {
    return {
      distribution: {
        programId: 4,
        userRole: 'SUBSCRIBED_USER',
        template: 'welcome-email',
        emailsFile: null,
        responseEmail: 'aibek.uisinbayev@gmail.com',
      },
      hasAgreed: false,
      success: false,
      statusText: '',
      loading: false,
    };
  },
  methods: {
    onUploadFile(e) {
      this.distribution.emailsFile = this.$fn.file.upload(e).file;
    },
    send() {
      this.loading = true;
      this.$api.accessDistribution.accessDistribution({ ...this.distribution })
        .then(() => {
          this.success = true;
          this.hasAgreed = false;
          this.statusText = '';
        })
        .catch(() => {
          this.statusText = 'Ошибка!';
          this.success = false;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    cancel() {
      this.hasAgreed = false;
      this.statusText = '';
    },
  },
};
</script>

<style lang="scss" scoped>
.mailing {
  padding: 100px;
  display: flex;
  align-items: center;
  flex-direction: column;
  h2 {
    margin-bottom: 30px;
    color: $danger;
    text-align: center;
  }
  form {
    display: flex;
    flex-direction: column;
    label {
      display: flex;
      flex-direction: column;
      input {
        width: 300px;
        padding: 10px 5px;
        margin-bottom: 30px;
        border: none;
        outline: none;
        box-shadow: 0 0 2px $primary;
        border-radius: 4px;
      }
      span {
        font-size: 14px;
        display: inline-block;
        margin-bottom: 5px;
      }
    }
    .error {
      max-width: 300px;
      color: $danger;
      font-size: 15px;
      margin-bottom: 15px;
    }
    .success {
      color: $success;
      margin-bottom: 5px;
    }
    button {
      max-width: 300px;
      margin-bottom: 10px;
    }
    .actions {
      display: flex;
      flex-direction: column;
      button {
        width: 300px;
        margin-bottom: 10px;
      }
    }
  }
}
</style>
