<template>
  <div>
    <PortfolioForm @on-submit="createPortfolio" />
  </div>
</template>

<script>
import PortfolioForm from './PortfolioForm';

export default {
  name: 'PortfolioNew',
  components: {
    PortfolioForm,
  },
  data() {
    return {};
  },
  methods: {
    createPortfolio(portfolioForm) {
      const data = {
        id: this.$route.params.id,
        title: portfolioForm.title,
        description: portfolioForm.description,
        portfolioImage: portfolioForm.portfolioImage,
      };
      this.$api.resume.addPortfolio({ ...data }).then(() => {
        this.$router.push({ name: 'portfolio-list' });
      });
    },
  },
};
</script>
