import { request } from './utils';

export default {
  getAllCourses() {
    return request('get', '/curriculum/course');
  },
  getCurriculum() {
    return request('get', '/curriculum/activities');
  },
  getAccessToCurriculumByRank(sheetId, rank) {
    return request('get', `/curriculum/get-access?sheet=${sheetId}&rank=${rank}`)
  },
  getAccessToCurriculumByCid(sheetId, cid) {
    return request('get', `/curriculum/get-access?sheet=${sheetId}&cid=${cid}`)
  },
  getCoursesByCurriculumId(id) {
    return request(
      'get',
      `/curriculum/course?group_type=CURRICULUM&group_id=${id}`
    );
  },
  getCommonCourses() {
    return request('get', '/curriculum/course/commons')
  }
};
