import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import apisause from 'apisauce';
import trackAmplitudeEvent from '@/utils/trackAmplitudeEvent';

import $globalFn from '../fn';
import $router from '../router';

const authToken = {
  accessToken: $globalFn.ls.get('auth_token'),
  refreshToken: $globalFn.ls.get('refresh_token'),
};

const instance = apisause.create({
  baseURL: process.env.VUE_APP_SERVICE_CURRICULUM,
  headers: { Authorization: `Bearer ${authToken.accessToken}` },
});

export const login = (token) => {
  $globalFn.ls.set('auth_token', token.accessToken);
  $globalFn.ls.set('refresh_token', token.refreshToken);
  authToken.accessToken = token.accessToken;
  authToken.refreshToken = token.refreshToken;
};

export const leave = (tokenExpired) => {
  const userId = $globalFn.ls.get('userId');

  trackAmplitudeEvent('sign_out', userId, {
    tokenExpired,
  });
  $globalFn.ls.remove('auth_token');
  $globalFn.ls.remove('refresh_token');
  $router.push('/auth');
};

const refreshLogic = () => async (failedRequest) => {
  const response = await axios
    .get(
      `${process.env.VUE_APP_SERVICE_PROFILE}/api/iam/profile/auth/refresh?token=${authToken.refreshToken}`
    )
    .catch(() => {
      const tokenExpired = true;
      leave(tokenExpired);
    });
  authToken.accessToken = response.data.accessToken;
  authToken.refreshToken = response.data.refreshToken;
  $globalFn.ls.set('auth_token', response.data.accessToken);
  $globalFn.ls.set('refresh_token', response.data.refreshToken);
  if (failedRequest.response.config.url.includes('/whoami')) {
    failedRequest.response.config.data = {
      token: response.data.accessToken,
    };
  }

  failedRequest.response.config.headers.Authorization = `Bearer ${response.data.accessToken}`;

  return Promise.resolve();
};

createAuthRefreshInterceptor(instance.axiosInstance, refreshLogic(instance));

export const request = (method, url, data = {}, options = {}) => {
  if (options.isFile) {
    instance.defaults.headers.common['Content-Type'] = 'multipart/form-data';
  }
  let responseType = 'json';
  if (options.isBlob) {
    responseType = 'blob';
  }
  if (url.includes('/assets')) {
    instance.setBaseURL(process.env.VUE_APP_SERVICE_ASSETS);
  } else if (url.includes('/iam') || url.includes('/auth')) {
    instance.setBaseURL(process.env.VUE_APP_SERVICE_PROFILE);
  } else {
    instance.setBaseURL(process.env.VUE_APP_SERVICE_CURRICULUM);
  }
  instance.axiosInstance.defaults.headers.Authorization = `Bearer ${authToken.accessToken}`;
  return instance.axiosInstance({
    method,
    url: `api${url}`,
    ...data,
    responseType,
  });
};
