import Vue from 'vue';
import Vuex from 'vuex';

// Modules
import user from './user';
import lesson from '../views/Lesson/store';
import units from '../views/Lesson/unit.store';
import courses from '../views/MyCourses/store';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isMobile: false,
    isNewsBarActive: true,
    currentCourse: null,
  },
  getters: {
    getIsActiveNewsBar(state) {
      return state.isNewsBarActive;
    },
    getCurrentCourse(state) {
      return state.currentCourse;
    }
  },
  mutations: {
    setIsMobile(state, value) {
      state.isMobile = value;
    },
    setIsNewsBarActive(state, value) {
      state.isNewsBarActive = value;
    },
    setCourse(state, value) {
      state.currentCourse = value;
    }
  },
  actions: {
    showNewsBar({ commit }, value) {
      commit('setIsNewsBarActive', value);
    },
    initCurrentCourse({ commit }, value) {
      commit('setCourse', value);
    }
  },
  modules: {
    courses,
    user,
    lesson,
    units,
  },
});
