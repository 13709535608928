<template>
  <div class="attachment" v-if="lesson?.attachments">
    <h3>Раздаточные материалы</h3>
    <MaterialList />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import MaterialList from '../MaterialList.vue';

export default {
  name: 'LessonAttachments',
  computed: {
    ...mapGetters({
      lesson: 'getLesson',
    }),
  },
  components: { MaterialList },
};
</script>

<style lang="scss" scoped>
.attachment {
  margin: auto;
  margin-top: 75px;
  width: 100%;
  max-width: 1140px;
}
h3 {
  margin-bottom: 30px;
  margin-left: 30px;
  @media (max-width: $layout-s) {
    margin-left: 5px;
  }
}
.attachment-item {
  margin-top: 5px;
  background: map-get($gray, 200);
  border-radius: 8px;
  padding: 14px;
}
.link {
  cursor: pointer;
  color: $primary;
  text-decoration: none;
  font-weight: bold;
  width: 100%;
  height: 100%;
  &:hover {
    text-decoration: underline;
  }
}
@media screen and (max-width: $layout-s) {
  .attachment {
    margin-top: 30px;
  }
  h2 {
    margin-top: 20px;
    font-size: 17px;
    font-family: 'Rubik', sans-serif;
    font-weight: 500;
    margin-bottom: 10px;
  }
}
</style>
