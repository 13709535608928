<template>
  <div class="layout">
    <div class="row">
      <img class="user-bg" src="@/assets/images/user_bg.png" alt="" />
      <img class="user-icon" src="@/assets/images/userIcon.png" />
    </div>
    <div class="row" style="margin-top: 45px">
      <div class="col-25">
        <sidebar class="sidebar-desktop" />
        <div class="sidebar-mobile" @click="isProfileMenuModalOpened = true">
          <div class="sidebar-button">
            <div class="sidebar-text">Личный кабинет</div>
            <div><i class="fas fa-border-all" /></div>
          </div>
        </div>
      </div>
      <div class="col-75">
        <router-view />
      </div>
    </div>
    <modal
      fluid
      :show="isProfileMenuModalOpened"
      @close="closeProfileMenuModal"
    >
      <sidebar @on-item-choose="closeProfileMenuModal" />
    </modal>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Sidebar from '@/components/Profile/Sidebar';
import Modal from '@/components/Modal';

export default {
  name: 'Settings',
  components: {
    Sidebar,
    Modal,
  },
  computed: {
    ...mapState(['user']),
  },
  data() {
    return {
      isProfileMenuModalOpened: false,
    };
  },
  methods: {
    closeProfileMenuModal() {
      this.isProfileMenuModalOpened = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.row {
  margin: 20px 0;
  position: relative;
}
/* Clear floats after the columns */
.row:after {
  content: '';
  display: table;
  clear: both;
}

.col-25 {
  float: left;
  width: 40%;
  padding: 0 20px 0 20px;
}

.col-75 {
  width: 60%;
  float: left;
  padding: 0 20px;
}

.sidebar {
  &-text {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: $dark;
  }
  &-mobile {
    display: none;
  }
  &-button {
    display: flex;
    background: map-get($gray, 900);
    padding: 12px;
    border-radius: 5px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}

.user-bg {
  width: 100%;
  border-radius: 16px;
}

.user-icon {
  position: absolute;
  bottom: -20px;
  left: 50px;
  width: 90px;
  height: 90px;
}

/* Responsive layout - when the screen is less than 600px wide,
make the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: $layout-s) {
  .row:nth-child(1) {
    display: flex;
    justify-content: center;

    img:nth-child(1) {
      width: 80vw;
    }
  }
  .col-25,
  .col-75 {
    width: 100%;
    padding: 0;
  }
  .col-75 {
    margin-top: 20px;
  }
  .sidebar {
    &-desktop {
      display: none;
    }
    &-mobile {
      display: block;
    }
  }
}
</style>
