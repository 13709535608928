<template>
  <transition name="modal-fade" appear>
    <div v-if="show" class="modal-overlay" @click="close">
      <div
        class="modal"
        :class="[
          { fluid },
          { 'modal-no-padding': noPadding },
          { 'modal-no-full-width': noFullWidth },
          { 'modal-width-s': modalWidthS },
        ]"
        @click.stop="onClickBody"
      >
        <div v-if="closeButton" class="close-modal-icon" @click="close"></div>
        <slot></slot>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'Modal',
  props: {
    fluid: { type: Boolean, default: false },
    closable: { type: Boolean, default: true },
    closeButton: { type: Boolean, default: true },
    show: { type: Boolean, default: false },
    noPadding: { type: Boolean, default: false },
    noFullWidth: { type: Boolean, default: false },
    modalWidthS: { type: Boolean, default: false },
  },
  watch: {
    show(val) {
      if (val) {
        this.$fn.layout.removeBodyScroll();
        return;
      }
      this.$fn.layout.addBodyScroll();
    },
  },
  methods: {
    close() {
      if (this.closable) this.$emit('close');
    },
    onClickBody() {},
  },
};
</script>

<style lang="scss" scoped>
.modal-fade-enter,
.modal-fade-leave-to {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.2s ease-in-out;
}

.modal {
  position: relative;
  padding: 32px;
  background: $white;
  box-sizing: border-box;
  margin: auto;
  overflow: hidden;
  border-radius: 25px;

  &.fluid {
    width: 100%;
    height: 100%;
  }

  &.modal-no-padding {
    padding: 0;
  }

  &.modal-no-full-width {
    max-width: 40%;

    @media screen and (max-width: $layout-s) {
      max-width: 90%;
    }
  }

  &.modal-width-s {
    width: 100%;
    max-width: 700px;
  }

  &-overlay {
    backdrop-filter: blur(5px);
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
    overflow: auto;
    padding: 2rem 1rem;
  }

  .close-modal-icon {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;

    &::after,
    &::before {
      position: absolute;
      left: 0;
      top: 10px;
      content: '';
      width: 100%;
      height: 2px;
      background-color: #fff;
    }

    &::after {
      transform: rotate(-45deg);
    }

    &::before {
      transform: rotate(45deg);
    }
  }
}
</style>
