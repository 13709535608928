<template>
  <div>
    <form @submit.prevent="setEmail">
      <div class="row">
        <div class="col-25">
          <label for="email">Текущий адрес</label>
        </div>
        <div class="col-75">
          <span>{{ user.profile.email }}</span>
          <small class="email-verified" v-if="user.profile.isVerified">
            Подтвержден
          </small>
          <small class="email-dirty" v-else> Не подтвержден </small>
        </div>
      </div>
      <div class="row">
        <div class="col-25">
          <label for="email">Новый адрес</label>
        </div>
        <div class="col-75">
          <input
            type="email"
            placeholder="Введите новый почтовый адрес"
            v-model="newEmail"
            required
          />
          <small class="error" v-if="!$v.newEmail.minLength">
            Почтовый адрес должен иметь
            {{ $v.newEmail.$params.minLength.min }} символов.
          </small>
        </div>
      </div>
      <div class="row">
        <div class="col-25"></div>
        <div class="col-75">
          <input
            type="email"
            placeholder="Повторите новый почтовый адрес"
            v-model="reNewEmail"
            required
          />
          <small class="error" v-if="!$v.reNewEmail.minLength">
            Почтовый адрес должен иметь
            {{ $v.reNewEmail.$params.minLength.min }} символов.
          </small>
        </div>
      </div>
      <div class="row">
        <div class="col-25">
          <label for="psw-current">Пароль</label>
        </div>
        <div class="col-75">
          <input
            type="password"
            placeholder="Введите пароль для безопасности"
            v-model="currentPassword"
            required
          />
          <small class="error" v-if="!$v.currentPassword.minLength">
            Пароль должен иметь
            {{ $v.currentPassword.$params.minLength.min }} символов.
          </small>
        </div>
      </div>
      <div class="server-error" v-if="errors.length">
        <ul v-for="error in errors" :key="error">
          <li>{{ error }}</li>
        </ul>
      </div>
      <div class="row">
        <form-button variant="primary" :loading="loading" :disabled="loading">
          Установить новый адрес
        </form-button>
      </div>
    </form>
    <div v-if="!user.profile.isVerified" class="row">
      <form-button
        @click="resendActivation"
        variant="primary"
        :loading="loading"
        :disabled="loading"
      >
        Переотправить письмо для подтверждения
      </form-button>
    </div>
  </div>
</template>

<script>
import { minLength } from 'vuelidate/lib/validators';
import { mapState } from 'vuex';
import FormButton from '../Button';

export default {
  name: 'SetEmail',
  components: { FormButton },
  data() {
    return {
      loading: false,
      newEmail: '',
      reNewEmail: '',
      currentPassword: '',
      errors: [],
    };
  },
  validations: {
    currentPassword: {
      minLength: minLength(8),
    },
    newEmail: {
      minLength: minLength(4),
    },
    reNewEmail: {
      minLength: minLength(4),
    },
  },
  computed: {
    ...mapState(['user']),
  },
  methods: {
    resendActivation() {
      this.loading = true;
      this.$api.user
        .resendActivation({
          data: { email: this.user.profile.email },
        })
        .finally(() => {
          this.loading = false;
        });
    },
    setEmail() {
      this.errors = [];
      this.$v.$touch();
      if (!this.$v.$error && !this.$v.$pending) {
        this.loading = true;
        this.$api.user
          .setEmail({
            data: {
              new_email: this.newEmail.toLowerCase(),
              re_new_email: this.reNewEmail.toLowerCase(),
              current_password: this.currentPassword,
            },
          })
          .then(() => {
            this.user.profile.email = this.newEmail;
            this.newEmail = '';
            this.reNewEmail = '';
            this.currentPassword = '';
          })
          .catch((error) => {
            const { data } = error.response;
            if ('error' in data) {
              this.errors.push(data.error);
            }
            if ('newEmail' in data) {
              this.errors.push(...data.newEmail);
            }
            if ('nonFieldErrors' in data) {
              this.errors.push(...data.nonFieldErrors);
            }
            if ('currentPassword' in data) {
              this.errors.push(...data.currentPassword);
            }
            if ('reNewEmail' in data) {
              this.errors.push(...data.reNewEmail);
            }
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
span {
  display: inline-block;
  padding-top: 12px;
  padding-bottom: 12px;
}
/* Full-width input fields */
input {
  width: 100%;
  padding: 15px;
  margin-top: 5px;
  display: inline-block;
  background: map-get($gray, 1000);
  border: none;
  border-radius: 10px;
}

input:focus {
  outline: none;
}

.error,
.email-dirty {
  color: $danger;
}

.email-verified {
  color: $success;
}

.server-error {
  color: $danger;
  margin-bottom: 10px;
}

.server-error ul {
  list-style-type: none;
}

/* Clear floats after the columns */
.row:after {
  content: '';
  display: table;
  clear: both;
}

.col-25 {
  float: left;
  width: 25%;
  margin-top: 6px;
}

.col-75 {
  width: 75%;
  float: left;
  margin-top: 6px;
}

button {
  margin-top: 20px;
  float: right;
  padding: 12px 20px;
}

label {
  padding: 12px 12px 12px 0;
  display: inline-block;
  color: rgba(0, 0, 0, 0.15);
}

/* Responsive layout - when the screen is less than 600px wide,
make the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: $layout-s) {
  .col-25,
  .col-75 {
    margin-top: 0px;
    width: 100%;
  }
  button {
    width: 100%;
    margin-top: 20px;
  }
}
</style>
