<template>
  <div>
    <loader v-if="showLoading" />
    <PortfolioForm
      v-if="portfolio"
      :portfolio="portfolio"
      @on-submit="updatePortfolio"
    />
  </div>
</template>

<script>
import Loader from '@/components/Loader';
import PortfolioForm from './PortfolioForm';

export default {
  name: 'PortfolioEdit',
  components: {
    PortfolioForm,
    Loader,
  },
  data() {
    return {
      showLoading: true,
      portfolio: null,
    };
  },
  created() {
    this.getPortfolio();
  },
  methods: {
    getPortfolio() {
      this.$api.resume
        .getPortfolio({ id: this.$route.params.id })
        .then(({ data }) => {
          this.portfolio = data;
        })
        .finally(() => {
          this.showLoading = false;
        });
    },
    updatePortfolio(portfolioForm) {
      const data = {
        id: this.$route.params.id,
        title: portfolioForm.title,
        description: portfolioForm.description,
        portfolioImage: portfolioForm.portfolioImage,
      };
      this.$api.resume.updatePortfolio({ ...data }).finally(() => {
        this.$router.push({ name: 'portfolio-list' });
      });
    },
  },
};
</script>
