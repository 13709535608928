import { track } from '@amplitude/analytics-browser';

export default function trackAmplitudeEvent(
  eventName,
  userId,
  eventData,
  onSuccess = () => {
    const userIdData = { user_id: userId };
    const data = { ...eventData, ...userIdData };

    console.log(`Tracked event: ${eventName} with data`, data);
  },
  onError = () => {
    const userIdData = { user_id: userId };
    const data = { ...eventData, ...userIdData };

    console.log(`Failed to track event: ${eventName} with data`, data);
  }
) {
  const userIdData = { user_id: userId };
  const data = { ...eventData, ...userIdData };

  track(eventName, data, userIdData).promise.then(onSuccess).catch(onError);
}
