<template>
  <button
    class="btn"
    :class="[{ fluid }, variant]"
    :disabled="disabled"
    @click="onClick"
  >
    <slot />
    <i v-if="loading" class="fas fa-spinner fa-spin" />
  </button>
</template>

<script>
export default {
  name: 'Button',
  props: {
    variant: {
      type: String,
      default: 'primary',
      validator: (value) =>
        [
          'dark',
          'primary',
          'secondary',
          'yellow',
          'green',
          'outline-primary',
        ].includes(value),
    },
    fluid: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
  },
  methods: {
    onClick() {
      this.$emit('click');
    },
  },
};
</script>

<style lang="scss" scoped>
.btn {
  outline: none;
  border: none;
  cursor: pointer;
  &.fluid {
    width: 100%;
  }
  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
  &.primary {
    background-color: $primary;
    font-weight: 400;
    font-size: 14px;
    color: $white;
    border-radius: 4px;
    transition: all 0.2s ease-in-out;
    padding: 15px;
    &:hover:enabled {
      background-color: $primary-dark;
    }
  }
  &.secondary {
    background-color: $secondary;
    font-weight: 400;
    height: 47px;
    font-size: 14px;
    color: $primary;
    border-radius: 4px;
    transition: all 0.2s ease-in-out;
  }
  &.dark {
    background-color: $dark;
    font-weight: 500;
    height: 47px;
    font-size: 14px;
    color: $white;
    border-radius: 4px;
  }
  &.yellow {
    background-color: $yellow;
    color: $primary;
    font-weight: 500;
    font-size: 14px;
    border-radius: 4px;
    padding: 0 45px;
    height: 47px;
  }
  &.green {
    background-color: $success;
    color: $white;
    font-weight: 500;
    font-size: 14px;
    border-radius: 4px;
    padding: 0 45px;
    height: 47px;
  }
  &.outline-primary {
    color: $primary;
    background-color: transparent;
    border: 1px solid $primary;
    font-weight: 500;
    height: 47px;
    font-size: 14px;
    border-radius: 4px;
    transition: all 0.2s ease-in-out;
    &:hover:enabled {
      background-color: $primary;
      color: $white;
    }
  }
}
</style>
