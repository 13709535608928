<template>
  <div class="login-form">
    <img class="forgot-image" src="@/assets/images/forgot-boy.png" />
    <h2>Cбросить пароль</h2>
    <div v-if="status === 1">
      <section class="verify">
        <form @submit.prevent="changePassword">
          <p class="verify-header">Письмо с кодом отправлено вам на почту</p>
          <div class="form-box">
            <p class="newpass-header">Новый пароль</p>
            <div class="password-field">
              <form-input
                  v-model="newPass"
                  type="password"
                  placeholder="Введите новый пароль"
                  icon="auth-password"
                  class="login-form-mail"
                  required
                />
                <i class="fa fa-eye" @click="togglePasswordVisibility" id="newPass"></i>
            </div>
            <small class="error" v-if="!$v.newPass.minLength">
              Пароль должен иметь
              {{ $v.newPass.$params.minLength.min }} символов.
            </small>
            <small class="error" v-if="!$v.newPass.origin">
              Пожалуйста, введите другой пароль
            </small>
            <p class="newpass-header">Подтвердите пароль</p>
            <div class="password-field">
              <form-input
                v-model="verifyPass"
                type="password"
                placeholder="Подтвердите пароль"
                icon="auth-password"
                class="login-form-mail"
                required
              />
              <i class="fa fa-eye" @click="togglePasswordVisibility" id="verifyPass"></i>
            </div>
            <small class="error" v-if="!$v.verifyPass.sameAsPassword">
              Пароли должны совпадать
            </small>
            <p class="code-header">Код подтверждения</p>
            <form-input
              v-model="verifyCode"
              type="text"
              placeholder="Введите код"
              icon="auth-password"
              class="login-form-mail"
              required
            />
            <small class="error" v-if="!$v.verifyCode.minLength">
              Код должен иметь
              {{ $v.verifyCode.$params.minLength.min }} символов.
            </small>
            <small class="error" v-if="!verifyCodeCorrect">
              Код подтверждения не верный
            </small>
          </div>
          <form-button
            variant="primary"
            fluid
            :loading="loading"
            :disabled="loading"
          >
            Сменить пароль
          </form-button>
        </form>
      </section>
    </div>
    <form v-else @submit.prevent="resetPassword">
      <form-input
        v-model="email"
        type="email"
        placeholder="Эл. почта"
        icon="auth-mail"
        class="login-form-mail"
        required
      />
      <small class="error" v-if="!$v.email.minLength">
        Почтовый адрес должен иметь
        {{ $v.email.$params.minLength.min }} символов.
      </small>
      <small class="error" v-if="!$v.email.email">
        Почта указана в неверном формате
      </small>
      <div class="server-error" v-if="errors.length">
        <ul v-for="error in errors" :key="error">
          <li>{{ error }}</li>
        </ul>
      </div>
      <form-button
        variant="primary"
        fluid
        :loading="loading"
        :disabled="loading"
      >
        Отправить
      </form-button>
    </form>
    <div class="login-form-login" @click="switchToLoginAuthModal">
      Форма логина
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { minLength, email, sameAs } from 'vuelidate/lib/validators';
import trackAmplitudeEvent from '@/utils/trackAmplitudeEvent';
import trackAmplitudeError from '@/utils/trackAmplitudeError';
import FormInput from '../Input';
import FormButton from '../Button';
import $globalFn from '../../fn';

export default {
  name: 'ForgotPasswordForm',
  components: { FormInput, FormButton },
  data() {
    return {
      email: '',
      loading: false,
      errors: [],
      status: 0,
      newPass: '',
      verifyPass: '',
      verifyCode: '',
      verifyCodeCorrect: true,
    };
  },
  validations: {
    email: {
      email,
      minLength: minLength(4),
    },
    newPass: {
      minLength: minLength(6),
      origin: (value) => !['password', 'password123', 'qwerty'].includes(value)
    },
    verifyPass: {
      sameAsPassword: sameAs('newPass'),
    },
    verifyCode: {
      minLength: minLength(6),
    },
  },
  methods: {
    resetPassword() {
      this.errors = [];
      this.$v.$touch();
      if (!this.$v.$error && !this.$v.$pending) {
        this.loading = true;
        const userId = $globalFn.ls.get('userId');

        this.$api.user
          .resetPassword({
            data: {
              email: this.email.toLowerCase(),
            },
          })
          .then(() => {
            this.status = 1;
            trackAmplitudeEvent('password_reset', userId);
          })
          .catch((error) => {
            const { data } = error.response;
            trackAmplitudeError('password_reset', userId, { error });

            if (error.response.status === 400) this.errors.push(...data);
            if ('error' in data) this.errors.push(data.error);
            if ('nonFieldErrors' in data) {
              this.errors.push(...data.nonFieldErrors);
            }
            if ('email' in data) this.errors.push(...data.email);
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    async changePassword() {
      this.errors = [];
      this.$v.$touch();
      if (!this.$v.$error && !this.$v.$pending) {
        const userId = $globalFn.ls.get('userId');
        this.loading = true;
        const data = {
          email: this.email,
          code: this.verifyCode,
          password: this.newPass,
        };

        const resCode = await this.$api.user
          .validateCode(data)
          .catch((error) => {
            this.verifyCodeCorrect = false;
            this.loading = false;
            trackAmplitudeError('validate_code', userId, { error });
          });

        if (resCode.status === 200) {
          trackAmplitudeEvent('validate_code', userId);
          try {
            const res = await this.$api.user.changePassword(data);
            if (res.status === 200) {
              this.loading = false;
              this.switchToLoginAuthModal();
              trackAmplitudeEvent('password_change', userId);
            }
          } catch (error) {
            trackAmplitudeError('password_change', userId, { error });
          }
        }
      }
    },
    togglePasswordVisibility(e) {
      if (e.target.className === 'fa fa-eye') {
        e.target.className = 'fa fa-eye-slash';
      } else e.target.className = 'fa fa-eye';
      const formElement = e.srcElement.parentElement.children[0];
      const inputElement = formElement.children[0].children[1];
      if (inputElement.type === 'password') {
        inputElement.type = 'text';
      } else inputElement.type = 'password'
    },
    ...mapActions(['switchToLoginAuthModal']),
  },
};
</script>

<style lang="scss" scoped>
.verify-header {
  text-align: center;
  margin: 20px 0;
}

.newpass-header {
  margin-bottom: 10px;
}

.code-header {
  margin: 15px 0;
}

.forgot-image {
  position: absolute;
  right: -45%;
  bottom: -30%;

  @media (max-width: $layout-s) {
    display: none;
  }
}

.login-form {
  position: relative;
  width: 50%;
  height: 300px;

  h2 {
    font-weight: lighter;
    font-size: 18px;
    text-align: center;
  }

  form {
    margin-top: 30px;
  }

  &-mail {
    margin-bottom: 10px;
  }

  &-login {
    text-align: center;
    margin-top: 8px;
    height: 36px;
    line-height: 36px;
    font-size: 14px;
    text-decoration: underline;
    color: $primary;
    user-select: none;
    cursor: pointer;
  }

  .password-field {
    position: relative;

    .fa-eye, .fa-eye-slash {
      cursor: pointer;
      position: absolute;
      top: 35%;
      right: 10px;
    }
  }
}

.error {
  color: #e74c3c;
}

.server-error {
  color: #e74c3c;
  margin-bottom: 10px;
  font-size: 12px;

  ul {
    list-style-type: none;
  }
}

@media screen and (max-width: $layout-s) {
  .login-form {
    width: 90%;
    height: 50%;
  }
}
</style>
