<template>
  <div class="loader-wrapper">
    <div class="loader" :style="{ width, height }"></div>
    <div v-if="text" class="loader-text">{{ text }}</div>
  </div>
</template>

<script>
export default {
  name: 'Loader',
  props: {
    text: { type: String, default: '' },
    width: { type: String, default: '50px' },
    height: { type: String, default: '50px' },
  },
};
</script>

<style lang="scss" scoped>
.loader {
  border: 2px solid map-get($gray, 400);
  border-radius: 50%;
  border-top: 2px solid $primary;
  -webkit-animation: spin 1s linear infinite; /* Safari */
  animation: spin 1s linear infinite;
  &-text {
    font-size: 14px;
    margin-top: 20px;
  }
  &-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>
