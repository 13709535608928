/* eslint-disable no-console */

import { unregister } from 'register-service-worker';

if (process.env.NODE_ENV === 'production') {
  unregister();
  console.log(
    'App is being removed from cache and service worker is unregistered'
  );
  // register(`${process.env.BASE_URL}service-worker.js`, {
  //   ready() {
  //     console.log(
  //       'App is being served from cache by a service worker.\n' +
  //         'For more details, visit https://goo.gl/AFskqB'
  //     );
  //   },
  //   registered() {
  //     console.log('Service worker has been registered.');
  //   },
  //   cached() {
  //     console.log('Content has been cached for offline use.');
  //   },
  //   updatefound() {
  //     console.log('New content is downloading.');
  //     console.log('New version of app found.');
  //     window.location = `${window.location.href}?eraseCache=true`;
  //   },
  //   updated(registration) {
  //     registration.update();
  //     console.log('New version of app.');
  //   },
  //   offline() {
  //     console.log(
  //       'No internet connection found. App is running in offline mode.'
  //     );
  //   },
  //   error(error) {
  //     console.error('Error during service worker registration:', error);
  //   },
  // });
}
