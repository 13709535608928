<template>
  <div>
    <div class="d-flex">
      <div>
        <router-link
          v-if="experiences.length != 0"
          class="link"
          :to="{ name: 'experience-new' }"
        >
        </router-link>
      </div>
    </div>
    <loader v-if="loading" />
    <div class="no_content" v-if="experiences.length == 0 && !loading">
      <div class="no_content">
        <div class="title">Не указан опыт работы</div>
        <div class="text">
          Укажите места, где вы работали, а также опишите чем вы занимались на
          этой позиции
        </div>
        <div class="buttons">
          <router-link :to="{ name: 'experience-new' }"
            ><form-button>Добавить опыт работы</form-button></router-link
          >
        </div>
      </div>
    </div>
    <div
      class="experience-block"
      v-for="experience in experiences"
      :key="experience.id"
    >
      <div class="vacancy-row">
        <h4>{{ experience.positionName }}, {{ experience.companyName }}</h4>
      </div>
      <div class="vacancy-row">
        <h4 class="meta-info">
          {{ experience.dateFrom | experienceDate }} -
          {{ experience.dateTill | experienceDate }}
        </h4>
      </div>
      <div class="vacancy-row">
        <!-- <h3>Обязанности</h3> -->
        <div class="vacancy-description">
          {{ experience.obligations }}
        </div>
      </div>
      <div class="vacancy-row">
        <!-- <h3>Достижения</h3> -->
        <div class="vacancy-description">
          {{ experience.achievements }}
        </div>
      </div>
      <div class="buttons">
        <div>
          <router-link
            class="link"
            :to="{ name: 'experience-edit', params: { id: experience.id } }"
            >Редактировать</router-link
          >
        </div>
        <div>
          <a
            @click="deleteExperience({ id: experience.id })"
            class="link"
            style="color: #eb5757"
            >Удалить</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $dayjs from '@/plugins/dayjs';
import Loader from '@/components/Loader';
import FormButton from '../Button';

export default {
  components: { FormButton, Loader },
  data() {
    return {
      loading: true,
      experiences: [],
    };
  },
  filters: {
    experienceDate(date) {
      const monthNames = [
        'Январь',
        'Февраль',
        'Март',
        'Апрель',
        'Май',
        'Июнь',
        'Июль',
        'Август',
        'Сентябрь',
        'Октябрь',
        'Ноябрь',
        'Декабрь',
      ];
      return date
        ? `${monthNames[parseInt($dayjs(date).format('MM'), 10)]} ${$dayjs(
            date
          ).format('YYYY')}`
        : 'По настоящее время';
    },
  },
  created() {
    this.getExperiences();
  },
  methods: {
    deleteExperience(params) {
      if (window.confirm('Are you sure you want to delete this experience?')) {
        this.$api.resume.deleteExperience(params).then(() => {
          this.getExperiences();
        });
      }
    },
    getExperiences() {
      this.$api.resume
        .getExperiences()
        .then(({ data }) => {
          this.experiences = data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.experience-block {
  margin: 25px 0;
}
.d-flex {
  display: flex;
  justify-content: space-between;
}
.link {
  cursor: pointer;
  color: $primary;
  text-decoration: none;
  font-weight: bold;
  &:hover {
    text-decoration: underline;
  }
}
.vacancy-description {
  margin: 15px 0;
  font-size: 17px;
  white-space: pre-line;
}
.meta-info {
  color: #c4c4c4;
  font-size: 17px;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
}
.vacancy-row {
  margin-bottom: 15px;
}
.buttons div {
  display: inline;
  margin-right: 40px;
}
.no_content {
  padding: 50px 0;
  background: #fff;
  text-align: center;
  .title {
    font-size: 25px;
    color: #464646;
    max-width: 330px;
    margin: 0 auto 7px;
  }
  .text {
    font-size: 15px;
    color: #999;
    line-height: 21px;
    max-width: 350px;
    margin: auto;
  }
  .buttons {
    margin-top: 20px;
  }
}
</style>
