import { request } from './utils';

export default {
  /**
   * Получить информацию о резюме
   *
   * @param {Object}         params                          Параметры
   * @param {Number|String}  params.id                       ID вакансии
   *
   * @return {Promise}                                       Возвращает промис с ответом из сервера.
   */
  getUserSpecialization() {
    return request('get', '/resume/specialization/');
  },
  downloadResumePdf() {
    return request('get', '/resume/resume_pdf/', {}, { isBlob: true });
  },
  updateSpecialization(params = {}) {
    return request('put', '/resume/specialization/', params);
  },
  // portfolio
  getPortfolio(params) {
    return request('get', `/resume/portfolios/${params.id}/`);
  },
  deletePortfolio(params) {
    return request('delete', `/resume/portfolios/${params.id}/`);
  },
  getPortfolios() {
    return request('get', '/resume/portfolios/');
  },
  addPortfolio(params = {}) {
    const data = new FormData();
    if (params.portfolioImage) {
      data.append('portfolioImage', params.portfolioImage);
    }
    data.append('title', params.title);
    data.append('description', params.description);
    return request('post', '/resume/portfolios/', { data }, { isFile: params.portfolioImage });
  },
  updatePortfolio(params = {}) {
    const data = new FormData();
    if (params.portfolioImage) {
      data.append('portfolioImage', params.portfolioImage);
    }
    data.append('title', params.title);
    data.append('description', params.description);
    return request('patch', `/resume/portfolios/${params.id}/`, { data }, { isFile: params.portfolioImage });
  },

  // education
  getEducation(params) {
    return request('get', `/resume/educations/${params.id}/`);
  },
  updateEducation(params = {}) {
    return request('patch', `/resume/educations/${params.id}/`, params);
  },
  getEducations() {
    return request('get', '/resume/educations/');
  },
  addEducation(params = {}) {
    return request('post', '/resume/educations/', params);
  },
  deleteEducation(params) {
    return request('delete', `/resume/educations/${params.id}/`);
  },

  // experience
  deleteExperience(params) {
    return request('delete', `/resume/experiences/${params.id}/`);
  },
  getExperience(params) {
    return request('get', `/resume/experiences/${params.id}/`);
  },
  getExperiences() {
    return request('get', '/resume/experiences/');
  },
  addExperience(params = {}) {
    return request('post', '/resume/experiences/', params);
  },
  updateExperience(params = {}) {
    return request('patch', `/resume/experiences/${params.id}/`, params);
  },
};
