import { identify, Identify } from '@amplitude/analytics-browser';

export default function identifyAmplitudeEvent(
  key,
  value,
  onSuccess = () => {
    console.log(`Identify amplitude user property: ${key} with data`, value);
  },
  onError = () => {
    console.log(
      `Failed to add amplitude user property: ${key} with value`,
      value
    );
  }
) {
  const identifyEvent = new Identify();
  identifyEvent.set(key, value);

  identify(identifyEvent).promise.then(onSuccess).catch(onError);
}
