<template>
  <div class="radial-progress-bar" :class="[variant]">
    <span class="radial-percent">{{ progress }}%</span>
    <svg class="progress-ring" width="140" height="140">
      <circle
        class="progress-ring-circle-gray"
        cx="70"
        cy="70"
        r="56"
        fill="transparent"
        stroke-width="7px"
        stroke-dashoffset="352"
      />
      <circle
        class="progress-ring-circle"
        cx="70"
        cy="70"
        r="56"
        fill="transparent"
        stroke="#3863F6"
        stroke-width="7px"
        :stroke-dashoffset="calcProgress(progress)"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: 'RadialProgressBar',
  props: {
    progress: { type: Number, default: 0 },
    variant: {
      type: String,
      default: 'default',
      validator: (value) => ['default', 'invert', 'secondary'].includes(value),
    },
  },
  data() {
    return { circumference: 352 };
  },
  methods: {
    calcProgress(percent) {
      return Math.round(this.circumference - (percent / 100) * this.circumference);
    },
  },
};
</script>

<style lang="scss" scoped>
.radial-progress-bar {
  width: 140px;
  height: 140px;
  position: relative;
  .radial-percent {
    font-size: 24px;
    font-weight: 500;
    position: absolute;
    top: 50%;
    left: 53%;
    transform: translate(-50%, -50%);
    z-index: 99;
  }
  .progress-ring-circle {
    transform-origin: center;
    transform: rotate(-90deg);
    stroke-dasharray: 352, 352;
  }
  &.default {
    .progress-ring-circle-gray {
      stroke: map_get($gray, 400);
    }
    .radial-percent {
      color: $primary;
    }
  }
  &.invert {
    .progress-ring-circle-gray {
      stroke: #3058e1;
    }
    .radial-percent {
      color: $white;
    }
  }
  &.secondary {
    .progress-ring-circle-gray {
      stroke: $white;
    }
    .radial-percent {
      color: $white;
    }
    .progress-ring-circle {
      stroke: $yellow;
    }
  }
  @media screen and (max-width: $layout-s) {
    margin: 0 auto;
  }
}
</style>
