<template>
  <div class="first_auth_modal__container">
    <div class="first_auth_modal__stage">
      <div class="first_auth_modal__content">
        <p>
          Ваши данные не заполнены! <br />Чтобы продолжить использование
          платформы заполните свои данные
        </p>
        <img src="../../assets/images/firstAuthVector.svg" alt="" />
        <div class="first_auth_modal__content___rules">
          <span>
            <i
              v-if="this.passwordCheck"
              class="fa fa-check"
              style="color: #54ce27"
            />
            <span v-else class="icon-x">x</span>
            Поменяйте системный пароль на свой пароль
          </span>
          <span>
            <i
              v-if="this.onBoardingCheck"
              class="fa fa-check"
              style="color: #54ce27"
            />
            <span v-else class="icon-x">x</span>
            Заполните личные данные: ФИО, номер телефона и дату рождения.
          </span>
        </div>
        <button @click="navigateToPage">Перейти к настройкам</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import $router from '@/router';

export default {
  name: 'FirstAuthModal',
  components: {},
  computed: {
    ...mapGetters({
      payload: 'getFirstAuthModal',
    }),
  },
  data() {
    return {
      passwordCheck: this.$store.state.user.profile.custom_password_set,
      onBoardingCheck: this.$store.state.user.profile.onboarding_finished,
    };
  },
  methods: {
    ...mapActions(['openFirstAuthModal']),
    navigateToPage() {
      const { state } = this.payload;
      const path =
        state === 'password' ? '/settings/set-password' : '/settings/profile';
      $router.push(path);
    },
  },
};
</script>

<style lang="scss">
.first_auth_modal {
  &__container {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 999;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.6);
    padding: 50px;
    list-style-type: none;
  }

  &__stage {
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: clamp(300px, 50%, 600px);
    background: #fff;
    padding: 30px;
    @media (max-width: $layout-s) {
      padding: 15px;
    }
    background-clip: padding-box;
    border-radius: 10px;
  }

  &__content {
    position: relative;
    display: grid;
    place-items: center;
    text-align: center;
    width: 100%;

    &___rules {
      display: flex;
      flex-direction: column;
      margin: 10px 0;
      gap: 6px;
    }

    .icon-x {
      color: #eb370a;
      font-size: 25px;
    }

    p {
      width: inherit;
      margin-bottom: 25px;
    }

    img {
      max-width: 150px;
      width: inherit;
    }

    button {
      background: #1c77ff;
      color: #ffffff;
      font-weight: 500;
      width: 100%;
      border: none;
      border-radius: 10px;
      margin-top: 8px;
      padding: 15px 12px;
      cursor: pointer;
    }
  }
}
</style>
