<template>
  <div class="container">
    <loader-overlay :show="loading" text="Загружаем квиз" />
    <span v-if="error" class="error">Куиз не доступен в данный момент</span>
    <QuizForm
      v-if="state === 1"
      :quizTitle="currQuiz.title"
      :quizLength="currQuiz.entries.length"
      :question="currQuestion"
      :questIndex="currIndex"
      @answer="changeQuest"
    />
    <QuizReview
      v-if="state === 2"
      :quizReview="currReview"
      :isPassed="isPassed"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import QuizForm from '@/components/Quiz/QuizForm';
import QuizReview from '@/components/Quiz/QuizReview';
import LoaderOverlay from '@/components/LoaderOverlay';
import trackAmplitudeEvent from '../../utils/trackAmplitudeEvent';
import $globalFn from '../../fn';

export default {
  props: ['quizId'],
  components: {
    QuizForm,
    QuizReview,
    LoaderOverlay,
  },
  data() {
    return {
      loading: true,
      state: 0,
      currQuiz: {},
      currReview: {},
      currIndex: 1,
      currQuestion: {},
      userAnswers: [],
      error: false,
      threshhold: null,
    };
  },
  created() {
    this.loadQuiz();
  },
  computed: {
    ...mapGetters({
      lesson: 'getLesson',
    }),
    score() {
      let points = 0;
      let percentage = 0;
      const total = this.userAnswers.length;
      this.userAnswers.forEach((el) => {
        if (el.answer.isCorrect) {
          points += 1;
        }
      });
      percentage = (points * 100) / total;
      return percentage;
    },
    isPassed() {
      if (this.threshhold > this.score) return false;
      return true;
    },
  },
  methods: {
    loadQuiz() {
      this.currQuiz = this.lesson.quizzes[0];
      this.threshhold = this.currQuiz.threshold;
      this.currQuestion = this.currQuiz?.entries[0];
      this.state = 1;
      this.loading = false;
    },
    submitQuiz() {
      const entries = {};
      const lessonQuiz = this.lesson.quizzes[0];
      this.loading = true;
      this.currReview = this.userAnswers;
      this.currReview.forEach((el) => {
        entries[el.question] = el.answer.id;
      });
      const data = {
        entries: { ...entries },
        score: this.score,
      };
      this.$api.quiz.submit(lessonQuiz.id, { data }).then(() => {
        this.state = 2;
        this.loading = false;
      });
      if (this.isPassed) {
        this.$emit('quizPassed');
      }
      const userId = $globalFn.ls.get('userId');

      trackAmplitudeEvent('quiz_submit', userId, {
        lesson_id: this.lesson.id,
        passed: this.isPassed,
      });
    },
    changeQuest(obj) {
      this.userAnswers.push(obj);
      if (obj.index === this.currQuiz.entries.length) {
        this.submitQuiz();
      } else {
        this.currQuestion = this.currQuiz.entries[obj.index];
        this.currIndex += 1;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  margin: 30px auto;
  height: 100%;
  justify-content: center;
  align-content: center;
  align-self: center;
}

.error {
  font-size: 14px;
  margin: 5px;
}
</style>
