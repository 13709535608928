<template>
  <div>
      Jobs Template
  </div>
</template>

<script>
export default {

};
</script>

<style>

</style>
