<template>
  <div @click="$emit('on-item-choose')" class="profile">
    <ul class="profile-sidebar-nav">
      <li>
        <router-link :to="{ name: 'profile' }" active-class="active">
          Личная информация
        </router-link>
      </li>
<!--      <li v-if="isAvailable">-->
<!--        <router-link active-class="active" :to="{ name: 'specialization' }">-->
<!--          Желаемая должность-->
<!--        </router-link>-->
<!--      </li>-->
<!--      <li v-if="isAvailable">-->
<!--        <router-link active-class="active" :to="{ name: 'experience-list' }">-->
<!--          Опыт работы-->
<!--        </router-link>-->
<!--      </li>-->
<!--      <li v-if="isAvailable">-->
<!--        <router-link active-class="active" :to="{ name: 'education-list' }">-->
<!--          Образование-->
<!--        </router-link>-->
<!--      </li>-->
<!--      <li v-if="isAvailable">-->
<!--        <router-link active-class="active" :to="{ name: 'portfolio-list' }">-->
<!--          Портфолио-->
<!--        </router-link>-->
<!--      </li>-->
      <li v-if="isAvailable">
        <router-link active-class="active" :to="{ name: 'certificates' }">
          Мои сертификаты
        </router-link>
      </li>
<!--      <li v-if="isAvailable">-->
<!--        <router-link active-class="active" :to="{ name: 'resume-file' }">-->
<!--          Скачать резюме-->
<!--        </router-link>-->
<!--      </li>-->

<!--      <div class="tech-support">-->
<!--        <h4>Нашли техническую ошибку?</h4>-->
<!--        <span>Напишите нам, мы с радостью поможем вам в решении</span>-->
<!--        <form-button-->
<!--          class="btn"-->
<!--          variant="primary"-->
<!--          fluid-->
<!--          :loading="showLoading"-->
<!--          :disabled="showLoading"-->
<!--        >-->
<!--          Написать-->
<!--        </form-button>-->
<!--      </div>-->
    </ul>

    <ul class="profile-sidebar-nav">
      <li>
        <router-link active-class="active" :to="{ name: 'set-password' }">
          Смена пароля
        </router-link>
      </li>
<!--      <li>-->
<!--        <router-link active-class="active" :to="{ name: 'set-email' }">-->
<!--          Смена почты-->
<!--        </router-link>-->
<!--      </li>-->
    </ul>
  </div>
</template>
<script>
import { mapState } from 'vuex';
// import FormButton from '../Button';

export default {
  components: {
    // FormButton,
  },
  data() {
    return {
      showLoading: false,
    };
  },
  computed: {
    ...mapState(['user']),
    isAvailable() {
      //      UNCOMMENT WHEN ENDPOINT IS READY
      //
      // const { tariffs } = this.$vars;
      // return this.user.profile.roles.find((r) =>
      //   [tariffs.standard, tariffs.business].includes(r)
      // );
      return true;
    },
  },
};
</script>
<style lang="scss" scoped>
.profile {
  & ul {
    list-style: none;
    width: 100%;
    border-radius: 10px;
    margin-bottom: 30px;
    padding: 15px;
    background: map-get($gray, 1000);
    height: 100%;
    & li {
      padding: 10px 0;
      &:first-of-type a {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
      }
      &:last-of-type a {
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
      }
      & a {
        font-weight: 500;
        padding: 10px 20px;
        display: flex;
        align-items: center;
        text-decoration: none;
        color: inherit;
        font-size: 16px;
        font-weight: lighter;
        color: rgba(0, 0, 0, 0.37);
        &:hover {
          background: map-get($gray, 900);
        }
        &.active {
          font-weight: 400;
          color: $deep-black;
        }
        & i {
          margin-right: 20px;
        }
      }
    }
  }
}

.tech-support {
  padding: 10px 20px;

  span {
    display: block;
    margin: 15px auto;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    /* or 180% */
    color: rgba(0, 0, 0, 0.37);
  }

  .btn {
    padding: 10px;
  }
}

@media screen and (max-width: $layout-s) {
  .profile {
    & ul {
      margin-bottom: 20px;
    }
  }
}
</style>
