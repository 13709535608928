import Vacancy from './index';
import Resume from './views/Resume';
import Jobs from './views/Jobs';
import Responses from './views/Responses';

export default [
  {
    path: '/vacancy',
    name: 'vacancy-list',
    component: Vacancy,
    children: [
      {
        path: '/resume',
        name: 'resume',
        component: Resume,
      },
      {
        path: '/jobs',
        name: 'jobs',
        component: Jobs,
      },
      {
        path: '/responses',
        name: 'Responses',
        component: Responses,
      },
    ],
  },
];
