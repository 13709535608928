<template>
  <div class="form-input-wrapper">
    <label class="form-input" :class="{ error }">
      <div v-if="icon" class="form-input-icon">
        <icon :name="icon" />
      </div>
      <input
        v-model="inputValue"
        :type="type"
        @input="$emit('input')"
        :placeholder="placeholder"
        :v-mask="mask"
      />
    </label>
    <span v-if="error && errorText" class="form-input-error-text">
      <i class="fas fa-exclamation-circle"></i>
      {{ errorText }}
    </span>
  </div>
</template>

<script>
import { mask } from 'vue-the-mask';
import Icon from '../icons';

export default {
  name: 'Input',
  components: { Icon },
  directives: { mask },
  props: {
    value: { type: String, default: '' },
    placeholder: { type: String, default: '' },
    icon: { type: String, default: '' },
    error: { type: Boolean, default: false },
    errorText: { type: String, default: '' },
    type: { type: String, default: 'text' },
    mask: { type: String, default: null },
  },
  model: {
    prop: 'value',
    event: 'change',
  },
  watch: {
    inputValue(val) {
      this.$emit('change', val);
    },
    value(val) {
      this.inputValue = val;
    },
    activeMask(val) {
      this.mask = val;
    },
  },
  data() {
    return {
      inputValue: '',
    };
  },
};
</script>

<style lang="scss" scoped>
.form-input {
  display: flex;
  align-items: center;
  background-color: map-get($gray, 1000);
  padding: 15px 20px;
  border-radius: 4px;
  transition: box-shadow 0.2s ease-in-out;
  &:focus-within {
    box-shadow: 0 0 0 1px $primary;
    ::v-deep {
      path {
        fill: $primary !important;
      }
    }
  }
  input {
    background: transparent;
    border: none;
    outline: none;
    width: 100%;
    font-size: 14px;
    &::placeholder {
      color: map-get($gray, 600);
    }
  }
  &-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    margin-right: 15px;
  }
  &.error {
    border: 1px solid $danger;
    &:focus-within {
      box-shadow: none !important;
    }
  }
  &-error-text {
    color: $danger;
    font-size: 12px;
    padding-left: 5px;
  }
}
</style>
