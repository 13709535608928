<template>
  <div class="program-stepper">
    <div
      v-for="item in items[user.tariff]"
      :key="item.label"
      class="item"
      :class="{ completed: item.completed }"
    >
      <div class="dot">
        <i v-if="item.completed" class="fal fa-check"></i>
        <div class="label">{{ item.label }}</div>
      </div>
      <div class="line"></div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'ProgramStepper',
  props: {
    statuses: { type: Object, default: () => {} },
  },
  data() {
    return {
      items: {
        SUBSCRIBED_USER: [
          {
            label: '1. Пройдите вводную часть: 5 блоков',
            completed: true,
          },
          {
            label: '2.  Выберите одну из четырех профессий: SMM-специалист, Таргетолог, Блогер, Интернет-маркетолог',
            completed: this.statuses.specialChose,
          },
          {
            label: '3. Выполняйте практику: домашние задания доступны только для просмотра и самостоятельной практики без обратной связи от кураторов',
            completed: this.statuses.specialCompleted,
          },
          {
            label: '4. Закончите курс блоком "Монетизация"',
            completed: this.statuses.lastCompleted,
          },
        ],
        SUBSCRIBED_USER_PLUS: [
          {
            label: '1. Пройдите вводную часть: 5 блоков',
            completed: true,
          },
          {
            label: '2.  Выберите одну из четырех профессий: SMM-специалист, Таргетолог, Блогер, Интернет-маркетолог',
            completed: this.statuses.specialChose,
          },
          {
            label: '3. Выполняйте практику: сдайте все домашние задания, пройдите тестирования и получите обратную связь наставников',
            completed: this.statuses.specialCompleted,
          },
          {
            label: '4. Закончите курс блоком "Монетизация"',
            completed: this.statuses.lastCompleted,
          },
          {
            label: '5. При выполнении всех условий - получите сертификат об окончании курса',
            completed: this.statuses.certificateReceived,
          },
        ],
        BUSINESS_USER: [
          {
            label: '1. Пройдите вводную часть: 5 блоков',
            completed: true,
          },
          {
            label: '2.  Выберите одну из четырех профессий: SMM-специалист, Таргетолог, Блогер, Интернет-маркетолог',
            completed: this.statuses.specialChose,
          },
          {
            label: '3. Выполняйте практику: сдайте все домашние задания, пройдите тестирования и получите обратную связь наставников',
            completed: this.statuses.specialCompleted,
          },
          {
            label: '4. Закончите курс надо блоком "Монетизация" и бонусным Бизнес-блоком',
            completed: this.statuses.lastCompleted,
          },
          {
            label: '5. При выполнении всех условий - получите сертификат об окончании курса',
            completed: this.statuses.certificateReceived,
          },
        ],
      },
    };
  },
  computed: {
    ...mapState(['user']),
  },
};
</script>

<style lang="scss" scoped>
.program-stepper {
  display: flex;
  .item {
    display: flex;
    align-items: center;
    min-width: 160px;
    width: 100%;
    &.completed {
    }
    .dot {
      position: relative;
      width: 16px;
      height: 16px;
      background-color: $primary;
      border-radius: 50%;
      color: $white;
      i {
        position: absolute;
        font-size: 10px;
        top: 3px;
        left: 4px;
      }
      .label {
        width: 150px;
        margin-top: 28px;
        margin-right: 10px;
        font-size: 12px;
        color: $dark;
      }
    }
    .line {
      width: 100%;
      border-bottom: 1px solid map-get($gray, 400);
    }
    &:last-child {
      .line {
        display: none;
      }
    }
  }
}
</style>
