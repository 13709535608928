<template>
  <div class="lesson-wrapper">
    <div v-if="isDataReady" :class="isNewsBarActive ? 'layout' : 'layout-wide'">
      <div class="lesson-main">
        <div class="lesson-content">
          <div class="sidebar__triggers">
            <span
              class="sidebar__triggers_item playlist"
              :class="{ active: isOpenPlaylist }"
              @click="togglePlaylistSidebar"
            >
              Оглавление
            </span>
            <!-- <span
              class="sidebar__triggers_item terms"
              :class="{ active: isOpenTerms }"
              @click="toggleTermsSidebar"
              >Глоссарий
            </span> -->
          </div>
          <div class="sidebar-playlist" :class="{ open: isOpenPlaylist }">
            <sidebar
              @show-modal="isLessonDisable = true"
              @lesson-handler="isLessonDisable = true"
              @block-handler="isBlockDisable = true"
              @close-sidebar="isOpenPlaylist = false"
              :courseActivity="lessonActivity"
              :lessonsActivities="lessonsActivityMap"
              @change-lesson="onSidebarLessonChange"
              :currentIndex="currentIndex"
              :currentLessonId="this.lesson?.id"
            />
          </div>
          <div class="sidebar-terms" :class="{ open: isOpenTerms }">
            <terms :terms="lesson?.entries" />
          </div>
          <div class="lesson-header">
            <div class="lesson-number">
              <p>Урок {{ currentIndex }}</p>
            </div>
            <div class="lesson-controls up-button">
              <button
                v-if="isNotFirstLesson"
                @click="prevLesson"
                class="control prev"
                :disabled="!isPrevAvailable"
              >
                Предыдущий
              </button>
              <button
                v-if="isNotLastLesson"
                @click="switchToNextLesson"
                class="control next"
                :disabled="!isNextAvailable"
              >
                Следующий
              </button>
            </div>
          </div>
          <div class="lesson-video">
            <div v-if="!this.lesson?.id" class="lesson-player-skeleton">
              <icon name="loader" />
            </div>

            <div v-else class="lesson-player">
              <player @video-ended="onVideoEnded" @time-update="onTimeUpdate" />
            </div>

            <sidebar
              @lesson-handler="isLessonDisable = true"
              :lesson="lesson"
              class="desktop-sidebar"
              :courseActivity="lessonActivity"
              :lessonsActivities="lessonsActivityMap"
              @change-lesson="onSidebarLessonChange"
              :currentIndex="currentIndex"
              :currentLessonId="this.lesson?.id"
            />
          </div>
          <div class="lesson-controls bottom-button">
            <button
              v-if="isNotFirstLesson"
              @click="prevLesson"
              class="control prev"
              :disabled="!isPrevAvailable"
            >
              Предыдущий
            </button>
            <button
              v-if="isNotLastLesson"
              @click="switchToNextLesson"
              class="control next"
              :disabled="!isNextAvailable"
            >
              Следующий
            </button>
          </div>
        </div>
      </div>
      <div v-if="lesson?.quizzes?.length > 0" class="quiz-box">
        <h3>Тест</h3>
        <table class="homework-list">
          <tr>
            <th>Заголовок</th>
            <th>Статус</th>
          </tr>
          <tr>
            <td>
              <div>
                <div class="homework-label">
                  <p>
                    {{ lesson?.quizzes[0]?.title }}
                  </p>
                </div>
              </div>
            </td>
            <td>
              <button
                class="homework-btn homework-btn-start"
                @click="showQuiz = !showQuiz"
              >
                Пройти тест
              </button>
            </td>
          </tr>
        </table>
        <div class="mobile-view">
          <div @click="showQuiz = !showQuiz" class="mobile-view__item">
            <div class="description">
              <p class="header">
                {{ lesson?.quizzes[0] ? lesson.quizzes[0].title : null }}
              </p>
            </div>
            <div class="button">
              <img src="./../../assets/images/mobileButton.svg" alt="" />
            </div>
          </div>
        </div>
      </div>
      <Homework v-if="lesson?.assignments?.length > 0" />
      <IndependentWork v-if="lesson?.selfAssignments?.length > 0" />
      <attachments v-if="lesson?.attachments?.length > 0" />
    </div>
    <grade-modal :show="showGradeModal" @close="closeModal" />
    <modal :show="isLessonDisable" @close="closeModal">
      <div
        v-if="
          isVideoNotCompleted &&
          isHomeWorkNotSend &&
          user.tariff !== $vars.tariffs.base &&
          user.tariff !== $vars.tariffs.business &&
          lesson?.homework?.isSubmittable
        "
        class="lesson-modal-disable"
      >
        <i class="far fa-exclamation-circle"></i>
        <h5>Урок пока не доступен</h5>
        <p>
          Вы не сможете перейти на следующий урок, пока вы не закончите просмотр
          текущего урока и не сделаете домашнее задание.
        </p>
        <btn variant="primary" fluid @click="closeModal">Понятно, спасибо</btn>
      </div>
      <div v-else-if="isVideoNotCompleted" class="lesson-modal-disable">
        <i class="far fa-exclamation-circle"></i>
        <h5>Урок пока не доступен</h5>
        <p>
          Вы не сможете перейти на следующий урок, пока вы не закончите просмотр
          текущего урока.
        </p>
        <btn variant="primary" fluid @click="closeModal">Понятно, спасибо</btn>
      </div>
      <div
        v-else-if="
          isHomeWorkNotSend &&
          user.tariff !== $vars.tariffs.base &&
          user.tariff !== $vars.tariffs.business &&
          lesson?.homework?.isSubmittable
        "
        class="lesson-modal-disable"
      >
        <i class="far fa-exclamation-circle"></i>
        <h5>Урок пока не доступен</h5>
        <p>
          Вы не сможете перейти на следующий урок, пока вы не сделаете домашнее
          задание.
        </p>
        <btn variant="primary" fluid @click="closeModal">Понятно, спасибо</btn>
      </div>
      <div v-else class="lesson-modal-disable">
        <i class="far fa-exclamation-circle"></i>
        <h5>Урок пока не доступен</h5>
        <p>
          Вы не сможете перейти на следующий урок, пока вы не закончите просмотр
          предыдущего урока.
        </p>
        <btn variant="primary" fluid @click="closeModal">Понятно, спасибо</btn>
      </div>
    </modal>
    <modal
      :show="showQuiz"
      @close="closeModal"
      :noPadding="true"
      :noFullWidth="true"
    >
      <QuizContainer
        @quizPassed="onQuizPassed"
        :quizId="hasQuiz"
        @close="closeModal"
      />
    </modal>
    <modal :show="isBlockDisable" @close="closeModal">
      <div class="lesson-modal-disable">
        <i class="far fa-exclamation-circle"></i>
        <h5>Блок пока не доступен</h5>
        <p>
          Вы не сможете перейти на следующий блок, подождите открытия блока.
        </p>
        <btn variant="primary" fluid @click="closeModal">Понятно, спасибо</btn>
      </div>
    </modal>
    <RateUnit
      :show="showFeedback"
      @send="feedbackSent"
      :lessonId="lesson?.id"
    />
  </div>
</template>

<script src="https://cdn.jsdelivr.net/npm/deep-diff@1/dist/deep-diff.min.js"></script>
<script>
import { mapActions, mapGetters } from 'vuex';
import { throttle } from 'lodash';
import Icon from '@/icons';
import Player from '@/components/Lesson/Player';
import Sidebar from '@/components/Lesson/Sidebar';
import GradeModal from '@/components/Lesson/modals/Grade';
import Terms from '@/components/Lesson/Terms';
import Attachments from '@/components/Lesson/Attachments';
import Modal from '@/components/Modal';
import Btn from '@/components/Button';
import trackAmplitudeEvent from '@/utils/trackAmplitudeEvent';
import QuizContainer from '@/components/Quiz/QuizContainer';
import IndependentWork from '@/components/Lesson/IndependentWork';
import RateUnit from '../../components/Lesson/modals/RateUnit';
import Homework from '../../components/Lesson/Homework.vue';
import $globalFn from '../../fn';

export default {
  name: 'Lesson',
  components: {
    Player,
    Sidebar,
    Attachments,
    IndependentWork,
    Icon,
    Modal,
    Btn,
    GradeModal,
    Terms,
    QuizContainer,
    Homework,
    RateUnit,
  },
  async created() {
    await this.fetchCourseUnits(this.currentCourse);
    await this.initLessonActivity(this.getCurrentCourse);
    this.isDataReady = true;
    this.$parent.$refs.news_bar.$data.isLesson = true;
    this.throttledSendActivity = throttle(this.sendActivityThrottled, 6000); // throttle for 6 seconds to send only 1 event update
  },
  beforeDestroy() {
    this.$parent.$refs.news_bar.$data.isLesson = false;
    this.initCurrentLessonIndex(0);
  },
  data() {
    return {
      id: null,
      activeTab: 0,
      isBlockDisable: false,
      isLessonDisable: false,
      showGradeModal: false,
      showQuiz: false,
      isNotCompleted: false,
      isOpenTerms: false,
      isOpenPlaylist: false,
      lessonId: null,
      currentNumber: 1,
      currentIndex: 1,
      isDataReady: false,
      lessonsActivityMap: new Map(),
      isPrevAvailable: true,
      isInitialized: false,
      showFeedback: false,
    };
  },
  watch: {
    lessonActivity() {
      const lessonsActivity = this.lessonActivity.allLessonsActivity;

      if (!lessonsActivity) {
        return;
      }

      lessonsActivity.forEach((activity) => {
        this.lessonsActivityMap.set(activity.lessonId, { ...activity });
      });

      const lessonId =
        this.lessonActivity.lastLessonId ?? this.units.lessons[0];
      this.checkAvailabilityOnLesson(lessonId);

      if (this.isInitialized) {
        return;
      }

      this.initLesson(lessonId);
      this.changeLesson(lessonId);
      this.isInitialized = true;
    },
    getUnits() {
      this.initLesson(this.getUnits[0].lessons[0]);
      this.changeLesson(this.getUnits[0].lessons[0]);
    },
    lesson(newLesson) {
      this.setLessonActivity(newLesson);
    },
  },
  computed: {
    ...mapGetters([
      'getIsActiveNewsBar',
      'getCurrentCourse',
      'getLesson',
      'getUnits',
    ]),
    ...mapGetters({
      lesson: 'getLesson',
      lessonActivity: 'getLessonActivity',
      lessonInfo: 'getLessonInfo',
    }),
    lastLesson() {
      if (this.lessonActivity?.lastLessonId) {
        return this.beforeLessonChange(this.lessonActivity.lastLessonId);
      }
      return this.beforeLessonChange(this.units?.[0]?.lessons?.[0]?.id);
    },
    isNextAvailable() {
      const nextUnit = this.getUnits.find((unit) =>
        unit?.lessons.includes(this.nextLesson)
      );
      const now = new Date();

      /**
       * Check if unit has started or not
       */
      if (
        new Date(nextUnit.startDate) > now ||
        new Date(nextUnit.endDate) < now
      ) {
        return false;
      }

      return this.lessonInfo?.isLessonViewed;
    },
    currentCourse() {
      const { id } = this.$route.params;
      this.initCurrentCourse(id);
      return this.getCurrentCourse;
    },
    isNewsBarActive() {
      return this.getIsActiveNewsBar;
    },
    blocksFlat() {
      this.setLesson();
      const units = this.getUnits;
      return units.flatMap((v) => [...v.lessons]);
    },
    nextLesson() {
      const currentLessonIdx = this.blocksFlat.findIndex(
        (item) => item.id === +this.$route.params.id
      );
      return this.blocksFlat.find((item, idx) => idx === currentLessonIdx + 1);
    },
    hasQuiz() {
      if (this.lesson?.quizzes) {
        return this.lesson?.id;
      }
      return false;
    },
    isVideoNotCompleted() {
      return this.lesson?.lessonUser
        ? !this.lesson.lessonUser?.isCompleted
        : true;
    },
    isHomeWorkNotSend() {
      if (!this.lesson?.homework) return false;
      if (!this.lesson?.homework?.homeTaskUser) return true;
      return +this.lesson.homework.homeTaskUser.status === 0;
    },
    isNotFirstLesson() {
      const firstLesson = this.blocksFlat?.[0];
      return firstLesson?.id !== +this.$route.params.id;
    },
    isNotLastLesson() {
      const lastLesson = this.blocksFlat?.[this.blocksFlat.length - 1];
      return lastLesson?.id !== +this.$route.params.id;
    },
    isLastLessonOfUnit() {
      const units = this.getUnits;
      const lastLessonsOfUnits = [];
      units.forEach((el) => lastLessonsOfUnits.push(...el.lessons.slice(-1)));
      return lastLessonsOfUnits.some((el) => el.id === this.lesson?.id);
    },
  },
  methods: {
    setCurrentIndex(lessonId) {
      this.currentIndex =
        this.blocksFlat.findIndex((_lesson) => lessonId === _lesson.id) + 1;
    },
    setLesson() {
      // this.$parent.$refs.news_bar.$data.lesson = this.lesson;
    },
    async sendActivityThrottled(time, duration, hasViewed) {
      await this.sendLessonActivity({
        id: this.lesson?.id,
        courseId: this.getCurrentCourse,
        viewedSeconds: time,

        ...(hasViewed && {
          isLessonViewed: true,
          ...((!this.lesson?.quizzes || this.lesson?.quizzes.length === 0) && {
            isQuizCompleted: true,
          }),
        }),
      });

      if (hasViewed) {
        const currentLesson = this.blocksFlat.find(
          (v) => v?.id === this.lesson?.id
        );
        const userId = $globalFn.ls.get('userId');
        const programId = this.$fn.ls.get('program');
        const currentUnit = this.getUnits.find((unit) =>
          unit?.lessons.includes(currentLesson)
        );

        trackAmplitudeEvent('lesson_watched', userId, {
          program_id: programId,
          course_id: this.currentCourse,
          lesson_title: this.lesson?.title,
          lesson_id: this.lesson?.id,
          unit_id: currentUnit.id,
          unit_title: currentUnit.title,
        });
        await this.setLessonActivity(this.lesson);
        this.initLessonActivity(this.getCurrentCourse);
      }
    },
    async setLessonActivity(lesson) {
      const currentLesson = this.lessonActivity?.allLessonsActivity.find(l => l.lessonId === lesson?.id);
      const lastViewSeconds = currentLesson?.viewedSeconds;
      await this.sendLessonActivity({
        id: lesson?.id,
        courseId: this.getCurrentCourse,
        viewedSeconds: lastViewSeconds ?? 1,
      });
      await this.initLessonInfo(lesson?.id);
    },
    checkAvailabilityOnLesson(lessonId) {
      const currentLessonActivity = this.lessonsActivityMap.get(lessonId);
      const newLesson = this.blocksFlat.find(
        (lesson) => lesson?.id === lessonId
      );

      let newLessonIndex = this.blocksFlat.indexOf(newLesson);
      if (newLessonIndex < 0) {
        newLessonIndex = 0;
      }

      const currentUnit = this.getUnits.find((unit) =>
        unit?.lessons.includes(newLesson)
      );

      /**
       * Check if next lesson is available
       */
      if (newLessonIndex === this.blocksFlat.length - 1) {
        this.isNextAvailable = false;
      } else {
        const nextLesson = this.blocksFlat?.[newLessonIndex + 1];
        const newLessonActivity = this.lessonsActivityMap.get(lessonId);
        const nextLessonActivity = this.lessonsActivityMap.get(nextLesson?.id);
        const nextUnit = this.getUnits.find((unit) =>
          unit?.lessons.includes(nextLesson)
        );
        const now = new Date();

        /**
         * Check if unit has started or not
         */
        if (
          new Date(nextUnit.startDate) > now ||
          new Date(nextUnit.endDate) < now
        ) {
          /**
           * If hasn't started disable next button
           */
          this.isNextAvailable = false;
        } else if (!nextLessonActivity) {
          this.isNextAvailable = !!(
            newLessonActivity?.isQuizCompleted &&
            currentLessonActivity?.isLessonViewed
          );
        } else {
          this.isNextAvailable = true;
        }
      }

      /**
       * Check if previous lesson is available
       */
      this.isPrevAvailable = newLessonIndex > 0;

      return currentUnit;
    },
    onSidebarLessonChange(lessonId) {
      this.checkAvailabilityOnLesson(lessonId);
      this.changeLesson(lessonId);
      this.$router.replace({ query: null });
    },
    changeLesson(lessonId) {
      this.setCurrentIndex(lessonId);
      this.beforeLessonChange(lessonId);
    },
    switchToNextLesson() {
      if (!this.isNextAvailable) return;
      if (this.isLastLessonOfUnit) {
        this.showFeedback = true;
      } else {
        const currentLesson = this.blocksFlat.find(
          (v) => v?.id === this.lesson?.id
        );
        const currentLessonIndex = this.blocksFlat.indexOf(currentLesson);
        this.checkAvailabilityOnLesson(
          this.blocksFlat?.[currentLessonIndex + 1]?.id
        );
        this.changeLesson(this.blocksFlat?.[currentLessonIndex + 1]?.id);
        this.initCurrentLessonIndex(currentLessonIndex + 1);
        this.$router.replace({ query: null });
      }
    },
    feedbackSent() {
      this.showFeedback = false;
      if (!this.isNextAvailable) return;

      const currentLesson = this.blocksFlat.find(
        (v) => v?.id === this.lesson?.id
      );

      const currentLessonIndex = this.blocksFlat.indexOf(currentLesson);

      this.checkAvailabilityOnLesson(
        this.blocksFlat?.[currentLessonIndex + 1]?.id
      );
      this.changeLesson(this.blocksFlat?.[currentLessonIndex + 1]?.id);
      this.initCurrentLessonIndex(currentLessonIndex + 1);
    },
    prevLesson() {
      if (!this.isPrevAvailable) return;

      const currentLesson = this.blocksFlat.find(
        (v) => v?.id === this.lesson?.id
      );
      const currentLessonIndex = this.blocksFlat.indexOf(currentLesson);
      const prevLesson = this.blocksFlat?.[currentLessonIndex - 1];
      this.initCurrentLessonIndex(currentLessonIndex - 1);
      this.checkAvailabilityOnLesson(prevLesson?.id);
      this.changeLesson(this.blocksFlat?.[currentLessonIndex - 1]?.id);
      this.$router.replace({ query: null });
    },
    closeModal() {
      this.isBlockDisable = false;
      this.isLessonDisable = false;
      this.showGradeModal = false;
      this.isNotCompleted = false;
      this.showQuiz = false;
    },
    changeActiveTab(tab) {
      if (tab === this.activeTab) {
        this.activeTab = 0;
      } else {
        this.activeTab = tab;
      }
    },
    toggleTermsSidebar() {
      this.isOpenTerms = !this.isOpenTerms;
      this.isOpenPlaylist = false;
    },
    togglePlaylistSidebar() {
      this.isOpenPlaylist = !this.isOpenPlaylist;
      this.isOpenTerms = false;
    },
    onVideoEnded() {
      this.sendLessonActivity({
        isLessonViewed: true,
        id: this.lesson?.id,
        courseId: this.getCurrentCourse,
      });
    },
    async onTimeUpdate(event) {
      const time = event.target?.player?.currentTime();
      const duration = event.target?.player?.duration();
      if (!time) {
        return;
      }
      const hasViewed = time > duration - 5; // last 5 seconds

      if (this.lessonInfo.viewedSeconds < time - 5) {
        this.lessonInfo.viewedSeconds += 5;
        await this.sendLessonActivity({
          id: this.lesson?.id,
          courseId: this.getCurrentCourse,
          viewedSeconds: time,
        });
      }

      if (!hasViewed) {
        return;
      }

      await this.throttledSendActivity(time, duration, hasViewed);
    },
    onQuizPassed() {
      this.sendLessonActivity({
        isQuizCompleted: true,
        id: this.lesson?.id,
        courseId: this.getCurrentCourse,
      });

      this.initLessonActivity(this.getCurrentCourse);
    },
    ...mapActions([
      'initLesson',
      'beforeLessonChange',
      'fetchCourseUnits',
      'initCurrentCourse',
      'initCurrentLessonIndex',
      'sendLessonActivity',
      'initLessonActivity',
      'initLessonInfo',
    ]),
  },
};
</script>

<style lang="scss" scoped>
.mobile-view {
  display: none;
  margin-top: 20px;

  @media (max-width: $layout-xs) {
    display: block;
  }

  &__item {
    background-color: #e8e8e885;
    border-radius: 10px;
    padding: 20px 20px;
    display: flex;
    justify-content: space-between;

    .button {
      display: flex;
      align-items: center;
      padding-right: 20px;
    }

    .description {
      .header {
        font-weight: 700;
      }
    }
  }
}

.quiz-box {
  margin: auto;
  margin-top: 75px;
  width: 100%;
  max-width: 1140px;

  h3 {
    margin-left: 30px;

    @media (max-width: $layout-xs) {
      margin-left: 5px;
    }
  }
}

.layout {
  width: 95%;
  max-width: 2000px;
}

.lesson {
  &-video {
    display: flex;
    justify-content: stretch;
    max-width: 1140px;
    width: 100%;
    margin: auto;
    margin-top: 20px;

    @media screen and (max-width: $layout-l) {
      flex-direction: column;
    }
  }

  &-wrapper {
    padding-top: 36px;
    padding-bottom: 35px;
  }

  &-number {
    color: #3a7dff;
    background-color: rgba(58, 125, 255, 0.1);
    border-radius: 10px;
    width: 135px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-main {
    display: flex;

    @media screen and (max-width: $layout-m) {
      flex-direction: column;
    }
  }

  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 1140px;
    margin: auto;

    h1 {
      font-weight: 400;
      float: left;
      font-size: 26px;
    }
  }

  &-controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    .control {
      display: inline-block;
      align-items: center;
      justify-content: center;
      padding: 15px 30px 15px 30px;
      text-align: center;
      min-height: 50px;
      color: white;
      font-size: 14px;
      border-radius: 10px;
      font-family: 'Rubik', sans-serif;
      font-weight: 400;
      background-color: $primary;
      outline: none;
      box-shadow: none;
      border: none;

      &.prev {
        margin-right: 15px;
      }

      &:not(:disabled) {
        cursor: pointer;

        &:hover {
          transition: 0.5s;
          background-color: $yellow;
        }
      }

      &:disabled {
        cursor: not-allowed;
        background-color: map_get($gray, 500);
        opacity: 0.7;
      }
    }
  }

  &-player,
  &-player-skeleton {
    /*<!--border: 1px solid map-get($gray, 400);-->*/
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    max-height: 470px;
    width: 100%;
    max-width: 800px;

    @media screen and (max-width: $layout-l) {
      max-height: initial;
      max-width: 100%;
    }
  }

  &-player-skeleton {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: map-get($gray, 1000);
    height: 470px;
    margin-top: 20px;
  }

  &-content {
    width: 100%;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: $layout-m) {
      flex-direction: column;
    }
  }

  &-loader {
    margin-top: 50px;
  }

  &-modal-disable {
    width: 275px;
    padding-bottom: 15px;
    box-sizing: border-box;

    & i {
      margin-bottom: 40px;
      width: 35px;
      height: 35px;
      color: $danger;
      font-size: 35px;
    }

    & > h5 {
      margin-bottom: 25px;
      font-weight: 500;
      font-size: 18px;
    }

    & > p {
      margin-bottom: 35px;
      font-size: 14px;
      line-height: 21px;
    }

    & > .btn {
      height: 50px;
    }

    .text-attention {
      background-color: #f10b0b54;
    }
  }

  &-grade-icon {
    color: $yellow;
    margin-right: 20px;
    cursor: pointer;
    border: 1px solid;
    transition: all 0.2s ease-in-out;

    &:hover {
      color: $white;
      background-color: $yellow;
    }
  }
}

.sidebar__triggers {
  z-index: 2;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  height: 3em;
  width: 100vw;
}

/* Sidebar */
.sidebar__triggers_item {
  padding-top: 15px;
  text-align: center;
  // width: 50%;
  width: 100%;
  height: 100%;
  background: #ffffff;
  text-decoration: none;
  color: black;
  box-shadow: 0 2px 8px rgba(25, 40, 47, 0.1);

  &.active {
    color: $primary;
    font-weight: 500;
  }
}

.playlist {
  padding-left: 20px;
}

.quiz--btn {
  margin: 10px 0;
}

.sidebar {
  &-playlist,
  &-terms {
    width: 90%;
    height: 100vh;
    position: fixed;
    top: 127px;
    z-index: 1;
    overflow-y: auto;
    transition: 0.2s ease;
    background-color: $white;
    box-shadow: 0 2px 8px rgba(25, 40, 47, 0.2);
  }

  &-playlist {
    left: -100%;

    &.open {
      left: 0;
      z-index: 2;
    }
  }

  &-terms {
    right: -100%;

    &.open {
      right: 0;
      z-index: 2;
    }
  }
}

.layout-wide {
  margin: 0 32px 0 20px;

  @media (max-width: $layout-m) {
    margin: 0 16px 0 20px;
  }
}

.bottom-button {
  display: none;
}

@media (max-width: $layout-xs) {
  .control {
    margin-left: 20px;
    padding: 15px 10px 15px 10px;
  }

  .bottom-button {
    display: flex;
  }

  .up-button {
    display: none;
  }
}

@media (max-width: $layout-l) {
  .control {
    &.prev {
      margin: 15px 15px 0 0;
    }

    &.next {
      margin: 15px 0 0 0;
    }
  }
}

@media (min-width: $layout-s) {
  .sidebar__triggers {
    display: none;
  }
}

@media screen and (max-width: $layout-s) {
  .lesson-header {
    padding-right: 0;
  }

  .lesson-header h1 {
    width: 100vw;
    font-size: 20px;
    max-width: 100vw;
  }

  .desktop-sidebar {
    display: none;
  }

  .lesson-wrapper {
    margin-top: 3em;
  }

  .s-sidebar__nav {
    display: block;
  }
}

.sidebar-playlist {
  background-color: map-get($gray, 1000);
  border-radius: 10px;
  padding: 0;
}

.homework-list {
  @media (max-width: $layout-xs) {
    display: none;
  }

  width: 100%;
  border: 1px solid #e8e8e8;
  border-radius: 20px;
  margin: 15px auto;

  th {
    border-right: 1px solid #e8e8e8;
    text-align: left;
    padding: 10px;
    padding-left: 25px;
    font-size: 18px;
    line-height: 24px;
    /* identical to box height */
    font-weight: 200;
    color: rgba(0, 0, 0, 0.3);

    &:last-of-type {
      border: none;
    }
  }

  td {
    padding: 20px;
    border-bottom: 1px solid #e8e8e8;

    &:last-of-type {
      width: 30%;
    }

    .teacher {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 20px;

      img {
        width: 40px;
        height: auto;
      }
    }
  }

  tr {
    &:last-of-type {
      td {
        border: none;
      }
    }
  }
}

.homework {
  h3 {
    font-weight: 500;
    font-size: 20px;
    color: $yellow;
    margin-bottom: 31px;
  }

  &-item {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 35px 5px;
    color: $white;
    box-sizing: border-box;
    border-radius: 8px;

    &.is-done {
      color: $dark;
      background-color: map-get($gray, 200);
    }

    h5 {
      font-weight: 500;
      font-size: 16px;
      margin-top: 5px;
    }
  }

  &-status {
    font-size: 14px;
  }

  &-label {
    font-size: 14px;
  }

  &-btn {
    font-weight: 500;
    font-size: 14px;
    border-radius: 3px;
    width: 100%;
    height: 44px;
    border: none;
    outline: none;

    &-done {
      font-weight: 400;
      font-size: 18px;
      line-height: 21px;
      color: $primary;
      background: $secondary;
      padding: 0 30px;
      border-radius: 10px;
      text-align: center;
      margin: 0 auto;
    }

    &-start {
      font-weight: 400;
      font-size: 18px;
      line-height: 21px;
      color: $primary;
      background: $secondary;
      padding: 0 30px;
      border-radius: 10px;
      text-align: center;
      margin: 0 auto;
    }

    &-returned {
      background: $danger;
      color: $white;
    }
  }
}

@media screen and (max-width: $layout-s) {
  .homework-item {
    flex-direction: column;
    padding: 20px;
    text-align: center;
  }

  .homework-item > div:not(:last-child) {
    margin-bottom: 20px;
  }

  .homework-btn {
    width: 120px;

    :hover {
      cursor: pointer;
    }
  }
}
</style>
