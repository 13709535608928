<template>
  <div class="webinar-room">
    <div class="layout">
      <div v-if="!webinar.webinar.isRegistered && !isPast" class="registration">
        <div class="registration-welcome">
          <h3>Добро пожаловать на вебинар!</h3>
          <p class="registration-subtitle">
            Зарегистрируйтесь, чтобы получить доступ на вебинар
          </p>
          <ul class="webinar-meta">
            <li>
              <span class="webinar-meta-icon">
                <i class="fas fa-laptop" />
              </span>
              <div>
                <span>Тема вебинара</span> <br />
                <p>{{ webinar.webinar.title }}</p>
              </div>
            </li>
            <li>
              <span class="webinar-meta-icon">
                <i class="fas fa-calendar-alt" />
              </span>
              <div>
                <span>Начало трансляции</span> <br />
                <p>
                  <b>{{ webinar.broadcastAt | dateToHours }}</b>,
                  {{ webinar.broadcastAt | dateToDays }}
                </p>
              </div>
            </li>
          </ul>
          <h3>Спикеры</h3>
          <ul class="speakers">
            <li v-for="speaker in webinar.webinar.speakers" :key="speaker.id">
              <img :src="speaker.image" alt="Спикер" />
              <div>
                <span>Спикер</span> <br />
                <p>{{ speaker.firstName }} {{ speaker.lastName }}</p>
              </div>
            </li>
          </ul>
          <p v-if="webinar.isRegistrError" class="registration-error">
            В вашем тарифе данный вебинар недоступен
          </p>
          <btn
            @click="registerToWebinar(webinar.webinar.id)"
            :loading="webinar.loading"
            class="registration-btn"
          >
            Зарегистрироваться
          </btn>
        </div>
      </div>
      <div v-else class="webinar-room-general">
        <div class="webinar-room-content">
          <div class="webinar-room-media">
            <iframe
              :src="`https://youtube.com/embed/${webinar.webinar.link}`"
              :title="webinar.webinar.title"
              allow="accelerometer; autoplay;
              clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            />
          </div>
          <div class="webinar-room-info">
            <div class="webinar-topic">
              <h4>Тема вебинара</h4>
              <p>{{ webinar.webinar.title }}</p>
            </div>
            <div class="webinar-speakers">
              <span>Спикеры</span>
              <h4 v-for="speaker in webinar.webinar.speakers" :key="speaker.id">
                {{ speaker.firstName }} {{ speaker.lastName }}
              </h4>
            </div>
          </div>
        </div>
        <chat class="webinar-room-chat" v-if="webinar.webinar.isLive" />
        <div class="webinar-room-info mobile">
          <div class="webinar-topic">
            <h4>Тема вебинара</h4>
            <p>{{ webinar.webinar.title }}</p>
          </div>
          <div class="webinar-speakers">
            <span>Спикеры</span>
            <h4 v-for="speaker in webinar.webinar.speakers" :key="speaker.id">
              {{ speaker.firstName }} {{ speaker.lastName }}
            </h4>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import $dayjs from '@/plugins/dayjs';

import Btn from '@/components/Button';
import Chat from '@/components/Webinars/Chat';

export default {
  components: { Btn, Chat },
  mounted() {
    this.fetchWebinar(this.$route.params.id);
  },
  filters: {
    dateToDays(date) {
      return $dayjs(date).format('DD MMMM, YYYY');
    },
    dateToHours(date) {
      return $dayjs(date).format('hh:mm');
    },
  },
  computed: {
    ...mapState(['webinar', 'user']),
    isWebinarStarted() {
      return this.webinar.broadcast;
    },
    isPast() {
      return !!this.webinar.webinars.past?.find((v) => v.id === this.webinar.webinar.id);
    },
  },
  methods: {
    ...mapActions(['fetchWebinar', 'registerToWebinar']),
  },
};
</script>

<style lang="scss" scoped>
.webinar-room {
  padding-top: 10px;
  padding-bottom: 50px;
  background-color: map-get($gray, 200);
  &-general {
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: $layout-m) {
      flex-direction: column;
    }
  }
  &-content {
    width: 100%;
    //@media screen and (max-width: $layout-s) {
    //  position: sticky;
    //  top: 0;
    //  left: 0;
    //  z-index: 9;
    //}
  }
  .registration {
    padding: 0 100px;
    @media screen and (max-width: $layout-s) {
      padding: 0;
    }
    text-align: center;
    &-welcome {
      padding-top: 50px;
      h3 {
        margin-bottom: 25px;
        font-weight: 500;
        font-size: 28px;
        line-height: 33px;
      }
      .registration-subtitle {
        margin-bottom: 50px;
        font-size: 18px;
        line-height: 21px;
      }
      ul {
        display: flex;
        flex-wrap: wrap;
        list-style: none;
        margin-bottom: 70px;
        @media screen and (max-width: $layout-s) {
          margin-bottom: 20px;
        }
        li {
          max-width: 50%;
          margin: 0 50px 30px 0;
          display: flex;
          align-items: center;
          font-size: 14px;
          text-align: left;
          @media screen and (max-width: $layout-s) {
            max-width: unset;
            margin: 0 30px 30px 30px;
          }
          .webinar-meta-icon {
            margin-right: 15px;
            width: 45px;
            height: 45px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: $white;
            border-radius: 50%;
            background-color: $primary;
            overflow: hidden;
            font-size: 18px;
            img {
              max-width: 100%;
              object-fit: cover;
            }
          }
          div {
            span {
              display: inline-block;
              color: map-get($gray, 500);
              margin-bottom: 5px;
            }
          }
        }
      }
    }
    &-btn {
      width: 280px;
    }
    .speakers {
      img {
        margin-right: 15px;
        width: 45px;
        height: 45px;
        border-radius: 50%;
        object-fit: cover;
      }
    }
    &-error {
      margin: 20px;
      color: $danger;
    }
  }
  &-media {
    width: 100%;
    height: 300px;
    //position: relative;
    //width: 100%;
    //height: 0;
    //padding-bottom: 55%;
    iframe {
      width: 100%;
      height: 100%;
      display: block;
      //position: absolute;
      //top: 0;
      //left: 0;
      //width: 100%;
      //height: 100%;
    }
  }
  &-chat {
    width: 30%;
    min-width: 333px;
    max-height: 460px;
    margin-bottom: 30px;
    @media screen and (max-width: $layout-s) {
      width: 100%;
      margin-top: 20px;
      min-width: 100%;
      max-height: 100%;
    }
  }
}
.webinar {
  &-room-info {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 16px 100px 16px 16px;
    border: 1px solid map-get($gray, 400);
    border-radius: 6px;
    font-size: 14px;
    line-height: 1.4;
    &.mobile {
      display: none;
      @media screen and (max-width: $layout-s) {
        display: block;
      }
      }
    @media screen and (max-width: $layout-s) {
      display: none;
    }
      h4 {
      font-weight: 500;
      margin-bottom: 10px;
    }
    span {
      margin-bottom: 10px;
    }
  }
  &-topic {
    max-width: 60%;
    display: flex;
    flex-direction: column;
  }
  &-speakers {
    max-width: 20%;
    display: flex;
    flex-direction: column;
  }
}
</style>
