<!-- eslint-disable max-len -->

<template>
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.23131 0C3.24368 0 0 3.24668 0 7.23535V17.5687C0 21.5563 3.24668 24.8 7.23535 24.8H17.5687C21.5563 24.8 24.8 21.5533 24.8 17.5646V7.23131C24.8 3.24368 21.5533 0 17.5646 0H7.23131ZM19.6333 4.13333C20.2037 4.13333 20.6667 4.59627 20.6667 5.16667C20.6667 5.73707 20.2037 6.2 19.6333 6.2C19.0629 6.2 18.6 5.73707 18.6 5.16667C18.6 4.59627 19.0629 4.13333 19.6333 4.13333ZM12.4 6.2C15.8193 6.2 18.6 8.9807 18.6 12.4C18.6 15.8193 15.8193 18.6 12.4 18.6C8.9807 18.6 6.2 15.8193 6.2 12.4C6.2 8.9807 8.9807 6.2 12.4 6.2ZM12.4 8.26667C11.3038 8.26667 10.2524 8.70214 9.47729 9.47729C8.70214 10.2524 8.26667 11.3038 8.26667 12.4C8.26667 13.4962 8.70214 14.5476 9.47729 15.3227C10.2524 16.0979 11.3038 16.5333 12.4 16.5333C13.4962 16.5333 14.5476 16.0979 15.3227 15.3227C16.0979 14.5476 16.5333 13.4962 16.5333 12.4C16.5333 11.3038 16.0979 10.2524 15.3227 9.47729C14.5476 8.70214 13.4962 8.26667 12.4 8.26667Z"
      fill="black"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconInstagramFlat',
  props: ['color'],
};
</script>
<!-- eslint-enable -->
