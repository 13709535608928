<template>
  <div class="program-blocks">
    <div
      v-for="item in items"
      :key="item.id"
      class="program-blocks-item"
    >
      <div class="program-blocks-name">{{ item.title }}</div>
<!--      <div class="program-blocks-info">-->
<!--        <div class="program-blocks-duration">3 ч 54 мин.</div>-->
<!--        <div class="program-blocks-status">Завершен</div>-->
<!--      </div>-->
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProgramBlocks',
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
.program-blocks {
  padding-top: 45px;
  &-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 2px;
    background-color: $white;
    padding: 15px 20px 15px 25px;
    margin-bottom: 12px;
    @media screen and (max-width: $layout-s) {
      width: 90%;
      margin: 12px auto;
    }
  }
  &-name {
    font-weight: 500;
  }
  &-duration {
    margin-right: 35px;
    color: map-get($gray, 500);
  }
  &-info {
    display: flex;
  }
}
</style>
