<template>
  <div class="note">
    <div class="note-header">
      <span class="note-type" :style="{ 'background-color': note.noteType.color }"></span>
<!--      <div class="note-editing-tools" :class="{active: isEdit}">-->
<!--        <i class="fas fa-undo-alt note-editing-undo"></i>-->
<!--        <i class="fas fa-redo-alt note-editing-undone"></i>-->
<!--      </div>-->
      <span class="note-actions">
        <tooltip>
          <template #activator>
            <i class="fas fa-ellipsis-v"></i>
          </template>
          <template>
            <div class="note-actions-btns">
              <span @click="onEdit"><i class="far fa-edit" />Редактировать</span>
              <span @click="deleteNote(note.id)"><i class="far fa-trash-alt" />Удалить</span>
            </div>
          </template>
        </tooltip>
      </span>
    </div>
<!--      <h3 class="note-title">{{ note.lesson.title }}</h3>-->
      <p class="note-content"
         :class="{editing: isEdit}"
         :value="noteValue"
         @input="editHandler"
         @click="onEdit"
         @blur="clickOutside"
         contenteditable="true"
      >
        {{ note.text }}
      </p>
      <div class="note-footer">
        <div class="note-date">
          <span>Создано: {{ note.created }}</span>
          <span v-if="note.lastModified">Изменено: {{ note.lastModified }}</span>
        </div>
        <router-link
          :to="{name: 'lesson', params: {id: note.lesson.id}}"
          tag="span"
          class="note-link"
        >
          <i class="fas fa-chevron-right" />
        </router-link>
      </div>
    <modal>
      <div>
        <form class="note-edit-form" @submit.prevent>
          <textarea
            @focus="isEdit = true"
            class="note-content"
            :class="{'is-edit': isEdit}"
            v-model.trim="noteValue"
            ref="noteValue"
          />
          <btn v-if="isEdit" @click="updateNote">
            <icon v-if="loading" name="loader"/>
            <span v-else>Сохранить</span>
          </btn>
          <btn v-if="isEdit" class="note-edit-form-cancel" @click="cancelChange">Отменить</btn>
        </form>
      </div>
    </modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import Tooltip from '@/components/Tooltip';
import Modal from '@/components/Modal';
import Btn from '@/components/Button';
import Icon from '@/icons';

export default {
  name: 'Note',
  props: { note: { type: Object, default: () => ({}) } },
  components: {
    Modal,
    Tooltip,
    Btn,
    Icon,
  },
  directives: {},

  data() {
    return {
      isEdit: false,
      loading: false,
      noteValue: this.note.text,
    };
  },
  methods: {
    editHandler(e) {
      this.noteValue = e.target.innerText;
      // const words = e.target.innerText.split(' ');
    },
    updateNote() {
      this.loading = true;
      this.$api.lesson.notes.update({
        id: this.note.id,
        data: { text: this.noteValue },
      })
        .then(() => { this.isEdit = false; })
        .finally(() => { this.loading = false; });
    },
    cancelChange() {
      this.noteValue = this.note.text;
      this.isEdit = false;
    },
    onEdit() {
      this.isEdit = true;
    },
    clickOutside() {
      this.isEdit = false;
      if (this.noteValue !== this.note.text) this.updateNote();
    },
    ...mapActions(['deleteNote']),
  },
};
</script>

<style lang="scss" scoped>
.note {
  margin-right: 20px;
  margin-bottom: 20px;
  padding: 30px;
  width: 350px;
  height: fit-content;
  position: relative;
  border-radius: 12px;
  background-color: map-get($gray, 200);
  break-inside: avoid;
  &-header {
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
  }
  &-type {
    width: 15px;
    height: 15px;
    border-radius: 50%;
  }
  &-actions {
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    i {
      display: inline-block;
      padding: 10px;
      font-weight: 500;
      &:hover {
        color: $primary;
      }
    }
    &-btns {
      height: 80px;
      display: flex;
      flex-direction: column;
      border: 1px solid map-get($gray, 400);
      border-radius: 4px;
      background-color: $white;
    }
    ::v-deep .ui-tooltip-content {
      right: 0;
    }
    span {
      display: flex;
      align-items: center;
      padding: 2px 10px;
      font-size: 14px;
      transition: all .2s ease-in-out;
      &:hover {
        background-color: $primary;
        color: $white;
      }
    }
    span:not(:last-child) {
      border-bottom: .5px solid map-get($gray, 400);
    }
  }
  //&-title {
  //  margin-bottom: 15px;
  //  font-weight: 500;
  //  font-size: 16px;
  //  line-height: 19px;
  //  color: map-get($gray, 500)
  //}
  &-content {
    margin-bottom: 10px;
    padding: 5px;
    font-size: 15px;
    line-height: 24px;
    word-break: break-word;
    white-space: break-spaces;
    cursor: pointer;
    transition: all .2s ease-in-out;
    outline: none;
    border-radius: 4px;
    &:hover {
      box-shadow: 0 0 3pt 0 cornflowerblue;
    }
    &.editing {
      box-shadow: 0 0 3pt 0 cornflowerblue;
    }
  }
  &-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &-date {
    max-width: 200px;
    display: flex;
    flex-direction: column;
    font-size: 12px;
    color: map-get($gray, 700);
    span:last-child {
      margin-top: 5px;
    }
  }
  &-link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background-color: map-get($gray, 400);
    transition: all .2s ease-in-out;
    cursor: pointer;
    &:hover {
      transform: scale(1.1);
      background-color: $primary;
      color: $white;
    }
  }
  &-edit-form {
    display: flex;
    flex-direction: column;
    textarea {
      background-color: inherit;
      border: none;
      min-height: 100px;
      &.is-edit {
        border: 1px solid $primary;
      }
    }
    button {
      margin-bottom: 5px;
    }
    &-cancel {
      background-color: inherit!important;
      color: $dark!important;
    }
  }
  &-editing {
    &-tools {
      display: flex;
      color: map-get($gray, 200);
      transition: all .2s ease-in-out;
      &.active {
        color: map-get($gray, 500);
      }
      i {
        margin-right: 10px;
        cursor: not-allowed;
      }
    }
  }
}
</style>
