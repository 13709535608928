<template>
  <div class="terms">
    <div class="terms-header">
      <img src="@/icons/Glossary.svg">
      <h4>Глоссарий</h4>
    </div>
    <div class="div-line"></div>
    <div class="terms-list">
      <span v-if="terms === undefined" class="term-item" style="margin-top: 10px; margin-bottom: 10px;">Нет терминов...</span>
      <ul v-else>
        <li
          v-for="item in terms.entries"
          :key="item.term"
          class="term-item"
        >
          <div class="term-title">{{item.term}}</div>
          <div class="term-description">{{item.definition}}</div>
          <div class="div-line"></div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Terms',
  props: ['terms'],
};
</script>

<style lang="scss" scoped>
.terms-header {
  padding-left: 20px;
  vertical-align: middle;
  justify-content: center;
}
img {
  padding-top: 2px;
  display: inline-block;
  width: 20px;
  height: 20px;
}
h4 {
  margin-left: 10px;
  display: inline-block;
  color: black;
  padding-right: 20px;
  padding-top: 20px;
  font-size: 20px;
  font-weight: 300;
  font-family: 'Rubik', sans-serif;
}
.terms {
  padding-right: 2px;
  background-color: #F8F8F8;
  border-radius: 10px;
  height: 100%;
}

.term-item {
  padding-right: 20px;
  padding-left: 20px;
  display: block;
  cursor: pointer;
  border-radius: 6px;
  transition: 0.3s;
}

.term-title {
  margin-top: 10px;
  color: #424242;
  font-family: 'Rubik', sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 18px;
  line-height: 24px;
  width: 100%;
  margin-bottom: 10px;
}

.term-description {
  font-size: 15px;
  font-family: 'Rubik', sans-serif;
  color: #424242;
  opacity: 0.7;
  font-weight: 400;
  font-style: normal;
}

.div-line {
  width: 100%;
  background-color: #E8E8E8;
  height: 1px;
  margin-top: 10px;
}
.terms-list {
  height: 300px;
  overflow: auto;
  scrollbar-color: $primary #777777;
}

@media (max-width: $layout-s) {
  .term-item {
    font-size: 14px;
  }
  .terms {
    height: 100%;
  }
  .terms-list {
    height: 78%;
    overflow: auto;
  }
}@media (max-width: $layout-xs) {
  .term-item {
    font-size: 14px;
  }
  .terms {
    height: 100%;
  }
  .terms-list {
    height: 78%;
    overflow: auto;
  }
}
</style>
