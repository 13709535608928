export default {
  created() {
    window.addEventListener('resize', this.onResize);
    this.setIsMobile();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    setIsMobile() {
      this.$store.commit(
        'setIsMobile',
        window.innerWidth <= this.$vars.mobileDeviceWidth,
      );
    },
    onResize() {
      this.setIsMobile();
    },
  },
};
