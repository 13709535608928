import api from '@/api';
import fn from '@/fn';
import identifyAmplitudeEvent from '../../utils/identifyAmplitudeEvent';

export default {
  state: {
    myCourses: null,
    activity: {},
    programs: [],
    selectedProgram: null,
    commonCourses: [],
  },
  getters: {
    getMyCourses(state) {
      return state.myCourses;
    },
    getCommons(state) {
      return state.commonCourses;
    },
    getMyCourseActivity(state) {
      return state.activity;
    },
    getPrograms(state) {
      return state.programs;
    },
    getSelectedProgram(state) {
      return state.selectedProgram;
    },
  },
  actions: {
    async fetchMyCourses({ commit }, id) {
      const res = await api.courses.getCoursesByCurriculumId(id);
      fn.ls.set('program', id);
      identifyAmplitudeEvent('last_selected_curriculum', id);
      commit('SET_MY_COURSES', res.data, id);
    },
    async fetchCommonCourses({ commit }) {
      const res = await api.courses.getCommonCourses();
      commit('SET_COMMONS', res.data);
    },
    async fetchMyCourseActivity({ commit }, id) {
      const res = await api.course.getCourseActivity(id);
      commit('SET_COURSE_ACTIVITY', res.data);
    },
  },
  mutations: {
    SET_COMMONS(state, data) {
      state.commonCourses = data;
    },
    SET_MY_COURSES(state, data) {
      state.myCourses = data;
    },
    CLEAR_SELECTED_PROGRAM(state) {
      state.selectedProgram = null;
      fn.ls.set('program', null);
    },
    SET_COURSE_ACTIVITY(state, data) {
      state.activity = data;
    },
    SET_PROGRAMS(state, data) {
      state.programs = data;
    },
  },
};
