<template>
  <div class="activation">
    <div v-if="status === 0">
      <h1>Загрузка</h1>
    </div>
    <div v-if="status === 1">
      <h1>Подтверждение адреса</h1>
      <div>Ваш электронный почтовый адрес успешно подтвержден</div>
    </div>
    <div v-else-if="status === 2">
      <h1>Не удалось подтвердить адрес</h1>
      <div class="server-error" v-if="errors.length">
        <ul v-for="error in errors" :key="error">
          <li>{{ error }}</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EmailVerification',
  data() {
    return {
      status: 0,
      errors: [],
    };
  },
  mounted() {
    this.activateAccount();
  },
  methods: {
    activateAccount() {
      this.errors = [];
      this.$api.user
        .activateAccount({
          data: {
            uid: this.$route.params.uid,
            token: this.$route.params.token,
          },
        })
        .then(() => {
          this.status = 1;
        })
        .catch((error) => {
          this.status = 2;
          const { data } = error.response;
          if ('detail' in data) {
            this.errors.push(data.detail);
          }
          if ('uid' in data) {
            this.errors.push(...data.uid);
          }
          if ('token' in data) {
            this.errors.push(...data.token);
          }
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.activation {
  text-align: center;
}
.server-error {
  color: $danger;
  margin-bottom: 10px;
}

.server-error ul {
  list-style-type: none;
}
</style>
