import { track } from '@amplitude/analytics-browser';

export default function trackAmplitudeError(
  errorName,
  userId,
  errorData,
  onSuccess = () => {
    const userIdData = { user_id: userId };
    const data = { ...errorData, ...userIdData };

    console.log(`Tracked error: ${errorName} with data`, data);
  },
  onError = () => {
    const userIdData = { user_id: userId };
    const data = { ...errorData, ...userIdData };

    console.log(`Failed to track error: ${errorName} with data`, data);
  }
) {
  // for failing requests we should send the same event name, here it will add '_error' suffix
  const errorToAmplitude = `${errorName}_error`;

  const userIdData = { user_id: userId };
  const data = { ...errorData, ...userIdData };

  track(errorToAmplitude, data, userIdData)
    .promise.then(onSuccess)
    .catch(onError);
}
