<template>
  <div class="progress-bar">
    <div
      class="filled-bar"
      :style="{ transform: `translate3d(-${(1 - value) * 100}%, 0, 0)` }"
    ></div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      default: 0,
    },
  },

  computed: {
    percentageText() {
      return `${Math.round(this.value * 100)}%`;
    },
  },
};
</script>

<style scoped>
.progress-bar {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 99;
  height: 8px;
  width: 100%;
  background: 1px solid rgba(201, 219, 255, 0.941);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 3px 3px 0 0;
}

.percentage-text {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  font-size: 14px;
  transform: translate3d(-50%, -50%, 0);
}

.filled-bar {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  height: 100%;
  width: 100%;
  background: #1c77ff;
}
</style>
