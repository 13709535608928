export default {
  personalDataAgreement:
    'https://docs.google.com/document/d/1rF3lmjhbmig97sPXv-MDri6HFylyBjUY/edit?usp=sharing&ouid=112341922399867938326&rtpof=true&sd=true',
  offerAgreement:
    'https://docs.google.com/document/d/1w1PY3ySA4EPUugmUyiyKnnTmRC36GYG7/edit?usp=sharing&ouid=112341922399867938326&rtpof=true&sd=true',
  // offerAgreementMentoring:
  //   'https://docs.google.com/document/d/1hUkI7l-SvUVIqzHfk0vu1tGOvuYbvehZ/edit?usp=sharing&ouid=112341922399867938326&rtpof=true&sd=true',
  telegramBot: {
    sova: process.env.VUE_APP_TELEGRAM_BOT_CHAT_SOVA,
    flow: process.env.VUE_APP_TELEGRAM_BOT_CHAT_POTOK,
  },
};
