<template>
  <div>
    <div class="d-flex">
      <div>
        <router-link
          v-if="portfolios.length != 0"
          class="link"
          :to="{ name: 'portfolio-new' }"
        >
          Добавить портфолио
        </router-link>
      </div>
    </div>
    <loader v-if="loading" />
    <div class="no_content" v-if="!loading && portfolios.length == 0">
      <div class="no_content">
        <div class="title">Не указано портфолио</div>
        <div class="text">
          Добавьте ваше порфтолио. Опишите его и добавьте картинку. Это может
          быть страница Инстаграм или скриншот веб-сайта
        </div>
        <div class="buttons">
          <router-link :to="{ name: 'portfolio-new' }"
            ><form-button>Добавить портфолио</form-button></router-link
          >
        </div>
      </div>
    </div>
    <div v-for="portfolio in portfolios" :key="portfolio.id">
      <div class="portfolio-item">
        <div class="info-block">
          <div class="vacancy-row">
            <h2>{{ portfolio.title }}</h2>
          </div>
          <div class="vacancy-row">
            <span>{{ portfolio.description }}</span>
          </div>
          <div class="img-block">
            <img :src="portfolio.portfolioImage" />
          </div>
        </div>
      </div>
      <div class="buttons">
        <div>
          <router-link
            class="link"
            :to="{ name: 'portfolio-edit', params: { id: portfolio.id } }"
            >Редактировать</router-link
          >
        </div>
        <div>
          <a
            @click="deleteExperience({ id: portfolio.id })"
            class="link"
            style="color: #eb5757"
            >Удалить</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $dayjs from '@/plugins/dayjs';
import Loader from '@/components/Loader';
import FormButton from '../Button';

export default {
  components: { FormButton, Loader },
  data() {
    return {
      loading: true,
      portfolios: [],
    };
  },
  filters: {
    portfolioDate(date) {
      return $dayjs(date).format('DD.MM.YYYY');
    },
  },
  created() {
    this.getPortfolios();
  },
  methods: {
    deleteExperience(params) {
      if (window.confirm('Are you sure you want to delete this portfolio?')) {
        this.$api.resume.deletePortfolio(params).then(() => {
          this.getPortfolios();
        });
      }
    },
    getPortfolios() {
      this.$api.resume
        .getPortfolios()
        .then(({ data }) => {
          this.portfolios = data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.link {
  cursor: pointer;
  color: $primary;
  text-decoration: none;
  font-weight: bold;
  &:hover {
    text-decoration: underline;
  }
}
.d-flex {
  display: flex;
  justify-content: space-between;
}
.vacancy-description {
  font-size: 17px;
}
.meta-info {
  color: grey;
  font-size: 17px;
}
.vacancy-row {
  margin-bottom: 15px;
}
.buttons div {
  display: inline;
  margin-right: 10px;
}
.no_content {
  padding: 50px 0;
  background: #fff;
  text-align: center;
  .title {
    font-size: 25px;
    color: #464646;
    max-width: 330px;
    margin: 0 auto 7px;
  }
  .text {
    font-size: 15px;
    color: #999;
    line-height: 21px;
    max-width: 350px;
    margin: auto;
  }
  .buttons {
    margin-top: 20px;
  }
}
.portfolio-item {
  margin: 35px 0;
  display: flex;
  justify-content: space-between;
}

.img-block img {
  width: 100%;
  height: auto;
  max-width: 300px;
}

.vacancy-row span {
  word-break: break-word;
  white-space: pre-line;
}
</style>
