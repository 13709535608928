<template>
  <modal :show="show" @close="close">
    <div class="grade">
      <div class="grade-nav">
        <div
          @click="activeComponentName = 'tech-grade'"
          :class="{ active: activeComponentName === 'tech-grade' }"
        >
          Тех. ст.
        </div>
        <div
          @click="activeComponentName = 'visual-grade'"
          :class="{ active: activeComponentName === 'visual-grade' }"
        >
          Визуал
        </div>
        <div
          @click="activeComponentName = 'content-grade'"
          :class="{ active: activeComponentName === 'content-grade' }"
        >
          Контент
        </div>
      </div>
      <tech-grade
        v-show="activeComponentName === 'tech-grade'"
        @selected-grade="selectTechGrade"
        v-model.trim="review.technicalComment"
      />
      <visual-grade
        v-show="activeComponentName === 'visual-grade'"
        @selected-grade="selectVisualGrade"
        v-model.trim="review.visualComment"
      />
      <content-grade
        v-show="activeComponentName === 'content-grade'"
        @selected-grade="selectContentGrade"
        v-model.trim="review.contentComment"
      />
      <btn fluid @click="submitGradeHandler">Отправить</btn>
    </div>
  </modal>
</template>

<script>
import { mapState } from 'vuex';

import Modal from '@/components/Modal';
import Btn from '@/components/Button';
import TechGrade from '@/components/Lesson/modals/Grade/TechGrade';
import ContentGrade from '@/components/Lesson/modals/Grade/ContentGrade';
import VisualGrade from '@/components/Lesson/modals/Grade/VisualGrade';

export default {
  props: {
    show: { type: Boolean, default: false },
  },
  components: {
    Modal,
    Btn,
    TechGrade,
    ContentGrade,
    VisualGrade,
  },
  data() {
    return {
      activeComponentName: 'tech-grade',
      review: {
        technicalGrade: null,
        visualGrade: null,
        contentGrade: null,
        technicalComment: '',
        visualComment: '',
        contentComment: '',
      },
      isGradeSent: false,
    };
  },
  computed: {
    ...mapState(['lesson']),
  },
  methods: {
    submitGradeHandler() {
      this.close();
      if (this.lesson.review) {
        this.$api.lesson.updateReview({
          data: { ...this.review },
          id: this.lesson.review.id,
        }).then(() => {
          this.isGradeSent = true;
        });
        return;
      }
      this.$api.lesson.createReview({
        data: { ...this.review, lesson: this.$route.params.id },
      }).then((r) => {
        this.$store.commit('setReview', r.data);
        this.isGradeSent = true;
      });
    },
    selectTechGrade(grade) {
      this.review.technicalGrade = grade;
    },
    selectVisualGrade(grade) {
      this.review.visualGrade = grade;
    },
    selectContentGrade(grade) {
      this.review.contentGrade = grade;
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
.grade {
  font-size: 14px;
  &-nav {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    div {
      flex: 1;
      padding: 10px 20px;
      border-top-right-radius: 4px;
      border-top-left-radius: 4px;
      cursor: pointer;
      text-align: center;
      //background-color: map-get($gray, 200);
      box-shadow: rgb(204, 219, 232) 3px 3px 6px 0 inset,
                  rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
      position: relative;
      &.active {
        box-shadow: rgba(0, 0, 0, 0.15) -1.95px -1.95px 2.6px;
        background-color: $white;
        &::before,
        &::after {
          content: "";
          position: absolute;
          bottom: 0;
          width: 20px;
          height: 10px;
          z-index: 999;
        }
        &::before {
          left: -20px;
          border-radius: 0 0 10px 0;
          -moz-border-radius: 0 0 10px 0;
          -webkit-border-radius: 0 0 10px 0;
          box-shadow: 10px 0 0 0 #fff;
        }
        &::after {
          right: -20px;
          border-radius: 0 0 0 10px;
          -moz-border-radius: 0 0 0 10px;
          -webkit-border-radius: 0 0 0 10px;
          box-shadow: -10px 0 0 0 #fff;
        }
      }
      &:last-child::after {
        content: none;
      }
      &:first-child::before {
        content: none;
      }
    }
  }
}
</style>
