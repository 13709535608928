<template>
  <div class="layout">
    <div class="row">
      <loader v-if="showLoading" />
      <div v-else>
        <div class="col-75">
          <div class="header">
            <div class="vacancy-title">{{ vacancy.name }}</div>
            <div v-if="vacancy.salary > 0" class="vacancy-label">
              {{ vacancy.salary.toLocaleString() }} тг/месяц
            </div>
          </div>
          <div class="meta-info">
            {{ vacancy.company }}. {{ vacancy.city.name }},
            {{ vacancy.address }}
          </div>
          <div class="meta-info">
            <span>{{ employmentTypesList }}</span>
          </div>
          <div class="meta-info">
            <span>{{ remoteWorksList }}</span>
          </div>
          <div class="skills-mobile-display">
            <div class="vacancy-label">Навыки</div>
            <div v-if="vacancy.skills.length == 0 && !showLoading">
              Список навыков пуст
            </div>
            <div class="skills">
              <div
                class="skill"
                v-for="skill in vacancy.skills"
                :key="skill.id"
              >
                {{ skill.name }}
              </div>
            </div>
          </div>
          <div class="vacancy-label">Обязанности</div>
          <div class="vacancy-description">
            {{ vacancy.responsibilities }}
          </div>
          <div class="vacancy-label">Условия</div>
          <div class="vacancy-description">
            {{ vacancy.terms }}
          </div>
          <btn
            :disabled="disableButton"
            :loading="disableButton"
            class="vacancy-button"
            @click="applyToJob"
            >Откликнуться</btn
          >
        </div>
        <div class="col-25">
          <img
            class="vacancy-image"
            v-if="vacancy.vacancyImage"
            :src="vacancy.vacancyImage"
            alt="Лого компании"
          />
          <div class="skills-desktop-display">
            <div class="vacancy-label">Навыки</div>
            <div class="skills">
              <div v-if="vacancy.skills.length == 0 && !showLoading">
                Список навыков пуст
              </div>
              <div
                class="skill"
                v-for="skill in vacancy.skills"
                :key="skill.id"
              >
                {{ skill.name }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from '@/components/Loader';
import Btn from '@/components/Button';

export default {
  components: {
    Loader,
    Btn,
  },
  data() {
    return {
      showLoading: true,
      disableButton: false,
      vacancy: {},
    };
  },
  created() {
    this.getVacancy();
  },
  computed: {
    remoteWorksList() {
      const names = this.vacancy.remoteWork.map((item) => item.name);
      return names.join(', ');
    },
    employmentTypesList() {
      const names = this.vacancy.employmentType.map((item) => item.name);
      return names.join(', ');
    },
    vacancyId() {
      return this.$route.params.id;
    },
  },
  methods: {
    applyToJob() {
      this.disableButton = true;
      this.$api.vacancy.applyVacancy({ data: { vacancy: this.vacancyId } }).finally(() => {
        this.disableButton = false;
      });
    },
    getVacancy() {
      this.$api.vacancy
        .getVacancy({ id: this.vacancyId })
        .then(({ data }) => {
          this.vacancy = data;
        })
        .finally(() => {
          this.showLoading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.skills {
  display: flex;
  flex-wrap: wrap;
}

.skills-desktop-display {
  display: block;
}

.skills-mobile-display {
  display: none;
}

.skill {
  margin-right: 0.5rem;
  margin-top: 0.5rem;
  padding: 5px 7px;
  color: $white;
  font-size: 14px;
  line-height: 17px;
  background-color: $primary;
}

.vacancy-description {
  font-size: 17px;
  white-space: pre-line;
}
.vacancy-image {
  max-width: 100%;
  height: auto;
}

.vacancy-title {
  font-weight: 500;
  font-size: 36px;
  line-height: 43px;
}
.vacancy-label {
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 20px;
  margin-top: 30px;
}
.meta-info {
  color: map-get($gray, 800);
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  margin-bottom: 20px;
}

.row {
  margin: 20px 0;
}
/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

.col-25 {
  float: left;
  width: 25%;
}

.col-75 {
  width: 75%;
  float: left;
}

.vacancy-button {
  margin-top: 35px;
}

/* Responsive layout - when the screen is less than 600px wide,
make the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: $layout-s) {
  .col-25,
  .col-75 {
    width: 100%;
  }
  .col-25 {
    margin-top: 20px;
  }
  .header {
    margin-bottom: 10px;
  }
  .vacancy-title {
    font-size: 18px;
    line-height: 21px;
  }
  .meta-info {
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 6px;
  }
  .vacancy-description {
    font-size: 14px;
    line-height: 140%;
    color: $dark;
  }
  .vacancy-label {
    font-size: 18px;
    line-height: 21px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .vacancy-button {
    width: 100%;
  }
  .skills-mobile-display {
    display: block;
  }
  .skills-desktop-display {
    display: none;
  }
  .skill {
    font-size: 12px;
    line-height: 14px;
    font-weight: 500;
    margin-top: 0.5rem;
    padding: 0 3px;
  }
}
</style>
