<template>
  <loader v-if="loadingProfile" />
  <div v-else>
    <form @submit.prevent="updateUser">
      <div class="row">
        <div class="col-25">
          <label>План</label>
        </div>
        <div class="col-75">
          <span> {{ subscriptionPlan }} </span>
        </div>
      </div>
      <div class="row">
        <label>
          Если данные указаны правильно, нажмите кнопку Сохранить, чтобы
          платформа зафиксировала ваши личные данные
        </label>
      </div>
      <div class="row inline">
        <div class="col-75 half-width">
          <div class="col-25">
            <label>Имя</label>
          </div>
          <input
            type="text"
            placeholder="Введите имя"
            v-model="profile.firstName"
            required
          />
        </div>
        <div class="col-75 half-width">
          <div class="col-25">
            <label>Фамилия</label>
          </div>
          <input
            type="text"
            placeholder="Введите фамилию"
            v-model="profile.lastName"
            required
          />
        </div>
      </div>
      <div class="row">
        <div class="col-25">
          <label>Телефон</label>
        </div>

        <div class="col-75">
          <VuePhoneNumberInput
            v-model="phone"
            :required="true"
            clearable
            type="tel"
            :preferred-countries="['KZ', 'RU', 'KG', 'UZ', 'TR', 'KR', 'AE']"
            size="sm"
            border-radius="10"
            show-code-on-list
            no-example
            no-validator-state
            :default-country-code="defaultCountryCode"
            :translations="{
              countrySelectorLabel: 'Код страны',
              countrySelectorError: 'Ошибка',
              phoneNumberLabel: 'Номер телефона',
              example: 'Пример:',
            }"
            @update="updatePhoneNumber"
          />
        </div>

        <!-- <div class="col-75">
          <input
            type="tel"
            placeholder="Введите телефон"
            v-model="profile.phoneNumber"
            :disabled="loading"
            required
          />
        </div> -->
      </div>
      <div class="row">
        <div class="col-25">
          <label>Почта</label>
        </div>
        <div class="col-75">
          <input
            type="text"
            placeholder="Введите вашу почту"
            v-model="profile.email"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-25">
          <label>Дата рождения {{ test }}</label>
        </div>
        <div class="col-75">
          <date-picker class="date-picker" v-model="profile.birthdate" />
        </div>
      </div>
      <!--      <div class="row">-->
      <!--        <div class="col-25">-->
      <!--          <label>Пол</label>-->
      <!--        </div>-->
      <!--        <div class="col-75">-->
      <!--          <select v-model="profile.more.gender">-->
      <!--            <option value="0">Не указан</option>-->
      <!--            <option value="1">Мужской</option>-->
      <!--            <option value="2">Женский</option>-->
      <!--          </select>-->
      <!--        </div>-->
      <!--      </div>-->
      <!--      <div class="row inline">-->

      <!--        <div class="col-75 half-width">-->
      <!--          <div class="col-25">-->
      <!--            <label>Страна</label>-->
      <!--          </div>-->
      <!--          <select v-model="profile.more.country">-->
      <!--            <option-->
      <!--              v-for="country in countries"-->
      <!--              :value="country.id"-->
      <!--              :key="country.id"-->
      <!--            >-->
      <!--              {{ country.name }}-->
      <!--            </option>-->
      <!--          </select>-->
      <!--        </div>-->

      <!--        <div class="col-75 half-width">-->
      <!--          <div class="col-25">-->
      <!--            <label>Город</label>-->
      <!--          </div>-->
      <!--          <select v-model="profile.more.city" :disabled="cityChanger">-->
      <!--            <option v-for="city in cities" :value="city.id" :key="city.id">-->
      <!--              {{ city.name }}-->
      <!--            </option>-->
      <!--          </select>-->
      <!--        </div>-->
      <!--      </div>-->
      <div class="server-error" v-if="errors.length">
        <ul v-for="error in errors" :key="error">
          <li>{{ error }}</li>
        </ul>
      </div>

      <div class="row-button">
        <form-button
          variant="primary"
          :loading="loading"
          :disabled="loading"
          :fluid="false"
        >
          Сохранить
          <img :src="btnicon" alt="" />
        </form-button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { minLength } from 'vuelidate/lib/validators';
import VuePhoneNumberInput from 'vue-phone-number-input';
import parsePhoneNumber from 'libphonenumber-js';

import Loader from '@/components/Loader';
import trackAmplitudeEvent from '@/utils/trackAmplitudeEvent';
import FormButton from '../Button';
import btnicon from '../../../public/img/icons/Vector.svg';
import $globalFn from '../../fn';
import trackAmplitudeError from '../../utils/trackAmplitudeError';

export default {
  name: 'UserInfo',
  components: { FormButton, Loader, VuePhoneNumberInput },
  data() {
    return {
      phone: '',
      defaultCountryCode: 'KZ',
      errors: [],
      loading: false,
      btnicon,
      loadingProfile: false,
      cities: [],
      countries: [],
      test: '',
    };
  },
  validations: {
    profile: {
      firstName: {
        minLength: minLength(3),
      },
    },
  },
  mounted() {
    const userId = $globalFn.ls.get('userId');
    if (this.profile.phoneNumber) {
      // set phone number state when navigating through the app
      this.phone = this.profile.phoneNumber;
      const parsedNumber = parsePhoneNumber(this.profile.phoneNumber);
      if (parsedNumber?.country) {
        this.defaultCountryCode = parsedNumber.country;
      }
    }
    trackAmplitudeEvent('profile_page_opened', userId);
  },
  watch: {
    profile: {
      handler() {
        if (this.profile.phoneNumber) {
          // set phone number state when reloading the app
          this.phone = this.profile.phoneNumber;
          const parsedNumber = parsePhoneNumber(this.profile.phoneNumber);
          if (parsedNumber?.country) {
            this.defaultCountryCode = parsedNumber.country;
          }
        }
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters({
      profile: 'getProfile',
    }),
    ...mapActions({
      closeAuthModal: 'closeFirstAuthModal',
    }),
    birthDate() {
      if (this.profile.birthdate) {
        return this.$date(this.profile.birthdate).format('DD/MM/YYYY');
      }
      return '';
    },
    subscriptionPlan() {
      let plan = '';
      if (this.profile.studentType === 'STANDART') {
        plan = 'Стандарт';
      } else if (this.profile.studentType === 'MEDIUM') {
        plan = 'Наставничество';
      } else if (this.profile.studentType === 'PREMIUM ') {
        plan = 'Мастер-майнд';
      }
      return plan;
    },
    cityChanger() {
      return this.profile.more.country === 0;
    },
  },
  methods: {
    ...mapActions({}),
    updatePhoneNumber(data) {
      this.profile.phoneNumber = data.formattedNumber;
    },
    async updateUser() {
      this.errors = [];
      this.loading = true;
      this.$v.$touch();
      await this.$api.user
        .update({
          ...this.profile,
          onboarding_finished: true,
        })
        .then(() => {
          this.closeAuthModal();
          const userId = $globalFn.ls.get('userId');
          trackAmplitudeEvent('user_profile_update', userId, {
            profile: this.profile,
          });
        })
        .catch((error) => {
          const { data } = error.response;
          const userId = $globalFn.ls.get('userId');
          trackAmplitudeError('user_profile_update', userId, { error });

          if ('error' in data) {
            if (`${data.message}`.includes('body.phoneNumber')) {
              this.errors.push(
                'Формат номера введен неправильно!\nДопустимый формат номера телефона:\n+(код страны)-(###)-###-##-##\n8-(###)-###-##-##'
              );
            } else {
              this.errors.push(data.message);
            }
          }
          if ('newPassword' in data) {
            for (const item of data.newPassword) {
              this.errors.push(item);
            }
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async getCities(countryId) {
      const cities = await this.$api.location.getCities(countryId);
      this.cities = cities.data;
    },
  },
};
</script>

<style lang="scss" scoped>
/* Full-width input fields */
input,
select {
  outline: none;
  width: 100%;
  margin-top: 5px;
  display: inline-block;
  background: map-get($gray, 1000);
  border: none;
  border-radius: 10px;
  padding: 12px;
  resize: none;

  &:focus {
    background-color: white;
    outline: none;
  }
}

span {
  display: inline-block;
  padding-top: 12px;
  padding-bottom: 12px;
}

.text-center {
  text-align: center;
}

.server-error {
  color: #e74c3c;
  margin-top: 24px;
  margin-bottom: 10px;
  font-size: 12px;

  ul {
    white-space: pre-line;
    list-style-type: none;
  }
}

h3 {
  margin-bottom: 15px;
}

/* Clear floats after the columns */
.row:after {
  content: '';
  display: table;
  clear: both;
}

.col-25 {
  float: left;
  width: 25%;
  margin-top: 6px;
}

.col-75 {
  width: 80%;
  float: left;
  margin-top: 6px;
}

.half-width {
  width: 40%;
}

.row {
  display: flex;
  flex-direction: column;
}

.row-button {
  display: flex;
  width: 80%;
  justify-content: flex-end;
}

.inline {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 89%;
}

button {
  margin-top: 20px;
  display: flex;
  padding: 12px 20px;
  width: 35%;
  justify-content: space-evenly;
  align-items: center;
}

label {
  padding: 0;
  display: inline-block;
  color: rgba(0, 0, 0, 0.15);
}

/* Responsive layout - when the screen is less than 600px wide,
make the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: $layout-s) {
  .col-25,
  .col-75 {
    margin-top: 14px;
    width: 100%;
  }

  button {
    width: 100%;
    margin-top: 20px;
  }
}

.date-picker {
  outline: none;
  width: 100%;
  margin-top: 5px;
  display: inline-block;
  background: map-get($gray, 1000);
  border: none;
  border-radius: 10px;
  padding: 12px;
  resize: none;

  &:focus {
    background-color: white;
    outline: none;
  }
}
</style>
