<template>
  <table class="other-container">
    <tr>
      <td class="other-container-first">
        <p class="stage-primary">3 этап</p>
        <p class="header-text">{{ course?.title }}</p>
        <p class="hint-text">{{ course?.description }}</p>
        <router-link class="primary-button" :to="{ name: 'lesson', params: { id: course?.id } }">Продолжить</router-link>
      </td>
      <td class="other-container-second">
        <img class="other-cover-image" :src="course?.coverImage">
      </td>
    </tr>
  </table>
</template>

<script>
export default {
  name: 'other-component',
  props: {
    course: null
  }
};
</script>

<style scoped>
.other-container {
  width: 100%;
  margin-top: 38px;
  background-color: #F8F8F8;
  border-radius: 22px;
  padding: 42px 65px;
}

.other-container-first {
  text-align: start;
  width: 55%;
}

.other-container-second {
  width: 45%;
  text-align: end;
}

.stage-primary {
  background-color: #3A7DFF;
  color: white;
  border-radius: 10px;
  width: max-content;
  font-size: 14px;
  padding: 5px 20px;
  font-family: Rubik, sans-serif;
}

.primary-button {
  padding: 14px 24px;
  color: white;
  font-size: 18px;
  background-color: #3A7DFF;
  width: max-content;
  transition: 0.3s;
  text-decoration: none;
  border-radius: 10px;
  cursor: pointer;
}

.primary-button:hover {
  background-color: #2c52d1;
}

.header-text {
  margin-top: 40px;
  color: rgba(0, 0, 0, 0.8);
  font-size: 35px;
  font-family: Rubik, sans-serif;
}

.hint-text {
  color: rgba(0, 0, 0, 0.8);
  margin-top: 18px;
  line-height: 33px;
  padding-bottom: 40px;
}

tr,
td {
  height: 1px;
}

@-moz-document url-prefix() {

  tr,
  td {
    height: 100%;
  }
}

.other-cover-image {
  width: 70%;
}

@media screen and (max-width: 870px) {
  .header-text {
    font-size: 24px;
    font-weight: 600;
  }

  .other-container {
    padding: 22px 24px;
  }

  .other-container-first {
    width: 100%;
  }

  .other-container-second {
    display: none;
  }
}
</style>
