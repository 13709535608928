import Vue from 'vue';
import VueRouter from 'vue-router';

import Main from '@/layout/Main';

// Routes
import authRoutes from '@/views/Auth/routes';
import lessonRoutes from '@/views/Lesson/routes';
import homeRoutes from '@/views/Home/routes';
import courseRoutes from '@/views/Course/routes';
import programRoutes from '@/views/Program/routes';
import profileRoutes from '@/views/Profile/routes';
import notesRoutes from '@/views/Notes/routes';
import notificationsRoutes from '@/views/Notifications/routes';
import webinarRoutes from '@/views/Webinars/routes';
import webinarRoomRoutes from '@/views/WebinarRoom/routes';
import mailing from '@/views/Mailing/routes';
import distribution from '@/views/Distribution/routes';
import vacancyRoutes from '@/views/Vacancy/routes';
import vacancyDetailRoutes from '@/views/VacancyDetail/routes';
import faqRoutes from '@/views/Faq/routes';
import myCourses from '@/views/MyCourses/routes';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'main',
    component: Main,
    children: [
      ...homeRoutes,
      ...lessonRoutes,
      ...programRoutes,
      ...courseRoutes,
      ...programRoutes,
      ...profileRoutes,
      ...notesRoutes,
      ...notificationsRoutes,
      ...webinarRoutes,
      ...webinarRoomRoutes,
      ...mailing,
      ...distribution,
      ...vacancyRoutes,
      ...vacancyDetailRoutes,
      ...faqRoutes,
      ...myCourses
    ],
  },
  ...authRoutes,
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

export default router;
