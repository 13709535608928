<template>
  <form @submit.prevent="$emit('on-submit', form)">
    <label for="educationName">Учебное заведение</label>
    <input
      placeholder="Учебное заведение"
      name="educationName"
      v-model="form.educationName"
      required
    />
    <label>Год окончания</label>
    <div class="row">
      <select v-model="form.dateTillYear">
        <option value="null">По настоящее время</option>
        <option v-for="year in years" :value="year" :key="year">
          {{ year }}
        </option>
      </select>
    </div>
    <label>Специальность</label>
    <input placeholder="Специальность" v-model="form.specialtyName" />
    <div class="server-error" v-if="errors.length">
      <ul v-for="error in errors" :key="error">
        <li>{{ error }}</li>
      </ul>
    </div>
    <div class="row">
      <form-button variant="primary" :loading="loading" :disabled="loading">
        Сохранить
      </form-button>
    </div>
  </form>
</template>

<script>
import FormButton from '../Button';

export default {
  name: 'EducationForm',
  components: { FormButton },
  props: {
    education: {
      type: Object,
    },
  },
  data() {
    return {
      loading: false,
      form: {
        educationName: this.education?.educationName || '',
        specialtyName: this.education?.specialtyName || '',
        dateTillYear: this.validatedTillYear(),
      },
      stillStudy: false,
      errors: [],
      months: this.$vars.profile.months,
    };
  },
  computed: {
    years() {
      const years = [];
      const start = 2025;
      const end = 1917;
      for (let i = start; i >= end; i -= 1) {
        years.push(i);
      }
      return years;
    },
  },
  methods: {
    validatedTillYear() {
      if (this.education && this.education?.dateTill) {
        return new Date(this.education?.dateTill).getFullYear();
      }
      return null;
    },
  },
};
</script>

<style lang="scss" scoped>
span {
  display: inline-block;
  padding-top: 12px;
  padding-bottom: 12px;
}
input,
textarea,
select {
  -ms-box-sizing: content-box;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  outline: none;
  margin-top: 5px;
  display: inline-block;
  width: 100%;
  padding: 15px;
  border-radius: 4px;
  background: map-get($gray, 1000);
  border: none;
  border-radius: 10px;
  input {
    resize: none;
  }
  &:focus {
    background-color: white;
    outline: none;
  }
}
select,
input[type='date'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%);
  background-position: calc(100% - 23px) calc(1em + 5px),
    calc(100% - 18px) calc(1em + 5px), calc(100% - 2.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;
  &:focus {
    background-image: linear-gradient(45deg, $primary 50%, transparent 50%),
      linear-gradient(135deg, transparent 50%, $primary 50%);
    background-position: calc(100% - 18px) 18px, calc(100% - 23px) 18px,
      calc(100% - 2.5em) 8px;
    background-size: 5px 5px, 5px 5px, 1px 1.5em;
    background-repeat: no-repeat;
    border-color: $primary;
    outline: 0;
  }
}

input[type='date'],
input[type='checkbox'] {
  width: 30%;
  margin-right: 0.5rem;
}

.d-flex {
  display: flex;
  flex-wrap: wrap;
}
.d-flex > div {
  padding: 0.1rem;
  color: grey;
  margin-right: 0.5rem;
  margin-top: 0.5rem;
  background-color: rgba(86, 61, 124, 0.15);
  border: 1px solid rgba(86, 61, 124, 0.15);
}
.vacancy-description {
  font-size: 17px;
}
.error,
.email-dirty {
  color: $danger;
}

.email-verified {
  color: $success;
}

.server-error {
  color: $danger;
  margin-bottom: 10px;
}

.server-error ul {
  list-style-type: none;
}

/* Clear floats after the columns */
.row:after {
  content: '';
  display: table;
  clear: both;
}

.col-50 {
  float: left;
  width: 48%;
  margin-right: 1rem;
}

button {
  margin-top: 20px;
  float: right;
  padding: 12px 20px;
}

label {
  padding: 12px 12px 12px 0;
  display: inline-block;
  color: rgba(0, 0, 0, 0.15);
}

/* Responsive layout - when the screen is less than 600px wide,
make the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: $layout-s) {
  .col-50 {
    width: 100%;
  }
  button {
    width: 100%;
    margin-top: 20px;
  }
}

@media (max-width: 620px) {
  .row input,
  .row select,
  .row textarea {
    width: 90%;
  }
}
</style>
