import { request } from './utils';

export default {
  /**
   * Получить информацию о вебинарах
   *
   * @param {Object}         params                          Параметры
   * @param {Number|String}  params.id                       ID вебинара
   *
   * @return {Promise}                                       Возвращает промис с ответом из сервера.
   */
  getWebinar(params) {
    return request('get', `/webinars/${params.id}/`);
  },
  getWebinars() {
    return request('get', '/webinars/');
  },
  registerWebinar(id) {
    return request('post', `/webinars/${id}/register/`);
  },
  unregisterWebinar(id) {
    return request('post', `/webinars/${id}/unregister/`);
  },
  getBanners() {
    return request('get', '/banners/', { params: { banner_type: 3 } });
  },
  getButton(id) {
    return request('get', `/webinars/${id}/button/`);
  },
};
