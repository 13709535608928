<template>
  <div class="homework">
    <h3>Домашнее задание</h3>
    <table class="homework-list">
      <tr>
        <th>Заголовок</th>
        <th>Дедлайн</th>
        <th>Статус</th>
      </tr>
      <tr>
        <td>
          <div>
            <div class="homework-label"></div>
            <p>
              {{ lesson.assignments[0] ? lesson.assignments[0].title : null }}
            </p>
          </div>
        </td>
        <td>
          <div>
            <div class="homework-label"></div>
            <p>{{ deadline }}</p>
          </div>
        </td>
        <td>
          <div v-if="status === 'unsent'" class="homework-item">
            <button
              class="homework-btn homework-btn-start"
              @click="submissionModalShow = true"
            >
              Начать
            </button>
          </div>
          <div v-else-if="status === 'onCheck'" class="homework-item">
            <button
              class="homework-btn homework-btn-start"
            >
              На проверке
            </button>
          </div>
          <div v-else class="homework-item">
            <button
              class="homework-btn homework-btn-start"
            >
              Принято
            </button>
          </div>
        </td>
      </tr>
    </table>
    <div class="mobile-view">
      <div @click="openSubmissionModal" class="mobile-view__item">
        <div class="description">
          <p class="header">
            {{ lesson.assignments[0] ? lesson.assignments[0].title : null }}
          </p>
          <p v-if="status === 'unsent'" class="time">Сдать до {{ deadline }}</p>
          <p v-if="status === 'onCheck'" class="time" >На проверке</p>
          <p v-if="status === 'accepted'" class="time" >Принято</p>
        </div>
        <div class="button">
          <img src="./../../assets/images/mobileButton.svg" alt="" />
        </div>
      </div>
    </div>
    <submission-modal
      :show="submissionModalShow"
      @close="submissionModalShow = false"
      @refresh="checkHomeWorkSubmission(lesson.assignments[0].id)"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SubmissionModal from '@/components/Lesson/modals/HomeworkSubmission';
import $dayjs from '@/plugins/dayjs';

export default {
  name: 'Homework',
  components: { SubmissionModal },
  data() {
    return {
      submissionModalShow: false,
      fullDescription: false,
      submission: {
        text: '',
        tab: 'homework',
      },
      status: 'unsent',
    };
  },
  mounted() {
    this.checkHomeWorkSubmission(this.lesson.assignments[0].id);
  },
  computed: {
    currentBlock() {
      let block = null;
      let breakCheck1 = false;
      for (let i = 0; i < this.units.length; i += 1) {
        block = this.units[i];
        for (let j = 0; j < block.lessons.length; j += 1) {
          if (block.lessons[j].id === this.lesson.id) {
            breakCheck1 = true;
            break;
          }
        }
        if (breakCheck1) break;
      }
      return block;
    },
    deadline() {
      return $dayjs(this.homeWorkUser[0].deadline).format('DD/MM/YYYY');
    },
    homeWorkUser() {
      const homeTaskUser = this.lesson.assignments;
      return homeTaskUser || null;
    },
    ...mapGetters({
      lesson: 'getLesson',
      units: 'getUnits',
    }),
  },
  watch: {
    lesson() {
      this.checkHomeWorkSubmission(this.lesson.assignments[0].id);
    }
  },
  methods: {
    openSubmissionModal() {
      if (this.status === 'unsent') this.submissionModalShow = true;
    },
    checkHomeWorkSubmission(id) {
      if (id) {
        this.$api.lesson.homework.check({ id }).then((res) => {
          // eslint-disable-next-line no-return-assign
          if (res.data.accepted === true) return this.status = 'accepted';
          // eslint-disable-next-line no-return-assign
          if (res.data.message !== undefined) return this.status = 'unsent';
          // eslint-disable-next-line no-return-assign
          return this.status = 'onCheck';
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.mobile-view {
  display: none;
  @media (max-width: $layout-xs) {
    display: block;
  }
  &__item {
    background-color: #e8e8e885;
    border-radius: 10px;
    padding: 20px 20px;
    display: flex;
    justify-content: space-between;
    .button {
      display: flex;
      align-items: center;
      padding-right: 20px;
    }
    .description {
      .header {
        font-weight: 700;
        margin-bottom: 10px;
      }
      .time {
        opacity: 0.5;
      }
    }
  }
}
.homework-list {
  @media (max-width: $layout-xs) {
    display: none;
  }
  width: 100%;
  border: 1px solid #e8e8e8;
  border-radius: 20px;
  margin: 15px auto;

  th {
    border-right: 1px solid #e8e8e8;
    text-align: left;
    padding: 10px;
    padding-left: 25px;
    font-size: 18px;
    line-height: 24px;
    /* identical to box height */
    font-weight: 200;
    color: rgba(0, 0, 0, 0.3);

    &:last-of-type {
      border: none;
    }
  }

  td {
    padding: 20px;
    border-bottom: 1px solid #e8e8e8;

    .teacher {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 20px;

      img {
        width: 40px;
        height: auto;
      }
    }
    &:last-of-type {
      width: 30%;
    }
  }

  tr {
    &:last-of-type {
      td {
        border: none;
      }
    }
  }
}
.homework {
  margin: auto;
  margin-top: 75px;
  width: 100%;
  max-width: 1140px;
  @media (max-width: $layout-xs) {
    margin-top: 30px;
  }
  h3 {
    margin-left: 30px;
    margin-bottom: 31px;
    @media (max-width: $layout-xs) {
      margin-left: 5px;
    }
  }
  &-item {
    display: flex;
    align-items: center;
    justify-content: space-around;
    color: $white;
    box-sizing: border-box;
    border-radius: 8px;
    &.is-done {
      color: $dark;
      background-color: map-get($gray, 200);
    }
    h5 {
      font-weight: 500;
      font-size: 16px;
      margin-top: 5px;
    }
  }
  &-status {
    font-size: 14px;
  }
  &-label {
    font-size: 14px;
  }
  &-btn {
    font-weight: 500;
    font-size: 14px;
    border-radius: 3px;
    width: 100%;
    height: 44px;
    border: none;
    outline: none;
    &-done {
      background: $success;
      color: $white;
    }
    &-start {
      font-weight: 400;
      font-size: 18px;
      line-height: 21px;
      color: $primary;
      background: $secondary;
      padding: 0 30px;
      border-radius: 10px;
      text-align: center;
      margin: 0 auto;
    }
    &-returned {
      background: $danger;
      color: $white;
    }
  }
}
@media screen and (max-width: $layout-s) {
  .homework-item {
    flex-direction: column;
    padding: 20px;
    text-align: center;
  }
  .homework-item > div:not(:last-child) {
    margin-bottom: 20px;
  }
  .homework-btn {
    width: 120px;
    :hover {
      cursor: pointer;
    }
  }
}
</style>
