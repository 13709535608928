<template>
  <div class="chat" :class="{hidden: !showChat}">
    <div class="chat-heading">
      <h4>Чат
<!--        <span>(567)</span>-->
      </h4>
        <span v-if="showChat" @click="showChat = false">Скрыть чат</span>
        <span v-else @click="showChat = true">Показать чат</span>
    </div>
    <div v-if="chatLoading && showChat" class="chat-empty">Загрузка...</div>
    <div v-else-if="!chatLoading && !messages.length" class="chat-empty">
      Пусто...
    </div>
    <div v-else class="chat-messages">
      <div v-for="message in messages" :key="message.id" class="chat-message">
        <div class="message-content">
          <div class="author-name">{{ message.author }}</div>
          <div class="chat-message-text">{{ message.content }}</div>
        </div>
      </div>
      <div ref="endMessage" />
    </div>
    <div v-if="showChat" class="chat-user-message">
      <form @submit.prevent="send">
        <textarea
          v-model.trim="newMessage"
          @keydown.enter.exact.prevent
          @keyup.enter.exact="send"
          @keydown.enter.shift.exact="newlineForMessage"
          placeholder="Написать в чат..."
        />
        <button type="submit" class="chat-send-message-btn">
          <i class="fas fa-paper-plane" />
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  data() {
    return {
      chatSocket: null,
      chatLoading: true,
      messages: [],
      showChat: true,
      newMessage: '',
    };
  },
  mounted() {
    this.chatSocket = new WebSocket(`${process.env.VUE_APP_SERVICE_CURRICULUM}/ws/chats/webinar/${this.$route.params.id}/`);
    this.chatSocket.onopen = () => {
      this.fetchMessages();
    };
    this.chatSocket.onmessage = (e) => {
      const data = JSON.parse(e.data);
      if (data.command === 'messages') {
        this.messages.push(...data.messages);
        this.chatLoading = false;
      } else {
        this.messages.push(data.message);
      }
      this.$nextTick(() => { this.scrollToBottom(); });
    };
    this.chatSocket.onerror = (error) => {
      console.log(error);
    };
    this.chatSocket.onclose = () => {
      console.log('Websocket Connection Closed');
    };
  },
  computed: {
    ...mapState(['user']),
  },
  methods: {
    scrollToBottom() {
      this.$refs.endMessage.scrollIntoView({ block: 'end', behavior: 'smooth' });
    },
    fetchMessages() {
      const payload = JSON.stringify({
        command: 'fetch_messages',
        content_type: 'webinar',
        object_id: this.$route.params.id,
      });
      this.chatSocket.send(payload);
    },
    send() {
      if (!this.newMessage) return;
      const payload = JSON.stringify({
        message: this.newMessage,
        from: this.user.profile.email,
        content_type: 'webinar',
        object_id: this.$route.params.id,
        command: 'new_message',
      });
      this.chatSocket.send(payload);
      this.newMessage = '';
    },
    newlineForMessage() {
      this.newMessage = `${this.newMessage}\n`;
    },
  },
};
</script>

<style lang="scss" scoped>
.chat {
  padding-bottom: 70px;
  width: 330px;
  height: 575px;
  border-radius: 5px;
  position: relative;
  background-color: $white;
  border: 1px solid map-get($gray, 400);

  @media screen and (max-width: $layout-s) {
    height: 393px;
  }

  &.hidden {
    max-height: 0;
  }

  &-heading {
    padding: 12px 15px;
    display: flex;
    justify-content: space-between;
    background-color: map-get($gray, 200);

    h4 {
      color: map-get($gray, 700);
      span {
        color: $primary;
      }
    }

    span {
      user-select: none;
      cursor: pointer;
      font-size: 14px;
      color: map-get($gray, 500);

      &:hover {
        color: $primary;
      }
    }
  }

  &-messages {
    max-height: 90%;
    overflow: auto;
  }

  &-message {
    display: flex;
    padding: 10px 16px;
    font-size: 13px;

    .author {
      &-icon {
        margin-right: 20px;
        width: 30px;
        min-width: 30px;
        height: 30px;
        border: 1px solid map-get($gray, 400);
        background-color: map-get($gray, 200);
        border-radius: 50%;

        img {
          max-width: 100%;
          object-fit: cover;
        }
      }

      &-content {
        display: flex;
        flex-direction: column;
      }

      &-name {
        margin-bottom: 5px;
        font-size: 12px;
        line-height: 14px;
        color: map-get($gray, 500);
      }

      &-text {
        word-break: break-all;
      }
    }
  }

  &-user-message {
    padding: 8px 16px;
    display: flex;
    position: absolute;
    bottom: -10px;
    left: 0;
    right: 0;
    border-radius: 4px;
    background-color: $white;
    border-top: 1px solid map-get($gray, 400);

    &.hidden {
      display: none;
    }

    .user-icon {
      width: 30px;
      height: 30px;
      margin-right: 20px;
      background-color: $white;
      border-radius: 50%;
      border: 1px solid map-get($gray, 400);

      img {
        max-width: 100%;
        object-fit: cover;
      }
    }

    form {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }

    textarea {
      padding: 12px;
      width: 100%;
      border: none;
      border-radius: 5px;
      outline: none;
      resize: none;
      transition: box-shadow 0.2s;
      background-color: map-get($gray, 200);

      &:focus {
        box-shadow: 0 0 2px $primary;
      }
    }
  }

  &-empty {
    margin-top: 150px;
    color: map-get($gray, 500);
    text-align: center;
  }

  &-send-message-btn {
    width: 30px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto auto auto 10px;
    border: none;
    background-color: $primary;
    color: $white;
    font-size: 13px;
    padding: 5px 10px;
    border-radius: 50%;
    cursor: pointer;
  }
}
</style>
