var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('div',{staticClass:"row f-col"},[_c('div',{staticClass:"question-status-bar"},[_c('span',{staticClass:"question-status-bar-text"},[_vm._v(_vm._s(_vm.questIndex)+" вопрос из "+_vm._s(_vm.quizLength))]),_c('div',{staticClass:"question-count"},_vm._l((_vm.quizLength),function(item,index){return _c('span',{key:index,staticClass:"indicator",class:_vm.questIndex - 1 === index ? 'indicator--active' : ''})}),0)]),_vm._m(0),_c('div',{staticClass:"question-text"},[_c('h4',[_vm._v(_vm._s(_vm.question.question.text))])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"quiz-container"},_vm._l((_vm.question.answers),function(answer){return _c('div',{key:answer.id,staticClass:"quiz-item"},[_c('label',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.userAnswer),expression:"userAnswer"}],staticClass:"card-input-element",attrs:{"type":"radio","name":"product"},domProps:{"value":{
              question: _vm.question.id,
              answer: answer,
              index: _vm.questIndex,
            },"checked":_vm._q(_vm.userAnswer,{
              question: _vm.question.id,
              answer: answer,
              index: _vm.questIndex,
            })},on:{"change":function($event){_vm.userAnswer={
              question: _vm.question.id,
              answer: answer,
              index: _vm.questIndex,
            }}}}),_c('Icon',{staticClass:"checkedFlag",attrs:{"name":"QuizCheckedFlag"}}),_c('div',{staticClass:"panel panel-default card-input"},[(answer.assets)?_c('expandable-image',{staticClass:"quest-image",attrs:{"src":answer.assets}}):_vm._e(),_c('div',{staticClass:"panel-body"},[_vm._v(_vm._s(answer.text))])],1)],1)])}),0)]),_c('div',{staticClass:"row"},[_c('button',{staticClass:"quiz-button",attrs:{"disabled":_vm.isDisabled},on:{"click":_vm.pushAnswer}},[_vm._v(" Следующий шаг ")])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"question-note"},[_c('span',[_vm._v("Вопрос на который нужно ответить")])])
}]

export { render, staticRenderFns }