import { request } from './utils';

export default {
  /**
   * Получить урок
   *
   * @param {Object}         params          Параметры
   * @param {String|Number}  params.id       Идентификатор урока
   *
   * @return {Promise}                       Возвращает промис с ответом из сервера.
   */
  getLesson(params = {}) {
    return request('get', `/curriculum/lesson/${params.id}`);
  },
  getLessonInfo(params = {}) {
    return request('get', `/curriculum/activity/lesson/${params}`);
  },
  getLessonsByUnit(params = {}) {
    return request(
      'get',
      `/curriculum/lesson?group_type=UNIT&group_id=${params.id}`
    );
  },
  postLessonActivity(params = {}) {
    return request('patch', `/curriculum/activity/lesson/${params.id}`, {
      data: params,
    });
  },
  getLessonActivity(id) {
    return request('get', `/curriculum/activity/course/${id}`);
  },
  /**
   * Завершить урок
   *
   * @param {Object}         params          Параметры
   * @param {String|Number}  params.id       Идентификатор урока
   *
   * @return {Promise}                       Возвращает промис с ответом из сервера.
   */
  completeLesson(params = {}) {
    return request('post', `/lessons/${params.id}/completed/`);
  },
  /**
   * Завиксировать момент плеера
   *
   * @param {Object}         params              Параметры
   * @param {String|Number}  params.id           Идентификатор урока
   * @param {Object}         params.data         Тело запроса
   * @param {Object}         params.data.moment  Объект вистии с зафиксированным временем
   *
   * @return {Promise}                           Возвращает промис с ответом из сервера.
   */
  setLessonMoment(params) {
    return request('post', `/lessons/${params.id}/moment/`, params);
  },
  /**
   * Получить список недосмотренных уроков
   *
   * @return {Promise}                           Возвращает промис с ответом из сервера.
   */
  getUncompleted() {
    return request('get', '/lessons/incompleted/');
  },
  notes: {
    /**
     * Получить список типов заметок с цветами
     *
     * @return {Promise}                             Возвращает промис с ответом из сервера.
     */
    getTypes() {
      return request('get', '/note_types/');
    },
    /**
     * Получить список заметок урока
     *
     * @param {Object}   params                Параметры
     * @param {Number}   params.lessonId             Идентификатор урока
     *
     * @return {Promise}                       Возвращает промис с ответом из сервера.
     */
    get(params = {}) {
      const queryParams = {
        lesson: params.lessonId,
      };
      return request('get', '/notes/', { params: queryParams });
    },
    /**
     * Создать заметку урока
     *
     * @param {Object}         params                Параметры
     * @param {Object}         params.data           Тело запроса
     * @param {String}         params.data.title     Заголовок
     * @param {String}         params.data.text      Текст заметки
     * @param {Number}         params.data.lessonId  Идентификатор урока
     * @param {Number}         params.data.noteType  Идентификатор типа заметки
     *
     * @return {Promise}                             Возвращает промис с ответом из сервера.
     */
    create(params = {}) {
      return request('post', '/notes/', params);
    },
    /**
     * Обновить заметку урока
     *
     * @param {Object}         params                Параметры
     * @param {Number}         params.id             Идентификатор заметки
     * @param {Object}         params.data           Тело запроса
     * @param {String}         params.data.title     Заголовок
     * @param {String}         params.data.text      Текст заметки
     * @param {Number}         params.data.lessonId  Идентификатор урока
     * @param {Number}         params.data.noteType  Идентификатор типа заметки
     *
     * @return {Promise}                             Возвращает промис с ответом из сервера.
     */
    update(params = {}) {
      return request('patch', `/notes/${params.id}/`, params);
    },
    /**
     * Удалить заметку урока
     *
     * @param {Object}         params                Параметры
     * @param {Number}         params.id             Идентификатор заметки
     *
     * @return {Promise}                             Возвращает промис с ответом из сервера.
     */
    remove(params = {}) {
      return request('delete', `/notes/${params.id}/`);
    },
  },
  independentWork: {
    submit(params = {}) {
      const data = new FormData();
      if (params.file) {
        data.append('file', params.file);
      }
      data.append('answer', params.answer);
      data.append('date', params.date);
      return request(
        'post',
        `/curriculum/activity/selfassignment/${params.id}/submit`,
        {
          data,
        }
      );
    },
    check(params = {}) {
      return request(
        'get',
        `/curriculum/activity/selfassignment/${params.id}/student_submission`
      );
    },
  },
  homework: {
    /**
     * Cдать дз
     *
     * @param {String|Number}  params.id           Идентификатор таска
     * @param {String}         params.file         Файл
     * @param {String}         params.text         Текст сообщения
     *
     * @return {Promise}                           Возвращает промис с ответом из сервера.
     */
    submit(params = {}) {
      const data = new FormData();
      if (params.file) {
        data.append('file', params.file);
      }
      data.append('answer', params.answer);
      data.append('date', params.date);
      return request(
        'post',
        `/curriculum/activity/assignment/${params.id}/submit`,
        {
          data,
        }
      );
    },

    check(params = {}) {
      return request(
        'get',
        `/curriculum/activity/assignment/${params.id}/student_submission`
      );
    },

    /**
     * Получить список недосмотренных уроков
     *
     * @param {String|Number}  params.id            Идентификатор таска
     * @param {String}         params.file          Файл
     * @param {String}         params.text          Текст сообщения
     * @param {String}         params.submissionId  Текст сообщения
     *
     * @return {Promise}                            Возвращает промис с ответом из сервера.
     */
    update(params = {}) {
      const data = new FormData();
      if (params.file) {
        data.append('file', params.file);
      }
      data.append('text', params.text);
      return request(
        'post',
        `/home_tasks/${params.id}/submissions/change/`,
        { data },
        { isFile: !params.file }
      );
    },
  },
  // self
  /**
   * Оценить урок
   *
   * @param {{}}                      params            Параметры
   * @param {String|Number}           params.id         Идентификатор урока
   * @param {Number}                  params.grade      Оценка пользователя
   * @param {String|Number}           params.comment    Отзыв пользователя
   * @param {Object}                  params.data       Тело запроса
   * @return {Promise}                                  Возвращает промис с ответом из сервера.
   */
  createReview(params = {}) {
    return request('post', '/reviews/', params);
  },
  updateReview(params = {}) {
    return request('patch', `/reviews/${params.id}/`, params);
  },
};
