<template>
  <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.27717 16.9978C8.97928 16.9984 8.69059 16.8998 8.46122 16.7191C8.33212 16.6174 8.22541 16.4925 8.14719 16.3515C8.06897 16.2106 8.02078 16.0563 8.00538 15.8976C7.98999 15.739 8.00769 15.5789 8.05747 15.4268C8.10724 15.2746 8.18812 15.1332 8.29548 15.0108L14.0071 8.51662L8.49946 2.01033C8.39356 1.8864 8.31448 1.7438 8.26675 1.59073C8.21903 1.43765 8.20361 1.27713 8.22138 1.11837C8.23916 0.959618 8.28977 0.805768 8.37031 0.665662C8.45085 0.525557 8.55973 0.40196 8.6907 0.301976C8.82261 0.191676 8.97709 0.108479 9.14444 0.0576046C9.31179 0.00673075 9.4884 -0.0107208 9.66318 0.00634448C9.83796 0.0234097 10.0071 0.0746237 10.1601 0.156772C10.3131 0.238921 10.4465 0.35023 10.5521 0.483716L16.71 7.75331C16.8975 7.9701 17 8.24204 17 8.52267C17 8.80331 16.8975 9.07525 16.71 9.29204L10.3354 16.5616C10.2075 16.7083 10.045 16.8242 9.86113 16.8999C9.67728 16.9757 9.47721 17.0093 9.27717 16.9978Z" fill="#ADB2C8"/>
    <path d="M1.27717 16.9978C0.979279 16.9984 0.69059 16.8998 0.461216 16.7191C0.332119 16.6174 0.225406 16.4925 0.147187 16.3515C0.0689677 16.2106 0.0207803 16.0563 0.00538454 15.8976C-0.0100113 15.739 0.00768725 15.5789 0.057466 15.4268C0.107245 15.2746 0.188125 15.1332 0.295476 15.0108L6.00713 8.51662L0.499465 2.01033C0.393562 1.8864 0.314476 1.7438 0.266753 1.59073C0.219031 1.43765 0.203612 1.27713 0.221384 1.11837C0.239155 0.959618 0.289765 0.805768 0.370307 0.665662C0.450849 0.525557 0.559734 0.40196 0.690703 0.301976C0.822614 0.191676 0.977091 0.108479 1.14444 0.0576046C1.31179 0.00673075 1.4884 -0.0107208 1.66318 0.00634448C1.83796 0.0234097 2.00715 0.0746237 2.16012 0.156772C2.31309 0.238921 2.44654 0.35023 2.55209 0.483716L8.70997 7.75331C8.89749 7.9701 9 8.24204 9 8.52267C9 8.80331 8.89749 9.07525 8.70997 9.29204L2.33535 16.5616C2.20746 16.7083 2.04499 16.8242 1.86113 16.8999C1.67728 16.9757 1.47721 17.0093 1.27717 16.9978Z" fill="#ADB2C8"/>
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
