<template>
  <table class="intro-container">
    <tr>
      <td class="intro-container-first">
        <p class="stage-primary">1 этап</p>
        <p class="header-text">{{ course.title }}</p>
        <p class="hint-text">{{ course.description }}</p>
        <router-link
          class="primary-button"
          :to="{ name: 'lesson', params: { id: course.id } }"
          >Смотреть курс</router-link
        >
      </td>
      <td v-if="course.coverImage" class="intro-container-second">
        <img class="intro-cover-image" :src="course.coverImage" />
      </td>
    </tr>
  </table>
</template>

<script>
export default {
  name: 'intro-component',
  props: {
    course: null,
  },
};
</script>

<style lang="scss" scoped>
.intro-container {
  @media (max-width: $layout-s) {
  }
  width: 100%;
  background-color: #f8f8f8;
  border-radius: 22px;
  min-width: 400px;
  padding: 42px 65px;
}
.intro-container-first {
  text-align: start;
  width: 55%;
}
.intro-container-second {
  width: 45%;
  text-align: end;
}
.stage-primary {
  background-color: #3a7dff;
  color: white;
  border-radius: 10px;
  width: max-content;
  font-size: 14px;
  padding: 5px 20px;
  font-family: Rubik, sans-serif;
}
.primary-button {
  padding: 14px 24px;
  color: white;
  font-size: 18px;
  background-color: #3a7dff;
  width: max-content;
  text-decoration: none;
  transition: 0.3s;
  border-radius: 10px;
  cursor: pointer;
}
.primary-button:hover {
  background-color: #2c52d1;
}
.header-text {
  margin-top: 40px;
  color: rgba(0, 0, 0, 0.8);
  font-size: 35px;
  font-family: Rubik, sans-serif;
}
.hint-text {
  color: rgba(0, 0, 0, 0.8);
  margin-top: 18px;
  line-height: 33px;
  padding-bottom: 40px;
}
tr,
td {
  height: 1px;
}
@-moz-document url-prefix() {
  tr,
  td {
    height: 100%;
  }
}
.intro-cover-image {
  width: 70%;
}

@media screen and (max-width: 870px) {
  .header-text {
    font-size: 24px;
    font-weight: 600;
  }
  .intro-container {
    padding: 22px 24px;
  }
  .intro-container-first {
    width: 100%;
  }
  .intro-container-second {
    display: none;
  }
}
</style>
