import { request } from './utils';

export default {
  getCourseUnits(params = {}) {
    return request('get', `/curriculum/unit/${params.id}`);
  },
  getUnitsByCourseId(params = {}) {
    return request(
      'get',
      `/curriculum/unit?group_type=COURSE&group_id=${params.id}`
    );
  },
  sendUnitFeedback(params = {}) {
    return request('post', '/curriculum/activity/nps/submit', { data: params })
  }
};
