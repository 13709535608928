<template>
  <div class="layout">
    <div v-if="showLoading">Loading...</div>
    <div v-else>
      <div class="row">
        <div class="col-50">
          <div class="col-25">
            <btn class="course-btn">
              Редактировать
            </btn>
          </div>
          <div class="col-25">
            <btn class="course-btn secondary">
              Скачать в PDF
            </btn>
          </div>
        </div>
        <div class="col-75">
          <div class="vacancy-row">
            <h1>{{ vacancy.name }}</h1>
          </div>
          <div class="vacancy-row">
            <div class="meta-info">{{ vacancy.age }} года, {{ vacancy.city }}</div>
          </div>
          <div class="vacancy-row">
            <h2>{{ vacancy.title }}</h2>
          </div>
          <div class="meta-info">
            {{ vacancy.remote }}
          </div>
          <div class="meta-info">
            {{ vacancy.time }}
          </div>
        </div>
        <div class="row">
          <div class="col-75">
            <div class="vacancy-row d-flex">
              <h2>Опыт работы:</h2>
              <span class="meta-info ml-20">{{ vacancy.experience }}</span>
            </div>
            <div class="vacancy-row">
              <h4>Слесарь, Bi Group</h4>
              <div class="vacancy-time-period">
                <span class="meta-info">99 год 99 месяцев</span>
                <span class="meta-info">Январь, 2007 - по настоящее время</span>
              </div>
              <p>
                Гадать на карта, знать таро, собирать кубик рубик за 3 секунды, гулять в местах с
                плохими отзывами, быть не гибким, много материться, иметь водительские права класа
                Г,Л,, пить только сады Плохими отзывами, быть не гибким, много материться, иметь
                водительские права класа Г,Л,, пить только сады
              </p>
            </div>
            <div class="vacancy-row">
              <h4>Слесарь, Bi Group</h4>
              <p>
                Гадать на карта, знать таро, собирать кубик рубик за 3 секунды, гулять в местах с
                плохими отзывами, быть не гибким, много материться, иметь водительские права класа
                Г,Л,, пить только сады Плохими отзывами, быть не гибким, много материться, иметь
                водительские права класа Г,Л,, пить только сады
              </p>
            </div>
            <div class="vacancy-row">
              <h2>{{ vacancy.salary }} тг/месяц</h2>
            </div>
            <div class="vacancy-row">
              <div class="meta-info">
                {{ vacancy.company.name }}
              </div>
            </div>
            <div class="vacancy-row">
              <div class="meta-info">
                {{ vacancy.city }}
              </div>
            </div>
            <div class="vacancy-row">
              <div class="meta-info">
                <span v-if="vacancy.time === 'project'"> Project work </span>
                <span v-else-if="vacancy.time === 'full-time'">
                  Full time work
                </span>
                <span v-else-if="vacancy.time === 'part-time'"> Part time work </span>,
                <span v-if="vacancy.remote === 'remote'"> Remote work </span>
              </div>
            </div>
            <div class="vacancy-row">
              <h3>Описание</h3>
              <div class="vacancy-description">
                {{ vacancy.description }}
              </div>
            </div>
            <div class="vacancy-row">
              <h3>Обязанности</h3>
              <div class="vacancy-description">
                <ul>
                  <li v-for="obligation in vacancy.obligations" :key="obligation">
                    {{ obligation }}
                  </li>
                </ul>
              </div>
            </div>
            <div class="vacancy-row">
              <h3>Условия</h3>
              <div class="vacancy-description">
                <ul>
                  <li v-for="condition in vacancy.conditions" :key="condition">
                    {{ condition }}
                  </li>
                </ul>
              </div>
            </div>
            <div class="vacancy-row">
              <h3>Навыки</h3>
              <div class="d-flex vacancy-description">
                <div v-for="skill in vacancy.skills" :key="skill">
                  {{ skill }}
                </div>
              </div>
            </div>
          </div>
          <div class="col-25">
            <img :src="vacancy.company.logo" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Btn from '@/components/Button';

export default {
  name: 'Resume',
  components: {
    Btn,
  },
  data() {
    return {
      showLoading: true,
      vacancy: {
        id: 1,
        name: 'Иванов Иван',
        age: 23,
        title: 'Таргетолог',
        salary: '20000',
        experience: '50 лет',
        company: {
          name: 'IP Kairat',
          logo:
            'https://habrastorage.org/getpro/moikrug/uploads/company/497/810/467/logo/medium_2696023295dd50b6428e2907d7ac30d6.jpeg',
        },
        city: 'Almaty',
        address: 'Ryskulov 123',
        time: 'full-time',
        remote: 'remote',
        description: 'fdsf',
        obligations: ['dsad', 'sdsd', 'dsads'],
        conditions: ['dsfsdf', 'dsf', 'dsfdsf'],
        skills: ['1213', 'dsadasdasdsddddddddddddddddddddddddddsadsad', 'dsdsffdsfsdfdsf'],
      },
    };
  },
  created() {
    this.getVacancy();
    this.showLoading = false;
  },
  computed: {
    vacancyId() {
      return this.$route.params.id;
    },
  },
  methods: {
    getVacancy() {
      this.$api.vacancy.getVacancy({ id: this.vacancyId }).then(({ data }) => {
        this.vacancy = data;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.d-flex {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
}

.d-flex > div {
  padding: 0.1rem;
  color: grey;
  margin-right: 0.5rem;
  margin-top: 0.5rem;
  background-color: rgba(86, 61, 124, 0.15);
  border: 1px solid rgba(86, 61, 124, 0.15);
}
.vacancy-description {
  font-size: 17px;
}
.meta-info {
  color: #c4c4c4;
  font-size: 17px;
  font-weight: 500;
  margin: 15px 0;
}
.vacancy-row {
  margin-bottom: 15px;
}

.vacancy-row p {
  width: 80%;
}
.row {
  margin: 20px 0;
}
/* Clear floats after the columns */
.row:after {
  content: '';
  display: table;
  clear: both;
}

.col-25 {
  float: left;
  width: 25%;
}

.col-75 {
  width: 75%;
  float: left;
}

.course-btn {
  width: 95%;
  margin: 15px 0;
}

.ml-20 {
  display: block;
  margin-left: 20px;
}

/* Responsive layout - when the screen is less than 600px wide,
make the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: $layout-s) {
  .col-25,
  .col-75 {
    width: 100%;
  }
}
</style>
