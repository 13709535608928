export default {
  upload(event) {
    const fileReader = new FileReader();
    let formData = '';
    fileReader.onload = (e) => {
      formData = e.target.result;
    };
    fileReader.readAsText(event.target.files[0]);
    return {
      file: event.target.files[0],
      reader: fileReader,
      formData,
    };
  },
};
