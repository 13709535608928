import { request } from './utils';

export default {
  /**
   * Отправить письмо студентам
   *
   * @param {Object}    params                   Параметры
   * @param {Object}    params.data              Данные
   * @param {String}    params.data.template     Имя шаблона
   * @param {String}    params.data.email        Почта получателя
   * @param {String}    params.data.userRole     Роль получателя
   *
   * @return {Promise}                       Возвращает промис с ответом из сервера.
   */
  sendMail(params = {}) {
    return request('post', '/users/send_template_emails/', params);
  },
};
