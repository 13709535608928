import { request } from './utils';

export default {
  /**
   * Получить информацию о вакансиях
   *
   * @param {Object}         params                          Параметры
   * @param {Number|String}  params.id                       ID вакансии
   *
   * @return {Promise}                                       Возвращает промис с ответом из сервера.
   */
  getVacancy(params) {
    return request('get', `/resume/vacancies/${params.id}/`);
  },
  getFilteredVacancies(params) {
    const filterParams = new URLSearchParams();
    filterParams.append('specializations', params.specializations);
    filterParams.append('work_format', params.workFormat);
    filterParams.append('employment_type', params.employmentType);
    if (params.country) {
      filterParams.append('country', params.country);
    }
    if (params.city) {
      filterParams.append('city', params.city);
    }
    return request('get', '/resume/vacancies/', { params: filterParams });
  },
  getVacancies(params) {
    return request('get', `/resume/vacancies/?page=${params.page}`);
  },
  applyVacancy(params = {}) {
    return request('post', '/resume/matches/', params);
  },
};
