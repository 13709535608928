<template>
  <svg
    width="7"
    height="11"
    viewBox="0 0 7 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 1L5.5 5.5L1 10"
      stroke="black"
      stroke-opacity="0.38"
      stroke-width="1.5"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
