<template>
  <Modal :show="isProgramSelected === 'null'">
    <section class="program">
      <h1 class="program__header">Выберите Программу</h1>
      <div class="program__items">
        <div
          class="program__item"
          v-for="program in programs"
          :key="program.id"
          @click="changeProgram(program.id)"
        >
          <div class="program__preview">
            <img
              v-if="program.coverImage"
              class="program__preview-image"
              :src="program.coverImage"
              :alt="program.title"
            />
            <img
              v-else
              class="program__preview-image"
              src="../assets/images/program.png"
              alt=""
            />
            <p class="program__preview-text">{{ program.title }}</p>
          </div>
        </div>
      </div>
    </section>
  </Modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import Modal from '@/components/Modal';
import trackAmplitudeEvent from '@/utils/trackAmplitudeEvent';

import $globalFn from '../fn';

export default {
  name: 'SelectProgram',
  props: ['clear'],
  components: {
    Modal,
  },
  mounted() {
    if (this.$fn.ls.get('program')) {
      this.isProgramSelected = this.$fn.ls.get('program');
    }
  },
  data() {
    return {
      isProgramSelected: 'null',
      test: this.$fn.ls.get('program'),
    };
  },
  computed: {
    ...mapGetters({
      programs: 'getPrograms',
      courses: 'getMyCourses',
      selectedProgram: 'getSelectedProgram',
    }),
  },
  watch: {
    clear: {
      handler() {
        if (this.clear) {
          this.isProgramSelected = 'null';
        }
      },
      immediate: true,
    },
    programs: {
      handler() {
        if (this.programs.length === 1) {
          this.isProgramSelected = this.programs;
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions(['fetchMyCourses']),
    async changeProgram(value) {
      const userId = $globalFn.ls.get('userId');
      trackAmplitudeEvent('change_program', userId, { program_id: value });
      await this.fetchMyCourses(value);
      this.isProgramSelected = '';
      this.$emit('close');
      if (this.$route.path !== '/') {
        await this.$router.push('/');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.program {
  &__header {
    text-align: center;
    margin-bottom: 20px;
  }

  &__items {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  &__item {
    cursor: pointer;
    margin: 20px 40px;
  }

  &__preview {
    text-align: center;

    p {
      margin-top: 10px;
      font-size: 20px;
      font-weight: 700;
    }
  }

  &__preview-image {
    object-fit: fill;
    border-radius: 30px;
    height: 300px;
    width: 500px;
  }
}

@media (max-width: $layout-s) {
  .program {
    &__items {
      flex-wrap: wrap;
    }

    &__preview-image {
      width: 100%;
      height: 200px;
    }

    &__item {
      margin: 20px 0;
    }
  }
}
</style>
