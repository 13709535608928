<template>
  <div>
    <div class="auth-container">
      <div class="auth-image"></div>
      <div class="auth-form--container">
        <login-form
          @success="success"
          v-if="user.authModal.state === 'LOGIN'"
        />
        <registration-form v-if="user.authModal.state === 'REG'" />
        <forgot-password-form
          v-if="user.authModal.state === 'FORGOT_PASSWORD'"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import LoginForm from '@/components/Auth/LoginForm';
import RegistrationForm from '@/components/Auth/RegistrationForm';
import ForgotPasswordForm from '@/components/Auth/ForgotPasswordForm';

export default {
  components: {
    LoginForm,
    RegistrationForm,
    ForgotPasswordForm,
  },
  data() {
    return {
      state: 'login',
    };
  },
  computed: {
    ...mapGetters({
      user: 'getUser',
    }),
  },
  methods: {
    async success() {
      await this.$router.push('/');
    },
  },
};
</script>

<style lang="scss" scoped>
.auth {
  &-container {
    width: 100%;
    display: flex;
    height: 100vh;
  }
  &-image {
    width: 100%;
    background-image: url('./../../assets/images/welcome.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  &-form--container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
}
@media screen and (max-width: $layout-s) {
  .auth {
    &-container {
      flex-direction: column;
      height: fit-content;
    }
    &-image {
      max-width: 100%;
      height: 400px;
    }
    &-form--container {
      height: 600px;
      align-items: flex-start;
      margin-top: 20px;
    }
  }
}
</style>
