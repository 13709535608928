<template>
  <div class="notes">
    <div class="layout">
      <div class="notes-wrap">
        <h1>Мои заметки</h1>
        <div>
          <div class="notes-filters">
            <div class="notes-filter">
              <tooltip>
                <template #activator>
                  <div class="notes-filter-header">
                    <span>{{ note.course ? note.course.title : 'Курс' }}</span>
                    <i class="fas fa-chevron-circle-up" />
                  </div>
                </template>
                <template>
                  <ul>
                    <li
                      v-for="course in note.courses"
                      :key="course.id"
                      @click="onChangeCourse(course)"
                    >
                      {{ course.title }}
                    </li>
                  </ul>
                </template>
              </tooltip>
            </div>
            <div class="notes-filter">
              <tooltip>
                <template #activator>
                  <div class="notes-filter-header">
                    <span>{{ note.block ? note.block.title : 'Блок' }}</span>
                    <i class="fas fa-chevron-circle-up" />
                  </div>
                </template>
                <template>
                  <ul>
                    <li
                      v-for="block in note.blocks"
                      :key="block.id"
                      @click="onChangeBlock(block)"
                    >{{ block.title }}
                    </li>
                  </ul>
                </template>
              </tooltip>
            </div>
            <div class="notes-filter">
              <tooltip>
                <template #activator>
                  <div class="notes-filter-header">
                    <span>{{ note.lesson ? note.lesson.title : 'Урок' }}</span>
                    <i class="fas fa-chevron-circle-up" />
                  </div>
                </template>
                <template>
                  <ul>
                    <li
                      v-for="lesson in note.lessons"
                      :key="lesson.id"
                      @click="onChangeLesson(lesson)"
                    >{{ lesson.title }}</li>
                  </ul>
                </template>
              </tooltip>
            </div>
          </div>
          <ul class="notes-types">
            <li
              v-for="noteType in note.noteTypes"
              :key="noteType.id"
              @click="onChangeType(noteType.id)"
              :style="{'background-color': noteType.color}"
            >
              {{ noteType.name }}
            </li>
          </ul>
          <loader v-if="note.loading">Загрузка...</loader>
          <div v-else class="notes-list">
            <note v-for="note in note.notes" :key="note.id" :note="note" />
          </div>
          <h2 v-if="!note.notes.length && !note.loading" class="notes-empty">
            Заметок пока нет...
          </h2>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import Tooltip from '@/components/Tooltip';
import Note from '@/components/Notes/Note';
import Loader from '@/components/Loader';

export default {
  components: { Tooltip, Note, Loader },
  mounted() {
  },
  computed: {
    ...mapState(['note']),
  },
  methods: {
    ...mapActions([
      'fetchNotes',
      'fetchNoteTypes',
      'onChangeCourse',
      'onChangeBlock',
      'onChangeLesson',
      'onChangeType',
    ]),
  },
};
</script>

<style lang="scss" scoped>
.notes {
  padding-top: 45px;
  padding-bottom: 200px;
  user-select: none;
  h1 {
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: .5px solid map-get($gray, 200);
  }
  &-filters {
    display: flex;
    margin-bottom: 30px;
    @media screen and (max-width: $layout-s) {
      flex-direction: column;
    }
  }
  &-filter {
    margin-right: 30px;
    @media screen and (max-width: $layout-s) {
      margin-right: 0;
      ::v-deep .ui-tooltip {
        display: block;
        margin-bottom: 10px;
      }
    }
    &-header {
      padding: 12px 45px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid map-get($gray, 400);
      border-radius: 4px;
      cursor: pointer;
      user-select: none;
      span {
        font-weight: 500;
        font-size: 16px;
        margin-right: 30px;
      }
    }
    ::v-deep .ui-tooltip-content {
      width: 100%;
      top: 45px;
      background-color: $white;
      border: 1px solid map-get($gray, 400);
      border-radius: 4px;
      li {
        padding: 12px 20px;
        font-size: 14px;
        border-bottom: .5px solid map-get($gray, 400);
        list-style: none;
        background-color: $primary;
        color: $white;
        &:last-child {
          border-bottom: none;
        }
      }
    }
    &-active {
      background-color: $primary;
      color: $white;
    }
  }
  &-types {
    display: flex;
    margin-bottom: 70px;
    @media screen and (max-width: $layout-s) {
      flex-direction: column;
    }
    li {
      padding: 15px 20px;
      margin-right: 30px;
      border-radius: 4px;
      background-color: $primary;
      font-weight: 500;
      font-size: 13px;
      color: $white;
      list-style: none;
      cursor: pointer;
      @media screen and (max-width: $layout-s) {
        margin-right: 0;
        margin-bottom: 5px;
      }
    }
  }
  &-list {
    column-count: 3;
    @media screen and (max-width: $layout-s) {
      display: flex;
      flex-wrap: wrap;
    }
  }
  &-empty {
    text-align: center;
  }
}
</style>
