<template>
  <div v-if="assignments" class="home-work">
    <div class="home-work-header">
      <h2>Домашние задания</h2>
    </div>
    <table class="homework-list">
      <tr>
        <th>Заголовок</th>
        <th>Дедлайн</th>
        <th>Статус</th>
      </tr>
      <tr v-for="item in assignments" :key="item.id">
        <td>
          {{ item.title }}
        </td>
        <td>{{ $day_js(item.deadline).format('DD/MM/YYYY') }}</td>
        <td><span @click="show = !show" class="active">Выполнить</span></td>
      </tr>
    </table>

    <div class="homework-moble-list">
      <h4>Домашние задания</h4>
      <div
        class="homework-moble-list-item"
        v-for="item in assignments"
        :key="item.id"
      >
        <img class="teacher" src="@/assets/images/userIcon.png" />
        <div class="description">
          <span>{{ item.description }}</span>
          <span>{{ $day_js(item.deadline).format('DD/MM/YYYY') }}</span>
        </div>
        <ui-icon class="sidebar--icon" name="Forward" />
      </div>
    </div>

    <!-- <modal :show="show" @close="$emit('close')"> </modal> -->
    <submission-modal
      :show="show"
      @close="show = false"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SubmissionModal from '@/components/Lesson/modals/HomeworkSubmission';

export default {
  name: 'HomeWorkList',
  data() {
    return {
      show: false,
    };
  },
  components: {
    SubmissionModal,
  },
  computed: {
    ...mapGetters({
      assignments: 'getAssignments',
    }),
  },
};
</script>

<style lang="scss" scoped>
.home-work-header {
  margin-top: 20px;
}
.show-all-div {
  padding: 5px;
  float: right;
  color: $primary;
  font-size: 16px;
  font-weight: 400;
  font-family: 'Rubik', sans-serif;
  cursor: pointer;
}
h2 {
  font-weight: 400;
  float: left;
  margin-bottom: 30px;
  font-size: 26px;
}
@media (max-width: $layout-s) {
  h2 {
    font-size: 20px;
    font-weight: 500;
  }
}
.homework-list {
  width: 100%;
  border: 1px solid #e8e8e8;
  border-radius: 20px;
  margin: 15px auto;

  @media (max-width: $layout-s) {
    display: none;
  }

  th {
    border-right: 1px solid #e8e8e8;
    text-align: left;
    padding: 10px;
    padding-left: 25px;
    font-size: 18px;
    line-height: 24px;
    /* identical to box height */
    font-weight: 200;
    color: rgba(0, 0, 0, 0.3);

    &:last-of-type {
      border: none;
    }
  }

  td {
    padding: 20px;
    border-bottom: 1px solid #e8e8e8;

    .teacher {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 20px;

      img {
        width: 40px;
        height: auto;
      }
    }
  }

  tr {
    &:last-of-type {
      td {
        border: none;
      }
    }
  }
}

.homework-moble-list {
  display: none;
  flex-direction: column;
  width: 100%;
  gap: 15px;
  margin-top: 20px;

  @media (max-width: $layout-s) {
    display: flex;
  }
  h4 {
    font-weight: 400;
    font-size: 16.9px;
    line-height: 20px;
    color: #000000;
    text-align: left;
  }

  &-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
    width: 100%;
    border: 1px solid #e8e8e8;
    border-radius: 20px;
    padding: 5px;
    cursor: pointer;

    span {
      &:last-of-type {
        color: rgba(0, 0, 0, 0.38);
        font-size: 13px;
        font-weight: lighter;
      }
    }

    img {
      width: 40px;
      height: auto;
    }

    .description {
      display: flex;
      flex-direction: column;
    }
  }
}

.done {
  display: block;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: $success;
  background: $lilac-secondary;
  padding: 15px 30px;
  border-radius: 10px;
  text-align: center;
  width: 100%;
  margin: 0 auto;
}

.active {
  display: block;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: $primary;
  background: $secondary;
  padding: 15px 30px;
  border-radius: 10px;
  text-align: center;
  width: 100%;
  margin: 0 auto;
  &:hover {
    cursor: pointer;
  }
}
.missed {
  display: block;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #e85555;
  background: rgba(255, 58, 58, 0.05);
  padding: 15px 30px;
  border-radius: 10px;
  text-align: center;
  width: 100%;
  margin: 0 auto;
}
</style>
