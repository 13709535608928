<template>
  <div class="layout">
    <div class="row">
      <div class="col-25">
        <vacancy-filter
          class="filter-desktop"
          @on-submit="getFilteredVacancies"
        />
        <div class="filter-mobile" @click="isVacancyFilterModalOpened = true">
          <div class="filter-button">
            <div class="filter-text">Фильтры</div>
            <div><ui-icon name="filter-config" /></div>
          </div>
        </div>
      </div>
      <div class="col-75">
        <loader v-if="showLoading" />
        <div class="center" v-if="!showLoading && vacancies.length == 0">
          По вашему запросу ничего не найдено
        </div>
        <div class="vacancy" v-for="vacancy in vacancies" :key="vacancy.id">
          <div class="vacancy-row d-flex">
            <div class="header-title_underlined">
              <router-link
                :to="{ name: 'vacancy-detail', params: { id: vacancy.id } }"
                style="text-decoration: none; color: inherit"
              >
                {{ vacancy.name }}
              </router-link>
            </div>
            <div v-if="vacancy.salary > 0" class="header-title">
              {{ vacancy.salary.toLocaleString() }} тг
            </div>
          </div>
          <div class="vacancy-row">
            <div class="meta-info">
              <span>{{ vacancy.company }}</span
              >,
              <span>{{ vacancy.city.name }} </span>
            </div>
          </div>
          <div class="vacancy-row">
            <div class="meta-info">
              <span> {{ remoteWorksList(vacancy) }} </span>
            </div>
          </div>
          <div class="vacancy-row">
            <div class="meta-info">
              {{ employmentTypesList(vacancy) }}
            </div>
          </div>
          <div class="vacancy-row">
            <div class="vacancy-description">
              {{ vacancy.responsibilities }}
            </div>
          </div>
          <btn class="vacancy-button" @click="goToVacancyDetails(vacancy)"
            >Подробнее</btn
          >
        </div>
        <div v-if="vacancies.length > 0 && pagesNumber > 1" class="center">
          <div class="pagination">
            <a v-if="showPrevButton" @click="loadPrevious">&laquo;</a>
            <a
              :class="{ active: page == currentPage }"
              v-for="page in pagesNumber"
              :key="page"
              @click="getPage(page)"
              >{{ page }}</a
            >
            <a v-if="showNextButton" @click="loadNext">&raquo;</a>
          </div>
        </div>
      </div>
    </div>
    <modal
      fluid
      :show="isVacancyFilterModalOpened"
      @close="closeVacancyFilterModal"
    >
      <vacancy-filter
        :vacancy-filter="vacancyFilter"
        @on-submit="getFilteredVacancies"
      />
    </modal>
  </div>
</template>

<script>
import VacancyFilter from '@/components/Vacancy/VacancyFilter';
import Btn from '@/components/Button';
import Loader from '@/components/Loader';
import Modal from '@/components/Modal';

export default {
  name: 'Vacancy',
  components: {
    VacancyFilter,
    Btn,
    Loader,
    Modal,
  },
  data() {
    return {
      showLoading: true,
      vacancies: [],
      pagesNumber: null,
      currentPage: 1,
      showNextButton: false,
      showPrevButton: false,
      isVacancyFilterModalOpened: false,
      vacancyFilter: {},
    };
  },
  mounted() {
    this.getVacancies();
  },
  methods: {
    closeVacancyFilterModal() {
      this.isVacancyFilterModalOpened = false;
    },
    goToVacancyDetails(vacancy) {
      this.$router.push({ name: 'vacancy-detail', params: { id: vacancy.id } });
    },
    remoteWorksList(vacancy) {
      const names = vacancy.remoteWork.map((item) => item.name);
      return names.join(', ');
    },
    employmentTypesList(vacancy) {
      const names = vacancy.employmentType.map((item) => item.name);
      return names.join(', ');
    },
    loadNext() {
      this.currentPage += 1;
      this.getVacancies();
    },
    loadPrevious() {
      this.currentPage -= 1;
      this.getVacancies();
    },
    getPage(page) {
      this.currentPage = page;
      this.getVacancies();
    },
    getFilteredVacancies(filterData) {
      this.vacancies = [];
      this.showLoading = true;
      this.vacancyFilter = filterData;
      this.closeVacancyFilterModal();
      const filterParamsAndPageNumber = {
        ...filterData,
        page: this.currentPage,
      };
      this.$api.vacancy
        .getFilteredVacancies(filterParamsAndPageNumber)
        .then(({ data }) => {
          this.configurePagination(data);
        })
        .finally(() => {
          this.showLoading = false;
        });
    },
    getVacancies() {
      this.vacancies = [];
      this.showLoading = true;
      this.$api.vacancy
        .getVacancies({ page: this.currentPage })
        .then(({ data }) => {
          this.configurePagination(data);
        })
        .finally(() => {
          this.showLoading = false;
        });
    },
    configurePagination(data) {
      this.vacancies = data.results;
      this.pagesNumber = Math.ceil(data.count / 10);
      this.showPrevButton = false;
      this.showNextButton = false;
      if (data.next) {
        this.showNextButton = true;
      }
      if (data.previous) {
        this.showPrevButton = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.row {
  margin: 20px 0;
}
/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

.col-25 {
  float: left;
  padding-right: 10px;
  width: 25%;
  margin-top: 6px;
}

.col-75 {
  width: 75%;
  padding-left: 10px;
  float: left;
  margin-top: 6px;
}

.d-flex {
  display: flex; /* or inline-flex */
  justify-content: space-between;
}

.header-title,
.header-title_underlined {
  font-size: 24px;
  font-weight: 450;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  &_underlined:hover {
    text-decoration: underline;
  }
}
.vacancy {
  border-left: 1px solid map-get($gray, 400);
  border-right: 1px solid map-get($gray, 400);
  border-top: 1px solid map-get($gray, 400);
  border-radius: 2px;
  padding: 18px;
  &-button {
    border-radius: 2px;
    width: 270px;
    height: 47px;
  }
}
.vacancy:last-child {
  border-bottom: 1px solid map-get($gray, 400);
}
.vacancy .meta-info {
  color: map-get($gray, 800);
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 8px;
}
.vacancy-row {
  margin-bottom: 5px;
}
.vacancy-description {
  font-size: 15px;
  margin-bottom: 15px;
  white-space: pre-line;
}
.center {
  text-align: center;
}
.pagination {
  display: inline-block;
}

.pagination a {
  color: black;
  border-radius: 2px;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
  transition: background-color 0.3s;
  border: 1px solid map-get($gray, 800);
  margin: 0 4px;
}

.pagination a.active {
  background-color: $primary;
  color: white;
  border: 1px solid $primary;
}

.pagination a:hover:not(.active) {
  background-color: #ddd;
}
.filter {
  &-text {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: $dark;
  }
  &-mobile {
    display: none;
  }
  &-button {
    display: flex;
    background: map-get($gray, 900);
    padding: 12px;
    border-radius: 5px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}

/* Responsive layout - when the screen is less than 600px wide,
make the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: $layout-s) {
  .col-25,
  .col-75 {
    padding-left: 0;
    padding-right: 0;
    width: 100%;
  }
  .col-25 {
    margin-top: 0;
  }
  .d-flex {
    flex-direction: column;
  }
  .header-title,
  .header-title_underlined {
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 5px;
  }
  .header-title_underlined {
    margin-bottom: 10px;
  }
  .meta-info {
    margin-bottom: 5px;
    font-weight: normal;
  }
  .vacancy-button {
    width: 100%;
  }
  .filter-desktop {
    display: none;
  }
  .filter-mobile {
    display: block;
  }
}
</style>
