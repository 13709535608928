import { request } from './utils';

export default {
  /** Получить курс
   *
   * @param {Object}         params              Параметры
   * @param {String|Number}  params.id           Идентификатор курса
   *
   * @return {Promise}                           Возвращает промис с ответом из сервера.
   */
  getCourse(params) {
    return request('get', `/curriculum/course/${params.id}`);
  },

  getCourseActivity(id) {
    return request('get', `/curriculum/activity/course/${id}`);
  },

  getCertificate(params) {
    return request('get', `/courses/${params.id}/certificate`);
  },
  /**
   * Вывод всех уроков курса разделенных по блокам
   *
   * @param {Object}         params              Параметры
   * @param {String|Number}  params.id           Идентификатор курса
   *
   * @return {Promise}                           Возвращает промис с ответом из сервера.
   */

  getBlocks(params = {}) {
    return request('get', `/courses/${params.id}/blocks/`);
  },
  /**
   * Получить мои блоки (курсы)
   *
   * @return {Promise}                           Возвращает промис с ответом из сервера.
   */
  getMine() {
    return request('get', '/curriculum/course');
  },
  /**
   * Начать курс
   *
   * @param {Object}            params           Параметры
   * @param {Number|String}     params.id        ID курса
   *
   * @return {Promise}                           Возвращает промис с ответом из сервера.
   */
  start(params = {}) {
    return request('post', `/courses/${params.id}/start/`);
  },
  /**
   * Выбрать профессию
   *
   * @param {Object}            params           Параметры
   * @param {Number|String}     params.id        ID курса
   *
   * @return {Promise}                           Возвращает промис с ответом из сервера.
   */
  choose(params = {}) {
    return request('patch', `/curriculum/activity/course/${params.id}`, {
      data: params,
    });
  },
};
