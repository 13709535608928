import { request } from './utils';

export default {
  /**
   * Получить сферы деятельности
   *
   * @return {Promise}                           Возвращает промис с ответом из сервера.
   */
  getScopes() {
    return request('get', '/scopes/');
  },
  getSpecialties() {
    return request('get', '/specialties/');
  },
  getSkills() {
    return request('get', '/resume/skills/');
  },
  getWorkFormats() {
    return request('get', '/resume/work_formats/');
  },
  getEmploymentTypes() {
    return request('get', '/resume/employment_types/');
  },
};
