<template>
  <div class="webinars">
   <div>
     <div class="layout">
       <div class="webinars-filter">
         <div
           @click="setActiveFilter(webinarFilters.UPCOMING)"
           class="webinars-filter-item"
           :class="{active: activeFilter === webinarFilters.UPCOMING}"
         >
           Предстоящие вебинары
         </div>
         <div
           @click="setActiveFilter(webinarFilters.PAST)"
           class="webinars-filter-item"
           :class="{active: activeFilter === webinarFilters.PAST}"
         >
           Прошедшие вебинары
         </div>
       </div>
       <p v-if="!webinar.upcomingWebinars.length" class="webinars-is-empty">Скоро...</p>
       <div v-else-if="activeFilter === webinarFilters.UPCOMING" class="webinars-list">
         <webinar-card
           v-for="webinar in webinar.upcomingWebinars"
           :key="webinar.id"
           :webinar="webinar"
         />
       </div>
       <div v-else class="webinars-list">
         <p v-if="!webinar.pastWebinars.length" class="webinars-is-empty">Скоро...</p>
         <webinar-card
           v-else
           v-for="webinar in webinar.pastWebinars"
           :key="webinar.id"
           :webinar="webinar"
           isPast
         />
       </div>
     </div>
   </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import WebinarCard from '@/components/Webinars/WebinarCard';

export default {
  components: { WebinarCard },
  data() {
    return {
      activeFilter: this.$vars.webinar.webinarFilters.UPCOMING,
    };
  },
  created() {
    this.fetchWebinars();
  },
  computed: {
    webinarFilters() {
      return this.$vars.webinar.webinarFilters;
    },
    ...mapState(['webinar']),
  },
  methods: {
    setActiveFilter(value) {
      this.activeFilter = value;
    },
    ...mapActions(['fetchWebinars']),
  },
};
</script>

<style lang="scss" scoped>
.webinars {
  background-color: $lilac-secondary;
  &-filter {
    display: flex;
    height: 60px;
    &-item {
      padding: 0 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 25px;
      font-size: 14px;
      transition: .2s;
      color: map-get($gray, 500);
      &:hover {
        //color: $primary;
        cursor: pointer;
        background-color: $lilac;
      }
      &.active {
        color: $primary;
        border-bottom: 4px solid $primary;
      }
    }
  }
  &-is-empty {
    padding: 100px;
    text-align: center;
    font-size: 26px;
    color: map-get($gray, 500);
    font-weight: 500;
  }
}
</style>
