export default {
  bind(el, binding) {
    const clickEventHandler = (event) => {
      if (!el.contains(event.target) && el !== event.target) {
        binding.value(event);
      }
    };
    // eslint-disable-next-line no-underscore-dangle, no-param-reassign
    el.__vueClickEventHandler__ = clickEventHandler;
    document.addEventListener('click', clickEventHandler);
  },
  unbind(el) {
    // eslint-disable-next-line no-underscore-dangle, no-param-reassign
    document.removeEventListener('click', el.__vueClickEventHandler__);
  },
};
