<template>
  <div class="program">
    <loader-overlay :show="loading" text="Загружаем программу" />
    <div v-if="!loading">
      <div v-if="defaultCourse">
        <header>
          <div class="program-preview">
            <img :src="program.image" :alt="program.title" />
          </div>
          <div class="program-general">
            <div class="program-label">Программа</div>
            <h1>{{ program.title }}</h1>
            <div class="program-duration">Продолжительность {{ program.duration }}</div>
            <div class="description">{{ program.description }}</div>
            <btn @click="showTableModal = true" fluid>Расписание курса</btn>
          </div>
        </header>
        <section class="program-additional">
          <div class="layout">
            <div class="block">
              <div class="label">Продолжительность</div>
              <div class="content">{{ program.opensAt }} — {{ program.closesAt }}</div>
            </div>
            <div class="block">
              <div class="label">Формат обучения</div>
              <div class="content">Видеоуроки / Практические задания / Тесты / Вебинары</div>
            </div>
          </div>
        </section>
        <section v-if="program.programUserStatuses" class="program-cert">
          <div class="layout">
            <h2 class="program-cert-title">Как будет проходить курс?</h2>
            <div class="stepper">
              <stepper :statuses="program.programUserStatuses" />
            </div>
          </div>
        </section>
        <section class="program-intro">
          <div class="layout">
            <div class="program-intro-content">
              <div>
                <div>
                  <h2>1. Вводный курс</h2>
                  <h5>Научить правильно учиться, мышлению маркетолога и изучите базу маркетинга</h5>
                  <div class="controls">
                    <div class="program-intro-btns" v-if="introCourse.courseUser">
                      <btn
                        variant="green"
                        v-if="introCourse.courseUser.completion === 2"
                        @click="startIntro"
                      >
                        Вы окончили вводный курс
                      </btn>
                      <btn v-else @click="continueIntro">Продолжить курс</btn>
                    </div>
                    <btn v-else @click="startIntro" :loading="introBtnLoad"
                      >Начать вводный курс</btn
                    >
                    <a
                      v-if="introCourse.courseUser && introCourse.courseUser.completion !== 2"
                      href="#"
                      @click.prevent="toggleBlocks"
                    >
                      <span>Программа курса</span>
                      <i :class="`far fa-chevron-${showBlocks ? 'up' : 'down'}`"></i>
                    </a>
                  </div>
                </div>
              </div>
              <radial-progress-bar
                v-if="introCourse.courseUser"
                :progress="introCourse.courseUser.progress"
              />
            </div>
            <div class="program-blocks-wrapper" :class="{ open: showBlocks }">
              <blocks :items="introCourse.blocks" />
            </div>
          </div>
        </section>
        <section class="program-study">
          <div class="layout">
            <h2>2. {{ secondSectionTitle }}</h2>
            <h5>Одна из профессий - обязательная. Остальные вы можете изучить дополнительно</h5>
            <btn v-if="!areSpecialsAvailable" class="program-study-btn" variant="dark">
              Пока не доступно
            </btn>
            <div v-else>
              <div v-if="!chosenCourse" class="program-profession">
                <ul>
                  <li v-for="course in specialCourses" :key="course.id" @click="goToCourse(course)">
                    <img :src="course.programPageImage" alt="" />
                    <h4>{{ course.title }}</h4>
                  </li>
                </ul>
              </div>
              <div
                v-if="
                  introCourse.courseUser && introCourse.courseUser.completion === 2 && chosenCourse
                "
              >
                <div class="program-profession-current">
                  <div>
                    <h4>{{ chosenCourse.title }}</h4>
                    <!-- <div class="profession-current-info">-->
                    <!--   <span>Кол-во уроков: {{ chosenCourse.lessonsCount }}</span>-->
                    <!--   <span-->
                    <!--     >Домашних заданий: {{ chosenCourse.homeTasksCount }}</span-->
                    <!--   >-->
                    <!--   <span>Тестов: {{ chosenCourse.quizzesCount }}</span>-->
                    <!-- </div>-->
                    <btn variant="yellow" @click="continueChosenCourse">
                      Продолжить обучение<i class="fas fa-play" />
                    </btn>
                  </div>
                  <radial-progress-bar
                    v-if="introCourse.courseUser"
                    :progress="chosenCourse.courseUser.progress"
                    variant="secondary"
                  />
                </div>
                <div class="program-profession-to-change">
                  <div
                    v-for="course in specialCoursesToChange"
                    :key="course.id"
                    @click="goToCourse(course)"
                    class="program-profession-to-change-item"
                    :style="{
                      backgroundImage: `url(${course.programPageImage})`,
                    }"
                  ></div>
                </div>
              </div>
              <course-modal
                v-if="courseModalShow"
                :show="courseModalShow"
                @close="closeCourseModal"
                :course="activeCourse"
                :isIntroCourseCompleted="isIntroCourseCompleted"
                :isCourseChosen="isCourseChosen"
                @loadProgram="loadProgram"
              />
            </div>
          </div>
        </section>
        <section class="program-motivation">
          <div class="layout">
            <div class="program-motivation-content">
              <div>
                <h2>3. Монетизация и мотивация</h2>
                <h5>Пройдите завершающий курс и получите заслуженный сертификат</h5>
                <btn
                  v-if="program.programUserStatuses.specialCompleted"
                  @click="continueLastCourse"
                  class="program-motivation-btn"
                >
                  Продолжить просмотр
                </btn>
                <btn class="program-motivation-btn" v-else variant="dark">Пока не доступно</btn>
              </div>
              <radial-progress-bar />
            </div>
          </div>
        </section>
        <section v-if="user.tariff === $vars.tariffs.business" class="program-business">
          <div class="layout">
            <div class="program-business-content">
              <div>
                <h2>4. Бизнес</h2>
                <h5>Данный блок посвящен управлению и менеджменту в маркетинге</h5>
                <btn
                  v-if="businessCourse.isOpen"
                  @click="goToBusinessCourse"
                  class="program-business-btn"
                >
                  Продолжить просмотр
                </btn>
                <btn class="program-business-btn" v-else variant="dark">Пока не доступно</btn>
              </div>
              <radial-progress-bar :progress="businessCourse.progress" />
            </div>
          </div>
        </section>
        <!-- <section class="program-map">
        <div class="layout">
          <h2>Карта программы ПОТОК PRO 🌊</h2>
        </div>
        <div class="program-map-waves">
          <wave :items="map" />
        </div>
      </section> -->
      </div>
      <div v-else>
        <header>
          <div class="program-preview">
            <img :src="program.image" :alt="program.title" />
          </div>
          <div class="program-general">
            <div class="program-label">Программа</div>
            <h1>{{ program.title }}</h1>
            <div class="program-duration">Продолжительность {{ program.duration }}</div>
            <div class="description">{{ program.description }}</div>
            <btn @click="showTableModal = true" fluid>Расписание курса</btn>
          </div>
        </header>
        <section class="program-additional">
          <div class="layout">
            <div class="block">
              <div class="label">Продолжительность</div>
              <div class="content">{{ program.opensAt }} — {{ program.closesAt }}</div>
            </div>
            <div class="block">
              <div class="label">Формат обучения</div>
              <div class="content">Видеоуроки / Практические задания / Тесты / Вебинары</div>
            </div>
          </div>
        </section>
        <section class="program-intro">
          <div class="layout">
            <div class="program-intro-content">
              <div>
                <div>
                  <h2>1. Исток</h2>
                  <div class="controls">
                    <div class="program-intro-btns" v-if="introCourse.courseUser">
                      <btn
                        variant="green"
                        v-if="introCourse.courseUser.completion === 2"
                        @click="startIntro"
                      >
                        Вы окончили вводный курс
                      </btn>
                      <btn v-else @click="continueIntro">Продолжить курс</btn>
                    </div>
                    <btn v-else @click="startIntro" :loading="introBtnLoad"
                      >Начать вводный курс</btn
                    >
                    <a
                      v-if="introCourse.courseUser && introCourse.courseUser.completion !== 2"
                      href="#"
                      @click.prevent="toggleBlocks"
                    >
                      <span>Программа курса</span>
                      <i :class="`far fa-chevron-${showBlocks ? 'up' : 'down'}`"></i>
                    </a>
                  </div>
                </div>
              </div>
              <radial-progress-bar
                v-if="introCourse.courseUser"
                :progress="introCourse.courseUser.progress"
              />
            </div>
            <div class="program-blocks-wrapper" :class="{ open: showBlocks }">
              <blocks :items="introCourse.blocks" />
            </div>
          </div>
        </section>
      </div>
    </div>
    <modal :show="isNotComeDate" @close="closeDateModal">
      <div class="program-not-come-date">
        <p>
          <b>Потерпи</b> еще чуть-чуть!<br />
          Уроки откроются <b>1 августа!</b>
        </p>
        <btn fluid @click="closeDateModal">Понятно</btn>
      </div>
    </modal>
    <modal :show="showTableModal" @close="closeTableModal">
      <div class="program-timetable">
        <div>
          <img :src="program.schedule" alt="Расписание курса" />
        </div>
        <btn class="program-timetable-btn" @click="closeTableModal" fluid>Будь с нами 🏄‍♂️</btn>
      </div>
    </modal>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';

import Stepper from '@/components/Program/Stepper';
import Blocks from '@/components/Program/Blocks';
import Btn from '@/components/Button';
// import Wave from '@/components/Wave';
import RadialProgressBar from '@/components/RadialProgressBar';
import CourseModal from '@/components/Course/modals/Course';
import LoaderOverlay from '@/components/LoaderOverlay';
import Modal from '@/components/Modal';

export default {
  name: 'Program',
  components: {
    LoaderOverlay,
    Stepper,
    Btn,
    Blocks,
    // Wave,
    RadialProgressBar,
    CourseModal,
    Modal,
  },
  data() {
    return {
      // timeTableUrlDesktop: 'https://fs.theflow.online/files/public/RK/H3/5206e0b646de4a1292097781acacec8c.png',
      timeTableUrlDesktop:
        'https://fs.theflow.online/files/public/FU/5Y/59b797e7fde04731b68707feb00608e8.png',
      // timeTableUrlMobile: 'https://fs.theflow.online/files/public/IZ/GU/0e737ebe4d8a454192364bd713943e9d.jpg',
      loading: true,
      showBlocks: false,
      specialCourses: [],
      program: {},
      courseId: null,
      introBtnLoad: false,
      activeCourse: {},
      lastLesson: {},
      professionCurrent: {},
      courseModalShow: false,
      showTableModal: false,
      introCourse: {},
      businessCourse: {},
      defaultCourse: true,
      map: [
        {
          id: 0, // добавил пушта выходит за рамки
          name: '',
          tooltip: '',
        },
        {
          id: 1,
          name: 'Инструктаж',
          tooltip: 'Поймешь как эффективно проходить курс без ошибок',
        },
        {
          id: 2,
          name: 'Учиться как учиться',
          tooltip: 'Научишься выжимать максимум из любой информации ',
        },
        {
          id: 3,
          name: 'Мышление',
          tooltip: 'Ты научишься мышлению маркетолога и проработаешь свои страхи и блоки',
        },
        {
          id: 4,
          name: 'База маркетинга',
          tooltip: 'Изучишь основы маркетинга',
        },
        {
          id: 5,
          name: 'Профориентация',
          tooltip: 'Найдешь себя и свое место в маркетинге и поймешь какая профессия тебе подходит',
        },
        {
          id: 6,
          name: 'Профессиональные знания',
          tooltip: 'Научишься всеми навыками своей профессии',
        },
        {
          id: 7,
          name: 'Дополнительные знания',
          tooltip: 'Узнаешь специфику профессии и познакомишься с деталями',
        },
        {
          id: 8,
          name: 'Монетизация',
          tooltip: 'Поймешь как дорого продавать себя и найдешь первых клиентов',
        },
        {
          id: 9,
          name: 'Мотивация',
          tooltip: 'Узнаешь все о выгорании и обучишься кризис-менеджменту',
        },
      ],
      isNotComeDate: false,
    };
  },
  mounted() {
    this.loadProgram();
  },
  computed: {
    areSpecialsAvailable() {
      return this.specialCourses.find((v) => v.isOpen);
    },
    ...mapState(['user']),
    chosenCourse() {
      return this.specialCourses.find((v) => v.courseUser?.isChosen);
    },
    isCourseChosen() {
      return !!this.chosenCourse;
    },
    specialCoursesToChange() {
      return this.specialCourses.filter((v) => v.id !== this.chosenCourse.id);
    },
    secondSectionTitle() {
      return this.chosenCourse ? 'Закончите основное направление' : 'Время выбрать направление';
    },
    spotSizeTimeTableImg() {
      return this.timeTableUrlDesktop;
      // return this.$store.state.isMobile ? this.timeTableUrlMobile : this.timeTableUrlDesktop;
    },
    isIntroCourseCompleted() {
      return !!this.introCourse.courseUser && this.introCourse.courseUser.completion === 2;
    },
  },
  methods: {
    loadProgram() {
      this.loading = true;
      // this.$api.program.get({ id: +this.$route.params.id })
      this.$api.program.get().then((r) => {
        const data = { ...r.data };
        data.opensAt = this.$date(data.opensAt).locale('ru-RU').format('DD MMMM YYYY');
        data.closesAt = this.$date(data.closesAt).locale('ru-RU').format('DD MMMM YYYY');
        this.specialCourses = data.courses.filter((v) => v.isSpecial);
        this.introCourse = data.courses.find((x) => x.isIntro);
        this.businessCourse = data.courses.find((x) => x.isBusiness);
        this.lastLesson = data.courses.find((x) => x.isLast);
        this.program = data;
        if (data.id === 6) {
          this.setSpecialCourse(true);
          this.defaultCourse = false;
        }
        this.loading = false;
      });
    },
    toggleBlocks() {
      this.showBlocks = !this.showBlocks;
    },
    goToCourse(course) {
      this.activeCourse = course;
      this.courseModalShow = true;
    },
    closeDateModal() {
      this.isNotComeDate = false;
    },
    closeTableModal() {
      this.showTableModal = false;
    },
    closeCourseModal() {
      this.activeCourse = {};
      this.courseModalShow = false;
    },
    startIntro() {
      if (!this.introCourse.isOpen) {
        this.isNotComeDate = true;
        return;
      }
      this.introBtnLoad = true;
      this.$api.course.start({ id: this.introCourse.id }).then(() => {
        this.$router.push({ name: 'course', params: { id: this.introCourse.id } });
        this.introBtnLoad = false;
      });
    },
    goToBusinessCourse() {
      this.$router.push({ name: 'course', params: { id: this.businessCourse.id } });
    },
    continueIntro() {
      this.$router.push({ name: 'course', params: { id: this.introCourse.id } });
    },
    continueChosenCourse() {
      this.$router.push({ name: 'course', params: { id: this.chosenCourse.id } });
    },
    continueLastCourse() {
      this.$router.push({ name: 'course', params: { id: this.lastLesson.id } });
    },
    ...mapMutations(['setSpecialCourse']),
  },
};
</script>

<style lang="scss" scoped>
.program {
  h1 {
    font-weight: 500;
    font-size: 24px;
  }
  header {
    display: flex;
    align-items: center;
  }
  section {
    h2 {
      font-weight: 500;
      font-size: 24px;
      @media screen and (max-width: $layout-s) {
        font-size: 20px;
      }
    }
    h5 {
      margin-top: 8px;
      font-weight: normal;
      font-size: 14px;
    }
  }
  &-label {
    text-transform: uppercase;
    font-size: 12px;
    color: $forms-text;
    margin-bottom: 10px;
  }
  &-preview {
    display: flex;
    align-items: center;
    width: 50%;
    max-height: 300px;
    overflow: hidden;
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
  &-general {
    margin-left: 70px;
    .description {
      margin-top: 30px;
      margin-left: 25px;
      line-height: 27px;
      white-space: pre;
    }
  }
  &-duration {
    margin-top: 15px;
    font-size: 14px;
    color: $primary;
  }
  &-additional {
    border-bottom: 1px solid map-get($gray, 200);
    .layout {
      display: flex;
    }
    .block {
      padding: 28px 0;
      margin-right: 70px;
      .label {
        text-transform: uppercase;
        color: $forms-text;
        font-size: 12px;
      }
      .content {
        font-size: 14px;
        font-weight: 500;
        margin-top: 10px;
      }
    }
  }
  &-cert {
    padding: 44px 0 160px;
    .stepper {
      margin-top: 72px;
      /*overflow-x: auto;*/
      /*overflow-y: hidden;*/
    }
  }
  &-intro {
    background-color: map-get($gray, 200);
    padding: 35px 0 40px;
    &-content {
      display: flex;
      justify-content: space-between;
    }
    .controls {
      display: flex;
      align-items: center;
      margin-top: 36px;
      button.btn {
        padding: 0 60px;
      }
      a {
        display: flex;
        align-items: center;
        font-size: 14px;
        color: $primary;
        margin-left: 36px;
        text-decoration: none;
        span {
          margin-right: 7px;
        }
      }
    }
  }
  &-blocks-wrapper {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-in-out;
    &.open {
      max-height: 500px;
    }
  }
  &-path {
    height: 1000px;
  }
  &-study {
    padding: 60px 0;
    border-bottom: 1px solid map-get($gray, 400);
    .btn {
      width: 270px;
      margin-top: 36px;
    }
  }
  &-profession {
    margin-top: 50px;
    &-current {
      display: flex;
      justify-content: space-between;
      background-color: $primary;
      border-radius: 6px;
      padding: 25px 30px;
      color: $white;
      margin-top: 48px;
      h4 {
        font-size: 24px;
        font-weight: 500;
      }
      .profession-current-info {
        font-size: 14px;
        margin-top: 20px;
        span {
          margin-right: 48px;
        }
      }
      .btn {
        margin-top: 25px;
        i {
          margin-left: 10px;
        }
      }
    }
    &-to-change {
      display: flex;
      margin-top: 48px;
      &-item {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-width: 212px;
        max-width: 212px;
        height: 189px;
        padding: 25px 30px;
        box-sizing: border-box;
        background: $lilac center no-repeat;
        background-size: contain;
        border-radius: 6px;
        margin-right: 32px;
        color: $white;
        h4 {
          font-weight: 500;
          font-size: 24px;
        }
        p {
          font-size: 14px;
        }
      }
    }
    ul {
      display: flex;
      justify-content: space-between;
      list-style: none;
      overflow: auto;
      li {
        position: relative;
        margin-right: 10px;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-width: 268px;
        height: 189px;
        padding: 25px 30px;
        box-sizing: border-box;
        background-color: #f63888;
        color: $white;
        border-radius: 12px;
        h4 {
          font-weight: 500;
          font-size: 24px;
          position: absolute;
          z-index: 2;
        }
        p {
          font-size: 14px;
          position: absolute;
          z-index: 2;
        }
        img {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 1;
        }
      }
    }
  }
  &-motivation {
    padding: 50px 0;
    border-bottom: 1px solid map-get($gray, 400);
    &-content {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
    .layout {
      display: flex;
      justify-content: space-between;
    }
    .btn {
      width: 270px;
      margin-top: 36px;
    }
  }
  &-business {
    padding: 50px 0;
    border-bottom: 1px solid map-get($gray, 400);
    &-content {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
    .layout {
      display: flex;
      justify-content: space-between;
    }
    .btn {
      width: 270px;
      margin-top: 36px;
    }
  }
  &-map {
    padding: 65px 0 0;
    h2 {
      text-align: center;
    }
    &-waves {
      margin-top: 100px;
      overflow: hidden;
    }
  }
  &-not-come-date {
    & p {
      font-size: 18px;
      line-height: 148.5%;
      margin-bottom: 20px;
    }
  }
  &-timetable {
    text-align: center;
    &-btn {
      width: 300px !important;
    }
    img {
      max-width: 100vw;
      max-height: 100vh;
    }
  }
}
@media screen and (max-width: $layout-s) {
  .program {
    & header {
      flex-direction: column;
      align-items: flex-start;
    }
    &-preview {
      width: 100%;
      margin-bottom: 20px;
    }
    &-general {
      margin-left: 20px;
      & h1 {
        font-size: 20px;
      }
    }
    &-additional {
      & .block {
        margin-right: 30px;
      }
    }
    &-cert {
      overflow: auto;
      padding-bottom: 60px;
      &-title {
        font-size: 20px;
      }
      & .stepper {
        overflow: auto;
        min-height: 140px;
        margin-top: 45px;
      }
    }
    &-intro {
      &-content {
        align-items: center;
        flex-direction: column;
        & .controls {
          margin-bottom: 20px;
        }
      }
      &-btns {
        width: 100%;
        margin-bottom: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
    &-study {
      & section h2 {
        font-size: 20px;
      }
      & .program-profession-to-change {
        overflow: auto;
      }
      &-btn {
        margin: 30px auto;
      }
    }
    &-profession-current {
      padding: 25px 15px;
      flex-direction: column;
      align-items: center;
      & .profession-current-info {
        flex-direction: column;
        span {
          display: block;
        }
      }
    }
    &-motivation {
      &-content {
        flex-direction: column;
        align-items: center;
        div {
          display: flex;
          flex-direction: column;
        }
      }
      &-btn {
        margin: 30px auto;
      }
    }
    &-business {
      &-content {
        flex-direction: column;
        align-items: center;
        div {
          display: flex;
          flex-direction: column;
        }
      }
      &-btn {
        margin: 30px auto;
      }
    }
    .controls {
      display: flex;
      flex-direction: column;
    }
  }
}
</style>
