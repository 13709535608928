<template>
  <div class="accordion">
    <div class="accordion-header" :class="{ active: show }" @click="toggle">
      <span class="accordion-header-text">
        Модуль {{ index + 1 }}: {{ block.title }}</span
      >
      <slot name="accordion-header-meta">
        <slot name="accordion-toggle-text">
          <span
            v-if="show"
            class="accordion-toggle-text"
            :class="{ active: show }"
          >
            Скрыть программу
          </span>
          <span v-else class="accordion-toggle-text" :class="{ active: show }">
            Показать программу
          </span>
        </slot>
        <i
          v-if="collapsable"
          class="arrow fas fa-chevron-circle-up"
          :class="{ rotate: show }"
        />
      </slot>
    </div>
    <!--    <transition-->
    <!--      name="accordion"-->
    <!--      v-on:before-enter="beforeEnter" v-on:enter="enter"-->
    <!--      v-on:before-leave="beforeLeave" v-on:leave="leave">-->
    <!--      >-->
    <div v-if="collapsable">
      <div class="accordion-body" :class="{ open: show }" v-if="show">
        <ul class="accordion-content-list">
          <li
            class="accordion-content-item"
            v-for="(lesson, lessonIndex) in block.lessons"
            :key="lesson.id"
          >
            <b>Урок {{ lessonStartIndex + lessonIndex + 1 }}: </b>{{ lesson.title }}
          </li>
        </ul>
      </div>
    </div>
    <!--    </transition>-->
  </div>
</template>

<script>
export default {
  name: 'Accordion',
  props: {
    block: { type: Object, default: () => ({}) },
    index: { type: Number, default: 0 },
    lessonStartIndex: { type: Number, default: 0 },
    collapsable: { type: Boolean, default: () => true },
  },
  data() {
    return {
      show: false,
    };
  },
  methods: {
    toggle() {
      this.show = !this.show;
    },
  },
  // methods: {
  //   toggle() {
  //     this.show = !this.show;
  //   },
  //   beforeEnter(e) {
  //     e.style.height = '0';
  //   },
  //   enter(e) {
  //     e.style.height = `${e.scrollHeight}px`;
  //   },
  //   beforeLeave(e) {
  //     e.style.height = `${e.scrollHeight}px`;
  //   },
  //   leave(e) {
  //     e.style.height = '0';
  //   },
  // },
};
</script>

<style lang="scss" scoped>
.accordion {
  display: flex;
  flex-direction: column;
  &:last-child {
    margin-bottom: 30px;
  }
  &-header {
    margin-bottom: 10px;
    padding: 15px 25px;
    background-color: map_get($gray, 200);
    display: flex;
    align-items: center;
    border-radius: 2px;
    font-weight: 500;
    font-size: 16px;
    cursor: pointer;
    &-text {
      flex-grow: 1;
    }
    & i.rotate {
      transform: rotate(180deg);
      transition-duration: 0.3s;
    }
  }
  &-header.active {
    background-color: $primary;
    color: $white;
    & .accordion-toggle-text {
      color: $white;
    }
  }
  &-toggle-text {
    margin: 0 15px 0 60px;
    min-width: 140px;
    font-size: 14px;
    font-weight: normal;
    text-align: end;
    color: $primary;
  }
  &-body {
    padding: 25px 50px 0;
    margin-bottom: 24px;
    /*max-height: 400px;*/
    overflow: hidden;
    transition: all 0.2s;
    border: 1px solid map-get($gray, 300);
    border-radius: 2px;
  }
  &-content {
    &-list {
      display: flex;
      flex-direction: column;
    }
    &-item {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 10px;
    }
  }
}
@media screen and (max-width: $layout-s) {
  .accordion {
    width: 90%;
    margin: 0 auto;
    &-toggle-text {
      display: none;
    }
    &-header-text {
      font-size: 14px;
    }
    & .arrow {
      margin-left: 15px;
    }
    &-body {
      padding: 20px;
    }
    &-content {
      &-item {
        display: flex;
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 15px;
        & b {
          display: block;
          margin-right: 10px;
          white-space: nowrap;
        }
      }
    }
  }
}
</style>
