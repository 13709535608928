<template>
  <div>
    <div class="row f-col">
      <span class="review-note">Работа над ошибками</span>
      <h4 class="review-answer-count">Ваш балл {{ totalPoints }}</h4>
      <span v-if="!isPassed" class="wrong-answer"
        >Вы не прошли тестирование</span
      >
    </div>
    <div class="row">
      <div class="review-card-container">
        <div
          class="review-card"
          v-for="(review, index) in reviews"
          :key="index"
        >
          <div class="review-card-header">
            <span class="review-card-number">Вопрос {{ index + 1 }}</span>
            <h4 class="review-card-title">{{ review.questText }}</h4>
          </div>
          <div class="review-card-body">
            <div class="user-answer">
              <span
                :class="review.status === 1 ? 'correct-answer' : 'wrong-answer'"
                >Вы ответили:
              </span>
              <p v-if="review.status === 1">{{ review.text }}</p>
              <p v-else>{{ review.text }}</p>
            </div>
            <!-- <div class="true-answer" v-if="review.status === 0">
              <span class="correct-answer">Правильный ответ: </span>
              {{ review.text }}
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'QuizReview',
  data() {
    return {
      reviews: [],
    };
  },
  props: ['quizReview', 'isPassed'],
  created() {
    this.quizReview.forEach((element) => {
      const result = {};
      result.questText = element.question.text;
      if (element.answer.isCorrect) {
        result.status = 1;
        result.text = element.answer.text;
      } else {
        result.text = element.answer.text;
        result.status = 0;
      }
      this.reviews.push(result);
    });
  },
  computed: {
    totalPoints() {
      const res = this.reviews.filter((el) => el.status === 1);
      return res.length;
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.row {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 15px auto;
}

.f-col {
  flex-direction: column;
}

.review-note {
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  color: rgba(49, 49, 49, 0.7);
}

.review-answer-count {
  text-align: center;
  /* Color/Black */
  color: #313131;
}

.review-card-container {
  padding: 30px;
  background: map-get($gray, 1000);
  border-radius: 10px;
  min-width: 90%;
}

.review-card {
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);

  &-number {
    display: block;
    margin: 15px 0;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.38);
  }

  &-header {
    padding-bottom: 15px;
    margin-bottom: 15px;
  }

  p {
    margin-left: 5px;

    @media screen and (max-width: $layout-s) {
      margin-left: 0;
    }
  }
}

.user-answer {
  margin: 10px 0;
  padding-right: 15px;
  display: flex;

  @media screen and (max-width: $layout-s) {
    flex-wrap: wrap;
    flex-direction: column;
  }
}

.wrong-answer {
  color: #ef5353;
}

.correct-answer {
  color: #80c575;
}
</style>
