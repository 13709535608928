import { request } from './utils';

export default {
  /**
   * Отправить письмо студентам
   *
   * @param {Object}    params                   Параметры
   * @param {Object}    params.data              Данные
   * @param {String}    params.data.template     Имя шаблона
   * @param {String}    params.data.email        Почта получателя
   * @param {String}    params.data.userRole     Роль получателя
   *
   * @return {Promise}                       Возвращает промис с ответом из сервера.
   */
  accessDistribution(params = {}) {
    const data = new FormData();
    data.append('emailsFile', params.emailsFile);
    data.append('programId', params.programId);
    data.append('userRole', params.userRole);
    data.append('template', params.template);
    data.append('responseEmail', params.responseEmail);
    return request('post', '/users/access_distribution/', { data }, { isFile: true });
  },
};
