import SetEmail from '@/components/Profile/SetEmail';
import PasswordChange from '@/components/Profile/PasswordChange';
import UserInfo from '@/components/Profile/UserInfo';
import EmailVerification from '@/components/Profile/EmailVerification';
import Specialization from '@/components/Profile/Specialization';
import ExperienceList from '@/components/Profile/ExperienceList';
import EducationList from '@/components/Profile/EducationList';
import EducationNew from '@/components/Profile/EducationNew';
import ExperienceNew from '@/components/Profile/ExperienceNew';
import PortfolioNew from '@/components/Profile/PortfolioNew';
import PortfolioEdit from '@/components/Profile/PortfolioEdit';
import PortfolioList from '@/components/Profile/PortfolioList';
import EducationEdit from '@/components/Profile/EducationEdit';
import ExperienceEdit from '@/components/Profile/ExperienceEdit';
import File from '@/components/Profile/File';
import Certificate from '@/components/Profile/Certificate';
import Settings from './index';

export default [
  {
    path: '/activate/:uid/:token',
    name: 'activate-account',
    component: EmailVerification,
  },
  {
    path: '/settings',
    name: 'settings',
    component: Settings,
    redirect: '/settings/profile',
    children: [
      {
        path: 'profile',
        component: UserInfo,
        name: 'profile',
      },
      {
        path: 'set-password',
        component: PasswordChange,
        name: 'set-password',
      },
      {
        path: 'set-email',
        component: SetEmail,
        name: 'set-email',
      },
      {
        path: 'specialization',
        component: Specialization,
        name: 'specialization',
      },
      {
        path: 'experiences/new',
        component: ExperienceNew,
        name: 'experience-new',
      },
      {
        path: 'experiences/:id/edit',
        component: ExperienceEdit,
        name: 'experience-edit',
      },
      {
        path: 'experiences',
        component: ExperienceList,
        name: 'experience-list',
      },
      {
        path: 'educations',
        component: EducationList,
        name: 'education-list',
      },
      {
        path: 'educations/new',
        component: EducationNew,
        name: 'education-new',
      },
      {
        path: 'educations/:id/edit',
        component: EducationEdit,
        name: 'education-edit',
      },
      {
        path: 'portfolios',
        component: PortfolioList,
        name: 'portfolio-list',
      },
      {
        path: 'portfolios/new',
        component: PortfolioNew,
        name: 'portfolio-new',
      },
      {
        path: 'portfolios/:id/edit',
        component: PortfolioEdit,
        name: 'portfolio-edit',
      },
      {
        path: 'file',
        component: File,
        name: 'resume-file',
      },
      {
        path: 'certificate',
        component: Certificate,
        name: 'certificates',
      },
    ],
  },
];
