<template>
  <div>
    <h3>Добавление образования</h3>
    <hr />
    <EducationForm @on-submit="createEducation" />
  </div>
</template>

<script>
import EducationForm from './EducationForm';

export default {
  name: 'EducationNew',
  components: {
    EducationForm,
  },
  data() {
    return {};
  },
  methods: {
    createEducation(educationForm) {
      const data = {
        educationName: educationForm.educationName,
        specialtyName: educationForm.specialtyName,
        dateFrom: null,
        dateTill: educationForm.dateTillYear
          ? `${educationForm.dateTillYear}-01-01`
          : null,
      };
      this.$api.resume.addEducation({ data }).then(() => {
        this.$router.push({ name: 'education-list' });
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
