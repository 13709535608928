<template>
  <div class="notifications">
    <div class="layout">
      <h1>Уведомления</h1>
      <router-link
        v-for="n in notifications"
        :key="n.id"
        class="notification"
        tag="div"
        :to="n.route"
      >
        <b>{{ n.notificationType.title }} <span>({{ n.notificationType.label }})</span></b>
        <p>{{ n.sentence }}</p>
        <span v-if="n.route" class="notification-btn">Перейти</span>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Notifications',
  data() {
    return {
      notifications: [],
    };
  },
  mounted() {
    this.loadNotificaitons();
  },
  methods: {
    loadNotificaitons() {
      this.$api.notification.get().then((r) => {
        this.notifications = r.data.results;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.notifications {
  margin-bottom: 50px;

  h1 {
    margin: 20px 0;
  }

  .notification {
    padding: 10px 20px;
    background: map-get($gray, 200);
    border-radius: 10px;
    margin-top: 20px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color .1s ease-in-out;
    &:hover p {
      color: $primary;
      text-decoration: underline;
    }

    &-btn {
      display: none;
      margin-top: 10px;
      padding: 5px 10px 5px 0;
      text-decoration: underline;
      color: $primary!important;
      @media screen and (max-width: $layout-s) {
        display: block;
      }
    }

    p {
      margin-top: 10px;
      word-break: break-word;
      transition: all .1s ease-in-out;
      @media screen and (max-width: $layout-s) {
        //color: $primary;
        //text-decoration: underline;
      }
    }

    b {
      font-size: 12px;
      color: $primary !important;
    }

    span {
      color: map-get($gray, 700);
    }
  }
}
</style>
