<template>
  <div>
    <loader v-if="showLoading" />
    <EducationForm
      v-if="education"
      :education="education"
      @on-submit="updateEducation"
    />
  </div>
</template>

<script>
import Loader from '@/components/Loader';
import EducationForm from './EducationForm';

export default {
  name: 'EducationEdit',
  components: {
    EducationForm,
    Loader,
  },
  data() {
    return {
      showLoading: true,
      education: null,
    };
  },
  created() {
    this.getEducation();
  },
  methods: {
    getEducation() {
      this.$api.resume
        .getEducation({ id: this.$route.params.id })
        .then(({ data }) => {
          this.education = data;
        })
        .finally(() => {
          this.showLoading = false;
        });
    },
    updateEducation(educationForm) {
      const data = {
        educationName: educationForm.educationName,
        specialtyName: educationForm.specialtyName,
        dateFrom: null,
        dateTill:
          educationForm.dateTillYear !== 'null'
            ? `${educationForm.dateTillYear}-01-01`
            : null,
      };
      this.$api.resume
        .updateEducation({ id: this.$route.params.id, data })
        .finally(() => {
          this.$router.push({ name: 'education-list' });
        });
    },
  },
};
</script>
