import { request } from './utils';

export default {
  /**
   * Получить программу или список программ
   *
   * @param {Object}         params          Параметры
   * @param {String|Number}  params.id       Идентификатор программы
   *
   * @return {Promise}                       Возвращает промис с ответом из сервера.
   */
  get(params = {}) {
    const id = params.id ? `${params.id}/` : 'main_program/';
    return request('get', `/programs/${id}`);
  },
};
