import { request } from './utils';

export default {
  get(params) {
    return request('get', `/curriculum/quiz/${params}`);
  },
  submit(id, params = {}) {
    return request('post', `/curriculum/activity/quiz/${id}/submit`, params);
  },
};
