import { request } from './utils';

export default {
  get() {
    return request('get', '/notifications/');
  },
  /**
   * Отметить уведомление как прочитанное
   *
   * @param {Object}         params            Параметры
   * @param {Number}         params.id         ID уведомления
   *
   * @return {Promise}                         Возвращает промис с ответом из сервера.
   */
  markIsRead(id) {
    return request('post', `/notifications/${id}/read/`);
  },
  getUnread() {
    return request('get', '/notifications/unread_list/?mark_as_read=true');
  },
  unreadCount() {
    return request('get', '/notifications/unread_count/');
  },
  search(params = {}) {
    return request('get', '/search/', params);
  },
  noteTypes() {
    return request('get', '/note_types/');
  },
};
