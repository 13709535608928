import $api from '@/api';

export default {
  state: {
    units: [],
    lastLesson: null,
    currentLessonIndex: 1,
  },
  getters: {
    getUnits(state) {
      return state.units;
    },
  },
  actions: {
    // changeLesson({ commit }, isNext) {
    //   let newIndex = state.currentLessonIndex
    //   if(isNext) {
    //     newIndex++
    //     if (newIndex > state.units)
    //   }
    // },
    async fetchCourseUnits({ commit }, courseId) {
      function sortByPosition(a, b) {
        const posA = a.position;
        const posB = b.position;

        if (posA > posB) {
          return 1;
        }

        if (posA < posB) {
          return -1;
        }

        return 0;
      }

      const res = await $api.units.getUnitsByCourseId({ id: courseId });
      res.data.forEach((el) => {
        $api.lesson
          .getLessonsByUnit({ id: el.id })
          .then((r) => {
            el.lessons = r.data;
            el.lessons.sort(sortByPosition);
          })
          .catch((er) => {
            console.log(er);
          });
      });
      res.data.sort(sortByPosition);
      commit('SET_UNITS', res.data);
    },
  },
  mutations: {
    SET_CURRENT_LESSON_INDEX(state, data) {
      state.currentLessonIndex = data;
    },
    SET_UNITS(state, data) {
      state.units = data;
    },
  },
};
