<template>
  <form @submit.prevent="$emit('on-submit', form)">
    <div class="row">
      <label for="companyName">Название компании</label>
      <input
        placeholder="Название компании"
        name="companyName"
        v-model="form.companyName"
        required
      />
    </div>
    <div class="row">
      <label>Сфера деятельности</label>
      <select v-model="form.companyField">
        <option :value="null" disabled>
          Выберите сферу деятельности компании
        </option>
        <option v-for="s in scopes" :value="s.id" :key="s.id">
          {{ s.scope }}
        </option>
      </select>
    </div>
    <div class="row">
      <label>Начало работы</label>
      <div class="col-80">
        <select class="col-20" v-model="form.dateFromMonth">
          <option v-for="month in months" :value="month.id" :key="month.id">
            {{ month.name }}
          </option>
        </select>
        <select class="col-20" v-model="form.dateFromYear">
          <option v-for="year in years" :key="year" :value="year">
            {{ year }}
          </option>
        </select>
      </div>
    </div>
    <div class="row">
      <label>Окончание работы</label>
      <div class="col-80">
        <select class="col-20" v-model="form.dateTillMonth">
          <option :value="null">По настоящее время</option>
          <option v-for="month in months" :value="month.id" :key="month.id">
            {{ month.name }}
          </option>
        </select>
        <select
          v-if="form.dateTillMonth"
          class="col-20"
          v-model="form.dateTillYear"
        >
          <option v-for="year in years" :key="year" :value="year">
            {{ year }}
          </option>
        </select>
      </div>
    </div>
    <div class="row">
      <label>Должность</label>
      <input v-model="form.positionName" placeholder="Напишите должность" />
    </div>
    <div class="row">
      <label>Обязанности</label>
      <textarea
        placeholder="Расскажите, какие обязанности вы выполняли в компании"
        v-model="form.obligations"
        required
      />
    </div>
    <div class="row">
      <label>Достижения</label>
      <textarea
        placeholder="Расскажите, каких результатов и успехов смогли добиться"
        v-model="form.achievements"
      />
    </div>
    <div class="server-error" v-if="errors.length">
      <ul v-for="error in errors" :key="error">
        <li>{{ error }}</li>
      </ul>
    </div>
    <div class="row flex-end">
      <form-button variant="primary" :loading="loading" :disabled="loading">
        Сохранить
      </form-button>
    </div>
  </form>
</template>

<script>
import FormButton from '../Button';

export default {
  name: 'experienceForm',
  components: { FormButton },
  props: {
    experience: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      form: {
        companyName: this.experience?.companyName || '',
        positionName: this.experience?.positionName || '',
        companyField: this.experience?.companyField || '',
        dateFromMonth: this.validatedFromMonth(),
        dateFromYear: this.validatedFromYear(),
        dateTillMonth: this.validatedTillMonth(),
        dateTillYear: this.validatedTillYear(),
        obligations: this.experience?.obligations || '',
        achievements: this.experience?.achievements || '',
      },
      errors: [],
      scopes: [],
      months: this.$vars.profile.months,
    };
  },
  computed: {
    years() {
      const years = [];
      const start = 2021;
      const end = 1917;
      for (let i = start; i >= end; i -= 1) {
        years.push(i);
      }
      return years;
    },
  },
  created() {
    this.$api.profession.getScopes().then(({ data }) => {
      this.scopes = data;
    });
  },
  methods: {
    validatedFromMonth() {
      if (this.experience) {
        return new Date(this.experience?.dateFrom).getMonth() + 1;
      }
      return 1;
    },
    validatedFromYear() {
      if (this.experience) {
        return new Date(this.experience?.dateFrom).getFullYear();
      }
      return 2021;
    },
    validatedTillMonth() {
      if (this.experience && this.experience?.dateTill) {
        return new Date(this.experience?.dateTill).getMonth() + 1;
      }
      return null;
    },
    validatedTillYear() {
      if (this.experience && this.experience?.dateTill) {
        return new Date(this.experience?.dateTill).getFullYear();
      }
      return 2021;
    },
  },
};
</script>

<style lang="scss" scoped>
input,
textarea,
select {
  -ms-box-sizing: content-box;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  outline: none;
  margin-top: 5px;
  display: inline-block;
  background: map-get($gray, 1000);
  border: none;
  border-radius: 10px;
  width: 100%;
  padding: 15px;
  input {
    resize: none;
  }
  &:focus {
    background-color: white;
    outline: none;
  }
}

textarea {
  resize: vertical;
}

select,
input[type='date'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%);
  background-position: calc(100% - 23px) calc(1em + 5px),
    calc(100% - 18px) calc(1em + 5px), calc(100% - 2.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;
  &:focus {
    background-image: linear-gradient(45deg, $primary 50%, transparent 50%),
      linear-gradient(135deg, transparent 50%, $primary 50%);
    background-position: calc(100% - 18px) 18px, calc(100% - 23px) 18px,
      calc(100% - 2.5em) 8px;
    background-size: 5px 5px, 5px 5px, 1px 1.5em;
    background-repeat: no-repeat;
    border-color: $primary;
    outline: 0;
  }
}

.d-flex {
  display: flex;
  flex-wrap: wrap;
}
.d-flex > div {
  padding: 0.1rem;
  color: grey;
  margin-right: 0.5rem;
  margin-top: 0.5rem;
  background-color: rgba(86, 61, 124, 0.15);
  border: 1px solid rgba(86, 61, 124, 0.15);
}
.vacancy-description {
  font-size: 17px;
}
.error,
.email-dirty {
  color: $danger;
}

.email-verified {
  color: $success;
}

.server-error {
  color: $danger;
  margin-bottom: 10px;
}

.server-error ul {
  list-style-type: none;
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 15px 0;
}

.row input,
.row select,
.row textarea {
  width: 80%;
}

.row label {
  width: 15%;
  text-align: right;
  clear: both;
  float: left;
  margin-right: 15px;
  min-width: 150px;
}

/* Clear floats after the columns */
.row:after {
  content: '';
  display: table;
  clear: both;
}

.col-20 {
  float: left;
  max-width: 20%;
  min-width: 150px;
  margin-right: 1rem;
}

.col-50 {
  float: left;
  width: 48%;
  margin-right: 1rem;
}

.col-80 {
  float: left;
  width: 80%;
  margin-right: 2rem;
}

button {
  margin-top: 20px;
  float: right;
  padding: 12px 20px;
}

label {
  padding: 12px 12px 12px 0;
  display: inline-block;
  color: rgba(0, 0, 0, 0.15);
}

.flex-end {
  justify-content: flex-end;
}

form {
  margin-left: 25px;
}

/* Responsive layout - when the screen is less than 600px wide,
make the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: $layout-s) {
  .col-50 {
    width: 100%;
  }
  button {
    width: 100%;
    margin-top: 20px;
  }
}

@media (max-width: 986px) {
  .row input,
  .row select,
  .row textarea {
    width: 80%;
    margin-right: 15px;
  }

  .row label {
    width: 40%;
  }
}

@media (max-width: 720px) {
  form {
    margin-left: 0px;
  }
  .row label {
    width: 25%;
  }
}

@media (max-width: 620px) {
  .row {
    flex-wrap: wrap;
  }
  .row input,
  .row select,
  .row textarea {
    margin-right: 0;
    width: 100%;
  }
  .row label {
    width: 100%;
    text-align: left;
    min-width: none;
  }

  .col-20,
  .col-80 {
    width: 100%;
    max-width: none;
  }
}
</style>
