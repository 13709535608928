<template>
  <div>
    <loader v-if="showLoading" />
    <ExperienceForm
      v-if="experience"
      :experience="experience"
      @on-submit="updateExperience"
    />
  </div>
</template>

<script>
import Loader from '@/components/Loader';
import ExperienceForm from './ExperienceForm';

export default {
  name: 'ExperienceEdit',
  components: {
    ExperienceForm,
    Loader,
  },
  data() {
    return {
      showLoading: true,
      experience: null,
    };
  },
  created() {
    this.getExperience();
  },
  methods: {
    getExperience() {
      this.$api.resume
        .getExperience({ id: this.$route.params.id })
        .then(({ data }) => {
          this.experience = data;
        })
        .finally(() => {
          this.showLoading = false;
        });
    },
    updateExperience(experienceForm) {
      const data = {
        companyName: experienceForm.companyName,
        positionName: experienceForm.positionName,
        companyField: experienceForm.companyField,
        dateFrom: `${experienceForm.dateFromYear}-${experienceForm.dateFromMonth}-01`,
        dateTill:
          experienceForm.dateTillMonth &&
          experienceForm.dateTillMonth !== 'null'
            ? `${experienceForm.dateTillYear}-${experienceForm.dateTillMonth}-01`
            : null,
        obligations: experienceForm.obligations,
        achievements: experienceForm.achievements,
      };
      this.$api.resume
        .updateExperience({ id: this.$route.params.id, data })
        .finally(() => {
          this.$router.push({ name: 'experience-list' });
        });
    },
  },
};
</script>
