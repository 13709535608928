<template>
  <form @submit.prevent="$emit('on-submit', form)">
    <div class="row">
      <div class="col-25">
        <label for="title">Заголовок</label>
      </div>
      <div class="col-75">
        <input placeholder="Заголовок" name="title" v-model="form.title" required />
      </div>
    </div>
    <div class="row">
      <div class="col-25">
        <label for="">Изображение</label>
      </div>
      <div class="col-75">
        <input type="file" @change="onFileUpload" />
        <img class="portfolio-image" :src="form.portfolioImage" alt="" />
      </div>
    </div>
    <div class="row">
      <div class="col-25">
        <label>Описание</label>
      </div>
      <div class="col-75">
        <textarea placeholder="Расскажите, каких результатов и успехов смогли добиться" v-model="form.description" />
      </div>
    </div>
    <div class="server-error" v-if="errors.length">
      <ul v-for="error in errors" :key="error">
        <li>{{ error }}</li>
      </ul>
    </div>
    <div class="row">
      <form-button variant="primary" :loading="loading" :disabled="loading">
        Сохранить
      </form-button>
    </div>
  </form>
</template>

<script>
import FormButton from '../Button';

export default {
  name: 'Portfolio',
  components: { FormButton },
  props: {
    portfolio: {
      type: Object,
    },
  },
  data() {
    return {
      loading: false,
      form: {
        title: this.portfolio?.title || '',
        description: this.portfolio?.description || '',
        portfolioImage: null,
      },
      errors: [],
    };
  },
  methods: {
    onFileUpload(e) {
      this.form.portfolioImage = this.$fn.file.upload(e).file;
      if (
        this.form.portfolioImage &&
        this.form.portfolioImage.size >= 10 * 1024 * 1024
      ) {
        alert('Размер файла слишком большой. Лимит размера - 10MB');
        this.form.portfolioImage = null;
        e.target.value = '';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.portfolio-image {
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
}

span {
  display: inline-block;
  padding-top: 12px;
  padding-bottom: 12px;
}

input,
textarea,
select {
  outline: none;
  margin-top: 5px;
  display: inline-block;
  background: map-get($gray, 1000);
  border: none;
  border-radius: 10px;
  width: 100%;
  background-color: map-get($gray, 200);
  padding: 15px;

  input {
    resize: none;
  }

  &:focus {
    background-color: white;
    outline: none;
  }
}

input[type='date'],
input[type='checkbox'] {
  width: 30%;
  margin-right: 0.5rem;
}

.d-flex {
  display: flex;
  flex-wrap: wrap;
}

.d-flex>div {
  padding: 0.1rem;
  color: grey;
  margin-right: 0.5rem;
  margin-top: 0.5rem;
  background-color: rgba(86, 61, 124, 0.15);
  border: 1px solid rgba(86, 61, 124, 0.15);
}

.vacancy-description {
  font-size: 17px;
}

.error,
.email-dirty {
  color: $danger;
}

.email-verified {
  color: $success;
}

.server-error {
  color: $danger;
  margin-bottom: 10px;
}

.server-error ul {
  list-style-type: none;
}

/* Clear floats after the columns */
.row:after {
  content: '';
  display: table;
  clear: both;
}

.col-25 {
  float: left;
  width: 25%;
  margin-top: 6px;
}

.col-75 {
  width: 75%;
  float: left;
  margin-top: 6px;
}

button {
  margin-top: 20px;
  float: right;
  padding: 12px 20px;
}

label {
  padding: 0;
  display: inline-block;
  color: rgba(0, 0, 0, 0.15);
}

/* Responsive layout - when the screen is less than 600px wide,
make the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: $layout-s) {

  .col-25,
  .col-75 {
    margin-top: 0px;
    width: 100%;
  }

  button {
    width: 100%;
    margin-top: 20px;
  }
}

@media (max-width: 620px) {

  .row input,
  .row select,
  .row textarea {
    width: 90%;
  }
}
</style>
