<template>
  <form @submit.prevent="$emit('on-submit', form)">
    <div class="vacancy-filter">
      <label>Профессии</label>
      <v-select
        v-model="form.specializations"
        multiple
        :options="specialties"
        :reduce="(specialty) => specialty.id"
        label="specialty"
        :placeholder="'Укажите профессию'"
      >
        <template v-slot:no-options="{ search, searching }">
          <template v-if="searching">
            Ничего не найдено по запросу <em>{{ search }}</em
            >.
          </template>
          <em v-else style="opacity: 0.5"
            >Начните печатать, чтобы найти профессию.</em
          >
        </template>
      </v-select>
    </div>
    <div class="vacancy-filter">
      <label>Занятость</label>
      <v-select
        multiple
        v-model="form.employmentType"
        :options="employmentTypes"
        :reduce="(employmentType) => employmentType.id"
        :placeholder="'Укажите занятость'"
        label="name"
      >
        <template v-slot:no-options="{ search, searching }">
          <template v-if="searching">
            Ничего не найдено по запросу <em>{{ search }}</em
            >.
          </template>
          <em v-else style="opacity: 0.5"
            >Начните печатать, чтобы найти занятость.</em
          >
        </template>
      </v-select>
    </div>
    <div class="vacancy-filter">
      <label>График работы</label>
      <v-select
        multiple
        v-model="form.workFormat"
        :options="workFormats"
        :reduce="(workFormat) => workFormat.id"
        :placeholder="'Укажите график работы'"
        label="name"
      >
        <template v-slot:no-options="{ search, searching }">
          <template v-if="searching">
            Ничего не найдено по запросу <em>{{ search }}</em
            >.
          </template>
          <em v-else style="opacity: 0.5"
            >Начните печатать, чтобы найти график работы.</em
          >
        </template>
      </v-select>
    </div>
    <div class="vacancy-filter">
      <label>Страна</label>
      <v-select
        v-model="form.country"
        :options="countries"
        :reduce="(country) => country.id"
        :placeholder="'Укажите страну'"
        label="name"
      >
        <template v-slot:no-options="{ search, searching }">
          <template v-if="searching">
            Ничего не найдено по запросу <em>{{ search }}</em
            >.
          </template>
          <em v-else style="opacity: 0.5"
            >Начните печатать, чтобы найти страну.</em
          >
        </template>
      </v-select>
    </div>
    <div class="vacancy-filter">
      <label>Город</label>
      <v-select
        v-model="form.city"
        :options="cities"
        :reduce="(city) => city.id"
        :placeholder="'Укажите город'"
        label="name"
        :disabled="cityChanger"
      >
        <template v-slot:no-options="{ search, searching }">
          <template v-if="searching">
            Ничего не найдено по запросу <em>{{ search }}</em
            >.
          </template>
          <em v-else style="opacity: 0.5"
            >Начните печатать, чтобы найти город.</em
          >
        </template>
      </v-select>
    </div>
    <btn fluid variant="primary" :loading="loading" :disabled="loading"
      >Применить фильтры</btn
    >
    <div @click="clearFilterConfig" class="link">Сбросить все фильтры</div>
  </form>
</template>

<script>
import Btn from '@/components/Button';

export default {
  name: 'VacancyFilter',
  components: {
    Btn,
  },
  props: {
    vacancyFilter: {
      type: Object,
    },
  },
  data() {
    return {
      loading: true,
      errors: [],
      form: {
        specializations: this.vacancyFilter?.specializations || [],
        workFormat: this.vacancyFilter?.workFormat || [],
        employmentType: this.vacancyFilter?.employmentType || [],
        country: this.vacancyFilter?.country || null,
        city: this.vacancyFilter?.city || null,
      },
      specialties: [],
      workFormats: [],
      employmentTypes: [],
      cities: [],
      countries: [],
    };
  },
  watch: {
    // eslint-disable-next-line func-names
    'form.country': function () {
      this.getCities(this.form.country);
      this.form.city = null;
    },
  },
  created() {
    Promise.all([
      this.$api.profession.getSkills(),
      this.$api.profession.getSpecialties(),
      this.$api.profession.getWorkFormats(),
      this.$api.profession.getEmploymentTypes(),
      this.$api.location.getCountries(),
    ])
      .then((r) => {
        this.skills = r[0].data;
        this.workFormats = r[2].data;
        this.employmentTypes = r[3].data;
        r[1].data.forEach((element) => {
          this.specialties.push(element);
        });
        this.countries.push(...r[4].data);
      })
      .finally(() => {
        this.loading = false;
      });
    if (this.form.country) {
      this.getCities(this.form.country);
    }
  },
  computed: {
    cityChanger() {
      if (this.form.country !== null) {
        return false;
      }
      return true;
    },
  },
  methods: {
    async getCities(countryId) {
      const cities = await this.$api.location.getCities(countryId);
      this.cities = cities.data;
    },
    clearFilterConfig() {
      this.form = {
        specializations: [],
        workFormat: [],
        employmentType: [],
        country: null,
        city: null,
      };
      this.$emit('on-submit', this.form);
    },
  },
};
</script>

<style lang="scss" scoped>
.vacancy-filter {
  label {
    margin-bottom: 5px;
    display: inline-block;
  }
  margin-bottom: 10px;
}
.link {
  cursor: pointer;
  color: $primary;
  margin-top: 15px;
  text-align: center;
  text-decoration: underline;
}
</style>
