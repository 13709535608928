import user from './user';
import lesson from './lesson';
import course from './course';
import carousel from './carousel';
import program from './program';
import notification from './notification';
import location from './location';
import notes from './notes';
import profession from './profession';
import webinar from './webinar';
import mail from './mail';
import accessDistribution from './accessDistribution';
import vacancy from './vacancy';
import resume from './resume';
import quiz from './quiz';
import courses from './courses';
import units from './units';

export default {
  courses,
  units,
  user,
  lesson,
  course,
  carousel,
  program,
  notification,
  location,
  notes,
  profession,
  webinar,
  mail,
  accessDistribution,
  vacancy,
  resume,
  quiz,
};
