<template>
  <modal :show="show" @close="close" no-padding>
    <div class="hw-submission">
      <div class="hw-submission-heading">
        <h2 class="hw-submission-back-to-chat" v-if="showEditForm">
          <i class="fal fa-long-arrow-left" @click="showEditForm = false"></i>
          <span @click="showEditForm = false">Назад в чат</span>
        </h2>
        <h2>Домашнее задание</h2>
        <!-- <p v-if="task && !showEditForm" class="hw-text">
          {{ lesson.assignments[0].description }}
        </p>
        <a
          class="hw-submission-show-example"
          v-if="task && !showEditForm && lesson.assignments[0].example"
          :href="lesson.assignments[0].example"
          target="_blank"
        >
          Смотреть пример
        </a> -->
      </div>
      <div class="hw-submission-body">
        <div v-if="task" class="hw-submission-form">
          <div class="hw-submission-label">Описание</div>
          <p>{{ lesson.assignments[0].description }}</p>
          <a
            class="hw-submission-show-example"
            v-if="Object.keys(lesson.assignments[0].example).length !== 0"
            :href="lesson.assignments[0].example"
            target="_blank"
          >
            Файл
          </a>
          <div v-if="lesson.assignments[0].isActive">
            <div class="hw-submission-message">
              <div class="hw-submission-label">Ваш ответ</div>
              <textarea
                v-model="message"
                placeholder="Начните вводить"
              ></textarea>
            </div>
            <div v-if="error" class="hw-submission-error">{{ error }}</div>
            <div class="hw-submission-file">
              <!--            <a v-if="task && task.submissions[0] && !showEditForm"-->
              <!--               :href="task.submissions[0].file"-->
              <!--               target="_blank">Посмотреть файл</a>-->
              <label>
                <span>{{ file ? file.name : 'Прикрепить файл' }}</span>
                <input type="file" @change="onFileUpload" />
              </label>
              <div v-if="!isVerified">
                При выходе из этого окна ничего не удалится
              </div>
            </div>
            <div v-if="isVerified" class="verified-answer">
              <div>Вы уверены, что это ваш окончательный ответ?</div>
              <div class="verified-answer-btns">
                <btn @click="isVerified = false" class="verified-answer-btns-no"
                  >Нет</btn
                >
                <btn
                  :disabled="loading"
                  :loading="loading"
                  @click="submit"
                  class="verified-answer-btns-yes"
                >
                  <span>Да</span>
                </btn>
              </div>
            </div>
            <btn
              fluid
              :disabled="btnDisabled"
              :loading="loading"
              @click="isVerified = true"
            >
              Отправить на проверку
            </btn>
          </div>
        </div>
        <div v-else class="hw-submission-chat">
          <h3>Чат с наставником</h3>
          <btn fluid variant="green" @click="showEditForm = true"
            >Отправить новый ответ</btn
          >
          <div class="hw-submission-messages">
            <div
              v-for="message in task.submissions"
              :key="message.id"
              class="hw-submission-message-chat mine"
            >
              <div class="hw-submission-message-chat-body">
                <div>{{ message.text }}</div>
                <a v-if="message.file" :href="message.file" target="_blank"
                  >Посмотреть файл</a
                >
              </div>
              <div
                v-for="comment in message.comments"
                :key="comment.id"
                class="hw-submission-message-chat comment"
              >
                <div class="hw-submission-message-chat-body">
                  {{ comment.text }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Modal from '@/components/Modal';
import Btn from '@/components/Button';
import trackAmplitudeEvent from '../../../utils/trackAmplitudeEvent';
import $globalFn from '../../../fn';
import trackAmplitudeError from '../../../utils/trackAmplitudeError';

// const STATUS_LABEL = {
//   REVIEW: {
//     text: 'На проверке',
//     icon: 'fas fa-hourglass-end',
//     backgroundColor: '#FFBC25',
//     fontColor: '#292929',
//   },
//   ACCEPTED: {
//     text: 'Проверено наставником. Домашнее задание выполнено',
//     icon: 'far fa-check',
//     backgroundColor: '#5AB413',
//     fontColor: '#FFFFFF',
//   },
//   REJECTED: {
//     text: 'Проверено наставником. Нужно доработать',
//     icon: 'fas fa-times',
//     backgroundColor: '#FF5A5F',
//     fontColor: '#FFFFFF',
//   },
// };

export default {
  name: 'HomeworkSubmission',
  components: { Modal, Btn },
  props: {
    show: { type: Boolean, default: false },
  },
  data() {
    return {
      showEditForm: false,
      file: null,
      message: '',
      loading: false,
      error: '',
      isVerified: false,
      parsedDescription: '',
    };
  },
  mounted() {
    const parser = new DOMParser();
    const doc = parser.parseFromString(
      this.lesson.assignments[0].description,
      'text/html'
    );
    this.parsedDescription = doc.body;
  },
  computed: {
    // hwStatus() {
    //   const statusCode = Object.entries(this.$vars.lesson.hwStatuses).find(
    //     (v) => v[1] === this.task.status
    //   )[0];
    //   return STATUS_LABEL[statusCode];
    // },
    // statusName() {
    //   return Object.entries(this.$vars.lesson.hwStatuses).find(
    //     (v) => v[1] === this.task.status
    //   )[0];
    // },
    btnDisabled() {
      return !(this.file || this.message);
    },
    task() {
      const task = this.lesson.assignments[0].isActive;
      return task;
    },
    ...mapGetters({
      lesson: 'getLesson',
    }),
  },
  methods: {
    close() {
      this.$emit('close');
    },
    onFileUpload(e) {
      this.file = this.$fn.file.upload(e).file;
      const uploadedFileType = e.target.files[0].type;
      const userId = $globalFn.ls.get('userId');

      trackAmplitudeEvent('homework_file_upload', userId, {
        lesson_id: this.lesson.id,
        file_type: uploadedFileType,
        date: new Date(),
      });
    },
    submit() {
      this.loading = true;
      if (this.file && this.file.size >= 10 * 1024 * 1024) {
        this.isVerified = false;
        this.error = 'Размер файла слишком большой. Лимит размера - 10MB';
        this.loading = false;
        return;
      }
      const data = {
        id: this.lesson.assignments[0].id,
        date: new Date().toISOString(),
        file: this.file,
        answer: this.message,
      };
      // if (this.showEditForm) {
      //   this.$api.lesson.assignments
      //     .update({
      //       ...data,
      //     })
      //     .finally(() => {
      //       this.showEditForm = false;
      //       this.loading = false;
      //     });
      //   return;
      // }
      const userId = $globalFn.ls.get('userId');

      this.$api.lesson.homework
        .submit({ ...data })
        .finally(() => {
          this.loading = false;
          this.close();
          this.$emit('refresh');

          trackAmplitudeEvent('homework_submit', userId, {
            lesson_id: this.lesson.id,
          });
        })
        .catch((error) => {
          trackAmplitudeError('homework_submit', userId, {
            error,
          });
          if (error.response.status === 403) {
            this.error = error.response.data.detail;
          }
        });
    },
    ...mapActions(['initLesson']),
  },
};
</script>

<style lang="scss" scoped>
.hw-submission {
  width: 571px;

  &-form {
    white-space: pre-line;
  }

  &-error {
    text-align: center;
    color: $danger;
  }

  &-heading {
    border-bottom: 1px solid map-get($gray, 200);
    padding: 30px 35px;

    h2 {
      font-weight: 500;
      font-size: 20px;

      span {
        margin-left: 20px;
      }

      i {
        font-size: 20px;
        color: $primary;
      }
    }

    .hw-text {
      overflow: auto;
      padding: 20px 10px;
      white-space: pre-line;
      font-size: 14px;
    }
  }

  &-body {
    padding: 20px 35px;
    overflow: auto;
    max-height: 500px;

    p {
      margin-top: 16px;
      font-size: 13px;
      margin-bottom: 15px;
      line-height: 18px;
    }

    a {
      color: $primary;
      text-decoration: underline;
      font-weight: 500;
    }

    .btn {
      margin-top: 32px;
    }
  }

  &-label {
    color: map-get($gray, 500);
  }

  &-message {
    margin-top: 44px;

    textarea {
      width: 100%;
      min-width: 100%;
      box-sizing: border-box;
      min-height: 150px;
      margin-top: 16px;
      border: 1px solid map-get($gray, 400);
      border-radius: 4px;
      padding: 16px 20px;
      font-size: 16px;

      &:focus {
        border: 1px solid $primary;
      }
    }
  }

  &-file {
    margin-top: 16px;

    input {
      display: none;
    }

    & > div {
      font-size: 14px;
      color: map-get($gray, 500);
      margin-top: 12px;
    }

    span {
      color: $primary;
      text-decoration: underline;
      font-weight: 500;
    }
  }

  &-status {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 54px;

    span {
      margin-left: 8px;
    }
  }

  &-chat {
    h3 {
      color: map-get($gray, 500);
      font-weight: normal;
      font-size: 16px;
      border-bottom: 1px solid map-get($gray, 200);
      padding-bottom: 12px;
    }

    button {
      position: sticky;
      top: 0;
    }
  }

  &-message-chat {
    margin-top: 46px;

    &-body {
      width: 70%;
      padding: 18px 20px;
      box-sizing: border-box;
      font-size: 14px;
      line-height: 20px;
    }

    &.mine {
      text-align: right;

      & > .hw-submission-message-chat-body {
        word-break: break-all;
        white-space: pre-line;
        display: inline-block;
        color: $dark;
        background-color: map-get($gray, 200);
        border-radius: 12px 0 12px 12px;

        a {
          display: inline-block;
          margin-top: 12px;
        }
      }
    }

    &.comment {
      .hw-submission-message-chat-body {
        white-space: pre-line;
        background-color: $primary;
        border-radius: 0 12px 12px 12px;
        color: $white;
        text-align: left;
      }
    }
  }

  &-show-example {
    margin: 10px 0;
    font-weight: 500;
    color: $primary;
    display: block;
  }
}

.verified-answer {
  margin-top: 10px;

  .verified-answer-btns {
    display: flex;
    justify-content: space-around;

    @media screen and (max-width: $layout-s) {
      button {
        width: 130px;
      }

      align-items: center;
    }

    &-yes {
      width: 200px;
    }

    &-no {
      width: 200px;
      background-color: transparent !important;
      color: $primary !important;
      border: 1px solid $primary;
    }
  }
}

@media screen and (max-width: $layout-s) {
  .hw-submission {
    width: 90vw;

    &-message {
      margin-top: 20px;

      textarea {
        min-height: 10px;
      }
    }

    &-chat h3 {
      font-size: 12px;
    }

    &-status {
      height: 30px;
      font-size: 12px;
    }

    &-heading {
      padding: 10px 15px;

      h2 {
        font-size: 14px;
      }
    }

    &-body {
      padding: 10px 15px;
      overflow: auto;

      .hw-submission-chat h3 {
        padding-bottom: 3px;
      }

      .btn {
        margin-top: 12px;
        font-weight: 500;
        height: 30px;
        font-size: 13px;
      }
    }
  }

  .hw-submission-message-chat {
    margin-top: 10px;
  }

  .hw-submission-message-chat-body {
    padding: 5px 7px;
    font-size: 12px;
  }

  .hw-submission-message-chat.mine > .hw-submission-message-chat-body a {
    margin-top: 5px;
  }
}
</style>
