<template>
  <div class="grade-content">
    <h3>Как вам урок?</h3>
    <p>Оцените, насколько полезный этот урок для вас?</p>
    <span>1 - не оказался полезным, 5 - очень полезный урок.</span>
    <div class="grade-nums">
      <div
        v-for="grade in grades"
        :key="grade.id"
        class="grade-num"
        @click="selectGrade(grade)"
      >
        <span :class="{selected: grade.selected}">{{ grade.grade }}</span>
        <!--<p class="lesson-grade-text" :class="{show: grade.selected}">{{ grade.text }}</p>-->
      </div>
    </div>
    <textarea
      :value="value"
      @input="$emit('input', $event.target.value)"
      placeholder="Оставьте свой коментарий"
    />
  </div>
</template>

<script>

export default {
  name: 'tech-grade',
  props: { value: { type: String, default: '' } },
  data() {
    return {
      grades: [
        {
          id: 1,
          grade: 1,
          text: 'Ужасно',
          selected: false,
        },
        {
          id: 2,
          grade: 2,
          text: 'Плохо',
          selected: false,
        },
        {
          id: 3,
          grade: 3,
          text: 'Средне',
          selected: false,
        },
        {
          id: 4,
          grade: 4,
          text: 'Хорошо',
          selected: false,
        },
        {
          id: 5,
          grade: 5,
          text: 'Отлично',
          selected: false,
        },
      ],
    };
  },
  methods: {
    selectGrade(grade) {
      // eslint-disable-next-line no-param-reassign
      this.grades.forEach((item) => { item.selected = item.grade === grade.grade; });
      this.$emit('selected-grade', grade.grade);
    },
  },
};
</script>

<style lang="scss" scoped>
.grade {
  h3 {
    margin-bottom: 10px;
    font-size: 21px;
  }

  p {
    margin-bottom: 20px;
    color: map-get($gray, 500);
  }

  span {
    display: inline-block;
    font-size: 12px;
    margin-bottom: 10px;
  }

  &-nums {
    display: flex;
    margin-bottom: 10px;
  }

  &-num {
    flex-basis: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;

    & span {
      margin-bottom: 10px;
      width: 50px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $primary;
      border: 1px solid #3863F6;
      border-radius: 2px;
      cursor: pointer;
      transition: all .2s ease-in-out;

      &:hover {
        background-color: $primary;
        color: $white;
      }

      &.selected {
        background-color: $primary;
        color: $white;
      }
    }
  }

  textarea {
    padding: 20px 25px;
    margin-bottom: 25px;
    width: 100%;
    height: 120px;
    background-color: map-get($gray, 200);
    border-radius: 3px;
    border: none;
    outline: none;
    resize: none;

    &.error {
      border: 1px solid $danger;
    }
  }
}
</style>
