<template>
  <div>
    <div class="nav-burger">
      <burger
        :opened="collapseMenuOpened"
        @click="collapseMenuOpened = !collapseMenuOpened"
        v-click-outside="clickOutside"
      />
    </div>
    <div class="sidebar" :class="collapseMenuOpened ? 'mobile' : ''">
      <div class="sidebar--routers">
        <!--        <router-link
          to="/"
          class="router"
          style="margin-top: 30px; margin-left: -12px"
        >
          <img class="logo" src="@/icons/flowicon.png" />
          <span class="sidebar&#45;&#45;tag hovered the-flow-app">The Flow</span>
        </router-link>-->
        <router-link to="/" class="router" style="margin-top: 20px">
          <ui-icon class="sidebar--icon" name="Home" />
          <span class="sidebar--tag hovered">Главная</span>
        </router-link>
        <router-link to="/my_courses" class="router">
          <ui-icon class="sidebar--icon" name="Book" />
          <span class="sidebar--tag hovered">Блоки</span>
        </router-link>
        <!-- <router-link to="/courses" class="router">
          <img
            style="opacity: 0.3"
            src="../assets/images/flowpro.svg"
            alt="Программы"
            class="sidebar--icon"
          />
         <span class="sidebar&#45;&#45;tag hovered">Поток PRO</span>
        </router-link> -->
        <div class="choose-program" @click="clearSelectedProgram">
          <img
            style="opacity: 0.3"
            src="../assets/images/progtams.svg"
            alt="Программы"
            class="sidebar--icon"
          />
          <span class="sidebar--tag hovered">Программы</span>
        </div>
        <div
          class="router"
          @click="logout"
          style="display: flex; vertical-align: middle; cursor: pointer"
        >
          <ui-icon
            class="sidebar--icon"
            style="display: inline-block"
            name="Logout"
          />
          <span
            class="sidebar--tag hovered"
            style="display: inline-block; padding-left: 10px; padding-top: 5px"
            >Выход</span
          >
        </div>
      </div>
    </div>
    <select-program @close="close" :clear="isLocalStorageCleared" />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

import Burger from '@/components/Burger';
import { leave } from '@/api/utils';

export default {
  components: {
    Burger,
  },
  props: {
    isMobile: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      collapseMenuOpened: false,
      show: false,
      isLocalStorageCleared: false,
    };
  },
  computed: {
    ...mapGetters({
      programs: 'getPrograms',
      courses: 'getMyCourses',
      selectedProgram: 'getSelectedProgram',
    }),
  },
  methods: {
    ...mapMutations(['CLEAR_SELECTED_PROGRAM']),
    close() {
      this.isLocalStorageCleared = false;
    },
    clearSelectedProgram() {
      this.CLEAR_SELECTED_PROGRAM();
      this.isLocalStorageCleared = true;
    },
    clickOutside() {
      this.collapseMenuOpened = false;
    },
    logout() {
      this.$api.user.logout();
      const tokenExpired = false;
      leave(tokenExpired);
    },
  },
};
</script>

<style lang="scss" scoped>
.nav-burger {
  z-index: 100;
  display: none;

  @media screen and (max-width: $layout-s) {
    display: block;
    padding: 5px;
    position: fixed;
  }
}

.sidebar {
  position: fixed;
  z-index: 99;
  left: 0;
  top: 0;
  height: 100%;
  width: 100px;
  background: white;
  border-right: 1px solid #e8e8e8;
  padding: 15px 30px;
  display: flex; //flex
  flex-direction: column;
  gap: 50px;
  transition: 0.3s;
  flex: auto;

  @media (max-width: $layout-s) {
    display: none;
  }
  @media (max-height: $layout-s) {
    max-height: $layout-s;
    overflow: scroll;
  }

  .router {
    color: map-get($gray, 800);

    &:visited {
      color: map-get($gray, 800);
    }

    &:hover {
      color: $primary !important;
      fill: $primary;
    }
  }

  .logo {
    width: 60px;
    height: 60px;
    padding: 12px;
    background-color: #f8f8f8;
    border-radius: 17px;
  }

  &:hover {
    width: 250px;
    align-items: flex-start;

    .hovered {
      visibility: visible;
      opacity: 1;
      position: inherit;
    }
  }

  &--tag {
    font-size: 18px;
    margin-left: 10px;
    color: #adb2c8;
  }

  &--tag:hover {
    transition: color 1ms;
    color: #7f7f85;
  }

  .the-flow-app {
    width: 100px;
    color: black;
  }

  .the-flow-app:hover {
    color: black;
  }

  &--icon {
    margin-left: 3px;
    width: 28px;
    height: auto;
  }

  &--routers {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 50px;

    a {
      display: flex;
      gap: 10px;
      align-items: center;
      text-decoration: none;
    }
  }
}

.hovered {
  visibility: hidden;
  opacity: 0;
  transition: 0.3s ease-in-out;
  position: absolute;
  left: -100%;
}

.mobile {
  display: flex !important;
  align-items: flex-start;
  width: 70%;

  .hovered {
    visibility: visible !important;
    opacity: 1 !important;
    position: inherit;

    &:hover {
      width: inherit !important;
    }
  }
}

.choose-program {
  display: flex;
  align-items: center;

  &:hover {
    cursor: pointer;
  }

  .sidebar--tag {
    margin-left: 20px;
  }
}
</style>
