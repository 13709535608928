<template>
  <div>
    <ExperienceForm @on-submit="createExperience" />
  </div>
</template>

<script>
import ExperienceForm from './ExperienceForm';

export default {
  name: 'ExperienceNew',
  components: {
    ExperienceForm,
  },
  data() {
    return {};
  },
  methods: {
    createExperience(experienceForm) {
      const data = {
        companyName: experienceForm.companyName,
        positionName: experienceForm.positionName,
        companyField: experienceForm.companyField,
        dateFrom: `${experienceForm.dateFromYear}-${experienceForm.dateFromMonth}-01`,
        dateTill: experienceForm.dateTillMonth
          ? `${experienceForm.dateTillYear}-${experienceForm.dateTillMonth}-01`
          : null,
        obligations: experienceForm.obligations,
        achievements: experienceForm.achievements,
      };
      this.$api.resume.addExperience({ data }).then(() => {
        this.$router.push({ name: 'experience-list' });
      });
    },
  },
};
</script>
