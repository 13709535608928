<template>
  <div class="coming-soon-cover">
    <div
      class="coming-carousel-cover"
      :class="{ 'moving-next': isMovingNext, 'moving-prev': isMovingPrev }"
    >
      <div v-if="banners.length > 0" class="coming-carousel-items">
        <div
          v-for="(item, index) in banners"
          class="coming-carousel-item"
          :class="{ active: isItemActive(item) }"
          :key="index"
        >
          <img :src="bannerImage(item)" />
        </div>
      </div>
      <!--      <div class="carousel-direction-buttons">-->
      <!--        <button-->
      <!--          type="button"-->
      <!--          @click="prevCarousel()"-->
      <!--          class="carousel-direction-btn &#45;&#45;prev"-->
      <!--          :disabled="isMovingPrev"-->
      <!--        >-->
      <!--          <i class="fas fa-chevron-left"></i>-->
      <!--        </button>-->
      <!--        <button-->
      <!--          type="button"-->
      <!--          @click="nextCarousel()"-->
      <!--          class="carousel-direction-btn &#45;&#45;next"-->
      <!--          :disabled="isMovingNext"-->
      <!--        >-->
      <!--          <i class="fas fa-chevron-right"></i>-->
      <!--        </button>-->
      <!--      </div>-->
    </div>
  </div>
</template>

<script>
export default {
  name: 'MainCarousel',
  data() {
    return {
      banners: [],
      items: [],
      currentItemIndex: 0,
      isMovingNext: false,
      isMovingPrev: false,
      timeout: null,
    };
  },
  mounted() {
    this.loadCarousel();
  },
  methods: {
    async loadCarousel() {
      const res = await this.$api.carousel.getBanners();
      this.items = res.data
        .filter((el) => el.isActive)
        .sort((a, b) => a.position - b.position);
      this.calculatePosition();
      this.setInterval();
    },
    prevCarousel() {
      this.clearInterval();
      this.isMovingPrev = true;
      this.prevCarouselIndex();
      setTimeout(() => {
        this.isMovingPrev = false;
        this.calculatePosition();
        this.setInterval();
      }, 600);
    },
    nextCarousel() {
      this.clearInterval();
      this.isMovingNext = true;
      this.nextCarouselIndex();
      setTimeout(() => {
        this.isMovingNext = false;
        this.calculatePosition();
        this.setInterval();
      }, 600);
    },
    setInterval() {
      this.timeout = setTimeout(() => {
        this.nextCarousel();
      }, 5000);
    },
    clearInterval() {
      clearTimeout(this.timeout);
    },
    bannerImage(banner) {
      return banner.file;
    },
    getElementByIndex(array, index) {
      if (index >= 0) {
        if (index < array.length) {
          return array[index];
        }
        return array[index - array.length];
      }
      return array[array.length + index];
    },
    nextCarouselIndex() {
      if (this.currentItemIndex < this.items.length - 1) {
        this.currentItemIndex += 1;
      } else {
        this.currentItemIndex = 0;
      }
    },
    prevCarouselIndex() {
      if (this.currentItemIndex > 0) {
        this.currentItemIndex -= 1;
      } else {
        this.currentItemIndex = this.items.length - 1;
      }
    },
    calculatePosition() {
      const newItems = [];
      for (
        let i = this.currentItemIndex - 2;
        i < this.currentItemIndex;
        i += 1
      ) {
        newItems.push(this.getElementByIndex(this.items, i));
      }
      for (
        let i = this.currentItemIndex;
        i < this.currentItemIndex + 3;
        i += 1
      ) {
        newItems.push(this.getElementByIndex(this.items, i));
      }
      this.banners = newItems;
    },
    isItemActive(item) {
      return item.position === this.items[this.currentItemIndex].position;
    },
  },
};
</script>

<style lang="scss" scoped>
.coming-carousel-cover {
  overflow-x: hidden;
  padding: 30px 0 10px;
  position: relative;
  display: flex;
  justify-content: center;

  &.moving-next {
    .coming-carousel-items {
      transform: translateX(-20%);
      transition: 0.6s;
    }

    .carousel-direction-buttons {
      opacity: 0;
    }
  }

  &.moving-prev {
    .coming-carousel-items {
      transform: translateX(20%);
      transition: 0.6s;
    }

    .carousel-direction-buttons {
      opacity: 0;
    }
  }

  .coming-carousel-items {
    display: flex;
    transition: 0s;

    .coming-carousel-item {
      position: relative;
      margin: 0 16px;
      opacity: 0.3;
      border-radius: 20px;

      &.active {
        opacity: 1;
      }

      .carousel-save-btn {
        position: absolute;
        display: flex;
        align-items: center;
        padding: 3px 8px;
        border: none;
        color: $dark;
        border-radius: 2px;
        bottom: 21px;
        right: 16px;

        svg {
          margin-right: 5px;
        }
      }

      img {
        width: 768px;
        height: auto;
        object-fit: cover;
        border-radius: 20px !important;
      }
    }
  }

  .carousel-direction-buttons {
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: 193px;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 826px;
    opacity: 1;
    transition: 0.3s;

    .carousel-direction-btn {
      cursor: pointer;
      width: 58px;
      height: 58px;
      border-radius: 50%;
      border: none;
      box-shadow: 0 0 25px rgba(41, 41, 41, 0.3);
      font-size: 18px;
      color: $primary;
    }
  }
}

.coming-soon-content {
  margin-top: 60px;

  h3 {
    width: fit-content;
    margin: 0 auto;
    color: $dark;
    font-size: 18px;
  }

  .support-text {
    width: fit-content;
    margin: 0 auto;
    color: $primary;
    font-size: 14px;
  }

  h4 {
    color: $dark;
    font-size: 18px;
    margin-top: 60px;
  }

  .courses-cover {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 30px;

    .course-item {
      width: 568px;
      position: relative;

      &:nth-child(n + 3) {
        margin-top: 20px;
      }

      .course-img-cover {
        position: relative;
        overflow: hidden;

        &:hover {
          .course-overlay {
            top: 0;
          }
        }

        .course-overlay {
          position: absolute;
          transition: 0.3s;
          top: 100%;
          left: 0;
          background-color: $primary;
          width: 100%;
          height: 100%;
          border-radius: 4px;
          display: flex;
          flex-direction: column;
          padding: 30px 20px;

          .overlay-sm-text {
            font-size: 12px;
          }

          .overlay-bg-text {
            font-size: 18px;
          }

          .overlay-md-text {
            font-size: 14px;
          }
        }
      }

      img {
        width: 100%;
        height: 284px;
        object-fit: cover;
        border-radius: 4px;
      }

      .course-tag {
        position: absolute;
        top: 12px;
        right: 12px;
        color: $dark;
        padding: 1px 16px;
        border-radius: 2px;
        font-weight: 500;
        font-size: 12px;
      }

      .course-info-cover {
        display: flex;
        align-items: flex-start;
        margin-top: 10px;

        .course-name {
          color: $dark;
          font-weight: 500;
          font-size: 14px;
        }

        .course-date {
          font-size: 14px;
          font-weight: 500;
          color: $primary;
          margin-left: auto;
          white-space: nowrap;
        }
      }
    }
  }
}

@media screen and (max-width: $layout-s) {
  .coming-carousel-cover {
    &.moving-next {
      .coming-carousel-items {
        transform: translateX(-300%);
      }
    }

    &.moving-prev {
      .coming-carousel-items {
        transform: translateX(-100%);
      }
    }

    .coming-carousel-items {
      transform: translateX(-200%);

      .coming-carousel-item {
        margin: 0;
        flex: 0 0 100%;

        img {
          width: 100%;
        }
      }
    }

    .carousel-direction-buttons {
      width: 98%;
    }
  }

  .coming-soon-content {
    .support-text {
      text-align: center;
    }

    .courses-cover {
      flex-direction: column;

      .course-item {
        width: 100%;

        &:not(:first-child) {
          margin-top: 20px;
        }

        img {
          width: 100%;
        }

        .course-info-cover {
          flex-wrap: wrap;

          .course-name {
            flex: 1 1 100%;
          }

          .course-date {
            margin-top: 10px;
            margin-left: 0;
          }

          .course-bookmark {
            margin-top: 10px;
            margin-left: auto;
          }
        }
      }
    }
  }
}
</style>
