<template>
  <div class="background-container">
    <div class="content">
      About
    </div>
  </div>
</template>

<script>
export default {
  name: 'about-component',
  props: {
    visible: Boolean,
    course: null
  }
};
</script>

<style scoped>
.background-container {
  z-index: 1000;
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  position: absolute;
  background-color: #00000085;
}
.content {
  z-index: 1000;
  background-color: white;
  width: 40%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 100px;
  height: 500px;
}
</style>
