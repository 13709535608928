<template>
  <form v-on:submit.prevent>
    <div class="row f-col">
      <div class="question-status-bar">
        <span class="question-status-bar-text"
          >{{ questIndex }} вопрос из {{ quizLength }}</span
        >
        <div class="question-count">
          <span
            class="indicator"
            :class="questIndex - 1 === index ? 'indicator--active' : ''"
            v-for="(item, index) in quizLength"
            :key="index"
          ></span>
        </div>
      </div>
      <div class="question-note">
        <span>Вопрос на который нужно ответить</span>
      </div>
      <div class="question-text">
        <h4>{{ question.question.text }}</h4>
      </div>
    </div>
    <div class="row">
      <div class="quiz-container">
        <div
          class="quiz-item"
          v-for="answer in question.answers"
          :key="answer.id"
        >
          <label>
            <input
              v-model="userAnswer"
              :value="{
                question: question.id,
                answer: answer,
                index: questIndex,
              }"
              type="radio"
              name="product"
              class="card-input-element"
            />
            <Icon name="QuizCheckedFlag" class="checkedFlag" />
            <div class="panel panel-default card-input">
              <expandable-image
                class="quest-image"
                :src="answer.assets"
                v-if="answer.assets"
              />
              <div class="panel-body">{{ answer.text }}</div>
            </div>
          </label>
        </div>
      </div>
    </div>
    <div class="row">
      <button :disabled="isDisabled" class="quiz-button" @click="pushAnswer">
        Следующий шаг
      </button>
    </div>
  </form>
</template>

<script>
import Icon from '@/icons';

export default {
  name: 'QuizForm',
  data() {
    return {
      userAnswer: {},
    };
  },
  components: {
    Icon,
  },
  props: ['quizTitle', 'quizLength', 'question', 'questIndex'],
  computed: {
    isDisabled() {
      return Object.keys(this.userAnswer).length === 0;
    },
  },
  methods: {
    pushAnswer() {
      this.$emit('answer', this.userAnswer);
      this.userAnswer = {};
    },
  },
};
</script>

<style lang="scss" scoped>
.row {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 15px auto;
}

.quiz-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

label {
  width: 100%;
}

.card-input-element {
  display: none;
}

.card-input {
  margin: 10px;
  padding: 25px 50px;
  text-align: center;
  border: 0.674455px solid rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
  border-radius: 9.44237px;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 13.4891px;
  line-height: 18px;
  text-align: center;
  color: rgba(49, 49, 49, 1);
}

.card-input:hover {
  cursor: pointer;
}

.quiz-item {
  position: relative;
}

.checkedFlag {
  display: none;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  height: 30px;
  width: 30px;
  text-align: center;
  background: #57a2fd;
  border-radius: 50%;
  padding: 2px 5px 5px 5px;
  margin-top: -5px;
}

.card-input-element:checked ~ .card-input {
  border: 0.674455px solid #57a2fd;
}

.card-input-element:checked ~ .checkedFlag {
  display: block;
}

.card-input-element:checked ~ .panel {
  color: #2c3340;
}

.f-col {
  flex-direction: column;
}

.question-text h4 {
  margin: 20px auto;
  padding: 0 15px;
  text-align: center;
}

.question-note {
  text-align: center;
  padding: 0 15px;
}

.question-status-bar {
  margin: 25px auto;
  text-align: center;
}

.question-status-bar-text {
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 13.4891px;
  line-height: 16px;
  text-align: right;

  color: rgba(49, 49, 49, 0.7);
}

.question-count {
  display: flex;
  gap: 8px;
  margin-top: 10px;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.indicator {
  display: block;
  width: 40px;
  border: 1.5px solid rgba(165, 165, 165, 0.2);
}

.indicator--active {
  border: 1.5px solid #80c575;
}

.quiz-button {
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: white;
  padding: 15px 35px;
  background: #80c575;
  border-radius: 6px;
  border: none;
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    background: #609458;
  }
  &:disabled {
    background: #609458;
  }
}

.quest-image {
  width: 150px;
  height: auto;
  max-width: 100%;
  margin-bottom: 5px;
}

@media (max-width: 720px) {
  .quiz-container {
    flex-direction: column;
    gap: 0px;
  }

  .card-input {
    margin: 5px;
  }
}
</style>
