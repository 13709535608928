import $api from '../api/index';

const authModalDefault = {
  show: false,
  state: 'LOGIN', // Statuses: LOGIN, REG, FORGOT_PASSWORD
  closable: true,
};

const firstAuthModalDefault = {
  show: false,
  state: 'password' // password || data
}

export default {
  state: {
    profile: [],
    authModal: { ...authModalDefault },
    tariff: '',
    firstAuthModal: { ...firstAuthModalDefault },
  },
  getters: {
    getProfile(state) {
      return state.profile;
    },
    getAuthModal(state) {
      return state.authModal;
    },
    getTariff(state) {
      return state.tariff;
    },
    getUser(state) {
      return state;
    },
    getFirstAuthModal(state) {
      return state.firstAuthModal
    }
  },
  mutations: {
    setUserProfile(state, value) {
      state.profile = value;
    },
    setAuthModal(state, value) {
      state.authModal = value;
    },
    setUserTariff(state, value) {
      state.tariff = value;
    },
    setFirstAuthModal(state, value) {
      state.firstAuthModal = value
    }
  },
  actions: {
    async fetchProfile({ commit }, payload) {
      const res = await $api.user.auth(payload)
      commit('setUserProfile', res.data)
      return res
    },
    openAuthModal({ commit }, payload) {
      commit('setAuthModal', {
        show: true,
        state: payload?.state || 'LOGIN',
        closable: payload?.closable || true,
      });
    },
    closeAuthModal({ commit }) {
      commit('setAuthModal', authModalDefault);
      window.location.reload();
    },
    openFirstAuthModal({ commit }, payload) {
      commit('setFirstAuthModal', {
        show: true,
        state: payload
      })
    },
    closeFirstAuthModal({ commit }) {
      commit('setFirstAuthModal', firstAuthModalDefault);
      window.location.reload();
    },
    switchToRegAuthModal({ state, commit }) {
      commit('setAuthModal', {
        show: state.authModal.show,
        state: 'REG',
      });
    },
    switchToForgotPasswordAuthModal({ state, commit }) {
      commit('setAuthModal', {
        show: state.authModal.show,
        state: 'FORGOT_PASSWORD',
      });
    },
    switchToLoginAuthModal({ state, commit }) {
      commit('setAuthModal', {
        show: state.authModal.show,
        state: 'LOGIN',
      });
    },
  },
};
