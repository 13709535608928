import { request } from './utils';

export default {
  /**
   * Получить список стран
   *
   * @return {Promise}                       Возвращает промис с ответом из сервера.
   */
  getCountries() {
    return request('get', '/countries/');
  },
  /**
   * Получить список городов
   *
   * @param { Number}  id              Идентификатор страны
   *
   * @return {Promise}                       Возвращает промис с ответом из сервера.
   */
  getCities(id = null) {
    let countryId = id;
    if (id === null) {
      countryId = '';
    }
    return request('get', `/cities/?country=${countryId}`);
  },
  getCity(id) {
    return request('get', `/cities/${id}/`);
  },
};
