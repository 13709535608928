<template>
  <div class="lesson-sidebar">
    <div class="tab-container">
      <ProgressBar :value="progress" class="progress-lesson" />
      <div class="tab-header">
        <h5>Оглавление</h5>
        <span>
          <strong>{{ currentIndex }}</strong>/{{ lessonsCount }}</span>
      </div>
      <div class="line-div"></div>
      <div class="tab-content" :class="{ mobileContent: isMobile }" ref="scroller" @scroll="onScroll">
        <div v-if="activeTab === 1">
          <div class="lessons-list">
            <ul>
              <li v-for="(block, index) in units" :key="block.id" :ref="block.id" @click="onBlockHandler(block)"
                class="lesson-item">
                <div class="lesson-item-heading">
                  <i v-if="!isUnitAvailable(block)" class="fas fa-lock" />
                  {{ index + 1 }}. {{ block.title }}
                </div>
                <ul>
                  <li v-for="(lesson, lessonIndex) in block.lessons" :key="lesson.id" :ref="lesson.id"
                    @click="changeLesson(lesson, isUnitAvailable(block), isLessonAvailable(index, lessonIndex, lesson, block))"
                    :class="{
                      active: lesson?.id === currentLessonId,
                      'not-available': !isLessonAvailable(index, lessonIndex, lesson, block),
                    }">
                    <div class="lesson-preview">
                      <div v-if="!isLessonAvailable(index, lessonIndex, lesson, block)" class="lesson-preview-disabled">
                        <i class="fas fa-lock" />
                      </div>
                      <div class="lesson-image">
                        <img src="/img/test.jpeg" />
                      </div>
                      <!-- <div class="lesson-progress">
                        <p :style="{ width: lessonProgress(lesson) }"></p>
                      </div>
                      <div class="lesson-duration">
                        <div class="lesson-attend">
                          <div
                            v-if="lesson.isHomeTaskExisting"
                            class="lesson-homework"
                          >
                            <div class="lesson-attend-icon">
                              <i class="fas fa-pencil-alt"></i>
                            </div>
                          </div>
                          <div
                            v-if="lesson.isExtraMaterialsExisting"
                            class="lesson-extra"
                          >
                            <div class="lesson-attend-icon">
                              <icon name="lesson-extra" />
                            </div>
                          </div>
                        </div>
                      </div> -->
                    </div>
                    <div class="lesson-info">
                      <h3>{{ lesson.title }}</h3>
                      <span v-if="lesson.lessonUser && lesson.lessonUser.isCompleted
                        ">
                        Просмотрено
                      </span>
                    </div>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
        <div v-else-if="activeTab === 2">
          <terms :terms="lesson.glossary" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Terms from './Terms';
import ProgressBar from './ProgressBar';

export default {
  name: 'LessonSidebar',
  components: { Terms, ProgressBar },
  data() {
    return {
      activeTab: 1,
      isStaffFlag: false,
      blocks: {},
      progress: 0,
    };
  },
  mounted() {
    this.isStaffFlag = this.$store.state.user.profile.isStaff;
    this.scrollCurrentBlock();
  },
  watch: {
    courseActivity() {
      this.scrollCurrentBlock(this.courseActivity.lastLessonId);
    },
    currentIndex() {
      const lessonId = this.units.reduce((arr, unit) => [...arr, ...unit.lessons], [])[this.currentIndex - 1]?.id;
      this.scrollCurrentBlock(lessonId);
    }
  },
  props: {
    lessonsActivities: { type: Map },
    courseActivity: { type: Object, default: () => ({}) },
    isMobile: { type: Boolean, default: false },
    currentIndex: { type: Number, default: 0 },
    currentLessonId: { type: String, default: '' }
  },
  computed: {
    ...mapGetters({
      lesson: 'getLesson',
      units: 'getUnits',
      lessonInfo: 'getLessonInfo',
    }),
    lessonsCount() {
      let count = 0;
      this.units.forEach((el) => {
        count += el.lessons.length;
      });
      return count;
    },
  },
  methods: {

    isUnitAvailable(block) {
      if (this.$store.state.user.profile.noLimitVideo) return true;
      const date = new Date();
      const d1 = new Date(block.startDate);
      const d2 = new Date(block.endDate);
      return d1 < date && d2 > date;
    },
    isLessonAvailable(unitIndex, lessonIndex, lesson, unit) {
      if (this.$store.state.user.profile.noLimitVideo) return true;
      const lessonActivity = this.lessonsActivities.get(lesson.id);

      let previousLesson;

      const now = new Date();

      if (new Date(unit.startDate) > now || new Date(unit.endDate) < now) {
        return false;
      }

      if (lessonIndex === 0) {
        if (unitIndex === 0) {
          return true;
        }

        const targetUnit = this.units[unitIndex - 1];

        previousLesson = targetUnit.lessons[targetUnit.lessons.length - 1];
      } else {
        previousLesson = unit.lessons[lessonIndex - 1];
      }

      const prevLessonActivity = this.lessonsActivities.get(previousLesson.id);

      if (prevLessonActivity?.isLessonViewed) {
        return true;
      }

      return (
        Boolean(lessonActivity?.isQuizCompleted && lessonActivity?.isLessonViewed) ||
        this.courseActivity?.lastLessonId === lesson.id
      );
    },
    scrollCurrentBlock(lessonId) {
      const block = this.$refs[lessonId];
      const container = this.$refs.scroller;
      if (block !== undefined) {
        container.scrollTop =
          block[0].offsetTop - block[0].offsetHeight - 180;
      }
    },
    changeActiveTab(tab) {
      this.activeTab = tab;
    },
    changeLesson(lesson, isUnitAvailable, isLessonAvailable) {
      if (!isUnitAvailable || !isLessonAvailable) {
        return;
      }

      this.$emit('close-sidebar');
      this.$emit('change-lesson', lesson.id);
      // this.beforeLessonChange(lesson.id);
      // if (!block.isOpen) {
      //   this.onBlockHandler();
      //   return;
      // }
      // if (!lesson.isAvailable && !this.isStaffFlag) {
      //   this.$emit('lesson-handler', lesson);
      //   return;
      // }
      // this.$router
      //   .push({
      //     name: 'lesson',
      //     params: { id: lesson.id },
      //   })
      //   .catch(() => {});
    },
    // lessonProgress() {
    //   // return `${
    //   //   ((lesson.lessonUser ? lesson.lessonUser.moment : 0) * 100) /
    //   //   lesson.vimeo.duration
    //   // }%`;
    // },
    onBlockHandler() {
      // this.$emit('block-handler');
    },
    onScroll() {
      const progress =
        this.$refs.scroller.scrollTop /
        (this.$refs.scroller.scrollHeight - this.$refs.scroller.clientHeight);
      if (progress > 1) {
        this.progress = 1;
      } else if (progress < 0) {
        this.progress = 0;
      } else {
        this.progress = progress;
      }
    },
    ...mapActions(['beforeLessonChange']),
  },
};
</script>

<style lang="scss" scoped>
.tab-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 20px;

  & span {
    margin: 20px;
    color: grey;

    & strong {
      color: #3a7dff;
    }
  }
}

.lessons-list {
  padding-bottom: 40px;
}

.tab-container h5 {
  color: black;
  font-family: 'Rubik', sans-serif;
  font-size: 20px;
  margin: 20px;
}

.tab-container {
  height: 100%;
  border-radius: 10px;
  background-color: map-get($gray, 1000);
  min-width: 300px;
}

.line-div {
  width: 100%;
  height: 1px;
  background: rgba(0, 0, 0, 0.07);
}

.progress-lesson {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  position: relative;
  z-index: 0;
}

.lesson {
  &-sidebar {
    flex: 1 1 auto;
    margin-left: 20px;

    &-tabs {
      display: flex;
      align-items: center;
      height: 40px;

      ul {
        display: flex;
        list-style: none;
        padding: 0;
        margin: 0;

        li {
          color: map-get($gray, 500);
          cursor: pointer;
          user-select: none;

          &:first-child {
            margin-right: 25px;
          }

          &.active {
            color: $primary;
            font-weight: 500;
          }
        }
      }
    }

    .tab-content {
      width: 100%;
      scroll-behavior: smooth;
      background-color: map-get($gray, 1000);
      border-radius: 10px;
      max-height: 362px;
      overflow-x: hidden;
      overflow-y: auto;

      &::-webkit-scrollbar-track {
        display: none;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 10px;
        background-color: map-get($gray, 1000);
      }

      &::-webkit-scrollbar {
        display: none;
        width: 5px;
        background-color: #f5f5f5;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: $primary;
      }

      .lessons-list {
        ul {
          list-style: none;
          margin: 0;
          padding: 0;

          .lesson-item-heading {
            position: sticky;
            z-index: 9;
            left: 0;
            top: 0;
            background-color: map-get($gray, 1000);
            font-weight: 500;
            padding: 20px;

            i {
              color: $danger;
              margin-right: 5px;
            }
          }

          li.lesson-item {
            ul {
              padding: 0;

              li {
                display: flex;
                align-items: flex-start;
                width: 100%;
                //margin-bottom: 5px;
                padding: 11px 20px;
                box-sizing: border-box;
                border-bottom: 1px solid rgba(0, 0, 0, 0.07);

                //&:hover {
                //  background-color: map-get($gray, 200);
                //}
                h3 {
                  font-weight: 500;
                  font-size: 14px;
                  margin: 0;
                }

                &.active {
                  background-color: map-get($gray, 300);
                }

                &:not(.not-available) {
                  cursor: pointer;
                }

                .lesson-preview {
                  position: relative;
                  width: 150px;
                  margin-right: 13px;

                  &-disabled {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background-color: rgba(0, 0, 0, 0.3);
                    z-index: 1;

                    & i {
                      color: $white;
                      font-size: 24px;
                    }
                  }

                  .lesson-progress {
                    position: relative;
                    height: 5px;
                    background-color: map-get($gray, 1000);
                    margin-top: -4px;
                    z-index: 10;

                    p {
                      max-width: 100%;
                      position: absolute;
                      width: 50%;
                      height: 100%;
                      display: block;
                      margin: 0;
                      z-index: 0;
                      background-color: $yellow;
                    }
                  }

                  .lesson-duration {
                    right: -6px;
                    bottom: 10px;
                    position: absolute;
                  }

                  .lesson-image {
                    padding-bottom: 56.25%;
                    width: 100%;
                    position: relative;

                    img {
                      position: absolute;
                      top: 0;
                      left: 0;
                      right: 0;
                      bottom: 0;
                      width: 100%;
                      height: 100%;
                      object-fit: cover;
                      border-top-left-radius: 2px;
                      border-top-right-radius: 2px;

                      @media screen and (max-width: $layout-s) {
                        max-height: 100%;
                      }
                    }
                  }

                  @media screen and (max-width: $layout-s) {
                    border: 1px solid;
                    width: 70px;
                    min-width: 70px;
                  }
                }

                .lesson-info {
                  width: 100%;
                  display: flex;
                  flex-direction: column;
                  justify-content: space-between;

                  h3 {
                    max-width: 100%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    line-clamp: 2;
                    -webkit-box-orient: vertical;

                    &::last-le {
                      color: red;
                    }
                  }

                  span {
                    margin-bottom: 10px;
                    font-size: 12px;
                    color: map-get($gray, 500);

                    @media screen and (max-width: $layout-s) {
                      font-size: 10px;
                    }
                  }
                }

                .lesson-attend {
                  display: flex;

                  .lesson-homework,
                  .lesson-extra {
                    display: flex;
                    align-items: center;
                  }

                  .lesson-attend-icon {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 21px;
                    height: 21px;
                    color: $white;
                    border-radius: 50%;
                    background-color: $primary;
                    font-size: 8px;
                    margin-right: 10px;
                  }

                  span {
                    font-size: 12px;
                    color: $primary;
                    font-weight: 500;
                  }
                }

                @media screen and (max-width: $layout-s) {
                  height: 60px;
                }
              }
            }
          }
        }
      }
    }

    .mobileContent {
      max-height: 80vh;
    }
  }
}

.not-available::v-deep {
  cursor: not-allowed;

  .lesson-progress,
  .lesson-duration {
    display: none !important; // !!!
  }

  h3,
  span {
    color: map_get($gray, 500) !important; // !!!
  }

  .lesson-attend-icon {
    background-color: map_get($gray, 500) !important; // !!!
  }
}

@media screen and (max-width: $layout-l) {
  .lesson-sidebar {
    margin-top: 20px;
    margin-left: 0;
  }
}

@media screen and (max-width: $layout-s) {
  .lesson-sidebar .tab-content .lessons-list ul li.lesson-item ul li {
    width: 100%;
    max-width: 100%;
  }

  .lesson-sidebar .tab-content {
    width: 100%;
    margin-top: 0;
  }

  .lesson-sidebar .tab-content .lessons-list ul li.lesson-item ul li .lesson-info h3 {
    width: 100%;
    font-size: 12px;
  }

  .lesson-sidebar .tab-content .lessons-list ul li.lesson-item ul li {
    padding: 5px 7px;
  }

  .lesson-sidebar .tab-content .lessons-list ul .lesson-item-heading {
    font-weight: 500;
    padding: 10px;
    font-size: 14px;
  }
}

@media (max-width: $layout-s) {
  .lesson-info {
    width: 100%;
    overflow: auto;
  }

  .tab-container {
    margin: 0 0 0 -20px;
  }
}

@media (min-width: $layout-s) {
  .lesson-info {
    margin-left: 0;
    width: 100%;
    max-width: 200px;
  }
}
</style>
