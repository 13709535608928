<template>
  <modal
    :show="false"
    :closable="user.authModal.closable"
    :fluid="true"
    :noPadding="true"
    @close="closeAuthModal"
  >
    <div>
      <div class="auth-container">
        <div class="auth-form--container">
          <login-form v-if="user.authModal.state === 'LOGIN'" />
          <registration-form v-if="user.authModal.state === 'REG'" />
          <forgot-password-form
            v-if="user.authModal.state === 'FORGOT_PASSWORD'"
          />
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Modal from '@/components/Modal';
import LoginForm from '@/components/Auth/LoginForm';
import RegistrationForm from '@/components/Auth/RegistrationForm';
import ForgotPasswordForm from '@/components/Auth/ForgotPasswordForm';

export default {
  name: 'AuthModal',
  components: {
    Modal,
    LoginForm,
    RegistrationForm,
    ForgotPasswordForm,
  },
  props: {
    show: { type: Boolean, default: false },
  },
  computed: {
    ...mapGetters({
      user: 'getUser'
    })
  },
  methods: {
    ...mapActions(['closeAuthModal']),
  },
};
</script>

<style lang="scss" scoped>
.auth {
  &-container {
    width: 100%;
    display: flex;
    height: 100vh;

    @media (max-width: $layout-s) {
      flex-direction: column;
    }
  }
  &-image {
    width: auto;
    max-width: 50%;

    @media (max-width: $layout-s) {
      display: none;
    }

    &--mob {
      display: none;
      width: 100%;

      @media (max-width: $layout-s) {
        display: block;
      }
    }
  }
  &-form--container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
}
</style>
