<template>
  <section class="rate">
    <Modal :show="show">
      <div class="rate__container">
        <h3 class="rate__title">Оцените блок</h3>
        <p class="rate__subtitle">
          Оцените блок от 1 до 10 для улучшения качества видео роликов и
          материалов.
        </p>
        <div class="rate__slider">
          <p v-if="point <= 6" class="rate__icon">&#128564;</p>
          <p v-if="point === 7 || point === 8" class="rate__icon">&#128528;</p>
          <p v-if="point >= 9" class="rate__icon">&#128525;</p>
          <range-slider
            class="slider"
            min="1"
            max="10"
            step="1"
            v-model="point"
          >
            <template slot="knob">
              <div class="knobby">
                <p>
                  {{ point }}
                </p>
              </div>
            </template>
          </range-slider>
        </div>
        <textarea
          v-model="commentary"
          placeholder="Введите ваше мнение о блоке"
          class="rate__textarea"
        >
        </textarea>
      </div>
      <button @click="sendFeedback" class="rate__btn">Отправить</button>
    </Modal>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';
import trackAmplitudeEvent from '@/utils/trackAmplitudeEvent';
import Modal from '../../Modal';
import $globalFn from '../../../fn';
import trackAmplitudeError from '../../../utils/trackAmplitudeError';

export default {
  name: 'RateUnit',
  props: ['show', 'lessonId'],
  components: {
    Modal,
  },
  data() {
    return {
      point: 1,
      commentary: '',
    };
  },
  mounted() {},
  computed: {
    ...mapGetters({
      units: 'getUnits',
      course: 'getCurrentCourse',
    }),
    currentUnit() {
      let unit = null;
      this.units.forEach((el) => {
        const test = el.lessons.filter((a) => a.id === this.lessonId);
        if (test.length !== 0) {
          unit = el;
        }
      });
      return unit;
    },
  },
  methods: {
    async sendFeedback() {
      const userId = $globalFn.ls.get('userId');
      const data = {
        score: this.point,
        commentary: this.commentary,
        unitId: this.currentUnit.id,
        courseId: this.course,
        date: new Date(),
      };

      try {
        const res = await this.$api.units.sendUnitFeedback(data);

        if (res.status === 200) {
          trackAmplitudeEvent('feedback_send', userId, {
            lesson_id: this.currentUnit.id,
            course_id: this.course,
            score: this.point,
            date: new Date(),
          });
          this.$emit('send');
        }
      } catch (error) {
        trackAmplitudeError('feedback_send', userId, {
          error,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.rate {
  &__container {
    max-width: 620px;
  }

  &__title {
    font-size: 25px;
    margin-bottom: 20px;
  }

  &__subtitle {
    font-size: 20px;
    margin-bottom: 30px;
  }

  &__slider {
    display: flex;
    align-items: center;
  }

  &__icon {
    font-size: 30px;

    @media (max-width: $layout-s) {
      display: none;
    }
  }

  &__textarea {
    border-radius: 10px;
    margin-top: 40px;
    width: 100%;
    resize: none;
    height: 200px;
    padding: 20px;
    font-size: 20px;
  }

  &__btn {
    width: 100%;
    margin-top: 30px;
    background-color: $primary;
    border: none;
    height: 60px;
    color: #ffffff;
    border-radius: 15px;
    font-size: 20px;
    cursor: pointer;
  }
}

.slider {
  width: 100%;
  padding-left: 20px;

  @media (max-width: $layout-s) {
    padding: 0;
  }
}

.slider .range-slider-rail {
  height: 11px;
  background-color: #c4e4eb;
  border-radius: 5px;
}

.range-slider-fill {
  height: 3px;
  margin: 0 4px;
  background-color: #ff5f5f;
}

.slider .range-slider-knob {
  height: 22px;
  width: 22px;
  color: #fff;
  background-color: $primary;
  border: 0;
  box-shadow: 0 0 0 10px rgb(0 111 138 / 0.2);
}

.slider .knobby p {
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  color: #000000;
}

.knobby {
  position: absolute;
  top: 25px;
  left: 5px;
}
</style>
