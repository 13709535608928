<template>
  <div
    class="ui-tooltip"
    v-click-outside="clickOutside"
    @mouseenter="hoverHandler"
    @mouseout="hoverHandler"
    @click="clickHandler"
  >
    <div class="ui-tooltip-activator">
      <slot name="activator" :show="show" />
    </div>
    <div v-if="show" class="ui-tooltip-content" :class="{ centered: centered }">
      <slot :close="close" :open="open" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Tooltip',
  props: {
    onHover: { type: Boolean, default: false },
    centered: { type: Boolean, default: false },
  },
  data() {
    return {
      show: false,
    };
  },
  methods: {
    close() {
      this.show = false;
    },
    open() {
      this.show = true;
    },
    toggle() {
      setTimeout(() => {
        this.show = !this.show;
      });
    },
    hoverHandler() {
      if (this.onHover) {
        this.show = !this.show;
      }
    },
    clickHandler() {
      if (!this.onHover) {
        this.toggle();
      }
    },
    clickOutside() {
      if (!this.onHover) {
        this.close();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.ui-tooltip {
  display: inline-block;
  position: relative;
  &-content {
    display: block;
    position: absolute;
    z-index: 1;
    &.centered {
      left: 50%;
      transform: translate(-50%, 0);
    }
  }
}
</style>
