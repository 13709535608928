<!-- eslint-disable -->
<template>
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.4 0C5.5521 0 0 5.5521 0 12.4C0 19.2479 5.5521 24.8 12.4 24.8C19.2479 24.8 24.8 19.2479 24.8 12.4C24.8 5.5521 19.2479 0 12.4 0ZM18.1474 8.43303C17.9614 10.3933 17.1533 15.1518 16.7431 17.3476C16.5695 18.2776 16.2264 18.5886 15.8958 18.6186C15.1755 18.6847 14.6289 18.1422 13.9314 17.6855C12.8402 16.9704 12.2233 16.5251 11.1641 15.8265C9.93963 15.0195 10.7332 14.5762 11.4307 13.8518C11.6136 13.6617 14.786 10.7756 14.848 10.5142C14.8552 10.4811 14.8635 10.3592 14.7901 10.2951C14.7167 10.231 14.6103 10.2527 14.5338 10.2703C14.4243 10.2951 12.6811 11.4473 9.3031 13.7268C8.80813 14.0668 8.35967 14.2321 7.9577 14.2238C7.51543 14.2145 6.66397 13.9738 6.03053 13.7671C5.2545 13.515 4.63657 13.3806 4.6903 12.9528C4.7182 12.7296 5.0251 12.5013 5.61203 12.2677C9.22663 10.6929 11.6374 9.65443 12.8433 9.15223C16.2874 7.72003 17.0025 7.471 17.4695 7.46273C18.0544 7.45447 18.1949 7.93703 18.1474 8.43303Z"
      fill="black"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconTelegramFlat',
  props: ['color'],
};
</script>
<!-- eslint-enable -->
