<template>
  <div class="course-wrapper"></div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.course-wrapper {
  display: flex;
  width: 80%;
  justify-content: center;
  margin: 0 auto;
}
</style>
