<template>
  <div class="reg-form">
    <h2>Регистрация</h2>
    <form @submit.prevent="register">
      <form-input
        v-model="email"
        placeholder="Эл. почта"
        class="reg-form-input"
        :error-text="emailError"
        :error="$v.email.$error"
        @input="$v.email.$reset()"
      />
      <form-input
        v-model="password"
        type="password"
        placeholder="Пароль"
        class="reg-form-input"
        :error="$v.password.$error"
        :error-text="passwordError"
        @input="$v.password.$reset()"
      />
      <form-input
        v-model="rePassword"
        type="password"
        placeholder="Повторить пароль"
        class="reg-form-input"
        :error="$v.rePassword.$error"
        :error-text="rePasswordError"
        @input="$v.rePassword.$reset()"
      />
      <div class="reg-form-options">
        <div class="reg-form-registration" @click="switchToLoginAuthModal">
          Уже есть аккаунт?
        </div>
      </div>
      <form-button
        variant="primary"
        fluid
        :loading="loading"
        :disabled="loading"
      >
        Регистрация
      </form-button>
    </form>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { required, minLength, email } from 'vuelidate/lib/validators';
import FormInput from '../Input';
import FormButton from '../Button';

export default {
  name: 'RegistrationForm',
  components: { FormInput, FormButton },
  data() {
    return {
      email: '',
      password: '',
      phone: '',
      rePassword: '',
      emailError: '',
      passwordError: '',
      rePasswordError: '',
      loading: false,
    };
  },
  validations: {
    email: {
      email,
      required,
      minLength: minLength(4),
    },
    password: {
      required,
      minLength: minLength(8),
    },
    rePassword: {
      required,
      minLength: minLength(6),
      mustEqualPassword(value) {
        return value === this.password;
      },
    },
  },
  computed: {
    activeMask() {
      return this.$vars.phoneMasks[this.activeCountry.co.toUpperCase()];
    },
  },
  methods: {
    checkErrors() {
      if (!this.$v.email.required) {
        this.emailError = 'Поле должно быть заполнено';
      }
      if (!this.$v.email.email) {
        this.emailError = 'Почта указана в неверном формате';
      }
      if (!this.$v.password.required) {
        this.passwordError = 'Поле должно быть заполнено';
      }
      if (!this.$v.password.minLength) {
        this.passwordError = 'Должено содержать больше 6 символов';
      }
      if (!this.$v.rePassword.required) {
        this.rePasswordError = 'Поле должно быть заполнено';
      }
      if (!this.$v.rePassword.mustEqualPassword) {
        this.rePasswordError = 'Пароли должны совпадать';
      }
    },
    register() {
      this.$v.$touch();
      this.checkErrors();
    },
    changeCountry(country) {
      this.activeCountry = country;
    },
    ...mapActions(['switchToLoginAuthModal']),
  },
};
</script>

<style lang="scss" scoped>
.reg-form {
  width: 50%;
  h2 {
    font-weight: lighter;
    font-size: 18px;
    text-align: center;
  }
  form {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  &-forgot {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 30px;
    a {
      text-decoration: none;
      font-size: 14px;
      line-height: 53px;
      color: map_get($gray, 800);
    }
  }
  &-registration {
    text-align: center;
    margin-top: 8px;
    height: 36px;
    line-height: 36px;
    font-size: 14px;
    text-decoration: underline;
    color: $primary;
    user-select: none;
    cursor: pointer;
  }

  &-options {
    display: flex;
    justify-content: center;
  }
}

.server-error {
  color: #e74c3c;
  margin-bottom: 10px;
  font-size: 12px;

  ul {
    list-style-type: none;
  }
}

@media screen and (max-width: $layout-s) {
  .reg-form {
    width: 90%;
    height: 50%;
  }
}
</style>
