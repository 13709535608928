<template>
  <form @submit.prevent="setPassword">
    <h3 class="success" v-if="success">Пароль успешно изменён!</h3>
    <div class="row">
      <div class="col-25">
        <label for="psw">Пароль</label>
      </div>
      <div class="col-75">
        <div style="position: relative">
          <input
            type="password"
            placeholder="Введите новый пароль"
            v-model="newPassword"
            required
          />
          <i
            class="fa fa-eye"
            @click="setPasswordVisible"
            id="togglePassword_1"
          ></i>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-25">
        <label for="psw-repeat">Повторить пароль</label>
      </div>
      <div class="col-75">
        <div style="position: relative">
          <input
            type="password"
            placeholder="Повторите новый пароль"
            v-model="reNewPassword"
            required
          />
          <i
            class="fa fa-eye"
            @click="setPasswordVisible"
            id="togglePassword_2"
          ></i>
        </div>
      </div>
    </div>
    <div class="server-error" v-if="errors.length">
      <ul v-for="error in errors" :key="error">
        <li>{{ error }}</li>
      </ul>
    </div>
    <div class="requirements-list">
      <div class="requirements-list-item">
        <i
          v-if="$v.newPassword.minLength"
          class="fa fa-check"
          style="color: #54ce27"
        />
        <span v-else class="icon-x">&#10005;</span>
        <label for="">Минимум 8 символов</label>
      </div>
      <div class="requirements-list-item">
        <i
          v-if="$v.reNewPassword.sameAsPassword"
          class="fa fa-check"
          style="color: #54ce27"
        />
        <span v-else class="icon-x">&#10005;</span>
        <label for="">Пароли должны совпадать</label>
      </div>
      <div class="requirements-list-item">
        <i
          v-if="$v.newPassword.origin"
          class="fa fa-check"
          style="color: #54ce27"
        />
        <span v-else class="icon-x">&#10005;</span>
        <label for="">Отличный от 'password', 'password123', 'qwerty'</label>
      </div>
    </div>
    <div class="row action">
      <form-button variant="primary" :loading="loading" :disabled="loading">
        Установить пароль
      </form-button>
    </div>
  </form>
</template>

<script>
import { minLength, sameAs } from 'vuelidate/lib/validators';
import trackAmplitudeEvent from '@/utils/trackAmplitudeEvent';
import { mapActions } from 'vuex';
import { login } from '@/api/utils';
import FormButton from '../Button';
import $globalFn from '../../fn';
import trackAmplitudeError from '../../utils/trackAmplitudeError';

export default {
  name: 'PasswordChange',
  components: { FormButton },
  data() {
    return {
      loading: false,
      newPassword: '',
      reNewPassword: '',
      currentPassword: '',
      errors: [],
      success: false,
    };
  },
  validations: {
    currentPassword: {
      minLength: minLength(8),
    },
    newPassword: {
      minLength: minLength(8),
      origin: (value) => !['password', 'password123', 'qwerty'].includes(value),
    },
    reNewPassword: {
      sameAsPassword: sameAs('newPassword'),
    },
  },
  mounted() {
    const userId = $globalFn.ls.get('userId');

    trackAmplitudeEvent('password_change_page_opened', userId);
  },
  methods: {
    ...mapActions({
      closeAuthModal: 'closeFirstAuthModal',
    }),
    setPassword() {
      this.errors = [];
      this.$v.$touch();
      if (!this.$v.$error && !this.$v.$pending) {
        this.loading = true;
        this.$api.user
          .setPassword({
            data: {
              password: this.newPassword,
              custom_password_set: true,
            },
          })
          .then((response) => {
            this.newPassword = '';
            this.reNewPassword = '';
            this.currentPassword = '';
            this.success = true;
            const userId = $globalFn.ls.get('userId');
            login(response.data.tokens);
            trackAmplitudeEvent('password_change', userId);
            this.closeAuthModal();
          })
          .catch((error) => {
            const { data } = error.response;
            const userId = $globalFn.ls.get('userId');
            trackAmplitudeError('password_change', userId, { error });

            if ('error' in data) {
              this.errors.push(data.error);
            }
            if ('newPassword' in data) {
              this.errors.push(...data.newPassword);
            }
            if ('nonFieldErrors' in data) {
              this.errors.push(...data.nonFieldErrors);
            }
            if ('reNewPassword' in data) {
              this.errors.push(...data.reNewPassword);
            }
            if ('currentPassword' in data) {
              this.errors.push(...data.currentPassword);
            }
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    setPasswordVisible(e) {
      if (e.target.className === 'fa fa-eye') {
        e.target.className = 'fa fa-eye-slash';
      } else e.target.className = 'fa fa-eye';
      const inputElement = e.srcElement.parentElement.children[0];
      if (inputElement.type === 'password') {
        inputElement.type = 'text';
      } else inputElement.type = 'password';
    },
  },
};
</script>

<style lang="scss" scoped>
/* Full-width input fields */
.success {
  color: green;
}

input {
  width: 100%;
  padding: 15px;
  margin-top: 5px;
  display: inline-block;
  background: map-get($gray, 1000);
  border: none;
  border-radius: 10px;
}

input:focus {
  background-color: #ddd;
  outline: none;
}

.form-group {
  margin-bottom: 20px;
}

.error,
.form-group--error {
  color: #e74c3c;
}

.server-error {
  color: #e74c3c;
  margin-bottom: 10px;
}

.server-error ul {
  list-style-type: none;
}

.row {
  display: flex;
  align-items: center;
}

.row .action {
  justify-content: flex-end;
}

/* Clear floats after the columns */
.row:after {
  content: '';
  display: table;
  clear: both;
}

.col-25 {
  float: left;
  width: 25%;
  margin-top: 6px;
}

.col-75 {
  width: 75%;
  float: left;
  margin-top: 6px;

  .fa {
    &-check {
      margin-right: 4px;
    }

    &-eye,
    &-eye-slash {
      cursor: pointer;
      position: absolute;
      top: 35%;
      right: 10px;
    }
  }
}

button {
  margin-top: 20px;
  float: right;
  padding: 12px 20px;
}

label {
  padding: 0;
  display: inline-block;
  color: rgba(0, 0, 0, 0.15);
}

.requirements-list {
  margin-top: 12px;

  &-item {
    background: none;
  }

  .icon-x {
    color: #eb370a;
    margin-right: 4px;
  }
}

/* Responsive layout - when the screen is less than 600px wide,
make the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: $layout-s) {
  .col-25,
  .col-75 {
    margin-top: 0px;
    width: 100%;
  }

  input {
    width: 80%;
    text-overflow: ellipsis;
  }

  button {
    width: 100%;
    margin-top: 20px;
  }
}
</style>
